import React from 'react';
import { appLinks } from '../../../data/enums/appLinks';
import RoundButtonIcon from '../../Buttons/RoundButtonIcon/RoundButtonIcon';
import styles from './experiencecard.module.scss';

const ExperienceCard = ({ card }) => {
    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <div className={styles.bar}></div>
                <div className={styles.logo}>
                    <img src={card.logo} alt="" />
                </div>
            </div>
            <div className={styles.title}>{card.title}</div>
            <div className={styles.description}>{card.textContent}</div>
            <div className={styles.btn}>
                <RoundButtonIcon
                    textContent={'En lire plus'}
                    bgColor={'smallBasic'}
                    link={appLinks.experiences.index}
                />
            </div>
        </div>
    );
};

export default ExperienceCard;
