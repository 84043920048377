import React from 'react';
import styles from './loader.module.scss';

const Loader = ({ text = 'Chargement...' }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <div className={styles.loader}></div>
            <div>{text}</div>
        </div>
    );
};

export default Loader;
