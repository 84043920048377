import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSSOURL } from '../utils/sso';

const useAuthenticated = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [userId, setUserId] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (authenticated) return;
        if (userId) return;
        if (loading) return;
        if (email) return;

        setLoading(true);

        fetch('/auth/check')
            .then((res) => {
                switch (res.status) {
                    case 200:
                        if (localStorage.getItem('lastKnownUrl')) {
                            navigate(localStorage.getItem('lastKnownUrl'));
                            localStorage.removeItem('lastKnownUrl');
                        }
                        setAuthenticated(true);
                        return res.json();
                    case 440:
                        window.location.href = getSSOURL('login');
                        localStorage.setItem('lastKnownUrl', `${window.location.pathname}${window.location.search}`);
                        sessionStorage.removeItem('userId');
                        setAuthenticated(false);
                        break;
                    default:
                        setAuthenticated(false);
                        break;
                }
            })
            .then((data) => {
                if (data.userId) {
                    sessionStorage.setItem('userId', data.userId);
                    setUserId(data.userId);
                }
                if (data.email) setEmail(data.email);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [navigate, authenticated, loading, userId, email]);

    return [authenticated, userId, email];
};

export default useAuthenticated;
