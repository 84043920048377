import inoLogo from '../../img/INOKUFU.png';
import jobLogo from '../../img/jobready.png';
import orienLogo from '../../img/ORIENTOI.png';
import time from '../../img/ICONE-SABLIER.svg';
import pers from '../../img/ICONE-PERSONNE.svg';
import starP from '../../img/ICONE-ETOILE1.svg';
import star from '../../img/ICONE-ETOILE2.svg';
import aide from '../../img/AIDE.svg';
import iconeData from '../../img/ICONE-DATA.svg';
import iconeCozy from '../../img/cozy-logo.png';

export const classifications = {
    NOUVEAUTES: 0,
    TOPVISIONS: 1,
    DERNIERES: 2,
    ORIENTATION: 3,
    STAGE: 4,
    COMPETENCES: 5,
    EMPLOI: 6,
    AUTRE: 7,
};

// const DATATYPE_TEMPLATE = {
//     _id: { $oid: '61fd2f3b2c664a75582ba4e2' }, // Id du datatype dans la db
//     name: 'Badges',
//     description: 'Badges de compétences de la personne après la discussion avec le chatbot',
//     provenance: { $oid: '61fd144b654ef1210c9c484b' }, // JObreay
// };

export const services = [
    {
        name: 'Orientoi',
        logo: 'http://visionspol.eu/wp-content/uploads/2022/02/LOGO-ORIENTOI.jpg',
        description:
            "Orientoi, l'outil d'aide à l'orientation scolaire et professionnelle. Et si on s'orientait par le jeu ? Orientoi a développé une solution ludique qui permet à chacun de construire son projet d'orientation de manière ludique, positive et amusante.",
        time: '20 minutes',
        timelogo: time,
        classification: classifications.ORIENTATION,
        minilogo: orienLogo,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [
            {
                name: 'Traits de personnalités', // Nom du type de donnée (nom de la donnée)
                description: 'Mise en lumière de vos talents et propositions de secteurs et métiers', // Texte descriptif sur la donnée
                light: true,
            },
            {
                name: 'Positionnement métiers',
                description: '',
                light: false,
            },
        ],
    },
    {
        name: 'JobReady',
        logo: 'http://visionspol.eu/wp-content/uploads/2021/09/jobready-logo.jpg',
        description:
            "Révèle la force qui est en toi ! Pour avoir confiance en toi et décrocher ton prochain job, il faut connaître toutes tes forces ! 💪 Jobready, c'est la seule plateforme qui transforme toutes tes expériences, professionnelles, personnelles, bénévoles, scolaires... en compétences claires et visibles pour les recruteurs.",
        time: '20 minutes',
        timelogo: time,
        classification: classifications.ORIENTATION,
        minilogo: jobLogo,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Expérience',
        datatypes: [
            {
                name: 'Soft skills',
                description: 'Détermination de vos Soft skills',
                light: true,
            },
        ],
    },
    {
        name: 'Inokufu',
        logo: 'http://visionspol.eu/wp-content/uploads/2022/02/logo-INOKUFU.jpg',
        description:
            "La recherche de ressources pédagogiques sur le web est pleine de défis et de distractions. Il n'est pas toujours facile de repérer les fake news et les contenus publicitaires. Notre technologie vous fournit uniquement des ressources d'apprentissage vérifiées et sûres. Actuellement, nous vous donnons accès à de nombreux formats dont des vidéos, des moocs, des podcasts, des livres, des ebooks, des formations, etc, tout ça dans votre boîte mail.",
        time: '20 minutes',
        // classification: classifications.ORIENTATION,
        minilogo: inoLogo,
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [
            {
                name: 'Positionnements formations',
                description: 'Découverte de ressources éducatives',
                light: true,
            },
        ],
    },
    {
        name: 'Study Advisor',
        logo: 'https://www.jaimelesstartups.fr/wp-content/uploads/2017/10/logo-studyadvisor.png',
        classification: classifications.NOUVEAUTES,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [],
    },
    {
        name: '5discovery',
        logo: 'https://www.5discovery.com/wp-content/uploads/2018/03/logo-5discovery.png',
        classification: classifications.NOUVEAUTES,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [],
    },
    {
        name: 'Skilit',
        logo: 'https://www.frenchtechbordeaux.com/wp-content/uploads/2021/05/logoskilit.png',
        // classification: classifications.NOUVEAUTES,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        datatypes: [],
    },
    {
        name: 'Pitangoo',
        logo: 'https://pitangoo.com/wp-content/uploads/2019/02/Pitangoo-baseline-4.png',
        classification: classifications.EMPLOI,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [],
    },
    {
        name: 'Trouve Ta Voie',
        logo: 'https://static.wixstatic.com/media/7c6ec6_73cb60907da44ba3a52e2d967378ab7f~mv2.png/v1/fill/w_416,h_70,al_c,q_85,usm_0.66_1.00_0.01/TTVnewlogog.webp',
        classification: classifications.EMPLOI,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [],
    },
    {
        name: 'Palm',
        logo: 'https://media-exp1.licdn.com/dms/image/C4D16AQEdvuANFWPnpA/profile-displaybackgroundimage-shrink_200_800/0/1614558238286?e=1649289600&v=beta&t=wSgvHXTNhATGO2Zw5p8v2l8DJejf37955QbSzxgUfMY',
        // classification: classifications.EMPLOI,
        description:
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus hic totam enim eligendi omnis aspernatur adipisci itaque consequatur eveniet. Velit aliquid, cum minima doloremque esse consequuntur sapiente? Nam, deserunt dicta?',
        time: '20 minutes',
        timelogo: time,
        pers: pers,
        star: star,
        starP: starP,
        type: 'Edtechs',
        tag: 'Réalité virtuelle',
        datatypes: [],
    },
];

export const experiences = {
    orientation: [
        services[0],
        {
            name: 'Cozy',
            logo: 'https://media-exp1.licdn.com/dms/image/C4D16AQEdvuANFWPnpA/profile-displaybackgroundimage-shrink_200_800/0/1614558238286?e=1649289600&v=beta&t=wSgvHXTNhATGO2Zw5p8v2l8DJejf37955QbSzxgUfMY',
            classification: classifications.TOPVISIONS,
            description: 'lorem',
            time: '20 minutes',
            timelogo: time,
            pers: pers,
            star: star,
            starP: starP,
            type: 'Edtechs',
            datatypes: [
                {
                    name: 'Cloud',
                    description: 'Stockez et partager vos données',
                    light: true,
                },
            ],
        },
        services[1],
        services[2],
    ],
    bilanservice: [
        services[0],
        services[1],
        services[2],
        {
            name: 'Cozy',
            logo: iconeCozy,
            classification: classifications.TOPVISIONS,
            description: 'lorem',
            time: '20 minutes',
            timelogo: time,
            pers: pers,
            star: star,
            starP: starP,
            type: 'Edtechs',
            datatypes: [
                {
                    name: 'Cloud',
                    description: 'Stockez et partager vos données',
                    light: true,
                },
            ],
        },
    ],
};

export const objectif = [
    {
        name: 'Orientation',
        logo: aide,
        link: '/user/orientation',
    },
    {
        name: 'Stage',
        logo: aide,
        link: '',
    },
    {
        name: 'Compétences',
        logo: aide,
        link: '',
    },
    {
        name: 'Emploi',
        logo: aide,
        link: '',
    },
];

export const bilan = [
    {
        name: "Bilan d'Orientation",
        logo: iconeData,
        text: 'Nous te proposons de réaliser un bilan d’orientation 100% en ligne et gratuit, qui tient compte de ta singularité et la valorise pour t’aider à trouver ta voie.',
        timelogo: time,
        time: '30 minutes',
        peoplelogo: pers,
        people: 'utilisé par 15 personnes',
        star: star,
    },
    {
        name: 'Bilan de RéOrientation',
        logo: iconeData,
        text: 'Tu vas découvrir les formations liés aux métiers de ton choix. Tu pourras comparer tes soft skills et ceux des offres d’emplois en autre.',
        timelogo: time,
        time: '30 minutes',
        peoplelogo: pers,
        people: 'utilisé par 15 personnes',
        star: star,
    },
];
