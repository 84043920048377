import { appImageAssets } from '../../data/enums/appImageAssets';

/**
 * The keys representing the different selectable avatars
 * when modifying, add more rows but never swap existing keys
 * this const is the only fixed data where the associations of
 * numbers - avatars is explicit so it should be altered carefully
 */
export const AVATAR_DATA = {
    0: appImageAssets.avatar.animal,
    1: appImageAssets.avatar.animalV2,
    2: appImageAssets.avatar.men,
    3: appImageAssets.avatar.women,
};
