import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navbar.module.scss';

import { eventLogNavigation, storeEventLog } from '../../utils/api/eventLogs';
import { useAuth } from '../../hooks/useAuth';

const NavBarContent = ({ icon, tab, activeTab, selectFc = null, redirectUrl, name }) => {
    const navigate = useNavigate();
    const { user } = useAuth();

    const menuRef = useRef(null);

    let activeClass = '';
    if (tab === activeTab) {
        activeClass = styles.active;
    }

    const handleClick = async (selectedTab) => {
        if (selectFc) selectFc();
        storeEventLog(user._id, eventLogNavigation[selectedTab], 'completed');
        if (redirectUrl) navigate(redirectUrl);
    };

    return (
        <>
            <li className={`${styles.list} ${activeClass}`} onClick={(e) => handleClick(tab)}>
                {icon && (
                    <span className={styles.icon}>
                        <img src={icon} alt="" />
                    </span>
                )}
                {name !== 'burgerMenu' && <p>{name}</p>}
                {name === 'burgerMenu' && (
                    <button ref={menuRef} className={styles.menuToggler}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                )}
            </li>
        </>
    );
};

export default NavBarContent;
