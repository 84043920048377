import React from 'react';
import styles from './usergrademodel.module.scss';

// ICON
const star = 'fa-solid fa-star';

const UserGradeModel = ({ gradeValue, hover, currentGrade, handleClick, setHover }) => {
    return (
        <label htmlFor="rating">
            <input className={styles.radio} type="radio" name="rating" value={gradeValue} />
            <i
                className={`${styles.star} ${star}`}
                onClick={() => handleClick(gradeValue)}
                onMouseEnter={() => setHover(gradeValue)}
                onMouseOut={() => setHover(null)}
                style={{ color: gradeValue <= (hover || currentGrade) ? '#21bbee' : '#18243f' }}
            ></i>
        </label>
    );
};

export default UserGradeModel;
