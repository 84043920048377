import React from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../../Components/Loaders/Loader/Loader';
import { appLinks } from '../../data/enums/appLinks';
import { useAuth } from '../../hooks/useAuth';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import useQuery from '../../hooks/useQuery';
import { serverCall } from '../../utils/serverAPICall';

const SSO = () => {
    const navigate = useNavigate();
    const { params } = useQuery();
    const { login } = useAuth();
    const ssoToken = params.get('ssoToken');

    useEffectOnce(() => {
        const doLogin = async () => {
            try {
                const data = await serverCall.POST(`/auth/sso/${ssoToken}`);

                if (!data || !data.user) return;

                login(data.user.id).then(() => {
                    // TMP FOR BO
                    if (localStorage.getItem('isLoggingFromBO') === 'true') {
                        localStorage.removeItem('isLoggingFromBO');
                        window.location.href = appLinks.experiences.bilan_orientation;
                    } else if (localStorage.getItem('isLoggingFromREO') === 'true') {
                        localStorage.removeItem('isLoggingFromREO');
                        window.location.href = appLinks.experiences.bilan_reorientation;
                    } else {
                        window.location.href = appLinks.home;
                    }
                });
            } catch (err) {
                console.error(err);
                navigate(appLinks.auth.error);
            }
        };

        doLogin();
    });

    return <Loader text="Authentification en cours..." />;
};

export default SSO;
