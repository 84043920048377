import React, { useState } from 'react';
import NavBarContent from './NavBarContent';
import styles from './navbar.module.scss';

import useEventLogLocation from '../../hooks/useEventLogLocation';

import { appLinks } from '../../data/enums/appLinks';
import { appImageAssets } from '../../data/enums/appImageAssets';
import FullScreenMenu from './FullScreenMenu';
import { useNavigate } from 'react-router';

const NavBar = ({ tabActive }) => {
    useEventLogLocation();
    const navigate = useNavigate();

    const [fullScreenMenuOpened, setFullScreenMenuOpened] = useState(false);
    const closeFullScrennMenu = () => {
        setFullScreenMenuOpened(false);
    };

    return (
        <React.Fragment>
            <div className={`${styles.navigation}`}>
                <div className={styles.container}>
                    <ul className={styles.content}>
                        <NavBarContent
                            icon={appImageAssets.icon.missions}
                            tab={1}
                            activeTab={tabActive}
                            redirectUrl={appLinks.missions.index}
                            name={'Mes missions'}
                        />
                        <NavBarContent
                            icon={appImageAssets.icon.explore}
                            tab={2}
                            activeTab={tabActive}
                            redirectUrl={appLinks.catalog.index}
                            name={"J'explore"}
                        />
                        <NavBarContent
                            icon={appImageAssets.icon.rewards}
                            tab={3}
                            activeTab={tabActive}
                            redirectUrl={appLinks.rewards.index}
                            name={'Récompenses'}
                        />
                        <NavBarContent
                            icon={appImageAssets.icon.matchs}
                            tab={4}
                            activeTab={tabActive}
                            redirectUrl={appLinks.matchs}
                            name={'Mes matchs'}
                        />
                        <NavBarContent
                            tab={5}
                            activeTab={tabActive}
                            name={'burgerMenu'}
                            selectFc={() => setFullScreenMenuOpened(true)}
                        />
                    </ul>
                </div>
            </div>
            <FullScreenMenu opened={fullScreenMenuOpened} closeMenuFc={closeFullScrennMenu}>
                <li onClick={() => navigate(appLinks.share)}>Mes données</li>
                <li onClick={() => navigate(appLinks.profile)}>Profil</li>
                <li onClick={() => navigate(appLinks.auth.logout)}>Déconnexion</li>
            </FullScreenMenu>
        </React.Fragment>
    );
};

export default NavBar;
