import React from 'react';
import BarGradient from '../../Bars/BarGradient/BarGradient';
import styles from './TitleWithIcon.module.scss';

const TitleWithIcon = ({
    icon,
    sx = {},
    customTagTitle = 'h3',
    darkenImg = false,
    widthBarGradient = '115%',
    children,
}) => {
    const CustomTag = customTagTitle;
    return (
        <div style={{ ...sx }} className={styles.container}>
            <div className={styles.title}>
                {icon && <img src={icon} alt="" className={darkenImg ? styles.darken : ''} />}
                <CustomTag>{children}</CustomTag>
            </div>
            <BarGradient sx={{ margin: '10px 0', width: widthBarGradient }} />
        </div>
    );
};

export default TitleWithIcon;
