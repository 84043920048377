import { appImageAssets } from '../enums/appImageAssets';
import { appLinks } from '../enums/appLinks';

export const linkCards = {
    cozy: {
        img: appImageAssets.icon.cozy,
        textContent: 'Retrouver toutes mes données',
        link: appLinks.share,
        eventLog: 'orientation_report',
        buttonText: 'En savoir +',
        name: 'Cozy',
    },
    meet: {
        img: appImageAssets.icon.meeting,
        textContent: 'Me faire accompagner par un conseiller',
        link: appLinks.meeting,
        eventLog: 'generate_orientation_report',
        buttonText: 'Prendre un RDV',
        name: 'Prendre un rendez-vous',
    },
    event: {
        img: appImageAssets.icon.event,
        textContent: 'Découvrez les événements à venir que nos partenaires et nous organisons',
        link: appLinks.events,
        eventLog: '',
        buttonText: 'Voir les événements',
        name: 'évènements',
    },
    game: {
        img: appImageAssets.icon.tinder,
        textContent: 'Trouver des outils pour vous aider à atteindre vos objectifs à travers un jeu',
        link: appLinks.visionsSwipe,
        eventLog: 'game',
        buttonText: 'Commencer',
        name: 'jeux',
    },
    coach: {
        img: appImageAssets.icon.coach,
        textContent: "Profitez d'une session de coaching gratuite avec l'un des 100 coachs partenaires de notre réseau",
        eventLog: '',
        buttonText: 'Je postule',
        name: 'coach',
    },
};
