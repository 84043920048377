import { Navigate, Outlet } from 'react-router-dom';
import { ContextSetter } from '../context/ContextSetter';
import { useAuth } from '../hooks/useAuth';

export const HomeLayout = () => {
    const { user } = useAuth();

    if (user) {
        return <Navigate to="/user/missions" />;
    }

    return (
        <ContextSetter>
            <Outlet />
        </ContextSetter>
    );
};
