import React from 'react';
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';
import GlobalError from '../GlobalError/GlobalError';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <>
                    <GlobalError></GlobalError>
                    <GenericBtn
                        textContent={"Retoruner à l'accueil"}
                        onClickFc={() => {
                            window.location.href = '/';
                        }}
                    />
                </>
            );
        }

        return this.props.children;
    }
}
