import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonMainCardDetails = ({ rewards = false }) => {
    return (
        <Skeleton parent rounded={20} parentSx={{ width: '90%', padding: !rewards && 10 }}>
            {!rewards && (
                <div style={{ display: 'flex', marginBottom: 10 }}>
                    <Skeleton width={90} height={90} rounded={10} sx={{ marginRight: 5 }}></Skeleton>
                    <div>
                        <Skeleton width={150} height={30} sx={{ margin: 5 }}></Skeleton>
                        <Skeleton width={80} height={30} sx={{ margin: 5 }}></Skeleton>
                    </div>
                </div>
            )}
            {rewards && <Skeleton height={220} sx={{ marginBottom: 10 }}></Skeleton>}
            <div style={{ padding: rewards && '0 10px' }}>
                {!rewards && <Skeleton height={80} sx={{ margin: 5 }}></Skeleton>}
                <Skeleton width={'auto'} height={50} sx={{ margin: 5 }}></Skeleton>
                <Skeleton width={'auto'} variant={'text'} sx={{ margin: 5 }}></Skeleton>
                <Skeleton width={'auto'} height={80} sx={{ margin: 5 }}></Skeleton>
                <Skeleton width={200} height={40} rounded={30} sx={{ margin: '5px auto' }}></Skeleton>
            </div>
        </Skeleton>
    );
};

export default SkeletonMainCardDetails;
