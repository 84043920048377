import React from 'react';

// Style & Img/Icon
import styles from './descriptionexperience.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { KNOWN_CONTEXTS } from '../../../libs/experiences/context';
import { getSpecificContextExperienceData } from '../../../data/context/experienceSpecificData';

const DescriptionExperience = ({ experience, context }) => {
    let imageExperience;
    switch (experience?.name) {
        case "Bilan d'Orientation":
            imageExperience = appImageAssets.icon.BO;
            break;
        case 'Bilan de Réorientation':
            imageExperience = appImageAssets.icon.BO;
            break;
        default:
            break;
    }

    /**
     * Verifies if the context is set and if so returns
     * the context specific data for the said key
     * @param {string} key The key to check
     */
    const contextDataOrExperienceData = (key) => {
        if (context === KNOWN_CONTEXTS.numerique) {
            return getSpecificContextExperienceData(context)[key] || experience[key];
        }
        return experience[key];
    };

    return (
        <div className={styles.card}>
            <div className={styles.logo}>
                <img
                    src={experience?.image === 'default.jpg' ? imageExperience : experience?.image}
                    alt={`icone ${experience?.name}`}
                />
            </div>
            <div className={styles.content}>
                <h3 className={styles.title}>{experience?.name}</h3>
                <div className={styles.information}>
                    <div className={styles.containerIconTime}>
                        <img src={appImageAssets.icon.timer} alt="Icone sablier" />
                    </div>
                    <div className={styles.time}>
                        <span>Temps estimé</span>
                        <span>{contextDataOrExperienceData('timeEstimation')} min</span>
                    </div>
                    <div className={styles.stars}>
                        <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                        <span>{experience?.visionsStars} VisionsStars</span>
                    </div>
                </div>
                <p className={styles.description}>{contextDataOrExperienceData('description')}</p>
            </div>
        </div>
    );
};

export default DescriptionExperience;
