import React from 'react';
import { useNavigate } from 'react-router-dom';

import { storeEventLog } from '../../../utils/api/eventLogs';

import './roundbuttonicon.scss';

const defaultExtras = {
    status: 'completed',
    resource: '',
};

const RoundButtonIcon = ({
    bgColor = 'gradient',
    arrowColor = 'gradient',
    textContent,
    hasArrow = true,
    type,
    link,
    onClickFc,
    scope,
    extras = {},
}) => {
    const userId = sessionStorage.getItem('userId');
    const navigate = useNavigate();

    const callBackOrRedirect = () => {
        if (userId && extras.activity)
            storeEventLog(
                userId,
                extras.activity,
                extras.status ? extras.status : defaultExtras.status,
                extras.resource ? extras.resource : defaultExtras.resource,
            );

        if (link) {
            navigate(link);
        } else {
            if (onClickFc) onClickFc();
        }
    };

    let classBg;
    switch (bgColor) {
        case 'gradient':
            classBg = 'btnGradient';
            break;
        case 'smallGradient':
            classBg = 'btnSmallGradient';
            break;
        case 'smallBasic':
            classBg = 'btnSmallBasic';
            break;
        case 'basic':
            classBg = 'btnBasic';
            break;
        case 'white':
            classBg = 'btnWhite';
            break;
        default:
            classBg = 'btnGradient';
            break;
    }

    const classArrow = arrowColor === 'gradient' ? 'arrowGradient' : 'arrowBasic';

    const arrowClasses = 'circle ' + classArrow;

    return (
        <button ref={scope} className={`${classBg}`} type={type} onClick={() => callBackOrRedirect()}>
            <p>{textContent}</p>
            {hasArrow && (
                <div className={arrowClasses}>
                    <i className="fas fa-arrow-right"></i>
                </div>
            )}
        </button>
    );
};

export default RoundButtonIcon;
