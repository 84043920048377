import React from 'react';
import styles from './skeleton.module.scss';

const Skeleton = ({
    variant = 'rectangular',
    animation = 'wave',
    width,
    height,
    rounded,
    parent = false,
    LightBgParent = false,
    parentSx = {},
    sx = {},
    children,
}) => {
    const displayVariantSkeleton = (variant) => {
        switch (variant) {
            case 'rectangular':
                return styles.rectangular;
            case 'circular':
                return styles.circular;
            case 'text':
                return styles.text;
            default:
                return '';
        }
    };

    const displayAnimationSkeleton = (animation) => {
        switch (animation) {
            case 'pulse':
                return styles.pulse;
            case 'wave':
                return styles.wave;
            default:
                return '';
        }
    };
    return (
        <div
            className={`${styles.skeleton} ${parent ? styles.parent : ''} ${LightBgParent ? styles.lightBg : ''}`}
            style={{ borderRadius: parent && rounded, width: parent && '100%', ...parentSx }}
        >
            <div
                className={`${displayVariantSkeleton(variant)} ${displayAnimationSkeleton(animation)} `}
                style={{
                    width: width,
                    height: height,
                    borderRadius: rounded,
                    overflow: parent && 'visible',
                    backgroundColor: parent && 'transparent',
                    ...sx,
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default Skeleton;
