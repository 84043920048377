import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches services from VisionsGalaxy
 * @returns An array of 3 elements [error, loading, services]
 */
export const useServices = ({ filter = 'all', isPublic = false, isSample = 'false' }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const getServices = async () => {
            try {
                const url = isPublic
                    ? `/public/services?filter=${filter}&isSample=${isSample}`
                    : `/services?filter=${filter}&isSample=${isSample}`;
                const response = await client.GET(url);
                if (!isMounted) return;
                if (!response) {
                    throw new Error('Could not retrieve services');
                }

                setServices(response);
            } catch (err) {
                setError(true);
                setServices([]);
            } finally {
                setLoading(false);
            }
        };

        getServices();

        return () => {
            isMounted = false;
        };
    }, [client, filter, isPublic, isSample]);

    return [error, loading, services];
};
