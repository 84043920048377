import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './contentcardexperience.module.scss';

import { appLinks } from '../../data/enums/appLinks';

// TMP DATA
import { experiences } from '../../data/tmp/dataExperience';
import GenericBtn from '../Buttons/GenericBtn/GenericBtn';

const ContentCardExperience = ({ children, name, resizeLogo = false }) => {
    const navigate = useNavigate();
    const resize = resizeLogo ? styles.resize : '';
    const content = experiences[name];

    const experienceRedirect = () => navigate(`${appLinks.experiences.index}/${content.slug}`);

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img src={content?.logo} alt="" className={resize} />
                </div>
                <div className={styles.title}>{content?.title}</div>
                <div className={styles.text}>{content?.textContent}</div>
                {content?.subTextContent && <p className={styles.text}>{content.subTextContent}</p>}
                <div>{children}</div>
                {name === 'orientation' && (
                    <div className={styles.jobsInTension}>
                        <div className={styles.icon}>
                            <i className="fa-solid fa-handshake-simple"></i>
                        </div>
                        <p>Proposition de métiers en tension, d'offres de formation et d'emploi</p>
                    </div>
                )}
            </div>
            <div className={styles.btn}>
                <GenericBtn textContent={"Lancer l'expérience"} bgColor={'gradient'} onClickFc={experienceRedirect} />
            </div>
        </div>
    );
};

export default ContentCardExperience;
