import React, { useState } from 'react';
import styles from './RewardsPage.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import ServiceCard from '../../../Components/Cards/ServiceCard/ServiceCard';
import DataNotFound from '../../../Components/ContentUnavailable/DataNotFound';
import Loader from '../../../Components/Loaders/Loader/Loader';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
// import RowVisionsStars from '../../../Components/Row/RowVisionsStars/RowVisionsStars';
import useVisionsStars from '../../../hooks/useVisionsStars';
import AllRewardsUsed from '../../../Components/NoData/AllRewardsUsed/AllRewardsUsed';
import PurchaseReward from '../../../Components/PurchaseReward/PurchaseReward';
import InfoCardUserStars from '../../../Components/Cards/InfoCard/InfoCardUserStars/InfoCardUserStars';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';

import { appLinks } from '../../../data/enums/appLinks';
import { appInfoRewardByType } from '../../../data/enums/appInfoRewards';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import RewardCard from '../../../Components/Cards/RewardCard/RewardCard';
import SkeletonInfoUserVisionsStars from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonInfoUserVisionsStars/SkeletonInfoUserVisionsStars';
import SkeletonServiceCard from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonServiceCard/SkeletonServiceCard';
import SkeletonRecapPurchaseRewards from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonRecapPurchaseRewards/SkeletonRecapPurchaseRewards';
import SkeletonGenericCard from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonGenericCard/SkeletonGenericCard';
import { useServices } from '../../../hooks/useServices';
import { useRewards } from '../../../hooks/useRewards';
import { useVisionsGalaxyData } from '../../../hooks/useVisionsGalaxyData';

const noRewards = 'Pas de récompense disponible';
const noAward = 'Vous pourrez retrouver ici vos futures récompenses';

const RewardsPage = () => {
    // Fetch Data for rewards, purchasesRewards & topVisions
    const [topVisionsError, topVisionsLoading, topVisionsData] = useServices({
        filter: 'top',
        isSample: 'true',
        isPublic: true,
    });
    const [rewardsError, rewardsLoading, rewardsData] = useRewards({ sort: true });
    const [purchasesError, purchasesLoading, purchasesData] = useVisionsGalaxyData({ path: '/rewards/purchased' });
    const [visionsStarsError, visionsStarsLoading, visionsStars] = useVisionsStars();

    const [curentOffsetValue, setCurrentOffsetValue] = useState(10);
    const [hasSetCurrentOffsetValue, setHasSetCurrentOffsetValue] = useState(false);

    const updateCardWidth = (width) => {
        if (hasSetCurrentOffsetValue) return;
        if (width * 2 === curentOffsetValue) return;
        setCurrentOffsetValue(width * 2);
        setHasSetCurrentOffsetValue(true);
    };

    return (
        <>
            <HeaderWithNavBar tabActive={3} />
            <div className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.cardInfo}>
                        {visionsStarsLoading && <SkeletonInfoUserVisionsStars moreInfo />}
                        {!visionsStarsLoading && !visionsStarsError && visionsStars !== undefined && (
                            <InfoCardUserStars totalStars={visionsStars} />
                        )}
                    </div>
                    <div className={styles.summary}>
                        <TitleBgCard column={true} icon={fontAwesome.gift} title={'vos récompenses'}>
                            {purchasesLoading && <SkeletonRecapPurchaseRewards />}
                            {purchasesError && (
                                <TextContainer>
                                    Une erreur est survenue <i className={fontAwesome.danger}></i>
                                </TextContainer>
                            )}
                            {!purchasesError &&
                                !purchasesLoading &&
                                purchasesData &&
                                purchasesData.rewards &&
                                purchasesData.rewards.length === 0 && <AllRewardsUsed textContent={noAward} />}
                            {!purchasesError &&
                                !purchasesLoading &&
                                purchasesData &&
                                purchasesData.rewards &&
                                purchasesData.rewards.length > 0 &&
                                purchasesData.rewards.map((item, index) => (
                                    <PurchaseReward key={index} reward={item.reward} transaction={item.transaction} />
                                ))}
                        </TitleBgCard>
                    </div>
                    <CardBgBottom>
                        <h3 className={styles.titleRewards}>Toutes les Récompenses disponibles</h3>
                        <div
                            className={styles.allRewards}
                            style={{ justifyContent: rewardsData?.rewards?.length === 1 && 'flex-start' }}
                        >
                            {rewardsLoading && <SkeletonGenericCard />}
                            {rewardsError && (
                                <TextContainer>
                                    Une erreur est survenue <i className={fontAwesome.danger}></i>
                                </TextContainer>
                            )}
                            {rewardsData?.rewards?.length === 0 && <AllRewardsUsed textContent={noRewards} />}
                            {rewardsData?.rewards &&
                                rewardsData.rewards.length > 0 &&
                                rewardsData.rewards.map((reward, index) => {
                                    if (
                                        (reward.endAt && new Date(reward?.endAt) < new Date()) ||
                                        (reward.startAt && new Date(reward?.startAt) > new Date())
                                    )
                                        return null;
                                    return (
                                        <RewardCard
                                            key={index}
                                            reward={reward}
                                            icon={appInfoRewardByType(reward.type).icon}
                                            updateCardWidth={updateCardWidth}
                                            mainTag={appInfoRewardByType(reward.type).mainTag}
                                            link={`${appLinks.rewards.details}/${reward._id}`}
                                        />
                                    );
                                })}
                        </div>
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.cardInfo}>
                        {visionsStarsLoading && <Loader />}
                        {!visionsStarsLoading && visionsStarsError && (
                            <TextContainer>
                                Une erreur est survenue <i className={fontAwesome.danger}></i>
                            </TextContainer>
                        )}
                        {!visionsStarsLoading && !visionsStarsError && visionsStars && (
                            <InfoCardUserStars
                                totalStars={visionsStars}
                                title={'Retrouvez ici les récompenses'}
                                text={'Que vous pouvez échanger contre vos visionsstars'}
                            />
                        )}
                    </div>
                    <div className={styles.summary}>
                        <TitleBgCard column={true} icon={fontAwesome.gift} title={'vos récompenses'}>
                            {purchasesLoading && <SkeletonRecapPurchaseRewards />}
                            {purchasesError && (
                                <TextContainer>
                                    Une erreur est survenue <i className={fontAwesome.danger}></i>
                                </TextContainer>
                            )}
                            {!purchasesError &&
                                !purchasesLoading &&
                                purchasesData &&
                                purchasesData.rewards &&
                                purchasesData.rewards.length === 0 && <AllRewardsUsed textContent={noAward} />}
                            {!purchasesError &&
                                !purchasesLoading &&
                                purchasesData &&
                                purchasesData.rewards &&
                                purchasesData.rewards.map((item, index) => (
                                    <PurchaseReward key={index} reward={item.reward} transaction={item.transaction} />
                                ))}
                        </TitleBgCard>
                    </div>
                    {/* TOP VISIONS */}
                    <div className={styles.topVisions}>
                        <TitleBgCard icon={fontAwesome.star} title={'Top VisionsGalaxy'} gradient={true} column={true}>
                            {topVisionsLoading && <SkeletonServiceCard topService />}
                            {topVisionsError && (
                                <TextContainer>
                                    Une erreur est survenue <i className={fontAwesome.danger}></i>
                                </TextContainer>
                            )}
                            {topVisionsData.services &&
                                topVisionsData.services.map((service, index) => (
                                    <ServiceCard
                                        key={index}
                                        service={service}
                                        index={index}
                                        bgGradient={true}
                                        link={`${appLinks.catalog.serviceDetails}/${service._id}`}
                                    />
                                ))}
                            {!topVisionsError &&
                                !topVisionsLoading &&
                                (topVisionsData.services?.length === 0 || !topVisionsData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </TitleBgCard>
                    </div>
                    <div className={styles.linkCard}>
                        <LinkCardContainer whichCardsArray={['meet', 'event']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default RewardsPage;
