import React, { useState } from 'react';
import styles from './TaskPresetIframe.module.scss';
import GenericCard from '../../GenericCard/GenericCard';
import HeaderCard from '../../../Header/HeaderCard/HeaderCard';
import { appImageAssets } from '../../../../data/enums/appImageAssets';
import SvgMatchGauge from '../../../SVG/SvgMatchGauge/SvgMatchGauge';
import { OBJECTIVE_RESEARCH_TYPE } from '../../../../data/enums/objectiveResearchType';
import Loader from '../../../Loaders/Loader/Loader';
import CheckboxInput from '../../../Inputs/CheckboxInput/CheckboxInput';
import { toggleItemInObjectStateArray } from '../../../../utils/stateFunctions/stateArrays';
import { Button } from '../../../Buttons/Button/Button';
import { ButtonContainer } from '../../../Containers/ButtonContainer/ButtonContainer';
import { useAuth } from '../../../../hooks/useAuth';
import { serviceIds } from '../../../../data/enums/services';
import { config } from '../../../../config';
import TextContainer from '../../../Text/TextContainer/TextContainer';
import { isEqual } from '../../../../utils/arrays';

const HEADAI_DATATYPES = ['63ff67baaa769c32564bb21c', '63ff67baaa769c32564bb21a'];

const TaskPresetIframe = ({
    iframe = '',
    servicesCompleted = 0,
    situation,
    loading = false,
    replay = false,

    /**
     * Pass in the User's HeadAI identifier to know how to call the HeadAI API
     */
    userPartnerIdentifier,
    taskId,
    onRefreshResults = () => {},
}) => {
    const { user } = useAuth();

    const [selectedTrainings, setSelectedTrainings] = useState([]);

    const [trainingSelections, setTrainingSelections] = useState([]);
    const [gettingTrainingSelections, setGettingTrainingSelections] = useState(false);
    const [shareProfileError, setShareProfileError] = useState(false);

    const [sharingProfile, setSharingProfile] = useState(false);
    const [profileShared, setProfileShared] = useState(false);

    const updateShareProfile = async () => {
        if (!userPartnerIdentifier) return;

        const prevTrainingSelected = trainingSelections.length
            ? trainingSelections.map((training) => training?.code)
            : [];

        setGettingTrainingSelections(true);

        const res = await fetch('https://visions.headai.com/api/v1/course/select?user=' + userPartnerIdentifier);
        const data = await res.json();

        if (data.results) {
            if (prevTrainingSelected.length) {
                const dataCheck = data.results.map((data) => data?.code);

                if (isEqual(dataCheck, prevTrainingSelected)) setProfileShared(false);
            }

            // Preselect toggles
            setSelectedTrainings(data.results);
            setTrainingSelections(data.results);
            setGettingTrainingSelections(false);
        } else {
            setGettingTrainingSelections(false);
            setShareProfileError(true);
        }
    };

    const onShareProfile = async () => {
        if (!selectedTrainings.length) {
            return alert('Impossible de partager le profil sans sélectionner de formation à partager');
        }

        setSharingProfile(true);

        const base_payload = {
            userId: user?._id,
            datatypes: HEADAI_DATATYPES,
            purposeName: 'Démarchage formations',
            purposeSlug: 'sell_trainings',
            providerId: serviceIds.headai,
        };

        let successConsents = 0;

        for (const selectedTraining of selectedTrainings) {
            if (!selectedTraining || !selectedTraining?.publisher) continue;
            const payload = { ...base_payload, consumer: selectedTraining?.publisher };
            const res = await fetch(`${config.visionstrustUrl}/v1/consents/custom/unknownconsumer`, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            if (res.status === 201) successConsents++;
        }

        console.log(`Profile share consents success: ${successConsents}/${selectedTrainings?.length}`);

        setSharingProfile(false);
        setProfileShared(true);
    };

    const handleTrainingSelection = (training, e) => {
        console.log(training);
        toggleItemInObjectStateArray(training, selectedTrainings, setSelectedTrainings);
    };

    return (
        <div className={styles.container}>
            <div className={styles.explication}>
                {situation === OBJECTIVE_RESEARCH_TYPE.job ? (
                    <p>Explorez les offres d'emploi qui vous correspondent et candidater à celles qui vous plaisent</p>
                ) : (
                    <p>
                        Explorez les offres de formation qui vous correspondent et candidatez à celles qui vous plaisent
                    </p>
                )}
                <Button onclick={onRefreshResults}>actualiser mes matchs</Button>
            </div>

            <GenericCard className={styles.iframeContainer}>
                <HeaderCard
                    color={'lightOpacity'}
                    mainTitle={'Matching réalisé par Headai'}
                    className={styles.header}
                ></HeaderCard>
                {!loading ? (
                    <iframe src={iframe} frameBorder="0" title="Inline Frame Headai"></iframe>
                ) : (
                    <Loader text="Récupération de vos matching" />
                )}
            </GenericCard>
            {!trainingSelections.length && !gettingTrainingSelections && (
                <ButtonContainer margin={'15px 0'}>
                    <Button onclick={() => updateShareProfile(true)}>Postuler</Button>
                </ButtonContainer>
            )}
            {gettingTrainingSelections && <Loader />}
            {trainingSelections.length !== 0 && (
                <GenericCard className={styles.shareProfile}>
                    <ButtonContainer margin={'0 0 10px 0'} position="end">
                        <Button size="small" sx={{ margin: 0 }} onclick={() => updateShareProfile(true)}>
                            actualiser mes choix
                        </Button>
                    </ButtonContainer>
                    {trainingSelections.length === 1 ? (
                        <p>
                            Cliquez sur {'<<'}envoyer mon profil{'>>'} pour postuler à la formation que vous avez
                            sélectionnée.
                        </p>
                    ) : (
                        <p>
                            Cliquez sur {'<<'}envoyer mon profil{'>>'} pour postuler aux {trainingSelections?.length}{' '}
                            formations que vous avez sélectionnées.
                        </p>
                    )}
                    {trainingSelections.map((training, index) => (
                        <React.Fragment key={'training' + index}>
                            <div className={styles.formation}>
                                <p>{training?.publisher}</p>
                                <span>{training?.title}</span>
                            </div>
                            <div className={styles.checkbox}>
                                <CheckboxInput
                                    data={training}
                                    index={index}
                                    onclick={handleTrainingSelection}
                                    checked={selectedTrainings.find((t) => t?.code === training?.code)}
                                ></CheckboxInput>
                            </div>
                            <div className={styles.separator}></div>
                        </React.Fragment>
                    ))}
                    {profileShared ? (
                        <TextContainer sx={{ margin: '10px' }}>
                            Votre profil a bien été envoyé aux organismes sélectionnés
                        </TextContainer>
                    ) : (
                        <>
                            <Button onclick={onShareProfile} disabled={sharingProfile}>
                                Envoyer mon profil
                            </Button>
                            <p className={styles.consentInfo}>
                                En cliquant sur le bouton ci-dessus, vous consentez à ce que votre profil et vos
                                coordonnées soient envoyés aux organismes sélectionnés pour qu'ils vous contactent au
                                sujet de cette formation.
                            </p>
                        </>
                    )}
                </GenericCard>
            )}
            {shareProfileError && (
                <p style={{ margin: '10px 0' }}>
                    Aucune formation séléctionnée. Vérifiez que vous avez bien dit être intéressé par une formation
                    avant de rééssayer.
                </p>
            )}

            <GenericCard className={`${styles.matchsCard} ${replay ? styles.replayMatchs : ''}`}>
                <div className={styles.matchsCardContainer}>
                    <div className={styles.headerMatchs}>
                        <img src={appImageAssets.icon.matchAccuracy} alt="Icone Matchs Précision" />
                        <h4>Niveau de précision de mes matchs</h4>
                    </div>
                    {replay ? (
                        <p>
                            Obtenez des matchs ultra précis en complétant votre profil davantage avec de nouvelles
                            plateformes ou en rejouant avec les plateformes sélectionnées.
                        </p>
                    ) : (
                        <p>
                            Obtenez des matchs ultra précis selon votre profil en complétant la mission 1 avec les 2
                            plateformes proposées.
                        </p>
                    )}
                </div>
                <div style={{ display: 'flex' }}>
                    <SvgMatchGauge
                        progress={servicesCompleted === 1 ? '50%' : 2 ? '100%' : '0%'}
                        className={styles.gauge}
                    />
                </div>

                {replay && !!taskId && (
                    <Button
                        navigate={`/user/missions/${taskId}`}
                        onclick={() => {
                            sessionStorage.setItem('fromMatchs', true);
                        }}
                    >
                        Améloirer mes matchs
                    </Button>
                )}
            </GenericCard>
        </div>
    );
};

export default TaskPresetIframe;
