import React from 'react';
import { useNavigate } from 'react-router-dom';
import { storeEventLog } from '../../../utils/api/eventLogs';
import styles from './btnback.module.scss';

const BtnBack = ({ link, extras = {}, onclick = () => {} }) => {
    const navigate = useNavigate();

    const handleClick = async () => {
        if (onclick) await onclick();
        if (extras.activity) storeEventLog(sessionStorage.getItem('userId'), extras.activity, 'completed');
        navigate(link);
    };

    return (
        <button className={styles.btnBack} onClick={async () => await handleClick()}>
            <div className={styles.circle}>
                <i className="fas fa-arrow-right"></i>
            </div>
            <p>Retour</p>
        </button>
    );
};

export default BtnBack;
