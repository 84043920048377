import React, { useRef } from 'react';
import styles from './requestPurchaseReward.module.scss';
import { openWindow } from '../../../../utils/openWindow';
import { Button } from '../../../Buttons/Button/Button';
import { ButtonContainer } from '../../../Containers/ButtonContainer/ButtonContainer';

const ConfirmationRequestPurchaseReward = ({ reward, closeModal, validation = false, promoCode }) => {
    const refCopy = useRef(null);

    const copyText = () => {
        if (!refCopy) return;

        navigator.clipboard.writeText(refCopy.current.innerText);
    };
    return (
        <div className={styles.contentModal}>
            <div className={styles.informationModal}>
                <p className={styles.congrat} style={{ color: validation && 'white' }}>
                    {validation ? 'Merci !' : reward.title}
                </p>
                <div className={styles.recap} style={{ fontStyle: !validation && 'italic' }}>
                    {validation ? (
                        <>
                            <p>
                                Vous avez choisi d'échanger <br /> <span>{reward.price} VisionsStars</span> contre{' '}
                                <br />
                                <span>{reward.title}</span>
                            </p>
                            <p>Vous pouvez retrouver votre achat sur la page Récompenses</p>
                            <div className={styles.promoCode}>
                                {promoCode !== '' && (
                                    <>
                                        <p>Code:</p>
                                        {/* <input
                                            type="text"
                                            readOnly="readonly"
                                            defaultValue={promoCode}
                                            ref={refCopy}
                                            id={styles.codeInput}
                                        /> */}
                                        <span id={styles.codeInput} ref={refCopy}>
                                            {promoCode}
                                        </span>
                                        <br />
                                        <Button size="smallMain" sx={{ color: '#fff' }} onclick={copyText}>
                                            Copier Code
                                        </Button>
                                    </>
                                )}
                            </div>
                            {reward?.onPurchase?.message ? (
                                <div style={{ margin: '10px 0' }}>{reward.onPurchase.message}</div>
                            ) : null}
                            {reward?.onPurchase?.action === 'websiteRedirectionBtn' ? (
                                <ButtonContainer>
                                    <Button
                                        size="main"
                                        sx={{ color: 'white' }}
                                        onclick={() =>
                                            openWindow(reward?.onPurchase?.callbackUrl || reward?.partner?.website)
                                        }
                                    >
                                        Utiliser ma récompense
                                    </Button>
                                </ButtonContainer>
                            ) : null}
                        </>
                    ) : (
                        <>
                            <p>Récapitulatif :</p>
                            <p>échanger</p>
                            <p className={styles.important} dangerouslySetInnerHTML={{ __html: reward.title }}></p>
                            <p>contre</p>
                            <p className={styles.important}>{reward.price} VisonsStars</p>
                        </>
                    )}
                </div>
            </div>
            <ButtonContainer>
                <Button size="main" sx={{ color: '#fff' }} onclick={closeModal}>
                    {validation ? 'Retour' : 'Confirmation'}
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default ConfirmationRequestPurchaseReward;
