import React from 'react';
import RoundButtonIcon from '../Buttons/RoundButtonIcon/RoundButtonIcon';
import Loader from '../Loaders/Loader/Loader';
import ServiceCard from '../Cards/ServiceCard/ServiceCard';
import styles from './contentcardrecommandation.module.scss';

import { appLinks } from '../../data/enums/appLinks';

const ContentCardRecommendation = ({ services, loading }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>
                    Vos Recommandations <span>suite à vos réponses</span>
                </h2>
            </div>
            <div className={styles.content}>
                {loading && <Loader />}
                {!loading &&
                    services &&
                    services.map((service, index) => (
                        <ServiceCard
                            key={index}
                            service={service}
                            index={index}
                            link={`${appLinks.catalog.serviceDetails}/${service._id}`}
                        />
                    ))}
                {!loading && services.length === 0 && <div>Aucune recommendation de service trouvée.</div>}
            </div>
            <div className={styles.btn}>
                <RoundButtonIcon textContent={'Refaire le jeu'} link={appLinks.visionsSwipe} bgColor={'basic'} />
            </div>
        </div>
    );
};

export default ContentCardRecommendation;
