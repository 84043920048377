import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { appLinks } from '../../../data/enums/appLinks';
import styles from './ConfigurationPage.module.scss';
import HeaderVisionsGalaxy from '../../../Components/Header/HeaderVisionsGalaxy';
import { OBJECTIVE_RESEARCH_TYPE } from '../../../data/enums/objectiveResearchType';
import { useAuth } from '../../../hooks/useAuth';
import { Button } from '../../../Components/Buttons/Button/Button';

const ConfigurationPage = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const localConfig = localStorage.getItem('headai');

    const saveResearchType = (e) => {
        const value =
            e.target.innerText.toUpperCase() === 'UNE FORMATION'
                ? OBJECTIVE_RESEARCH_TYPE.formation
                : OBJECTIVE_RESEARCH_TYPE.job;
        localStorage.setItem('researchType', value);
        navigate(appLinks.missions.index);
    };

    useEffect(() => {
        if (localConfig && JSON.parse(localConfig)?.namespace === 'sdn') {
            localStorage.setItem('researchType', OBJECTIVE_RESEARCH_TYPE.formation);
            navigate(appLinks.missions.index);
            return;
        }

        if (user?.experiencesInfo?.situation === OBJECTIVE_RESEARCH_TYPE.student) {
            navigate(appLinks.missions.index);
            return;
        }
    }, [user, navigate, localConfig]);

    return (
        <div className={styles.page}>
            <HeaderVisionsGalaxy />
            <section className={styles.container}>
                <img src={appImageAssets.illustration.configObjective} alt="Illustration planète et fusée" />
                <h2>Vous venez sur VisionsGalaxy pour trouver .......?</h2>
                <p className={styles.description}>
                    L'objectif que vous allez choisir servira à vous proposer un parcours adaptéà vos besoins.
                </p>
                <div className={styles.btn}>
                    <Button size="main" variant="white" onclick={(e) => saveResearchType(e)}>
                        Une formation
                    </Button>
                    <span>OU</span>
                    <Button size="main" variant="gradient" onclick={(e) => saveResearchType(e)}>
                        Un emploi
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default ConfigurationPage;
