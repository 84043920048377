import React, { useState, useRef } from 'react';
import styles from './contentcardetail.module.scss';
import { config } from '../../config';
import { appImageAssets } from '../../data/enums/appImageAssets';
import GenericModal from '../Modal/GenericModal/GenericModal';
import { openWindow } from '../../utils/openWindow';
import NoticePriceService from '../Notices/NoticePriceService/NoticePriceService';
import { Button } from '../Buttons/Button/Button';
import { ButtonContainer } from '../Containers/ButtonContainer/ButtonContainer';
import { Icon } from '../Icons/Icon/Icon';

const ContentCardDetail = ({ detail }) => {
    const [open, setOpen] = useState(false);

    const linkPopup = () => {
        setOpen((currentOpen) => !currentOpen);
    };

    const closePopUp = () => {
        setOpen((currentOpen) => !currentOpen);
    };

    const edtechRedirect = () => {
        if (detail.urls?.website) {
            // window.location.href = detail.urls.website
            openWindow(detail.urls.website);
            setOpen((prev) => !prev);
        } else {
            alert("Impossible d'accéder à la plateforme");
        }
    };

    const refCopy = useRef(null);

    const copyText = () => {
        if (!refCopy) return;

        navigator.clipboard.writeText(refCopy.current.innerText);
    };

    let numberVisionsStars = 0;

    if (detail.galaxy.user.visionsStars !== undefined) {
        numberVisionsStars = detail.galaxy.user.visionsStars;
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <div className={styles.img}>
                        <img src={`${config.logoBaseUrl}/${detail.logo}`} alt={`${detail.name} Logo`} />
                    </div>
                </div>
                <div className={styles.headline}>
                    <div className={styles.title}>{detail.name}</div>
                    <div className={styles.headlineInfo}>
                        {detail.galaxy.user.mainTag && (
                            <div className={styles.mainTag}>{detail.galaxy.user.mainTag}</div>
                        )}
                        <div className={styles.visionsStars}>
                            <img src={appImageAssets.icon.visionsStars} alt="Icone VisionsStars" />
                            <span>{numberVisionsStars} VisionsStars</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.description}>
                {detail.galaxy.user.description
                    ? detail.galaxy.user.description
                    : `${detail.name} n'a pas renseigné de description.`}
            </div>
            {detail.galaxy.user.tags.length !== 0 && (
                <div className={styles.tags}>
                    <p>Autres mots clés associés</p>
                    <div className={styles.showTags}>
                        {detail.galaxy.user.tags.map((tag) => (
                            <p key={tag}>{tag}</p>
                        ))}
                    </div>
                </div>
            )}
            {detail.code ? (
                <div className={styles.codePartner}>
                    <p>
                        Code d'utilisation du service : <span>{detail.code}</span>
                    </p>
                </div>
            ) : (
                <></>
            )}
            <div className={styles.content}>
                <h2>Le Concept ?</h2>
                <p>
                    {detail.description
                        ? detail.description
                        : `${detail.name} n'a pas renseigné d'informations sur le concept.`}
                </p>
            </div>
            {detail?.galaxy?.user?.isFree === false && <NoticePriceService />}
            <ButtonContainer>
                <Button onclick={linkPopup} size="main" sx={{ color: 'white' }} endIcon={<Icon icon="arrow" />}>
                    Lancer l'Expérience
                </Button>
            </ButtonContainer>
            <GenericModal
                open={open}
                closeModal={closePopUp}
                image={`${config.logoBaseUrl}/${detail.logo}`}
                bgImageWhite
            >
                <div className={styles.contentModal}>
                    <div className={styles.informationModal}>
                        <h3 className={styles.title}>{detail.name}</h3>
                        <p className={styles.desc}>Vous allez être redirigés vers l'expérience de {detail.name}</p>
                        {detail.code && (
                            <div className={styles.infoCode}>
                                <p className={styles.desc}>
                                    Veuillez utiliser le code <span ref={refCopy}>{detail.code}</span> pour accéder au
                                    service
                                </p>
                                <Button onclick={copyText} sx={{ color: 'white' }}>
                                    Copier Code
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className={styles.button}>
                        <Button
                            onclick={edtechRedirect}
                            size="main"
                            endIcon={<Icon icon="arrow" />}
                            sx={{ color: 'white' }}
                        >
                            Redirection vers {detail.name}
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </div>
    );
};

export default ContentCardDetail;
