import { appLinks } from '../data/enums/appLinks';

// TODO This should check in VT to find the correct user email for the service
const mapServiceToSlug = {
    '60796546a2be8fc2e9b02027': {
        slug: 'orientoi',
        visions_id: 'Orientoi',
        defaultFolderPath: 'Orientoi (via Visions)',
    },
    '5fb272090ee6207b28216bab': {
        slug: 'jobready',
        visions_id: 'Jobready',
        defaultFolderPath: 'Jobready (via Visions)',
    },
    '60796606a2be8fc2e9b0202f': {
        slug: 'inokufu',
        visions_id: 'Inokufu',
        defaultFolderPath: 'Inokufu (via Visions)',
    },
    '62695312b3417319920577cb': {
        slug: 'pitangoo',
        visions_id: 'PITANGOO',
        defaultFolderPath: 'PITANGOO (via Visions)',
    },
    '62692504b341731992056fe0': {
        slug: 'curiose',
        visions_id: 'Curiose',
        defaultFolderPath: 'Curiose (via Visions)',
    },
    '62669514ea1a363d856b4c5b': {
        slug: 'becomino',
        visions_id: 'Becomino',
        defaultFolderPath: 'Becomino (via Visions)',
    },
};

export const filterCozyFromServices = (services) => {
    return services.filter((s) => !s?.name?.toLowerCase()?.includes('cozy'));
};

export const generateCozyLink = (userId, email, serviceIds = []) => {
    const konnectors = {
        aggregator: {
            id: 'visions-aggregator',
            auth: {
                email: email,
                visions_id: userId,
            },
            trigger: false,
            exec: false,
        },
    };

    for (const serviceId of serviceIds) {
        const service = mapServiceToSlug[serviceId];

        if (!service) continue;

        konnectors[service.slug] = {
            account_type: service.slug,
            auth: { login: email, visions_id: service.visions_id },
            identifier: 'login',
            defaultFolderPath: `/Administratif/${service.defaultFolderPath}/${email}`,
            trigger: true,
            exec: true,
            state: null,
        };
    }

    const urlEncoded = encodeURI(JSON.stringify(konnectors));
    const url = `${appLinks.hrefs.cozyBase}&email=${encodeURIComponent(email)}&konnectors=${urlEncoded}`;
    return url;
};
