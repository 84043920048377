import React, { useEffect, useState } from 'react';
import NavBarLandingPage from '../../../Components/NavBar/NavBarLandingPage/NavBarLandingPage';
import styles from './PublicBilanOrientationTransition.module.scss';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { appLinks } from '../../../data/enums/appLinks';
import Loader from '../../../Components/Loaders/Loader/Loader';
import GenericBtn from '../../../Components/Buttons/GenericBtn/GenericBtn';

// TMP  DATA
import { stepConfig } from '../../../data/tmp/stepConfig';

// Image & icon import
import { logoPartner } from '../../../data/enums/logoPartner';
import logoVisions from '../../../img/LOGO ONBOARDING.svg';
import iconBO from '../../../img/ICONE-BO.svg';
import readyIllus from '../../../img/publicExperiences/pret.svg';
import concretelyIllus from '../../../img/publicExperiences/concretement.png';
import { useAuth } from '../../../hooks/useAuth';

const PublicBilanOrientationTransition = () => {
    const navigate = useNavigate();
    const searchParams = useSearchParams()[0];
    const step = searchParams.get('step');
    const redirect = searchParams.get('redirect');
    const { user } = useAuth();
    const { email, _id } = user;

    // Initialize the BO if not already done
    const [experienceUserConfig, setExperienceUserConfig] = useState(null);
    const [stepData, setStepData] = useState(redirect === 'api' ? stepConfig[3] : stepConfig[0]);
    const [checkStepLoaded, setCheckStepLoaded] = useState(false);

    useEffect(() => {
        let mounted = true;
        if (!email) return;
        if (redirect === 'api') {
            setCheckStepLoaded(true);
            return;
        }
        const getOrCreateExperienceUserConfig = async () => {
            const res = await fetch('/api/bo/getcreateuserconfig', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    experience: 'bilan-orientation',
                    shouldReset: parseInt(step) === 0 || redirect === null,
                }),
            });

            const data = await res.json();
            if (!mounted) return;
            setExperienceUserConfig(data);
            setStepData(stepConfig[data.step]);
            setCheckStepLoaded(true);
        };

        getOrCreateExperienceUserConfig();

        return () => {
            mounted = false;
        };
    }, [email, step, redirect]);

    // TODO : Block button
    const registerDoneStep = async () => {
        const res = await fetch('/api/bo/galaxydonestep', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                step: step ? parseInt(step) : 0,
                userId: _id,
            }),
        });

        const data = await res.json();

        console.log(data);

        window.location.href = experienceUserConfig.step === 0 ? stepData.startUrl : redirect;
    };

    if (!checkStepLoaded) {
        return <Loader />;
    }

    return (
        <>
            <div className={styles.header}>
                <NavBarLandingPage logo={logoVisions} link={appLinks.public.landing}></NavBarLandingPage>
                <div className={styles.border}></div>
            </div>
            <main>
                <section className={styles.roadmapContainer}>
                    <div className={styles.roadmap}>
                        <div className={styles.rowDashed}></div>
                        <div className={styles.stepRoadmap}>
                            <div
                                className={`${styles.step} ${
                                    stepData?.service === 'Jobready' ? styles.stepActive : ''
                                }`}
                            >
                                <img src={logoPartner.jobready.icon} alt="icone Jobready" />
                                <p>Jobready</p>
                            </div>
                            <div
                                className={`${styles.step} ${
                                    stepData?.service === 'Orientoi' ? styles.stepActive : ''
                                }`}
                            >
                                <img src={logoPartner.orientoi.icon} alt="icone Orientoi" />
                                <p>Orientoi</p>
                            </div>
                            <div
                                className={`${styles.step} ${stepData?.service === 'Inokufu' ? styles.stepActive : ''}`}
                            >
                                <img src={logoPartner.inokufu.icon} alt="icone Inokufu" />
                                <p>Inokufu</p>
                            </div>
                            <div className={styles.step}>
                                <img src={logoPartner.cozy.icon} alt="icone Cozy.io" />
                                <p>Cozy.io</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.partnerPresentation}>
                    <img src={readyIllus} alt="" />
                    <h3>{stepData?.title}</h3>
                    <p dangerouslySetInnerHTML={{ __html: stepData?.main }}></p>
                </section>

                <section className={styles.partnerStep}>
                    <img src={concretelyIllus} alt="" />
                    <section className={styles.partnerStepList}>
                        {stepData?.steps.map((step, index) => (
                            <div className={styles.stepContainer} key={index}>
                                <div className={styles.stepNumber}>
                                    <span>{index + 1}</span>
                                    <div className={styles.borderBg}></div>
                                </div>
                                <div className={styles.textPartner} style={{ width: '100%' }}>
                                    <h3>{step.summary}</h3>
                                    <p>{step.content}</p>
                                </div>
                            </div>
                        ))}
                    </section>
                </section>

                <section className={styles.lastInfo}>
                    <div className={styles.cardInfo}>
                        <header>
                            <h2>Pssss !</h2>
                            <div className={styles.borderBg}></div>
                        </header>
                        <p className={styles.textCenter}>
                            Tu vas pouvoir retrouver les objectifs à réaliser pendant ton parcours en cliquant sur ce
                            repère :
                        </p>
                        <div className={styles.iconBo}>
                            <img src={iconBO} alt="icon bilan d'orientation" />
                        </div>
                        {stepData?.code && (
                            <div className={styles.codeContainer}>
                                <p className={styles.textCenter}>
                                    Lors de votre inscription sur {stepData?.service} vous allez devoir rentrer un code,
                                    copiez et collez le à la prochaine étape :
                                </p>
                                <p className={styles.code}>{stepData?.code}</p>
                            </div>
                        )}
                        <div className={styles.btn}>
                            {redirect === 'api' ? (
                                <GenericBtn
                                    textContent={'Je termine !'}
                                    hasArrow={false}
                                    onClickFc={() => navigate(appLinks.experiences.transition.end)}
                                />
                            ) : (
                                <GenericBtn
                                    textContent={'Je commence !'}
                                    hasArrow={false}
                                    onClickFc={() => registerDoneStep()}
                                />
                            )}
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default PublicBilanOrientationTransition;
