import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CardBgBottom from '../../../../Components/Cards/CardBgBottom/CardBgBottom';
import { appLinks } from '../../../../data/enums/appLinks';
import styles from './PreStartBilanOrientation.module.scss';

// Image & icon
import iconBo from '../../../../img/ICONE ORIENTATION.svg';
import GenericBtn from '../../../../Components/Buttons/GenericBtn/GenericBtn';
import { useAuth } from '../../../../hooks/useAuth';

const PreStartBilanOrientation = () => {
    const { user } = useAuth();
    const { _id } = user;
    const navigate = useNavigate();

    const eventInput = useRef(null);
    const entityInput = useRef(null);

    const submitForm = async () => {
        if (!eventInput) return;
        if (!entityInput) return;
        if (!_id) return alert('Une erreur est survenue, veuillez réessayer plus tard.');

        // TODO replace call with client

        await fetch(`/api/bo/prestart`, {
            method: 'POST',
            body: JSON.stringify({
                eventCode: eventInput.current.value,
                recommendationName: entityInput.current.value,
                userId: _id,
            }),
            headers: {
                'content-type': 'application/json',
            },
        });

        navigate(appLinks.experiences.transition.ongoing);
    };

    return (
        <div className={styles.page}>
            <div className={styles.card}>
                <div className={styles.icon}>
                    <img src={iconBo} alt="" />
                </div>
                <div className={styles.information}>
                    <h2>Paramétrer votre Bilan d'Orientation</h2>
                    <p>
                        Afin de{' '}
                        <span>
                            pouvoir envoyer vos données vers les organismes de formation et les employeurs de votre
                            choix
                        </span>{' '}
                        nous avons besoin de quelques informations supplémentaires.
                    </p>
                </div>
            </div>
            <CardBgBottom>
                <div className={styles.parameter}>
                    <form action="">
                        <div className={styles.inputGroup}>
                            <div className={styles.input}>
                                <label htmlFor="event">
                                    Avez-vous un code évènement ? <br />
                                    Renseignez-le ici <span>(Facultatif)</span>
                                </label>
                                <input type="text" id="event" name="event" ref={eventInput} />
                            </div>
                            <div className={styles.input}>
                                <label htmlFor="entity">
                                    Un conseiller ou entité vous a recommandé de faire un bilan ? Mettez leur nom ou
                                    organisation ici <span>(Facultatif)</span>
                                </label>
                                <input type="text" id="entity" name="entity" ref={entityInput} />
                            </div>
                        </div>
                    </form>
                    <div className={styles.btn}>
                        <GenericBtn textContent={'Paramétrer mon bilan'} onClickFc={() => submitForm()} />
                    </div>
                    <p className={styles.skipStep} onClick={() => navigate(appLinks.experiences.transition.ongoing)}>
                        Passer cette étape
                    </p>
                </div>
            </CardBgBottom>
        </div>
    );
};

export default PreStartBilanOrientation;
