import React from 'react';
import { openWindow } from '../../../../utils/openWindow';
import GenericBtn from '../../../Buttons/GenericBtn/GenericBtn';

const TMP_TEXT = `Tu as identifié le métier qui te correspond le plus, bravo ! 
Va maintenant dans ton Cozy pour voir ton bilan et les offres de formation qui vont te permettre de réaliser ton projet !`;

const ComingFromPlatformModal = ({ textContent = TMP_TEXT, redirectionLink }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                witdh: '100%',
                height: '100%',
                minHeight: '300px',
            }}
        >
            <div style={{ padding: '20px', fontSize: '20px' }}>{textContent}</div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    witdh: '100%',
                    height: '100%',
                }}
            >
                <GenericBtn
                    textContent={'Voir mon Bilan VisionsGalaxy'}
                    onClickFc={() => openWindow(redirectionLink)}
                />
            </div>
        </div>
    );
};

export default ComingFromPlatformModal;
