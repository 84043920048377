import React from 'react';
import styles from './Icon.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';

const MAP = {
    arrow: fontAwesome.arrowRight,
    arrowLeft: fontAwesome.arrowleft,
    globe: fontAwesome.globe,
    angleTop: fontAwesome.angleTop,
};

/**
 *
 * @param {object} props
 * @param {string} props.icon set icon by mapping key
 * @param {"green" | "blue"} props.variant set variant design icon
 * @param {string} props.className set className to the icon container
 * @returns
 */
export const Icon = ({ icon = '', variant = '', className = '' }) => {
    if (!MAP.hasOwnProperty(icon)) return null;

    const selectVariant = () => {
        switch (variant) {
            case "green":
                return styles.green;
            case "blue":
                return styles.blue;

            default:
                return null;
        }
    };

    return (
        <span className={`${styles.icon} ${className} ${selectVariant()}`}>
            <i className={MAP[icon]}></i>
        </span>
    );
};
