// Jobreday
import iconJobready from '../../img/partner/jobready.png';
import logoJobready from '../../img/partner/job-logo.svg';

// Orientoi
import iconOrientoi from '../../img/partner/orientoi.png';

// Inokufu
import iconInokufu from '../../img/partner/inokufu.png';

// Cozy
import iconCozy from '../../img/partner/cozy.png';

export const logoPartner = {
    jobready: {
        icon: iconJobready,
        logo: logoJobready,
    },
    orientoi: {
        icon: iconOrientoi,
    },
    inokufu: {
        icon: iconInokufu,
    },
    cozy: {
        icon: iconCozy,
    },
};
