import React from 'react';
import { useNavigate } from 'react-router';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import { appLinks } from '../../../data/enums/appLinks';

import Styles from './IncompleteProfilePage.module.scss';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../../Components/Buttons/Button/Button';
import { Icon } from '../../../Components/Icons/Icon/Icon';

const profileMap = {
    age_group: 'Âge',
    situation: 'Situation',
    scholarshipLevel: "Niveau d'études",
};

export const IncompleteProfilePage = ({ missingFields = [] }) => {
    const navigate = useNavigate();

    return (
        <>
            <HeaderWithNavBar />
            <div className={Styles.page}>
                <div className={Styles.head}>
                    <h1>Votre profil est incomplet</h1>
                    <h4>
                        Pour pouvoir accéder à cette page il vous faut renseigner les informations suivantes sur votre
                        profil:
                    </h4>
                </div>
                <div className={Styles.body}>
                    <ul>
                        {missingFields.map((field) => (
                            <li key={field}>{profileMap[field] || ''}</li>
                        ))}
                    </ul>
                </div>
                <ButtonContainer>
                    <Button
                        endIcon={<Icon icon="arrow" />}
                        onclick={() => {
                            navigate(appLinks.profile);
                        }}
                    >
                        Voir mon profil
                    </Button>
                </ButtonContainer>
            </div>
        </>
    );
};
