import React, { useState } from 'react';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';
import { setUserAvatar } from '../../../libs/gamification/missions';
import Avatar from '../../Avatar/Avatar';
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';
import GenericModal from '../../Modal/GenericModal/GenericModal';
import styles from './titlebgcard.module.scss';

const TitleBgCard = ({
    logo,
    sizeLogo = 'normal',
    icon,
    title,
    children,
    gradient = false,
    toggled = false,
    column = false,
    border = false,
    overflow = 'hidden',
}) => {
    // TODO SHOULD NOT USE USER HERE BUT PASS IN THE AVATAR AS PROPS
    const { user } = useAuth();
    const { updateUser } = useClient();
    const [toggle, setToggle] = useState(false);
    const [editAvatar, setEditAvatar] = useState(false);
    const [avatarSelected, setAvatarSelected] = useState(-1);
    let sizeImg;

    switch (sizeLogo) {
        case 'normal':
            sizeImg = '';
            break;
        case 'small':
            sizeImg = styles.smallImg;
            break;
        case 'large':
            sizeImg = styles.largeImg;
            break;
        case 'profil':
            sizeImg = styles.profilImg;
            break;
        default:
            sizeImg = '';
            break;
    }

    const toggleEvent = () => {
        setToggle(!toggle);
    };

    const updateAvatarSelected = (avatar) => {
        setAvatarSelected(avatar);
    };

    const closeModal = () => {
        if (sizeLogo !== 'profil') return;
        setEditAvatar(false);
    };

    const setUpdateAvatar = () => {
        if (sizeLogo !== 'profil') return;
        if (!user) return;
        const currentAvatar = user.gamification.avatar;
        if (currentAvatar !== avatarSelected) updateUser({ avatar: avatarSelected });
        setEditAvatar(false);
        window.location.reload();
    };

    const Gradient = gradient ? styles.gradient : '';
    const Column = column ? styles.column : '';
    const Toggle = toggled ? styles.toggle : styles.none;
    const borderCard = border ? '' : styles.noBorder;

    return (
        <section className={`${styles.card} ${Gradient} ${borderCard}`}>
            <div className={styles.header}>
                {logo && (
                    <div
                        className={`${styles.logo} ${sizeImg}`}
                        onClick={() => {
                            sizeLogo === 'profil' && setEditAvatar(true);
                        }}
                    >
                        <img src={logo} alt="" style={{ cursor: sizeLogo === 'profil' && 'pointer' }} />
                    </div>
                )}
                {icon && (
                    <div className={`${styles.logo} ${sizeImg}`}>
                        <i className={icon}></i>
                    </div>
                )}
                <div className={styles.title}>{title}</div>
            </div>
            <div
                className={`${styles.content} ${Column} ${toggle ? styles.cardHeightSmall : styles.H100}`}
                style={{ overflow: overflow }}
            >
                {children}
            </div>
            <div className={Toggle}>
                <button
                    className={`${styles.arrowToggle} ${toggle ? styles.arrowToggleClose : ''}`}
                    type="button"
                    onClick={toggleEvent}
                >
                    <i className="fas fa-arrow-right"></i>
                </button>
            </div>
            {sizeLogo === 'profil' && (
                <GenericModal
                    open={editAvatar}
                    closeModal={() => closeModal()}
                    image={appImageAssets.icon.VG}
                    maxWidthModal={700}
                >
                    <div className={styles.contentModal}>
                        <Avatar avatarSelected={avatarSelected} updateAvatarSelectedFC={updateAvatarSelected} profile />
                        <GenericBtn
                            textContent={'Valider'}
                            hasArrow={false}
                            onClickFc={() => setUpdateAvatar()}
                            disable={avatarSelected === user?.gamification?.avatar}
                        />
                    </div>
                </GenericModal>
            )}
        </section>
    );
};

export default TitleBgCard;
