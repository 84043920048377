import React, { useState, useEffect, useRef } from 'react';

//Components
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import ManualExperience from '../../../Components/ContentCards/ManualExperience/ManualExperience';
import StepCardService from '../../../Components/Cards/StepCardService/StepCardService';
import ShareBilanExperiences from '../../../Components/Experiences/ShareBilanExperiences/ShareBilanExperiences';
import DescriptionExperience from '../../../Components/Experiences/DescriptionExperience/DescriptionExperience';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import PlatformJourneyGrade from '../../../Components/Grade/PlatformJourneyGrade/PlatformJourneyGrade';
import Loader from '../../../Components/Loaders/Loader/Loader';
import ExperiencesErrors from '../../../Components/Errors/ExperiencesErrors/ExperiencesErrors';

// Hooks & Utils
import { percentageOf } from '../../../utils/math/mathUtils';
import { appLinks } from '../../../data/enums/appLinks';
import { generateCozyLink } from '../../../utils/cozy';
import { serviceIds } from '../../../data/enums/services';

//Style & Img/Icon
import styles from './ExperienceReoPage.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { openWindow } from '../../../utils/openWindow';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';
import { useVisionsGalaxyData } from '../../../hooks/useVisionsGalaxyData';

// TMP CHANGE THIS TO BE DYNAMIC
const REO_EXPERIENCE_PLATFORMJOURNEY_ID = '62bc482233338f80344f9343';

const COZY_KONNECTORS = [serviceIds.orientoi, serviceIds.curiose, serviceIds.pitangoo];

const ExperienceReoPage = () => {
    const { user } = useAuth();
    const { _id, email } = user;

    const { updateUser } = useClient();

    const [experienceDataError, experienceDataLoading, experienceData] = useVisionsGalaxyData({
        path: `/userplatformjourneys/${REO_EXPERIENCE_PLATFORMJOURNEY_ID}/me`,
    });

    const [curentOffsetValue, setCurrentOffsetValue] = useState(10);
    const [hasSetCurrentOffsetValue, setHasSetCurrentOffsetValue] = useState(false);

    const [slideMultiplier, setSlideMultiplier] = useState(0);
    const [transformValue, setTransformValue] = useState(0);
    const [cardAmount, setCardAmount] = useState(0);

    const refSlideRight = useRef(null);
    const refSlideLeft = useRef(null);

    const [gaugePercentage, setGaugePercentage] = useState(0);

    const updateCardWidth = (width) => {
        if (hasSetCurrentOffsetValue) return;
        if (width * 3 === curentOffsetValue) return;
        setCurrentOffsetValue(width * 3);
        setHasSetCurrentOffsetValue(true);
    };

    // Set experience context if existing
    useEffect(() => {
        if (!_id) return;
        const saveContext = async () => {
            const context = localStorage.getItem('context');
            if (!context || context === 'null') return;
            await updateUser({ context });
        };

        saveContext();
    }, [_id, updateUser]);

    // Set slider logic on data reception
    useEffect(() => {
        if (!refSlideLeft.current || !refSlideRight.current) return;
        if (experienceDataError || experienceDataLoading) return;

        setCardAmount(experienceData?.platformJourney?.platforms?.length);

        if (cardAmount < 4) {
            refSlideRight.current.style.display = 'none';
            refSlideLeft.current.style.display = 'none';
        } else if (slideMultiplier * 3 + 1 > cardAmount - 3) {
            refSlideRight.current.style.display = 'none';
            refSlideLeft.current.style.display = 'flex';
        } else if (slideMultiplier === 0) {
            refSlideLeft.current.style.display = 'none';
            refSlideRight.current.style.display = 'flex';
        }
        setTransformValue(curentOffsetValue * -slideMultiplier);
    }, [slideMultiplier, curentOffsetValue, cardAmount, experienceData, experienceDataError, experienceDataLoading]);

    // SetGaugePercentage on data reception
    useEffect(() => {
        if (experienceDataError) return;
        if (experienceDataLoading) return;
        if (!experienceData.userPlatformJourney) return;
        // No
        const platforms = experienceData.userPlatformJourney.platforms;

        const finishedPlatforms = getFinishedPlatformsNumber(platforms);

        setGaugePercentage(percentageOf(finishedPlatforms, platforms.length));
    }, [experienceDataError, experienceDataLoading, experienceData]);

    const getFinishedPlatformsNumber = (platforms) => {
        return platforms.reduce((prev, cur) => {
            return cur.status === 2 ? prev + 1 : prev;
        }, 0);
    };

    if (experienceDataLoading)
        return (
            <div className={styles.page}>
                <Loader />
            </div>
        );

    if (experienceDataError)
        return (
            <div className={styles.page}>
                <HeaderWithNavBar />
                <ExperiencesErrors />
            </div>
        );

    if (Array.isArray(experienceData) && !experienceData.length) {
        return (
            <div className={styles.page}>
                <HeaderWithNavBar />
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.experiences.index} />
            <div className={styles.main}>
                <div className={styles.content}>
                    <DescriptionExperience experience={experienceData.platformJourney} />

                    {/*Content display only on mobile device */}
                    <div className={styles.isMobile}>
                        <TitleBgCard title={'Information'} gradient={true} column={true} icon={fontAwesome.info}>
                            <p style={{ textAlign: 'center' }}>
                                Si vous avez besoin d'aide lors de votre parcours au sein des plateformes partenaires
                            </p>
                            <p className={styles.blue}>cliquez sur cette icone</p>
                            <img className={styles.iconReo} src={appImageAssets.icon.REO} alt="Icone RéOrientation" />
                        </TitleBgCard>
                        <CardBgBottom color={'gradient'}>
                            <ManualExperience experience={experienceData?.platformJourney} />
                        </CardBgBottom>
                    </div>

                    <CardBgBottom color={'blue'}>
                        <div
                            ref={refSlideRight}
                            className={`${styles.slider} ${styles.sliderRight}`}
                            onClick={() => setSlideMultiplier(slideMultiplier + 1)}
                        >
                            <i className={fontAwesome.angleRight}></i>
                        </div>
                        <div
                            ref={refSlideLeft}
                            className={`${styles.slider} ${styles.sliderLeft}`}
                            onClick={() => setSlideMultiplier(slideMultiplier - 1)}
                        >
                            <i className={fontAwesome.angleLeft}></i>
                        </div>
                        <div className={styles.cards} style={{ transform: `translateX(${transformValue}px)` }}>
                            {experienceData.platformJourney !== null &&
                                experienceData.platformJourney.platforms.map((obj, index) => (
                                    <StepCardService
                                        key={index}
                                        service={obj.platform}
                                        cardAmount={experienceData.platformJourney.platforms.length}
                                        platformJourneyId={experienceData.platformJourney._id}
                                        userPlatformJourney={experienceData.userPlatformJourney}
                                        setCardWidthFc={updateCardWidth}
                                        setCardAmount={setCardAmount}
                                        id={_id}
                                    />
                                ))}
                        </div>
                        {experienceData.userPlatformJourney !== null && (
                            <ProgressBar
                                fillPercentage={gaugePercentage}
                                currentStep={getFinishedPlatformsNumber(experienceData.userPlatformJourney.platforms)}
                                totalSteps={experienceData.userPlatformJourney.platforms.length}
                            />
                        )}
                        {experienceData.userPlatformJourney === null && (
                            <ProgressBar
                                fillPercentage={gaugePercentage}
                                currentStep={0}
                                totalSteps={experienceData.platformJourney.platforms.length}
                            />
                        )}

                        {!experienceData?.userPlatformJourney && experienceData.platformJourney && (
                            <ShareBilanExperiences locked experienceName={experienceData.platformJourney.name} />
                        )}

                        {experienceData.userPlatformJourney && experienceData.platformJourney && (
                            <ShareBilanExperiences
                                locked={
                                    getFinishedPlatformsNumber(experienceData.userPlatformJourney.platforms) !==
                                    experienceData.userPlatformJourney.platforms.length
                                }
                                onClickFc={() => openWindow(generateCozyLink(_id, email, COZY_KONNECTORS))}
                                experienceName={experienceData?.platformJourney?.name}
                            />
                        )}
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.isMobile}>
                        <TitleBgCard title={'Information'} gradient={true} column={true} icon={fontAwesome.info}>
                            <p style={{ textAlign: 'center' }}>
                                Si vous avez besoin d'aide lors de votre parcours au sein des plateformes partenaires
                            </p>
                            <p className={styles.blue}>cliquez sur cette icone</p>
                            <img className={styles.iconReo} src={appImageAssets.icon.REO} alt="Icone RéOrientation" />
                        </TitleBgCard>
                        <CardBgBottom color={'gradient'}>
                            <ManualExperience experience={experienceData?.platformJourney} />
                        </CardBgBottom>
                        {experienceData.userPlatformJourney !== null && (
                            <PlatformJourneyGrade
                                grade={experienceData?.userPlatformJourney?.gradeGiven}
                                platformJourney={experienceData.platformJourney}
                                userId={_id}
                                userData={true}
                            />
                        )}
                        {experienceData.userPlatformJourney === null && (
                            <PlatformJourneyGrade
                                grade={experienceData.averageGrade}
                                platformJourney={experienceData.platformJourney}
                                userId={_id}
                                userData={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceReoPage;
