import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

// Components
import CardValues from '../../../Components/Cards/CardValues/CardValues';
import NavBarLandingPage from '../../../Components/NavBar/NavBarLandingPage/NavBarLandingPage';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import ContentExpTools from '../../../Components/ContentCards/ContentExpTools/ContentExpTools';
import OnlyLogoCard from '../../../Components/Cards/OnlyLogoCard/OnlyLogoCard';

// Utils
import { getSSOURL } from '../../../utils/sso';
import { trySetContext } from '../../../libs/experiences/context';
import useQuery from '../../../hooks/useQuery';

// Styles & Img/Icon
import styles from './HomePage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { openWindow } from '../../../utils/openWindow';
import ContainerFullWidth from '../../../Components/Containers/ContainerFullWidth/ContainerFullWidth';
import BarGradient from '../../../Components/Bars/BarGradient/BarGradient';
import ContainerFunctioningLandingPage from '../../../Components/Containers/ContainerFunctioningLandingPage/ContainerFunctioningLandingPage';
import TitleWithIcon from '../../../Components/Titles/TitleWithIcon/TitleWithIcon';
import Skeleton from '../../../Components/Skeletons/Skeleton/Skeleton';
import CarouselSlick from '../../../Components/Carousel/LibsCarousel/CarouselSlick/CarouselSlick';
import ImageBorder from '../../../Components/Image/ImageBorder/ImageBorder';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { serverCall } from '../../../utils/serverAPICall';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import { Button } from '../../../Components/Buttons/Button/Button';
import { Icon } from '../../../Components/Icons/Icon/Icon';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';

const HomePage = () => {
    const navigate = useNavigate();
    const { params } = useQuery();
    const context = params.get('context');
    const [servicesLoading, setServicesLoading] = useState(true);
    const [servicesError, setServicesError] = useState(false);
    const [services, setServices] = useState([]);
    /**
     * Redirects to the SSO Server
     * @param {string} type login || signup
     */
    const doAuth = (type) => {
        window.location.href = getSSOURL(type);
    };

    const doExperienceAuth = (type, storageKey) => {
        localStorage.setItem(storageKey, 'true');
        trySetContext(context);
        window.location.href = getSSOURL(type);
    };

    const doAuthBO = (type) => {
        // doExperienceAuth(type, 'isLoggingFromBO');
        localStorage.setItem('isLoggingFromBO', 'true');
        trySetContext(context);
        navigate('/public/bilan-orientation');
    };

    const doAuthREO = (type) => {
        doExperienceAuth(type, 'isLoggingFromREO');
    };

    useEffect(() => {
        let isMounted = true;

        const getPublicServices = async () => {
            try {
                const response = await serverCall.GET(`/public/services?filter=all&isSample=false`);
                if (!isMounted) return;
                if (!response) {
                    throw new Error('Could not retrieve services');
                }

                setServices(response?.services);
            } catch (error) {
                setServicesError(true);
            } finally {
                setServicesLoading(false);
            }
        };

        getPublicServices();

        return () => {
            isMounted = false;
        };
    }, []);

    return (
        <div className={styles.page}>
            {/* Example of solution for specific meta description (Helmet) */}
            <Helmet>
                <meta
                    name="description"
                    content="VisionsGalaxy offre une plateforme d'outils qui sont mis à votre disposition pour vous aider à saisir vos prochaines opportunités tout en gardant le contrôle sur vos données."
                />
            </Helmet>
            <NavBarLandingPage logo={appImageAssets.logo.VGonboarding} link={'/'}>
                <a href="/" className={styles.active}>
                    VisionsGalaxy
                </a>
                <span onClick={() => doAuthBO('signup')} className={styles.experiences}>
                    Bilan d'Orientation
                </span>
                <span onClick={() => doAuthREO('signup')} className={styles.experiences}>
                    Bilan de RéOrientation
                </span>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact@visionspol.eu">
                    Contact
                </a>
                <span className={styles.experiences}>
                    <Button variant="gradient" onclick={() => doAuth('login')} sx={{ fontWeight: 'bold' }}>
                        Me connecter
                    </Button>
                </span>
            </NavBarLandingPage>

            <ContainerFullWidth
                bgColor={'image'}
                sx={{
                    backgroundImage: `url(${appImageAssets.wallpaper.backgroundLandingPage})`,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1>Trouvez la formation ou l'emploi qui vous</h1>
                        <p className={styles.textBg}>correspond vraiment</p>
                    </div>

                    <p className={styles.description}>
                        Jouez avec des plateformes pour définir votre profil, les métiers et les secteurs qui vous
                        intéressent ainsi que vos compétences. Obtenez les meilleurs matchs à travers de nombreux
                        catalogues et candidatez en 2 clics sans CV ni lettre de motivation !
                    </p>

                    <div className={styles.btn}>
                        <Button
                            endIcon={<Icon icon="arrow" />}
                            size="largeMain"
                            variant="white"
                            onclick={() => doAuth('signup')}
                        >
                            M'inscrire
                        </Button>
                    </div>
                </div>
            </ContainerFullWidth>

            <ContainerFullWidth bgColor={'white'}>
                <div className={styles.functioning}>
                    <div className={styles.content}>
                        <div className={styles.header}>
                            <h2>Comment ça marche ?</h2>
                            <BarGradient />
                            <p>
                                Simple et ludique, VisionsGalaxy vous guide étape par étape pour construire votre
                                orientation et carrière professionnelle.
                            </p>
                        </div>
                        <div className={styles.infoSteps}>
                            <ContainerFunctioningLandingPage icon={appImageAssets.icon.inscription}>
                                <h3>Réalisez des missions et tâches pour définir votre profil</h3>
                                <p>
                                    et explorez vos matchs avec les offres d'emploi et de formation {'('}Indeed, Pôle
                                    Emploi, Onisep, CPF, ...{')'}.
                                </p>
                            </ContainerFunctioningLandingPage>
                            <ContainerFunctioningLandingPage icon={appImageAssets.icon.mission}>
                                <h3>Débloquez de nouveaux objectifs et missions</h3>
                                <p>pour progresser dans votre projet professionnel.</p>
                            </ContainerFunctioningLandingPage>
                            <ContainerFunctioningLandingPage icon={appImageAssets.icon.visionsStars}>
                                <h3>Gagnez à chaque étape</h3>
                                <p>
                                    des points échangeables contre des cadeaux {'('}places de concert, box culinaire,
                                    codes promo, ...{')'}.
                                </p>
                            </ContainerFunctioningLandingPage>
                        </div>
                        <Button
                            endIcon={<Icon icon="arrow" />}
                            size="largeMain"
                            variant="gradient"
                            onclick={() => doAuth('signup')}
                        >
                            M'inscrire
                        </Button>
                    </div>

                    <CarouselSlick type={1}>
                        <div className={styles.items} style={{ display: 'flex !important', justifyContent: 'center' }}>
                            <img src={appImageAssets.illustration.phoneCarouselOne} alt="" />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={appImageAssets.illustration.phoneCarouselTwo} alt="" />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <img src={appImageAssets.illustration.phoneCarouselThree} alt="" />
                        </div>
                    </CarouselSlick>
                </div>
            </ContainerFullWidth>

            <ContainerFullWidth>
                <section className={styles.matching}>
                    <h2 className={styles.matchingHeader}>
                        Des matchs ultra précis avec <br /> des offres de formation, d'emploi, de stage et d'alternance
                    </h2>

                    <div className={styles.matchingFlow}>
                        <div className={styles.matchingFlowStep}>
                            <ImageBorder size="small" image={appImageAssets.icon.experienceProfil}></ImageBorder>
                            <p>Complètez votre profil</p>
                        </div>

                        <i className={`${fontAwesome.arrowRight}`}></i>

                        <div className={styles.matchingFlowStep}>
                            <ImageBorder size="small" image={appImageAssets.icon.landingMatchs}></ImageBorder>
                            <p>Découvrez vos matchs avec les offres faites pour vous</p>
                        </div>

                        <i className={`${fontAwesome.arrowRight}`}></i>

                        <div className={styles.matchingFlowStep}>
                            <ImageBorder size="small" image={appImageAssets.icon.experienceBriefcase}></ImageBorder>
                            <p>Candidatez simplement en 2 cics</p>
                        </div>
                    </div>

                    <p className={styles.matchingDescription}>
                        Plus vous jouez avec les outils, plus vous précisez votre profil et donc vos matchs.
                    </p>

                    <ButtonContainer>
                        <Button
                            size="main"
                            variant="gradient"
                            endIcon={<Icon icon="arrow" />}
                            onclick={() => doAuth('signup')}
                        >
                            Je me fais matcher
                        </Button>
                    </ButtonContainer>
                </section>
            </ContainerFullWidth>

            <ContainerFullWidth bgColor={'blue'}>
                <div className={styles.rewards}>
                    <div className={styles.rewardsHeader}>
                        <img src={appImageAssets.logo.gift} alt="Icone Cadeaux" />
                        <p>À gagner en ce moment</p>
                    </div>
                    <div className={styles.rewardsPartner}>
                        <CardBgBottom>
                            <div className={styles.rewardsPartnerContent}>
                                <img
                                    src={appImageAssets.partnerRewards.CulturCity}
                                    style={{ height: 50 }}
                                    alt="Logo Cultur in the City"
                                />
                                <p className={styles.rewardsPartnerContentText}>
                                    <span>15%</span> de réduction sur les coffrets.
                                </p>
                                <p className={styles.rewardsPartnerContentText}>
                                    Des places pour assister à des <span>concerts, one-man show</span> et{' '}
                                    <span>pièces de théâtre.</span>
                                </p>
                                <ButtonContainer>
                                    <Button
                                        endIcon={<Icon icon="arrow" />}
                                        size="smallMain"
                                        onclick={() => openWindow('https://culturinthecity.com/')}
                                        sx={{ color: '#fff' }}
                                    >
                                        Découvrir
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardBgBottom>
                        <CardBgBottom>
                            <div className={styles.rewardsPartnerContent}>
                                <img src={appImageAssets.partnerRewards.CoqGourmet} alt="Logo le Coq Gourmet" />
                                <p className={styles.rewardsPartnerContentText}>
                                    Des réductions de <span>5€ à 20€</span> sur les abonnements aux Box Découverte et
                                    Dégustation.
                                </p>
                                <p className={styles.rewardsPartnerContentText}>
                                    Des <span>Box Découverte.</span>
                                </p>
                                <ButtonContainer>
                                    <Button
                                        endIcon={<Icon icon="arrow" />}
                                        size="smallMain"
                                        onclick={() => openWindow('https://lecoqgourmet.fr/')}
                                        sx={{ color: '#fff' }}
                                    >
                                        Découvrir
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardBgBottom>
                        <CardBgBottom>
                            <div className={styles.rewardsPartnerContent}>
                                <img src={appImageAssets.partnerRewards.HubbyInnovation} alt="Logo Hubby Innovation" />
                                <p className={styles.rewardsPartnerContentText}>
                                    <span>10% de réduction</span>
                                    <br />
                                    sur des centaines de produits qui révolutionnent votre quotidien.
                                </p>
                                <ButtonContainer>
                                    <Button
                                        endIcon={<Icon icon="arrow" />}
                                        size="smallMain"
                                        onclick={() => openWindow('https://huby-innovation.com/')}
                                        sx={{ color: '#fff' }}
                                    >
                                        Découvrir
                                    </Button>
                                </ButtonContainer>
                            </div>
                        </CardBgBottom>
                    </div>
                </div>
            </ContainerFullWidth>

            <ContainerFullWidth sx={{ padding: '50px 0' }}>
                <section className={styles.expTools}>
                    <div className={styles.expToolsHeader}>
                        <img src={appImageAssets.icon.laptop} alt="Icone ordinateur portable" />
                        <h2>+ de 30 outils de disponibles en une seule inscription pour booster votre avenir</h2>
                    </div>
                    <div style={{ alignSelf: 'flex-start' }}>
                        <TitleWithIcon sx={{ padding: '0 50px' }} darkenImg>
                            Les exclusivités
                        </TitleWithIcon>
                    </div>
                    <div className={styles.expCardContainer}>
                        <CardBgBottom color={'blue'}>
                            <ContentExpTools
                                icon={appImageAssets.icon.REO}
                                title={'Bilan de réorientation'}
                                text={
                                    'Construisez votre projet professionnel en réalisant ce parcours innovant et découvrez les formations que vous pouvez entreprendre !'
                                }
                                onClickFC={() => doAuthREO('signup')}
                            />
                        </CardBgBottom>
                        <CardBgBottom>
                            <ContentExpTools
                                icon={appImageAssets.icon.BO}
                                title={"Bilan d'orientation"}
                                text={
                                    "Apprenez à travers un parcours ludique à mieux vous connaître et explorez les opportunités qui s'offrent à vous !"
                                }
                                btnColor={'blue'}
                                onClickFC={() => doAuthBO('signup')}
                            />
                        </CardBgBottom>
                    </div>

                    <div style={{ alignSelf: 'flex-start' }}>
                        <TitleWithIcon sx={{ padding: '0 50px' }}>Les outils partenaires</TitleWithIcon>
                    </div>

                    <div className={styles.carousel}>
                        {servicesLoading && (
                            <div style={{ display: 'inline-flex', gap: 20, paddingBottom: 40 }}>
                                {[...Array(5)].map((skeleton, index) => (
                                    <Skeleton
                                        key={index}
                                        parent
                                        parentSx={{ width: 190, height: 110, padding: 10 }}
                                        rounded={20}
                                    >
                                        <Skeleton width={'100%'} height={90}></Skeleton>
                                    </Skeleton>
                                ))}
                            </div>
                        )}
                        {servicesError && (
                            <TextContainer>
                                Une erreur est survenue lors du chargement des différents partenaires
                            </TextContainer>
                        )}
                    </div>

                    <CarouselSlick type={2}>
                        {services?.map((service, index) => (
                            <div key={index} className={styles.carouselItem}>
                                <OnlyLogoCard service={service} size={'small'} isPublic></OnlyLogoCard>
                            </div>
                        ))}
                    </CarouselSlick>

                    <div className={styles.btn}>
                        <Button
                            endIcon={<Icon icon="arrow" />}
                            size="largeMain"
                            variant="gradient"
                            onclick={() => doAuth('signup')}
                        >
                            M'inscrire
                        </Button>
                    </div>
                </section>
            </ContainerFullWidth>

            <div className={styles.values}>
                <div className={styles.headerValues}>
                    <div className={styles.title}>
                        <div className={styles.miniLogo}>
                            <img src={appImageAssets.icon.VGonboarding} alt="" />
                        </div>
                        <h2>
                            À Propos de <span>Visions</span>
                        </h2>
                    </div>
                </div>
                <div className={styles.contentValues}>
                    <div className={styles.information}>
                        <div className={styles.textContent}>
                            <p>
                                Visions est une start-up dans le domaine de la portabilité des données qui permet
                                d'interconnecter des outils et de partager des données de compétences avec le
                                consentement de la personne vers les organismes de leur choix. Ainsi les données des
                                individus peuvent être utilisées pour les aider à trouver leur voie, leur métier, leur
                                information ou leur emploi.
                            </p>
                        </div>
                        <div className={styles.cardContainerValues}>
                            <CardValues title={'RGPD'} logo={appImageAssets.icon.shield} />
                            <CardValues title={'Human centric'} logo={appImageAssets.icon.human} />
                            <CardValues title={'Subventionné'} logo={appImageAssets.icon.UEFlag} filter={false} />
                            <CardValues title={'Sécurisé'} logo={appImageAssets.icon.security} />
                        </div>
                        <div className={styles.btnValues}>
                            <Button
                                endIcon={<Icon icon="arrow" />}
                                size="largeMain"
                                onclick={() => openWindow('https://visionspol.eu/')}
                                sx={{ color: '#fff' }}
                            >
                                En savoir +
                            </Button>
                        </div>
                    </div>
                    <div className={styles.illustration}>
                        <img src={appImageAssets.illustration.laptop} alt="" />
                    </div>
                </div>
            </div>

            <footer>
                <div className={styles.footerContent}>
                    <div className={styles.footerText}>
                        <a href="/terms-of-use">Conditions générales d'utilisation</a>
                    </div>
                    <div className={styles.footerText}>
                        <a href="/legal">Mentions légales</a>
                    </div>
                    <p className={styles.footerText}>{new Date().getFullYear()} - Visions tout droits réservés</p>
                </div>
            </footer>
        </div>
    );
};

export default HomePage;
