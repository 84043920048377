import React from 'react';

// Components
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import ServiceCard from '../../../Components/Cards/ServiceCard/ServiceCard';
import CardToggleContainer from '../../../Components/Cards/CardToggleContainer/CardToggleContainer';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import OnlyLogoCard from '../../../Components/Cards/OnlyLogoCard/OnlyLogoCard';
import RowEdtechs from '../../../Components/Row/RowEdtechs';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import Loader from '../../../Components/Loaders/Loader/Loader';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import DataNotFound from '../../../Components/ContentUnavailable/DataNotFound';
import GlobalError from '../../../Components/Errors/GlobalError/GlobalError';
import InfoCardUserStars from '../../../Components/Cards/InfoCard/InfoCardUserStars/InfoCardUserStars';

// Hooks & Utils
import useVisionsStars from '../../../hooks/useVisionsStars';
import { appLinks } from '../../../data/enums/appLinks';

// Style & Img/Icon
import styles from './CatalogPage.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { filterCozyFromServices } from '../../../utils/cozy';
import SkeletonServiceCard from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonServiceCard/SkeletonServiceCard';
import SkeletonInfoUserVisionsStars from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonInfoUserVisionsStars/SkeletonInfoUserVisionsStars';
import { useServices } from '../../../hooks/useServices';
import { useAuth } from '../../../hooks/useAuth';
import { Button } from '../../../Components/Buttons/Button/Button';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';

const CatalogPage = () => {
    const { user } = useAuth();
    const { id: userId } = user;

    const [topVisionsError, topVisionsLoading, topVisionsData] = useServices({
        filter: 'top',
        isSample: 'true',
        isPublic: true,
    });
    const [lastUsedError, lastUsedLoading, lastUsedData] = useServices({
        filter: 'lastUsed',
        isSample: 'true',
        isPublic: true,
    });
    const [orientationError, orientationLoading, orientationData] = useServices({
        filter: 'orientation',
        isSample: 'true',
        isPublic: true,
    });
    const [professionnalError, professionnalLoading, professionnalData] = useServices({
        filter: 'professionnal',
        isSample: 'true',
        isPublic: true,
    });
    const [skillsError, skillsLoading, skillsData] = useServices({ filter: 'skills', isPublic: true });
    const [internshipError, internshipLoading, internshipData] = useServices({
        filter: 'internship',
        isSample: 'true',
        isPublic: true,
    });
    const [jobError, jobLoading, jobData] = useServices({ filter: 'job', isSample: 'true', isPublic: true });

    const [visionsStarsError, visionsStarsLoading, visionsStars] = useVisionsStars(userId);

    return (
        <>
            <HeaderWithNavBar tabActive={2} />
            <div className={styles.main}>
                <div className={styles.catalogue}>
                    {/* NEW EDTECHS */}
                    {/* {newLoading && <Loader />}
                    {newError && <GlobalError flexDirection={'row'} />}
                    {newData.services && newData.services !== undefined && (
                        <TitleBgCard icon={fontAwesome.bulb} title={'Les Nouveautés'}>
                            {newData.services.map((service, index) => (
                                <ServiceCard key={index} service={service} index={index} />
                            ))}
                        </TitleBgCard>
                    )} */}
                    <div
                        className={styles.experiencesCard}
                        style={{ backgroundImage: `url(${appImageAssets.wallpaper.experiences})` }}
                    >
                        <div className={styles.experiencesCardImage}>
                            <img
                                src={appImageAssets.illustration.experiences}
                                alt="Illustration Experiences Visions Galaxy"
                            />
                        </div>
                        <div className={styles.experiencesCardContent}>
                            <div className={styles.exclu}>Exclusivité VisionsGalaxy</div>
                            <h3>Vous souhaitez-vous orienter ou vous réorienter ?</h3>
                            <p>
                                Découvrez les outils bilan d'orientation et de réorientation ! Des parcours ludiques
                                composés de plusieurs outils qui vous permettent d'être matché, puis trouvez les bonnes
                                formations !
                            </p>
                            <Button
                                variant="gradient"
                                size="smallMain"
                                navigate={appLinks.experiences.index}
                                className={styles.button}
                            >
                                Voir les outils
                            </Button>
                        </div>
                    </div>

                    <div className={`${styles.cardInfo} ${styles.isMobile}`}>
                        {visionsStarsLoading && <SkeletonInfoUserVisionsStars />}
                        {!visionsStarsLoading && visionsStarsError && <div>ERROR</div>}
                        {!visionsStarsLoading && !visionsStarsError && visionsStars !== undefined && (
                            <InfoCardUserStars totalStars={visionsStars} succinct={true} />
                        )}
                    </div>

                    {/* CATALOG BY CATEORY */}
                    <CardBgBottom>
                        <RowEdtechs title={'Compétence'} link={`${appLinks.catalog.category}/skills`}>
                            {skillsLoading && <SkeletonServiceCard />}
                            {skillsError && <GlobalError flexDirection={'row'} />}
                            {skillsData.services &&
                                filterCozyFromServices(skillsData.services).map((service, index) => {
                                    if (index > 1) return null;
                                    return <ServiceCard key={index} service={service} index={index} />;
                                })}
                            {!skillsError &&
                                !skillsLoading &&
                                (skillsData.services?.length === 0 || !skillsData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </RowEdtechs>
                    </CardBgBottom>
                    <CardBgBottom>
                        <RowEdtechs title={'Orientation'} link={`${appLinks.catalog.category}/orientation`}>
                            {orientationLoading && <SkeletonServiceCard />}
                            {orientationError && <GlobalError flexDirection={'row'} />}
                            {orientationData.services &&
                                orientationData.services.map((service, index) => (
                                    <ServiceCard key={index} service={service} index={index} />
                                ))}
                            {!orientationError &&
                                !orientationLoading &&
                                (orientationData.services?.length === 0 || !orientationData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </RowEdtechs>
                    </CardBgBottom>
                    <div className={styles.BO} style={{ backgroundImage: `url(${appImageAssets.wallpaper.BO})` }}>
                        <div className={styles.contentBO}>
                            <div className={styles.rewards}>
                                <img src={appImageAssets.icon.visionsStarsGradient} alt="" />
                                <span>100</span>
                            </div>
                            <div className={styles.descriptionBO}>
                                <img src={appImageAssets.icon.BO} alt="" />
                                <h4>Bilan d'Orientation</h4>
                                <p>
                                    Apprenez à travers un parcours ludique à mieux vous connaître et explorez les
                                    métiers qui s'offrent à vous ! Puis faites vous matcher avec des offres de
                                    formations !
                                </p>
                            </div>
                        </div>
                        <ButtonContainer margin={'10px 0 0 0'}>
                            <Button
                                size="small"
                                navigate={appLinks.experiences.bilan_orientation}
                                sx={{ color: 'white' }}
                            >
                                En savoir +
                            </Button>
                        </ButtonContainer>
                    </div>
                    <CardBgBottom>
                        <RowEdtechs title={'Stage'} link={`${appLinks.catalog.category}/internship`}>
                            {internshipLoading && <SkeletonServiceCard />}
                            {internshipError && <GlobalError flexDirection={'row'} />}
                            {internshipData.services &&
                                internshipData.services.map((service, index) => (
                                    <ServiceCard key={index} service={service} index={index} />
                                ))}
                            {!internshipError &&
                                !internshipLoading &&
                                (internshipData.services?.length === 0 || !internshipData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </RowEdtechs>
                    </CardBgBottom>
                    <CardBgBottom>
                        <RowEdtechs title={'Professionnalisation'} link={`${appLinks.catalog.category}/professionnal`}>
                            {professionnalLoading && <SkeletonServiceCard />}
                            {professionnalError && <GlobalError flexDirection={'row'} />}
                            {professionnalData.services &&
                                professionnalData.services.map((service, index) => (
                                    <ServiceCard key={index} service={service} index={index} />
                                ))}
                            {!professionnalError &&
                                !professionnalLoading &&
                                (professionnalData.services?.length === 0 || !professionnalData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </RowEdtechs>
                    </CardBgBottom>
                    <CardBgBottom>
                        <RowEdtechs title={'Emploi'} link={`${appLinks.catalog.category}/job`}>
                            {jobLoading && <SkeletonServiceCard />}
                            {jobError && <GlobalError flexDirection={'row'} />}
                            {jobData.services &&
                                jobData.services.map((service, index) => (
                                    <ServiceCard key={index} service={service} index={index} />
                                ))}
                            {!jobError && !jobLoading && (jobData.services?.length === 0 || !jobData.services) && (
                                <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                            )}
                        </RowEdtechs>
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={`${styles.cardInfo} ${styles.isMobile}`}>
                        {visionsStarsLoading && <SkeletonInfoUserVisionsStars />}
                        {!visionsStarsLoading && visionsStarsError && <div>ERROR</div>}
                        {!visionsStarsLoading && !visionsStarsError && visionsStars !== undefined && (
                            <InfoCardUserStars totalStars={visionsStars} succinct={true} />
                        )}
                    </div>
                    {/* TOP VISIONS */}
                    <div className={styles.topVisions}>
                        <TitleBgCard icon={fontAwesome.star} title={'Top VisionsGalaxy'} gradient column={true}>
                            {topVisionsLoading && <SkeletonServiceCard topService />}
                            {topVisionsError && <GlobalError />}
                            {topVisionsData.services &&
                                topVisionsData.services.map((service, index) => (
                                    <ServiceCard key={index} service={service} index={index} bgGradient={true} />
                                ))}
                            {!topVisionsError &&
                                !topVisionsLoading &&
                                (topVisionsData.services?.length === 0 || !topVisionsData.services) && (
                                    <DataNotFound text={"Aucune donnée n'a été trouvée"} />
                                )}
                        </TitleBgCard>
                    </div>

                    {/* LATEST EDTECHS USED */}
                    {lastUsedLoading && (
                        <CardToggleContainer
                            title={'Plateformes visitées'}
                            gradient={true}
                            noData={true}
                            minHeight={240}
                            showToggle={false}
                        >
                            <Loader />
                        </CardToggleContainer>
                    )}
                    {lastUsedError && (
                        <CardToggleContainer
                            title={'Plateformes visitées'}
                            gradient={true}
                            noData={true}
                            minHeight={240}
                            showToggle={false}
                        >
                            <GlobalError sxImg={{ margin: 0, maxHeight: 100 }} />
                        </CardToggleContainer>
                    )}
                    {lastUsedData?.services?.length !== 0 && (
                        <CardToggleContainer
                            title={'Plateformes visitées'}
                            gradient={true}
                            minHeight={240}
                            showToggle={lastUsedData?.services?.length > 2}
                        >
                            {lastUsedData?.services?.map((service, index) => (
                                <OnlyLogoCard key={index} service={service} />
                            ))}
                        </CardToggleContainer>
                    )}

                    {!lastUsedError &&
                        !lastUsedLoading &&
                        (lastUsedData.services?.length === 0 || !lastUsedData.services) && (
                            <CardToggleContainer
                                title={'Plateformes visitées'}
                                gradient={true}
                                bgGradient={true}
                                noData={true}
                                minHeight={240}
                                showToggle={false}
                            >
                                <DataNotFound text={"Aucune donnée n'a été trouvée"} maxHeightImg={100} />
                            </CardToggleContainer>
                        )}

                    <div className={styles.cardLink}>
                        <LinkCardContainer whichCardsArray={['event']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default CatalogPage;
