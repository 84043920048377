import React from 'react';
import styles from './cardsteppath.module.scss';

const CardStepPath = ({ logo, textContent, partner }) => {
    return (
        <div className={styles.card}>
            <div className={styles.logoPartner}>
                <img src={logo} alt={`logo ${partner}`} />
            </div>
            <div className={styles.content}>
                <p>{textContent}</p>
            </div>
        </div>
    );
};

export default CardStepPath;
