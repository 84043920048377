import React from 'react';
import styles from './cardprerequisite.module.scss';

const CardPrerequisite = ({ picto, textContent }) => {
    return (
        <div className={styles.tool}>
            <div className={styles.picto} style={{ backgroundImage: `url(${picto})` }}></div>
            <p>{textContent}</p>
        </div>
    );
};

export default CardPrerequisite;
