import React from 'react';
import styles from './ContainerFullWidth.module.scss';

const ContainerFullWidth = ({ bgColor, sx= {}, children }) => {
    const backgroundColor = (color) => {
        switch (color) {
            case 'blue':
                return styles.blue;
            case 'white':
                return styles.white;
            case 'image':
                return styles.bgImage;
            default:
                return '';
        }
    };
    return <div style={{...sx}} className={`${styles.container} ${backgroundColor(bgColor)}`}>{children}</div>;
};

export default ContainerFullWidth;
