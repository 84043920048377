import React from 'react';
import styles from './Headercard.module.scss';

const HeaderCard = ({
    image = '',
    icon = '',
    alt = '',
    mainTitle = '',
    subTitle = '',
    className,
    color = '',
    bgImage = false,
}) => {
    const displayGlobalColor = (color) => {
        switch (color) {
            case 'light':
                return styles.light;
            case 'lightOpacity':
                return styles.lightOpacity;
            case 'transparent':
                return styles.transparent;
            default:
                return '';
        }
    };
    return (
        <header
            className={`${styles.header} ${className} ${displayGlobalColor(color)} ${bgImage ? styles.bgImage : ''}`}
        >
            <div className={styles.imageContainer}>
                {image && <img src={image} alt={alt} />}
                {icon && <i className={icon}></i>}
            </div>
            <div className={styles.titles}>
                {mainTitle && <h3>{mainTitle}</h3>}
                {subTitle && <span>{subTitle}</span>}
            </div>
        </header>
    );
};

export default HeaderCard;
