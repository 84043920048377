import React from 'react';
import styles from './infocarduserstars.module.scss';

import { appImageAssets } from '../../../../data/enums/appImageAssets';

const InfoCardUserStars = ({ totalStars, succinct = false }) => {
    let displayStars;
    if (totalStars !== undefined) {
        displayStars = totalStars;
    }
    return (
        <section className={`${styles.card} ${styles.cardCatalog}`}>
            {succinct ? (
                <>
                    <div className={styles.img}>
                        <img src={appImageAssets.icon.visionsStars} alt="Visions Stars" />
                    </div>
                    <div className={styles.score}>
                        <span className={styles.count}>{displayStars}</span>
                        <p>VisionsStars gagnées</p>
                    </div>
                </>
            ) : (
                <>
                    <div className={styles.img}>
                        <img src={appImageAssets.logo.gift} alt="Cadeau" />
                    </div>
                    <div className={styles.textContent}>
                        <h3>Boutique cadeaux</h3>
                        <p>
                            Retrouvez ici tout les cadeaux que vous pouvez échanger contre vos VisionsStars : <br />{' '}
                            produit, chèque cadeau, place à un évènement, code promo et bien d'autre encore !
                        </p>
                    </div>
                    <div className={styles.score}>
                        <p>Votre solde s'élève à</p>
                        <div className={styles.round}>
                            <img src={appImageAssets.icon.visionsStars} alt="" />
                            <span>{displayStars}</span>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
};

export default InfoCardUserStars;
