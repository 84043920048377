import React from 'react';
import { appImageAssets } from '../../data/enums/appImageAssets';
import { AVATAR_DATA } from '../../libs/gamification/avatars';
import styles from './avatar.module.scss';
import { useAuth } from '../../hooks/useAuth';

const Avatar = ({ avatarSelected, updateAvatarSelectedFC, profile = false }) => {
    const { user } = useAuth();

    return (
        <div className={styles.avatarContainer}>
            {Object.entries(AVATAR_DATA).map((avatar, index) => {
                if (profile && user?.gamification.avatar === JSON.parse(avatar[0])) return null;
                return (
                    <div
                        className={`${styles.avatar} ${avatarSelected === index ? styles.selected : ''}`}
                        onClick={() => {
                            updateAvatarSelectedFC(index);
                        }}
                        key={index}
                    >
                        <img
                            src={avatar[1]}
                            alt="Avatar"
                            style={{ transform: avatar[0] === '3' && 'translateX(-10px)' }}
                        />
                        <img
                            src={
                                avatarSelected === index
                                    ? appImageAssets.illustration.platformCheck
                                    : appImageAssets.illustration.platform
                            }
                            alt={`Plateforme`}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default Avatar;
