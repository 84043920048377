import React from 'react';
import styles from './rowstatsnote.module.scss';
import StarGradient from '../../img/StarGradient.svg';

const RowStatsNote = ({ rank, totalNote, percentage }) => {
    const displayPercentage = percentage(totalNote) + '%';

    return (
        <div className={styles.row}>
            <div className={styles.rank}>
                <p>{rank}</p>
                <img src={StarGradient} alt="star" />
            </div>
            <div className={styles.gauge}>
                <div className={styles.meter} style={{ width: displayPercentage }}></div>
            </div>
            <p className={styles.totalNote}>{totalNote} personnes</p>
        </div>
    );
};

export default RowStatsNote;
