export const fontAwesome = {
    bulb: 'fa-solid fa-lightbulb',
    star: 'fa-regular fa-star',
    info: 'fa-solid fa-circle-info',
    angleLeft: 'fa-solid fa-angle-left',
    angleRight: 'fa-solid fa-angle-right',
    angleTop: 'fa-solid fa-angle-up',
    angleBottom: 'fa-solid fa-angle-down',
    lock: 'fa-solid fa-lock',
    unLock: 'fa-solid fa-unlock',
    mobilePhone: 'fa-solid fa-mobile-button',
    question: 'fa-regular fa-circle-question',
    plusCircle: 'fa-solid fa-circle-plus',
    handshake: 'fa-solid fa-handshake-simple',
    share: 'fa-solid fa-share',
    danger: 'fa-solid fa-triangle-exclamation',
    rotate: 'fa-solid fa-rotate-left',
    gift: 'fa-solid fa-gift',
    globe: 'fa-solid fa-globe',
    location: 'fa-solid fa-location-dot',
    user: 'fa-solid fa-user',
    clock: 'fa-solid fa-clock',
    pen: 'fa-solid fa-pen',
    euro: 'fa-solid fa-euro-sign',
    faceSmile: 'fa-solid fa-face-smile',
    faceNeutral: 'fa-solid fa-face-meh',
    faceDown: 'fa-solid fa-face-frown',
    arrowRight: 'fas fa-arrow-right',
    arrowleft: 'fas fa-arrow-left',
    check: 'fa-solid fa-circle-check',
    clockRotate: 'fa-solid fa-clock-rotate-left',
};
