import { useContext } from 'react';
import ClientContext from '../context/ClientProvider';

/**
 * The client instance for making API calls to the VisionsGalaxy server
 */
export const useClient = () => {
    const context = useContext(ClientContext);
    if (!context) {
        throw new Error('useClient must be used within a ClientContext');
    }

    return context;
};
