import { appImageAssets } from '../enums/appImageAssets';

export const stepsContentOnboarding = {
    welcome: {
        title: 'Bienvenue dans',
        VG: '<span>Visions</span>Galaxy',
        img: appImageAssets.icon.shortVG,
        text: "Embarquez dans une aventure qui va vous permettre d'atteindre tous vos objectifs professionnels. Progressez en vous amusant à travers des missions qui vous guideront tâche après tâche pour vous matcher avec les meilleures offres d'emploi et de formation.",
    },
    welcomeFormation: {
        title: 'Bienvenue dans',
        VG: '<span>Visions</span>Galaxy',
        img: appImageAssets.icon.shortVG,
        text: "Embarquez dans une aventure qui va vous permettre d'atteindre tous vos objectifs professionnels. Progressez en vous amusant à travers des missions qui vous guideront tâche après tâche pour vous matcher avec les meilleures offres de formation.",
    },
    welcomeGen: {
        title: 'Bienvenue dans',
        VG: '<span>Visions</span>Galaxy',
        img: appImageAssets.icon.shortVG,
        text: "Embarquez dans une aventure qui va vous permettre d'atteindre tous vos objectifs professionnels. Progressez en vous amusant à travers des missions qui vous guideront tâche après tâche pour vous matcher avec les meilleures offres de formation dans le numérique issues du catalogue de la Grande École du Numérique.",
    },
    progress: {
        title: 'Progressez et trouvez la voie qui vous correspond',
        img: appImageAssets.illustration.progressOnboarding,
        text: 'Effectuez des missions et explorez de nouveaux outils pour : <br /> - Gagner des VisionsStars échangeables contre des cadeaux',
    },
    information: {
        title: "Effectuez des missions pour trouver la formation ou l'emploi qui vous correspond",
        img: appImageAssets.illustration.progressOnboarding,
        text: "Chaque mission est découpée en tâches à réaliser pour définir votre profil afin de vous proposer les meilleurs matchs avec les offres d'emploi et de formation. <br /><br /> Pour récompenser votre progression, chaque tâche vous fait gagner des VisionStars échangeables contre des cadeaux.",
    },
    informationGen: {
        title: 'Effectuez des missions pour trouver la formation qui vous correspond',
        img: appImageAssets.illustration.progressOnboarding,
        text: 'Chaque mission est découpée en tâches à réaliser pour définir votre profil afin de vous proposer les meilleurs matchs avec les offres de formation dans le numérique issues du catalogue de la Grande École du Numérique. <br /><br /> Pour récompenser votre progression, chaque tâche vous fait gagner des VisionStars échangeables contre des cadeaux.',
    },
    informationFormation: {
        title: 'Effectuez des missions pour trouver la formation qui vous correspond',
        img: appImageAssets.illustration.progressOnboarding,
        text: 'Chaque mission est découpée en tâches à réaliser pour définir votre profil afin de vous proposer les meilleurs matchs avec les offres de formation. <br /><br /> Pour récompenser votre progression, chaque tâche vous fait gagner des VisionStars échangeables contre des cadeaux.',
    },
    results: {
        title: "Générez votre bilan d'aventure",
        img: appImageAssets.illustration.resultOnboarding,
        text: "Retrouvez tous vos accomplissements dans votre bilan d'aventure disponible dans votre coffre fort Cozy ! <br /> Visualisez et partagez votre bilan pour : <br /><br /> Vous faire accompagner par un conseiller d'orientation <br /> Matcher et postuler à des offres de formation, d'emploi, de stage ou d'alternance <br /><br /> Effectuez ces actions en toute sécurité et en gardant la maîtrise de vos données !",
    },
    resultsFormation: {
        title: "Générez votre bilan d'aventure",
        img: appImageAssets.illustration.resultOnboarding,
        text: "Retrouvez tous vos accomplissements dans votre bilan d'aventure disponible dans votre coffre fort Cozy ! <br /> Visualisez et partagez votre bilan pour : <br /><br /> Vous faire accompagner par un conseiller d'orientation <br /> Matcher et postuler à des offres de formation <br /><br /> Effectuez ces actions en toute sécurité et en gardant la maîtrise de vos données !",
    },
    matchs: {
        title: 'Découvrez vos matchs et candidatez en 2 clics',
        img: appImageAssets.illustration.matchsOnboarding,
        text: "Explorez une sélection personnalisée d'offres de formation, d'emploi, de stage ou d'alternance selon votre profil et candidatez simplement en 2 clics.",
    },
    matchsFormation: {
        title: 'Découvrez vos matchs et candidatez en 2 clics',
        img: appImageAssets.illustration.matchsOnboarding,
        text: "Explorez une sélection personnalisée d'offres de formation selon votre profil et candidatez simplement en 2 clics.",
    },
};
