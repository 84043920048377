import React, { useEffect, useState } from 'react';
import Loader from '../Components/Loaders/Loader/Loader';
import { useAuth } from '../hooks/useAuth';
import { useClient } from '../hooks/useClient';

/**
 * Applies the contexts to the user if set in localStorage
 */
export const ContextApplier = ({ children }) => {
    const { user } = useAuth();
    const { updateUser, client } = useClient();

    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (ready) return;
        let isMounted = true;
        (async () => {
            try {
                const payload = {};

                const context = localStorage.getItem('context');
                const experience = localStorage.getItem('experience');

                // Context
                if (context && context !== user?.experiencesInfo?.context) payload.context = context;

                // Experience
                try {
                    if (experience) {
                        await client.POST(`/userplatformjourneys/${experience}/me`);
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    localStorage.removeItem('experience');
                }

                if (Object.keys(payload).length > 0) await updateUser({ ...payload });
            } catch (err) {
                console.log(err);
            } finally {
                if (!isMounted) return;
                setReady(true);
            }
        })();

        return () => {
            isMounted = false;
        };
    }, [user, updateUser, client, ready]);

    if (!ready) return <Loader text="Checking context..." />;
    return <>{children}</>;
};
