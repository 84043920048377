import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonMissionCard = ({ completed = false }) => {
    return (
        <Skeleton parent rounded={20}>
            <div style={{ padding: '10px 15px' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                    <Skeleton variant={'circular'} height={45} width={45} sx={{ marginRight: 5 }}></Skeleton>
                    <Skeleton variant={'text'} width={240}></Skeleton>
                </div>
                {!completed && (
                    <>
                        <Skeleton height={95} rounded={10}></Skeleton>
                        <Skeleton height={10} sx={{ margin: '5px 0' }}></Skeleton>
                    </>
                )}
                {completed && (
                    <>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <Skeleton variant={'circular'} height={45} width={45} sx={{ marginRight: 5 }}></Skeleton>
                            <Skeleton variant={'text'} width={240}></Skeleton>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <Skeleton variant={'circular'} height={45} width={45} sx={{ marginRight: 5 }}></Skeleton>
                            <Skeleton variant={'text'} width={240}></Skeleton>
                        </div>
                    </>
                )}
            </div>
        </Skeleton>
    );
};

export default SkeletonMissionCard;
