import { useCallback } from 'react';
import { useClient } from './useClient';

export const useUserMissionsMethods = () => {
    const { client } = useClient();

    /**
     * Adds an action to track in a task to the user mission completion array
     * @param {string} taskId The ID of the task
     * @param {object} action The action to save
     * @param {'service' | 'consent' | 'redirect'} action.type The type of action
     * @param {string} action.resource The resource of the action. For example the ID of the selected service
     */
    const addAction = useCallback(
        async (taskId, action) => {
            const res = await client.POST(`/usermissions/tasks/${taskId}/action`, { action });
            return res;
        },
        [client],
    );

    return { addAction };
};
