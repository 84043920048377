import React from 'react';
import Tinder from '../../img/Tinder.svg';
import { Link } from 'react-router-dom';
import Univers from '../../img/Univers.svg';
import HeaderVisionsGalaxy from '../../Components/Header/HeaderVisionsGalaxy';
import styles from './ChoicePage.module.scss';
import { appLinks } from '../../data/enums/appLinks';

class ChoicePage extends React.Component {
    render() {
        return (
            <>
                <div className={styles.choice}>
                    <HeaderVisionsGalaxy />
                    <div className={styles.content}>
                        <Link to={appLinks.home} className={styles.card}>
                            <img src={Univers} alt="" />
                            <p>Je plonge dans l'univers VisionsGalaxy</p>
                        </Link>
                        <Link to={appLinks.visionsSwipe} className={styles.card}>
                            <img src={Tinder} alt="" />
                            <p>Je me laisse guider en commençant avec un jeu pour définir mes besoins</p>
                        </Link>
                    </div>
                </div>
            </>
        );
    }
}
export default ChoicePage;
