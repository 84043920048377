import React from 'react';
import GenericBtn from '../Buttons/GenericBtn/GenericBtn';
import styles from './rowedtechs.module.scss';

const RowEdtechs = ({ title, link, bgInterval = true, children }) => {
    const displayBg = bgInterval ? styles.bg : '';
    return (
        <div className={`${styles.row} ${displayBg}`}>
            <div className={styles.header}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.btn}>
                    <GenericBtn textContent={'Tout voir'} link={link} bgColor={'minimalist'} />
                </div>
            </div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default RowEdtechs;
