import React, { useState, useEffect } from 'react';
import styles from './informationpartnerreward.module.scss';
import { openWindow } from '../../../utils/openWindow';
import TextContainer from '../../Text/TextContainer/TextContainer';
import { config } from '../../../config';
import { Button } from '../../Buttons/Button/Button';
import { Icon } from '../../Icons/Icon/Icon';

const InformationPartnerReward = ({ partner }) => {
    const [hideSocialMedia, setHideSocialMedia] = useState('');

    let websiteBtn;
    if (!partner?.website) {
        websiteBtn = styles.none;
    }

    useEffect(() => {
        if (!partner) return;
        Object.values(partner?.socials).map((value) => {
            if (value === '') {
                setHideSocialMedia('none');
            } else {
                setHideSocialMedia('');
            }
            return <></>;
        });
    }, [partner]);

    const webRedirect = () => {
        if (partner?.website && partner?.website !== '') {
            openWindow(partner?.website);
        } else {
            alert("Impossible d'accéder à la plateforme");
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img src={config.visionstrustUrl + '/images/uploads/rewardPartners/' + partner?.image} alt="" />
                </div>
                <h2>{partner?.name}</h2>
            </div>
            <div className={styles.content}>
                {!partner?.name && !partner?.description && (
                    <div style={{ textAlign: 'center', marginBottom: 10 }}>
                        <TextContainer>Le partenaire n'a pas encore transmis d'information</TextContainer>
                    </div>
                )}
                {partner && (
                    <>
                        <div
                            className={styles.description}
                            dangerouslySetInnerHTML={{ __html: partner?.description }}
                        ></div>
                        <div
                            className={`${styles.socialMedia}`}
                            style={{ display: hideSocialMedia ? hideSocialMedia : '' }}
                        >
                            {Object.entries(partner?.socials).map(([key, value], index) => (
                                <div
                                    key={index}
                                    className={styles.media}
                                    style={!value ? { display: 'none' } : { display: 'flex' }}
                                    onClick={() => openWindow(value)}
                                >
                                    <i className={`fa-brands fa-${key}`}></i>
                                </div>
                            ))}
                        </div>
                    </>
                )}
                <div className={`${styles.btn} ${websiteBtn}`}>
                    <Button
                        className={styles.button}
                        startIcon={<Icon icon="globe" variant="blue" />}
                        size="main"
                        sx={{ color: '#fff' }}
                        onclick={webRedirect}
                    >
                        Site Web
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InformationPartnerReward;
