import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches the services associated to a task
 * @param {object} options
 * @param {boolean} options.taskId The task ID
 * @returns An array of 3 elements [error, loading, rewards]
 */
export const useTaskServices = ({ taskId }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;
        
        setLoading(true);
        
        const getMissions = async () => {
            if (!taskId) return;
            try {
                const response = await client.GET(`/public/missiontasks/${taskId}/services`);
                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get services from task');
                }

                setServices(response);
            } catch (err) {
                setError(true);
                setServices([]);
            } finally {
                setLoading(false);
            }
        };

        getMissions();

        return () => {
            isMounted = false;
        };
    }, [client, taskId]);

    return [error, loading, services];
};
