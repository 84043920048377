import { useCallback, useState } from 'react';
import { useClient } from './useClient';

export const useConsents = () => {
    const { client } = useClient();

    const [consentData, setConsentData] = useState(null);
    const [consentModalLoaded, setConsentModalLoaded] = useState(false);

    /**
     * Retrieves information about an available one to one export consent
     * @param {object} options
     * @param {string} options.dataUserId ID of the Service importing data
     * @param {string} options.dataProviderId ID of the Service exporting data
     */
    const oneToOneConsents = useCallback(
        async ({ dataUserId, dataProviderId }) => {
            try {
                setConsentModalLoaded(false);
                if (!dataProviderId || !dataUserId) throw new Error('Missing dataProviderId or dataUserId');
                const data = await client.GET(`/consents/popup/o2o/${dataProviderId}/${dataUserId}`);
                setConsentData(data);
                return data;
            } catch (err) {
                return handleErrors(err);
            } finally {
                setConsentModalLoaded(true);
            }
        },
        [client],
    );

    /**
     * Retrieves information about many to one available consents
     * @param {object} options
     * @param {string} options.dataUserId ID of the Service importing data
     */
    const manyToOneConsents = useCallback(
        async ({ dataUserId }) => {
            try {
                setConsentModalLoaded(false);
                if (!dataUserId) throw new Error('Missing dataUserId');
                const data = await client.GET(`/consents/popup/m2o/${dataUserId}`);
                setConsentData(data);
                return data;
            } catch (err) {
                return handleErrors(err);
            } finally {
                setConsentModalLoaded(true);
            }
        },
        [client],
    );

    /**
     * Handles the POST request made to VisionsTrust to launch a consent
     * @param {object} consentData One consent build from the ConsentModal component
     * @param {object[]} consentData.datatypes The datatypes for the consent and their checked status
     */
    const giveConsent = useCallback(
        async (consentData) => {
            const { datatypes, emailExport, emailImport, isNewAccount, serviceImport, userKey, serviceExport, purpose } =
                consentData;

            try {
                const res = await client.POST('/consents', {
                    datatypes,
                    emailExport,
                    emailImport,
                    isNewAccount,
                    serviceImport,
                    serviceExport,
                    userKey,
                    purpose,
                });
                return res;
            } catch (err) {
                console.error(err.message);
                return null;
            }
        },
        [client],
    );

    return { oneToOneConsents, manyToOneConsents, consentModalLoaded, consentData, giveConsent };
};

/**
 * Handles errors thrown from trying to gather consent popup data
 * @param {Error} err The thrown error
 */
const handleErrors = (err) => {
    if (err.isClientError) {
        const res = { err };
        if (res?.status === 404) {
            return { error: 'No exchange configuration available' };
        }
    }
    return { error: 'Failed to get consent data' };
};
