import React, { useState, useEffect } from 'react';

import { stepsContentOnboarding } from '../../../../data/project/contentOnboarding';

import styles from './StepsContentOnboarding.module.scss';

const StepsContentOnboarding = ({ step, animationSlide }) => {
    const [content, setContent] = useState({});

    const researchType = localStorage.getItem('researchType');
    const localConfig = localStorage.getItem('headai');
    const namespaceContext = localConfig ? JSON.parse(localConfig)?.namespace : null;

    useEffect(() => {
        switch (step) {
            case 1:
                setContent(
                    researchType === 'FORMATION' && namespaceContext === 'gen'
                        ? stepsContentOnboarding.welcomeGen
                        : researchType === 'FORMATION'
                        ? stepsContentOnboarding.welcomeFormation
                        : stepsContentOnboarding.welcome,
                );
                break;
            case 2:
                setContent(
                    researchType === 'FORMATION' && namespaceContext === 'gen'
                        ? stepsContentOnboarding.informationGen
                        : researchType === 'FORMATION'
                        ? stepsContentOnboarding.informationFormation
                        : stepsContentOnboarding.information,
                );
                break;
            case 3:
                setContent(
                    researchType === 'FORMATION'
                        ? stepsContentOnboarding.matchsFormation
                        : stepsContentOnboarding.matchs,
                );
                break;
            default:
                return;
        }
    }, [step, researchType, namespaceContext]);

    return (
        <div className={`${styles.container} ${animationSlide ? styles.slide : ''}`}>
            <div className={styles.header}>
                <h1 className={styles.title}>{content.title}</h1>
                {content.VG !== '' && <p className={styles.VG} dangerouslySetInnerHTML={{ __html: content.VG }}></p>}
                <img src={content.img} alt="" style={{ maxWidth: !content.VG && '300px' }} />
            </div>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: content.text }}></p>
        </div>
    );
};

export default StepsContentOnboarding;
