import React from 'react';
import styles from './progressbar.module.scss';

const ProgressBar = ({
    fillPercentage = 0,
    currentStep = 0,
    totalSteps = 0,
    text = 'étapes',
    informationText = true,
    gaugeHeight = 20,
    color = 'stylized',
}) => {
    let gaugeStyle = '';

    switch (color) {
        case 'basic':
            gaugeStyle = styles.basic;
            break;
        case 'stylized':
            gaugeStyle = '';
            break;
        default:
            break;
    }
    return (
        <div className={`${styles.gaugeContainer} ${gaugeStyle}`}>
            <div className={styles.gauge} style={{ height: gaugeHeight }}>
                <div
                    className={styles.meter}
                    style={{ width: fillPercentage + '%', height: color === 'basic' ? gaugeHeight - 4 : gaugeHeight }}
                ></div>
            </div>
            {informationText && (
                <div className={styles.stepGaugeMeter}>
                    {`${currentStep}/${totalSteps}`} {text}
                </div>
            )}
        </div>
    );
};

export default ProgressBar;
