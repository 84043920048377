import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import StepsContentOnboarding from './StepsOnboardingPage/StepsContentOnboarding';
import GenericBtn from '../../../Components/Buttons/GenericBtn/GenericBtn';

import styles from './OnboardingPage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { appLinks } from '../../../data/enums/appLinks';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';

const TOTAL_STEPS = 3;

const OnboardingPage = () => {
    const { user } = useAuth();
    const { updateUser } = useClient();
    const [step, setStep] = useState(1);
    const [animationSlide, setAnimationSlide] = useState(false);

    const navigate = useNavigate();

    const localConfig = localStorage.getItem('headai');
    const namespaceContext = localConfig ? JSON.parse(localConfig)?.namespace : null;

    const contextLinkButton = () => {
        if (user.experiencesInfo.situation === 'Lycéen') return appLinks.missions.index;
        else return appLinks.config;
    };

    const setDoneAndRedirect = async () => {
        try {
            await updateUser({ hasDoneOnboarding: true });
        } catch (err) {
            console.log(err);
        }
        navigate(contextLinkButton());
    };

    useEffect(() => {
        if (user?.gamification?.hasDoneOnboarding) {
            navigate(appLinks.missions.index);
        }

        if (namespaceContext === 'sdn') {
            navigate(appLinks.missions.index);
        }
    }, [user, navigate, namespaceContext]);

    return (
        <div className={styles.page} style={{ background: `url(${appImageAssets.wallpaper.wallpaperOnboarding})` }}>
            <div className={styles.container}>
                <StepsContentOnboarding step={step} animationSlide={animationSlide} />
                <div className={styles.bottom}>
                    <div className={styles.steps}>
                        {[...Array(3)].map((dot, index) => (
                            <span
                                key={index}
                                className={`${styles.dot} ${step >= index + 1 ? styles.active : ''}`}
                            ></span>
                        ))}
                    </div>
                    <div className={styles.btn}>
                        <GenericBtn
                            textContent={'Passer'}
                            capitalize={true}
                            hasArrow={false}
                            onClickFc={() => setDoneAndRedirect()}
                        />
                        <GenericBtn
                            textContent={'Suivant'}
                            capitalize={true}
                            hasArrow={false}
                            bgColor={'gradient'}
                            onClickFc={async () => {
                                if (step === TOTAL_STEPS) {
                                    await setDoneAndRedirect();
                                    return;
                                }

                                setAnimationSlide(true);
                                setTimeout(() => {
                                    if (step < TOTAL_STEPS) {
                                        setStep(step + 1);
                                        setAnimationSlide(false);
                                    }
                                }, 300);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OnboardingPage;
