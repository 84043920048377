import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appLinks } from '../../data/enums/appLinks';
import logoV from '../../img/LOGO-VISIONSGALAXY.svg';
import NavBar from '../NavBar/NavBar';
import styles from './headervisionsgalaxy.module.scss';

const HeaderWithNavBar = ({ tabActive }) => {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={styles.logoLeft} onClick={() => navigate(appLinks.missions.index)}>
                <img src={logoV} alt="" />
            </div>
            <NavBar tabActive={tabActive} />
        </div>
    );
};

export default HeaderWithNavBar;
