import React from 'react';
import NavBarLandingPage from '../../../Components/NavBar/NavBarLandingPage/NavBarLandingPage';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import styles from './LegalPage.module.scss';
import { trySetContext } from '../../../libs/experiences/context';
import { useNavigate } from 'react-router';
import useQuery from '../../../hooks/useQuery';
import { getSSOURL } from '../../../utils/sso';
import { Button } from '../../../Components/Buttons/Button/Button';

export const LegalPage = () => {
    const navigate = useNavigate();
    const { params } = useQuery();
    const context = params.get('context');

    /**
     * Redirects to the SSO Server
     * @param {string} type login || signup
     */
    const doAuth = (type) => {
        window.location.href = getSSOURL(type);
    };

    const doExperienceAuth = (type, storageKey) => {
        localStorage.setItem(storageKey, 'true');
        trySetContext(context);
        window.location.href = getSSOURL(type);
    };

    const doAuthBO = (type) => {
        // doExperienceAuth(type, 'isLoggingFromBO');
        localStorage.setItem('isLoggingFromBO', 'true');
        trySetContext(context);
        navigate('/public/bilan-orientation');
    };

    const doAuthREO = (type) => {
        doExperienceAuth(type, 'isLoggingFromREO');
    };
    return (
        <main>
            <NavBarLandingPage logo={appImageAssets.logo.VGonboarding} link={'/'}>
                <a href="/">VisionsGalaxy</a>
                <span onClick={() => doAuthBO('signup')} className={styles.experiences}>
                    Bilan d'Orientation
                </span>
                <span onClick={() => doAuthREO('signup')} className={styles.experiences}>
                    Bilan de RéOrientation
                </span>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact@visionspol.eu">
                    Contact
                </a>
                <span className={styles.experiences}>
                    <Button variant="gradient" onclick={() => doAuth('login')} sx={{ fontWeight: 'bold' }}>
                        Me connecter
                    </Button>
                </span>
            </NavBarLandingPage>

            <div className={styles.container}>
                <h1>
                    Mentions légales - Visions Galaxy <br />
                    <small>Version en date du 09 Novembre 2023</small>
                </h1>

                <section>
                    <h2>Préambule et champs d’application</h2>
                    <p>
                        Le but de cette politique de confidentialité est d’informer les utilisateurs de notre site des
                        données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant
                        :
                    </p>
                    <ul>
                        <li>Les données personnelles que nous recueillerons</li>
                        <li>L’utilisation des données recueillies</li>
                        <li>Les droits de utilisateurs du site</li>
                        <li>La politique de cookies du site</li>
                    </ul>

                    <h3>Lois applicables</h3>
                    <p>
                        Conformément au RGPD, cette politique de confidentialité est conforme aux règlements suivants :
                    </p>
                    <p>Les données à caractère personnel doivent être :</p>
                    <ul>
                        <li>
                            Traitées de manière licite, loyale et transparente au regard de la personne concernée
                            (licéité, loyauté, transparence) ;
                        </li>
                        <li>
                            Collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées
                            ultérieurement d’une manière incompatible avec ces finalités, le traitement ultérieur à des
                            fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou
                            historique ou à des fins statistiques n’est pas considéré, conformément à l’article 89,
                            paragraphe 1, comme incompatible avec mes finalités initiales (limitation des finalités) ;
                        </li>
                        <li>
                            Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour
                            lesquelles elles sont traitées (minimisation des données) ;
                        </li>
                        <li>
                            Exactes et, si nécessaire, tenues à jour, toutes les mesures raisonnables doivent être
                            prises pour que les données à caractère personnel qui sont inexactes, à l’égard des
                            finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder
                            (exactitude) ;
                        </li>
                        <li>
                            Conservées sous une forme permettant l’identification des personnes concernées pendant une
                            durée n’excédant pas celle nécessaire des finalités pour lesquelles elles sont traitées, les
                            données à caractère personnel peuvent être conservées pour des durées plus longues dans la
                            mesure où elles seront traitées exclusivement à des fins archivistiques dans l’intérêt
                            public, à des fins de recherche scientifique ou historique ou à des fins statistiques
                            conformément à l’article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures
                            techniques et organisationnelles appropriées requises par le règlement afin de garantir les
                            droits et libertés de la personne concernée (limitation de la conservation) ;
                        </li>
                        <li>
                            Traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y
                            compris la protection contre le traitement non autorisé ou illicite et contre la perte, la
                            destruction ou les dégâts d’origine accidentelle, à l’aide de mesures techniques ou
                            organisationnelles appropriées (intégrité et confidentialité)
                        </li>
                    </ul>

                    <p>
                        Le traitement n’est licite que si, et dans la mesure où, au moins une des conditions suivantes
                        est remplie :
                    </p>
                    <ul>
                        <li>
                            La personne concernée a consenti au traitement de ses données à caractère personnel pour une
                            ou plusieurs finalités spécifiques ;
                        </li>
                        <li>
                            Le traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée est
                            partie ou à l’exécution de mesures précontractuelles prises à la demande de celle-ci ;
                        </li>
                        <li>
                            Le traitement est nécessaire au respect d’une obligation légale à laquelle le responsable du
                            traitement est soumis ;
                        </li>
                        <li>
                            Le traitement est nécessaire à la sauvegarde des intérêt vitaux de la personne concernée ou
                            d’une autre personne physique ;
                        </li>
                        <li>
                            Le traitement est nécessaire à l’exécution d’une mission d’intérêt public ou relevant de
                            l’exercice de l’autorité publique dont est investi le responsable du traitement ;
                        </li>
                        <li>
                            Le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable
                            du traitement ou par un tiers, à moins que ne prévalent les intérêts ou libertés et droits
                            fondamentaux de la personne concernée qui exigent une protection des données à caractère
                            personnel, notamment lorsque la personne concernée est un enfant.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>Information</h2>
                    <p>Les utilisateurs sont informés qu’en utilisant notre site ils consentent à :</p>
                    <ul>
                        <li>Les conditions énoncées dans la présente politique de confidentialité ;</li>
                        <li>
                            La collecte, l’utilisation et la conservation des données énumérées dans la présente
                            politique.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>Données personnelles que nous collectons</h2>
                    <p>
                        Les données recueillies de façon non automatique sont votre nom, votre prénom, votre date de
                        naissance, votre numéro de téléphone, votre adresse postale, votre situation professionnelle et
                        scolaire, votre niveau d’études et votre email.
                    </p>
                    <p>
                        Ces données peuvent être recueillies au moyen des méthodes suivantes : inscription au site
                        visionsgalaxy.com, inscription à événement VisionsGalaxy et inscription à la newsletter.
                    </p>
                    <p>
                        Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif
                        énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données
                        supplémentaires sans vous informer au préalable.
                    </p>
                </section>

                <section>
                    <h2>Utilisation des fichiers « cookies »</h2>

                    <p>Le site a éventuellement recours aux techniques de « cookies ».</p>

                    <p>
                        Un « cookies » est un fichier de petite taille (moins de 4 ko), stocké par le site sur le disque
                        dur de l’Utilisateur, contenant des informations relatives aux habitudes de navigation de
                        l’utilisateur.
                    </p>

                    <p>
                        Ces fichiers lui permettent de traiter des statistiques et des informations sur le trafic, de
                        faciliter la navigation et d’améliorer le service pour le confort de l'utilisateur.
                    </p>

                    <p>
                        Ce consentement de l’utilisateur est considéré comme valide pour une durée de 6 mois maximum. A
                        l’issue de cette période, le site demandera à nouveau l’autorisation de l’utilisateur pour
                        enregistrer des fichiers « cookies » sur son disque dur.
                    </p>

                    <h3>Opposition de l’utilisateur à l’utilisation de fichiers « cookies » par le site</h3>

                    <p>
                        L’Utilisateur peut retirer son consentement à tout moment en demandant la suppression de son
                        compte par à <a href="mailto:hello@visionspol.eu">hello@visionspol.eu</a>.
                    </p>

                    <p>
                        De manière plus générale, il est porté à la connaissance de l’utilisateur qu’il peut s’opposer à
                        l’enregistrement de ces fichiers « cookies » en configurant son logiciel de navigation.
                    </p>

                    <p>
                        Dans le cas où l’Utilisateur décide de désactiver les fichiers « cookies », il pourra poursuivre
                        sa navigation sur le. Toutefois, tout dysfonctionnement du site provoqué par cette manipulation
                        ne pourrait être considéré comme étant du fait de l’éditeur du site.
                    </p>

                    <h3>Description des fichiers « cookies » utilisés par le site</h3>

                    <p>
                        L’éditeur du site attire l’attention de l’Utilisateur sur le fait que les cookies suivants sont
                        utilisés lors de sa navigation :
                    </p>
                    <ul>
                        <li>Cookies essentiels au bon fonctionnement du site</li>
                    </ul>
                    <p>
                        L’attention de l’Utilisateur est portée sur le fait que les sites partenaires à VisionsGalaxy
                        disposent de politiques de confidentialité propres et de conditions générales d’utilisation
                        possiblement différentes du site. L’éditeur du site invite les utilisateurs à consulter les
                        politiques de confidentialité et les conditions générales d’utilisation de ces sites.
                    </p>
                </section>

                <section>
                    <h2>Comment nous utilisons les données personnelles</h2>
                    <p>
                        Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins
                        précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site.{' '}
                    </p>
                    <p>
                        Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être
                        utilisées aux fins suivantes : fournir et gérer nos services, gérer les activités du site
                        www.visionsgalaxy.com, communiquer avec les utilisateurs, faire parvenir les récompenses et
                        cadeaux et améliorer la qualité de nos services et résoudre tout litige éventuel ou régler tout
                        problème dans le cadre de l’utilisation de nos services.
                    </p>
                </section>

                <section>
                    <h2>Avec qui nous partageons les données personnelles</h2>
                    <h3>Employés</h3>
                    <p>
                        Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a
                        raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique
                    </p>

                    <h3>Tierces parties</h3>
                    <p>
                        Nous pouvons partager les données utilisateurs avec les tiers partenaires de la plateforme
                        VisionsGalaxy. Ces données peuvent être utilisées à des fins d’études, de communication ou
                        d’utilisation d’une plateforme ou boutique partenaire.
                    </p>
                    <p>
                        Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est
                        raisonnablement nécessaire pour atteindre l’objectif donné.
                    </p>

                    <h3>Autres divulgations</h3>
                    <p>
                        Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas
                        suivants :
                    </p>
                    <ul>
                        <li>Si la loi l’exige ;</li>
                        <li>Si elle est requise pour toute procédure judiciaire ;</li>
                        <li>Pour prouver ou protéger nos droits légaux ;</li>
                        <li>
                            A des acheteurs ou des acheteurs potentiels de cette société dans le cas échéant où nous
                            cherchons à vendre la société.
                        </li>
                    </ul>
                    <p>
                        Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne
                        sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de
                        confidentialité.
                    </p>
                </section>

                <section>
                    <h2>Combien de temps nous stockons les données personnelles</h2>
                    <p>
                        Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour
                        atteindre les fins pour lesquelles elles sont recueillies.
                    </p>
                </section>

                <section>
                    <h2> Comment nous protégeons vos données personnelles</h2>
                    <p>
                        Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données
                        d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque
                        de préjudice. L’Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes
                        incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est
                        raisonnablement pratique.
                    </p>
                </section>

                <section>
                    <h2>Mineurs</h2>
                    <p>
                        Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins
                        de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal
                        pour que leurs données soient recueillies, traitées et utilisées.
                    </p>
                </section>

                <section>
                    <h2>Vos droits en tant qu’utilisateur</h2>
                    <p>En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :</p>
                    <ul>
                        <li>Droit accès</li>
                        <li>Droit de rectification</li>
                        <li>Droit à l’effacement</li>
                        <li>Droit de restreindre le traitement</li>
                        <li>Droit à la portabilité des données</li>
                        <li>Droit d’objection</li>
                    </ul>
                    <p>
                        Pour exercer vos droits envoyez un mail à contact@visionspol.eu. Si vous exercez ces droits,
                        nous nous efforçons de répondre à vos demandes dans les meilleurs délais.
                    </p>
                    <p>
                        De plus, vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du
                        RGPD.
                    </p>
                </section>

                <section>
                    <h2>Comment modifier, supprimer ou contester les données recueillies</h2>
                    <p>
                        Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une
                        autre, veuillez communiquer avec notre responsable de la protection des données : Matthias De
                        Bièvre à <a href="mailto:matthias@visionspol.eu">matthias@visionspol.eu</a>
                    </p>
                </section>

                <section>
                    <h2>Modifications</h2>
                    <p>
                        Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la
                        conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de
                        données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour
                        s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les
                        utilisateurs par courriel des changements apportés à cette politique.
                    </p>
                </section>

                <section>
                    <h2>Contact</h2>
                    <p>
                        Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant{' '}
                        <a href="mailto:contact@visionspol.eu">contact@visionspol.eu</a>.{' '}
                    </p>
                </section>
            </div>
        </main>
    );
};
