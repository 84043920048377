import React, { useState, useEffect } from 'react';
import styles from './SelectObjectiveMissionPage.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import CustomSelectInput from '../../../Components/Inputs/CustomSelectInput/CustomSelectInput';
import { getObjectives } from '../../../libs/gamification/missions';
import { useClient } from '../../../hooks/useClient';
import { Button } from '../../../Components/Buttons/Button/Button';

const SelectObjectiveMissionPage = ({ objectiveSelected }) => {
    const { updateUser } = useClient();

    const [mainObjective, setMainObjective] = useState('');
    const [objectives, setObjectives] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);

    useEffect(() => {
        let isMounted = true;
        const fetchObjectives = async () => {
            const res = await getObjectives();

            if (!isMounted) return;
            setObjectives(
                res.map((el) => {
                    return {
                        objective: el,
                        selected: false,
                    };
                }),
            );
        };

        fetchObjectives();

        return () => {
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        if (mainObjective === '') return;
        if (mainObjective !== '' && errorMessage) {
            setErrorMessage(false);
        }
    }, [mainObjective, errorMessage]);

    const updateMainObjective = (objective) => {
        setMainObjective(objective);
    };

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <section className={styles.container}>
                <img src={appImageAssets.illustration.configObjective} alt="Illustration planète et fusée" />

                <h2>Bravo, vous avez fini vos premières missions !</h2>
                <h3>Quel est l'objectif que vous souhaitez atteindre avec cette aventure ?</h3>

                <div className={styles.objectives}>
                    <CustomSelectInput
                        option={objectives}
                        selectedOption={mainObjective}
                        updateSelectedOption={updateMainObjective}
                        border
                    >
                        Trouver une formation
                    </CustomSelectInput>
                    <Button
                        
                        onclick={() => {
                            if (mainObjective === '') {
                                setErrorMessage(true);
                                return;
                            }
                            updateUser({ mainObjective });
                            objectiveSelected();
                        }}
                    >
                        Valider mon objectif
                    </Button>
                    {errorMessage && <span className={styles.errorMessage}>Veuillez sélectionner un objectif !</span>}
                </div>

                <p className={styles.description}>
                    L'objectif que vous allez choisir sera votre objectif activé pour votre aventure. <br></br>
                    Vous pourrez changer à tout moment votre objectif lors de votre aventure.
                </p>
            </section>
        </div>
    );
};

export default SelectObjectiveMissionPage;
