import React from 'react';
import styles from './RowLoader.module.scss';

export const RowLoader = ({ className = '' }) => {
    return (
        <div className={`${styles.spinner} ${className}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    );
};
