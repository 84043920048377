import React from 'react';
import { useParams } from 'react-router-dom';
import ExperienceReoPage from '../../views/Experiences/ExperienceReoPage/ExperienceReoPage';
import ExperienceBoPage from '../../views/Experiences/ExperienceBoPage/ExperienceBoPage';
import PreStartBilanOrientation from '../../views/Public/PublicBilanOrientation/PreStartBilanOrientation/PreStartBilanOrientation';
import ExperienceParcoursupPage from '../../views/Experiences/ExperienceParcoursupPage/ExperienceParcoursupPage';

const ExperienceSelector = () => {
    const { experienceName } = useParams();

    switch (experienceName) {
        case 'bilan-orientation':
            return <ExperienceBoPage />;

        case 'bilan-reorientation':
            return <ExperienceReoPage />;

        case 'parcoursup':
            return <ExperienceParcoursupPage />;

        case 'pre-start':
            return <PreStartBilanOrientation />;

        default:
            return (
                <div>
                    {/* // TODO: Make a 404 component */}
                    Erreur 404, page non trouvée
                </div>
            );
    }
};

export default ExperienceSelector;
