import React from 'react';

// Components
import ServiceCard from '../../Cards/ServiceCard/ServiceCard';
import GlobalError from '../../Errors/GlobalError/GlobalError';

// Hooks & Utils
import { appLinks } from '../../../data/enums/appLinks';

// Styles
import styles from './rowmosaic.module.scss';
import { filterCozyFromServices } from '../../../utils/cozy';
import SkeletonServiceCard from '../../Skeletons/SkeletonsTemplate/SkeletonServiceCard/SkeletonServiceCard';
import { useServices } from '../../../hooks/useServices';

const translator = {
    internship: 'Stage',
    job: 'Emploi',
    orientation: 'Orientation',
    skills: 'Compétences',
};

const RowMosaic = ({ category }) => {
    const [edtechError, edtechLoading, edtechData] = useServices({
        filter: category,
        isSample: 'false',
        isPublic: true,
    });

    return (
        <div className={styles.container}>
            <h2>
                Tous les outils disponibles pour la catégorie : <span>{translator[category]}</span>
            </h2>
            <div className={styles.mosaic}>
                {edtechLoading && <SkeletonServiceCard />}
                {edtechError && <GlobalError flexDirection={'row'} />}
                {edtechData.services &&
                    filterCozyFromServices(edtechData.services).map((service, index) => (
                        <ServiceCard
                            key={index}
                            service={service}
                            index={index}
                            link={`${appLinks.catalog.serviceDetails}/${service._id}`}
                        />
                    ))}
            </div>
        </div>
    );
};

export default RowMosaic;
