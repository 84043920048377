import { appImageAssets } from '../../data/enums/appImageAssets';

/**
 * The keys representing the different existing icons / pictos
 * When modifying, add more rows but never swap existing keys
 * this const is the only fixed data where the associations of
 * numbers - icons is explicit so it should be altered carefully
 */
export const MISSION_TASK_PICTOS = {
    1: appImageAssets.task.one,
    2: appImageAssets.task.two,
    3: appImageAssets.task.three,
    4: appImageAssets.task.four,
    5: appImageAssets.task.five,
    6: appImageAssets.task.six,
    7: appImageAssets.task.seven,
    8: appImageAssets.task.eight,
    9: appImageAssets.task.nine,
    10: appImageAssets.task.ten,
    11: appImageAssets.task.eleven,
    12: appImageAssets.task.twelve,
    13: appImageAssets.task.thirtheen,
    14: appImageAssets.task.fourteen,
    15: appImageAssets.task.fifteen,
    16: appImageAssets.task.sixteen,
    17: appImageAssets.task.seventeen,
    18: appImageAssets.task.eighteen,
    19: appImageAssets.task.nineteen,
    20: appImageAssets.task.twenty,
    21: appImageAssets.task.twentyOne,
    22: appImageAssets.task.twentyTwo,
    23: appImageAssets.task.twentyThree,
    24: appImageAssets.task.twentyFour,
    25: appImageAssets.task.twentyFive,
    26: appImageAssets.task.twentySix,
    27: appImageAssets.task.twentySeven,
    28: appImageAssets.task.twentyEight,
    29: appImageAssets.task.twentyNine,
    30: appImageAssets.task.thirty,
    31: appImageAssets.task.thirtyOne,
};
