import iconeOrientation from '../../img/ICONE-BO.svg';
import iconeReorientation from '../../img/ICONE-REO.svg';
import iconOrientoi from '../../img/ORIENTOI.png';
import iconCuriose from '../../img/curiose.png';
import iconPitangoo from '../../img/pitangoo.png';

// import logoREO from '../../img/ICONE-REO.svg';
import logoBO from '../../img/ICONE-BO.svg';

export const experiences = {
    orientation: {
        logo: iconeOrientation,
        slug: 'bilan-orientation',
        title: "Bilan d'orientation",
        textContent:
            "Apprenez à travers un parcours ludique à mieux vous connaître et explorez les métiers qui s'offrent à vous !",
    },
    reorientation: {
        logo: iconeReorientation,
        slug: 'bilan-reorientation',
        title: 'Bilan de Réorientation',
        textContent:
            "Apprenez à travers un parcours ludique à mieux vous connaître et explorez les métiers qui s'offrent à vous !",
    },
    parcoursup: {
        logo: '',
        slug: 'parcoursup',
        title: 'Parcoursup Coach',
        textContent: 'Découvrez le premier simulateur de pronostics pour les voeux de ParcourSup.',
        subTextContent:
            "Pour chaque formation visée découvrez quels sont vos chances d'être acceptés selon les 3 phases d'admissions.",
    },
};

export const manualExperience = {
    orientation: {
        steps: [
            {
                text: "<span>Réalisez votre Bilan d'Orientation</span> en explorant les 3 plateformes de cette expérience.",
            },
            {
                text: "<span>Générez et visualisez votre Bilan d'Orientation dans</span> votre Cozy cloud personnel. Pour <span>découvrir les formations qui vous correspondent.</span>",
            },
            {
                text: '<span>Partagez votre bilan et prenez rendez-vous avec un conseiller</span> pour être accompagné.',
            },
        ],
    },
    reorientation: {
        steps: [
            {
                text: '<span>Réalisez votre Bilan de Réorientation</span> en explorant les 3 plateformes de cette expérience.',
            },
            {
                text: '<span>Générez et visualisez votre Bilan de Réorientation dans</span> votre Cozy cloud personnel. Pour <span>découvrir les formations qui vous correspondent.</span>',
            },
            {
                text: '<span>Partagez votre bilan et prenez rendez-vous avec un conseiller</span> pour être accompagné.',
            },
        ],
    },
};

export const experienceData = {
    userPlatformJourney: {
        gradeGiven: 1,
        _id: '63184c5630522eaf24262b7b',
        platformJourney: '62bc482233338f80344f9343',
        user: '626b9c03832a3d09e84537bd',
        platforms: [
            {
                guide: [],
                status: 2,
                _id: '63184c5630522eaf24262b7c',
                platform: {
                    urls: {
                        consentExport: 'https://api-visions.orientoi.fr/decrypt_consents',
                        consentImport: 'https://api-visions.orientoi.fr/launcher_exports',
                        dataExport: 'https://api-visions.orientoi.fr/exports',
                        dataImport: 'https://api-visions.orientoi.fr/imports',
                        registerURL: 'https://app.orientoi.fr/#/welcome',
                        website: 'https://app.orientoi.fr',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Insertion Professionnelle'],
                            tinderSentences: [
                                'Cherchez-vous à trouver votre domaine de rêve ?',
                                'Souhaitez-vous découvrir les opportunités professionnelles liées à votre profil ?',
                                'Cherchez-vous à matcher vos compétences à un métier ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                'Je joue',
                                "J'identifie mes talents",
                                "Je découvre des secteurs d'activité et des métiers adaptés à ma personnalité",
                                'Je me positionne sur les découvertes',
                                'Je partage mes données avec les partenaires',
                            ],
                            description:
                                "Orientoi te permet d'identifier tes talents en jouant et de découvrir des métiers adaptés à ta personnalité.",
                            mainTag: 'Orientation',
                            timeEstimation: '7 - 10 minutes',
                        },
                        client: {
                            socials: {
                                linkedIn: 'https://www.linkedin.com/company/orientoi/',
                            },
                            useOpenBadges: false,
                            useReferentials: false,
                            price: 'Proposition sur demande',
                            contactEmail: 'offres@orientoi.fr',
                            contactPhoneNumber: '0666438716',
                            location: 'Lille',
                            calendarLink: 'https://orientoi.fr',
                        },
                    },
                    description:
                        "Orientoi te permet d'identifier tes talents en jouant et de découvrir des métiers adaptés à ta personnalité.",
                    logo: '1618578207099.jpg',
                    _id: '60796546a2be8fc2e9b02027',
                    name: 'Orientoi',
                },
            },
            {
                guide: [],
                status: 2,
                _id: '63184c5630522eaf24262b7d',
                platform: {
                    urls: {
                        consentExport: 'https://pitangoo-school.ew.r.appspot.com/api/v1/consent/export',
                        consentImport: '',
                        dataExport: 'https://pitangoo-school.ew.r.appspot.com/api/v1/data/export',
                        dataImport: '',
                        registerURL: 'https://l.ead.me/bcLoLl',
                        website: 'https://l.ead.me/bcLoLl',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Orientation', 'Insertion Professionnelle'],
                            tinderSentences: [
                                'Cherchez-vous à trouver votre domaine de rêve ?',
                                'Etes-vous satisfait de votre orientation ?',
                                'Aimeriez-vous découvrir comment faire ressortir vos compétences douces ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                'Dans cette 1ère étape tu pourras visionner des vidéos avec des personnalités aux parcours inspirants.',
                                "Ensuite tu remplis le questionnaire sur tes centres d'intérêts\nUne fois terminée, invite ta communauté de confiance (amis, frères, soeurs, entraîneur, cousin...)\nConsulte ton bilan et tu verras comment tes proches te définissent.",
                                "Passe le baromètre de l'estime de soi ! À travers un questionnaire de 30 questions.\nConsulte ton bilan et celui de ta communauté.",
                                'Quels sont tes points forts ?\nConsulte ton bilan et celui de ta communauté.',
                                "Quelles sont tes formes d'intelligence ?\nConsulte ton bilan et celui de ta communauté.",
                                'Quels sont tes facteurs de stress ?\nConsulte ton bilan et celui de ta communauté.',
                                'Enfin, organise ton projet professionnel !\nConsulte ton bilan et celui de ta communauté.',
                            ],
                            description:
                                "Le parcours PITANGOO te propose une méthodologie pour t'aider à réfléchir à ton projet professionnel et avancer concrètement. L'application te guide étape par étape avec des vidéos inspirantes pour te poser les bonnes question. Elle te permet aussi de valoriser tes compétences, tes expériences grâce à ton entourage qui pourra répondre à des questionnaires pour toi et t'envoyer des messages bienveillants et constructifs (méthodologie à 360°).\nAlors pose tes bases pour faire les bons choix et structure ton projet pour apprendre à bien en parler, en toutes circonstances (CV, entretien,...).",
                            mainTag: 'Orientation',
                            timeEstimation: '10min',
                        },
                        client: {
                            useOpenBadges: false,
                            useReferentials: false,
                            price: '',
                            contactEmail: 'orientation@digischool.fr',
                            contactPhoneNumber: '0451260089',
                            location: 'Lyon',
                            calendarLink: 'orientation@digischool.fr',
                        },
                    },
                    description:
                        'PITANGOO propose une méthodologie et des outils pour construire son projet professionnel. Se poser les bonnes questions, valoriser ses compétences en mobilisant son entourage et structurer son projet pour apprendre à bien en parler.',
                    logo: '1651822104167.png',
                    _id: '62695312b3417319920577cb',
                    name: 'PITANGOO',
                },
            },
            {
                guide: [],
                status: 0,
                _id: '63184c5630522eaf24262b7e',
                type: 1,
                platform: {
                    urls: {
                        consentExport: 'https://app.curiose.fr/version-test/api/1.1/wf/consent_export',
                        consentImport: '',
                        dataExport: 'https://app.curiose.fr/version-test/api/1.1/wf/data_export',
                        dataImport: '',
                        registerURL: 'https://app.curiose.fr/version-test/ssocheck',
                        website: 'https://app.curiose.fr',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Orientation'],
                            tinderSentences: [
                                'Souhaitez-vous découvrir les opportunités professionnelles liées à votre profil ?',
                                'Souhaitez-vous découvrir de nouveaux métiers ?',
                                'Cherchez-vous à matcher vos compétences à un métier ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                "Effectue le diagnostic de ta situation actuelle et découvre des ressources pour t'aider dans ton orientation (vidéos, articles etc). ",
                                "Réponds aux questions lors de la phase d'introspection pour que Curiose définisse tes traits de personnalité et trouve des métiers en lien avec tes singularités.  ",
                                'Tu recevras un code par ton conseiller pour pouvoir accéder à tes résultats. ',
                            ],
                            mainTag: 'Orientation',
                            description:
                                "Curiose est un parcours digital d'orientation professionnelle pour vous aider à trouver votre voie. Découvrez une expérience enrichissante qui vous propose des pistes de métiers adaptés à votre personnalité ainsi qu’à vos aspirations et contraintes personnelles. ",
                            timeEstimation: '20 min',
                        },
                        client: {
                            socials: {
                                linkedIn: 'https://www.linkedin.com/company/curiose',
                            },
                            useOpenBadges: false,
                            useReferentials: false,
                            price: 'Gratuit',
                            contactEmail: 'hello@curiose.fr',
                            contactPhoneNumber: '',
                            location: 'France',
                            calendarLink: 'hello@curiose.fr',
                        },
                    },
                    description:
                        "Curiose est un parcours d'orientation qui aide les lycéen.nes et les étudiant.es post-bac à construire le projet professionnel qui leur ressemble, en les accompagnant sur 3 volets : la connaissance de soi, l'exploration des métiers et la construction de leur parcours d'études. ",
                    logo: '1618578014892.png',
                    _id: '62692504b341731992056fe0',
                    name: 'Inokufu',
                },
            },
        ],
        createdAt: '2022-09-07T07:46:30.686Z',
        updatedAt: '2022-09-16T13:11:13.707Z',
        __v: 0,
    },
    platformJourney: {
        description:
            'Explorez votre projet de réorientation avec cet outil. Vous pouvez identifier les métiers ou secteurs qui vous intéressent, vos centres d’intérêt et votre profil de personnalité. Par la suite, vous pouvez vous faire recommender des formations au sein de CY et envoyer votre profil à un conseiller DOIP en quelques clics !',
        image: logoBO,
        guide: [
            'Réalisez votre Bilan de Réorientation en explorant les 3 plateformes de cette expérience.',
            'Générez et visualisez votre Bilan de Réorientation dans votre Cozy cloud personnel. Pour découvrir les formations qui vous correspondent.',
            'Partagez votre bilan et prenez rendez-vous avec un conseiller pour être accompagné.',
        ],
        _id: '62bc482233338f80344f9343',
        name: "Bilan d'orientation",
        platforms: [
            {
                _id: '62bc482233338f80344f9344',
                platform: {
                    urls: {
                        consentExport: 'https://api-visions.orientoi.fr/decrypt_consents',
                        consentImport: 'https://api-visions.orientoi.fr/launcher_exports',
                        dataExport: 'https://api-visions.orientoi.fr/exports',
                        dataImport: 'https://api-visions.orientoi.fr/imports',
                        registerURL: 'https://app.orientoi.fr/#/welcome',
                        website: 'https://app.orientoi.fr',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Insertion Professionnelle'],
                            tinderSentences: [
                                'Cherchez-vous à trouver votre domaine de rêve ?',
                                'Souhaitez-vous découvrir les opportunités professionnelles liées à votre profil ?',
                                'Cherchez-vous à matcher vos compétences à un métier ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                'Je joue',
                                "J'identifie mes talents",
                                "Je découvre des secteurs d'activité et des métiers adaptés à ma personnalité",
                                'Je me positionne sur les découvertes',
                                'Je partage mes données avec les partenaires',
                            ],
                            description:
                                "Orientoi te permet d'identifier tes talents en jouant et de découvrir des métiers adaptés à ta personnalité.",
                            mainTag: 'Orientation',
                            timeEstimation: '7 - 10 minutes',
                        },
                        client: {
                            socials: {
                                linkedIn: 'https://www.linkedin.com/company/orientoi/',
                            },
                            useOpenBadges: false,
                            useReferentials: false,
                            price: 'Proposition sur demande',
                            contactEmail: 'offres@orientoi.fr',
                            contactPhoneNumber: '0666438716',
                            location: 'Lille',
                            calendarLink: 'https://orientoi.fr',
                        },
                    },
                    description:
                        "Orientoi te permet d'identifier tes talents en jouant et de découvrir des métiers adaptés à ta personnalité.",
                    logo: '1618578207099.jpg',
                    _id: '60796546a2be8fc2e9b02027',
                    name: 'Orientoi',
                },
            },
            {
                _id: '62bc482233338f80344f9345',
                platform: {
                    urls: {
                        consentExport: 'https://pitangoo-school.ew.r.appspot.com/api/v1/consent/export',
                        consentImport: '',
                        dataExport: 'https://pitangoo-school.ew.r.appspot.com/api/v1/data/export',
                        dataImport: '',
                        registerURL: 'https://l.ead.me/bcLoLl',
                        website: 'https://l.ead.me/bcLoLl',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Orientation', 'Insertion Professionnelle'],
                            tinderSentences: [
                                'Cherchez-vous à trouver votre domaine de rêve ?',
                                'Etes-vous satisfait de votre orientation ?',
                                'Aimeriez-vous découvrir comment faire ressortir vos compétences douces ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                'Dans cette 1ère étape tu pourras visionner des vidéos avec des personnalités aux parcours inspirants.',
                                "Ensuite tu remplis le questionnaire sur tes centres d'intérêts\nUne fois terminée, invite ta communauté de confiance (amis, frères, soeurs, entraîneur, cousin...)\nConsulte ton bilan et tu verras comment tes proches te définissent.",
                                "Passe le baromètre de l'estime de soi ! À travers un questionnaire de 30 questions.\nConsulte ton bilan et celui de ta communauté.",
                                'Quels sont tes points forts ?\nConsulte ton bilan et celui de ta communauté.',
                            ],
                            description:
                                "Le parcours PITANGOO te propose une méthodologie pour t'aider à réfléchir à ton projet professionnel et avancer concrètement. L'application te guide étape par étape avec des vidéos inspirantes pour te poser les bonnes question. Elle te permet aussi de valoriser tes compétences, tes expériences grâce à ton entourage qui pourra répondre à des questionnaires pour toi et t'envoyer des messages bienveillants et constructifs (méthodologie à 360°).\nAlors pose tes bases pour faire les bons choix et structure ton projet pour apprendre à bien en parler, en toutes circonstances (CV, entretien,...).",
                            mainTag: 'Orientation',
                            timeEstimation: '10min',
                        },
                        client: {
                            useOpenBadges: false,
                            useReferentials: false,
                            price: '',
                            contactEmail: 'orientation@digischool.fr',
                            contactPhoneNumber: '0451260089',
                            location: 'Lyon',
                            calendarLink: 'orientation@digischool.fr',
                        },
                    },
                    description:
                        'PITANGOO propose une méthodologie et des outils pour construire son projet professionnel. Se poser les bonnes questions, valoriser ses compétences en mobilisant son entourage et structurer son projet pour apprendre à bien en parler.',
                    logo: '1605530307607.png',
                    _id: '62695312b3417319920577cb',
                    name: 'Jobready',
                },
            },
            {
                _id: '62bc482233338f80344f9346',
                type: 1,
                platform: {
                    urls: {
                        consentExport: 'https://app.curiose.fr/version-test/api/1.1/wf/consent_export',
                        consentImport: '',
                        dataExport: 'https://app.curiose.fr/version-test/api/1.1/wf/data_export',
                        dataImport: '',
                        registerURL: 'https://app.curiose.fr/version-test/ssocheck',
                        website: 'https://app.curiose.fr',
                    },
                    galaxy: {
                        user: {
                            testimonials: [],
                            tags: ['Soft Skills', 'Orientation'],
                            tinderSentences: [
                                'Souhaitez-vous découvrir les opportunités professionnelles liées à votre profil ?',
                                'Souhaitez-vous découvrir de nouveaux métiers ?',
                                'Cherchez-vous à matcher vos compétences à un métier ?',
                            ],
                            visionsStars: 100,
                            defaultGuide: [
                                "Effectue le diagnostic de ta situation actuelle et découvre des ressources pour t'aider dans ton orientation (vidéos, articles etc). ",
                                "Réponds aux questions lors de la phase d'introspection pour que Curiose définisse tes traits de personnalité et trouve des métiers en lien avec tes singularités.  ",
                                'Tu recevras un code par ton conseiller pour pouvoir accéder à tes résultats. ',
                            ],
                            mainTag: 'Orientation',
                            description:
                                "Curiose est un parcours digital d'orientation professionnelle pour vous aider à trouver votre voie. Découvrez une expérience enrichissante qui vous propose des pistes de métiers adaptés à votre personnalité ainsi qu’à vos aspirations et contraintes personnelles. ",
                            timeEstimation: '20 min',
                        },
                        client: {
                            socials: {
                                linkedIn: 'https://www.linkedin.com/company/curiose',
                            },
                            useOpenBadges: false,
                            useReferentials: false,
                            price: 'Gratuit',
                            contactEmail: 'hello@curiose.fr',
                            contactPhoneNumber: '',
                            location: 'France',
                            calendarLink: 'hello@curiose.fr',
                        },
                    },
                    description:
                        "Curiose est un parcours d'orientation qui aide les lycéen.nes et les étudiant.es post-bac à construire le projet professionnel qui leur ressemble, en les accompagnant sur 3 volets : la connaissance de soi, l'exploration des métiers et la construction de leur parcours d'études. ",
                    logo: '1618578014892.png',
                    _id: '62692504b341731992056fe0',
                    name: 'Inokufu',
                },
            },
        ],
        createdAt: '2022-06-29T12:40:02.462Z',
        updatedAt: '2022-06-29T12:40:02.462Z',
        __v: 0,
        experience: '61b880904cd5f999d4874ab5',
        averageGrade: 4,
        timeEstimation: 30,
        visionsStars: 100,
    },
    averageGrade: 0,
};

export const responseExperienceData = {
    infoText:
        "Visions ne stocke pas et n'accède jamais aux données qui circulent. Nous garantissons que vos données sont uniquement utilisées par Test-DataUser pour la finalité décrite et aucune autre.\n        Vous pouvez révoquer votre consentement à tout moment depuis Test-DataUser, Test-DataProvider ou depuis votre compte Visions. Nous vous envoyons un email récapitulatif. Les partenaires de notre réseau de données sont engagés juridiquement et contraints techniquement à respecter vos consentements. Nous vous avertirons au moindre écart ou à la moindre faille et nous assurerons que vos droits sont respectés.",
    purpose: 'Trouver un emploi',
    description: "Utilisation des données pour la recherche d'emploi",
    serviceImport: 'Test-DataUser',
    serviceImportLogo: 'https://visionstrust.com/images/services/default.jpg',
    serviceImportId: '5fb67b0445566b015587d39a',
    tosExport: 'https://www.fun-mooc.fr/payment/terms/',
    tosImport: 'https://fr.indeed.com/legal?hl=fr&redirect=true',
    emailImport: 'felix@visionspol.eu',
    datatypes: [
        {
            id: '5fe217af1e4d92318a81f8f7',
            name: 'Diplôme',
            description: '',
            provenance: {
                service: 'Test-DataProvider',
                checked: true,
            },
            checked: true,
        },
        {
            id: '5fe217bb1e4d92318a81f8f8',
            name: 'Compétences',
            description: "L'ensemble des compétences de la personne",
            provenance: {
                service: 'Test-DataProvider',
                checked: true,
            },
            checked: true,
        },
        {
            id: '5fe462298eada31af007f4b6',
            name: 'Langues parlées',
            description: 'Les différentes langues parlées de la personne',
            provenance: {
                service: 'Test-DataProvider',
                checked: true,
            },
            checked: true,
        },
    ],
};

export const stepServices = {
    user: '',
    services: [
        {
            name: 'Orientoi',
            logo: iconOrientoi,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '15 MIN',
            visionsStars: 100,
            url: 'https://app.orientoi.fr/#/welcome',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: false,
            status: 1,
        },
        {
            name: 'Curoise.',
            logo: iconCuriose,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '5 MIN',
            visionsStars: 100,
            url: 'https://www.google.com/',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: false,
        },
        {
            name: 'Pitangoo',
            logo: iconPitangoo,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '10 MIN',
            visionsStars: 100,
            url: 'https://www.google.com/',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: true,
        },
        {
            name: 'Pitangoo',
            logo: iconPitangoo,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '10 MIN',
            visionsStars: 100,
            url: 'https://www.google.com/',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: true,
        },
        {
            name: 'Orientoi',
            logo: iconOrientoi,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '15 MIN',
            visionsStars: 100,
            url: 'https://app.orientoi.fr/#/welcome',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: false,
        },
        {
            name: 'Pitangoo',
            logo: iconPitangoo,
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci, venenatis at dignissim ac, ultrices nec ante. Suspendisse tempor leo dui. Maecenas ut leo posuere, lacinia felis ac, lobortis turpis.',
            timeEstimation: '10 MIN',
            visionsStars: 100,
            url: 'https://www.google.com/',
            guide: [
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
                {
                    step: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed ut erat metus. Praesent sapien orci.',
                },
            ],
            averageGrade: 3,
            finish: true,
        },
    ],
};

export const descriptionExperience = {
    user: '',
    experience: [
        {
            name: 'Bilan de Réorientation',
            visionsStars: 100,
            icon: iconeReorientation,
            timeEstimation: '30 MIN',
            description:
                'Explorez votre projet de réorientation avec cet outil. Vous pouvez identifier les métiers ou secteurs qui vous intéressent, vos centres d’intérêt et votre profil de personnalité. Par la suite, vous pouvez vous faire recommender des formations au sein de CY et envoyer votre profil à un conseiller DOIP en quelques clics !',
        },
    ],
};
