import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Button.module.scss';
import { RowLoader } from '../../Loaders/RowLoader/RowLoader';
import { useComponentIsMounted } from '../../../hooks/useComponentIsMounted';

/**
 *
 * @param {object} props
 * @param {string} props.className add className to button container
 * @param {"gradient" | "blueGradient" | "blueGradientV2" | "blueV2" | "white" | "bordered" | "borderedGreen"} props.variant select variant button UI
 * @param {"main" | "normal" | "small" | "smallMain" | "largeMain"} props.size set button size
 * @param {"pulse"} props.animation select type of animation button
 * @param {JSX.Element} props.startIcon set icon component at the start of the button
 * @param {JSX.Element} props.endIcon set icon component at the end of the button
 * @param {string} props.navigate Navigate to the url
 * @param {string} props.type set the type of the button
 * @param {boolean} props.disabled set disabled btn if is true
 * @param {boolean} props.loading set a loader inside button
 * @param {{}} props.sx set quick style inline button
 * @param {() => any} props.onclick Return a custom function on the onclick button
 * @returns
 */
export const Button = ({
    className = '',
    variant = '',
    size = '',
    animation = '',
    startIcon,
    endIcon,
    navigate = '',
    type = '',
    disabled = false,
    loading = false,
    sx = {},
    onclick = () => {},
    children,
}) => {
    const [disabledBtn, setDisabledBtn] = useState(disabled);

    const isMountedRef = useComponentIsMounted();

    const domNavigate = useNavigate();

    const callBackOrRedirect = async (e) => {
        setDisabledBtn(true);

        if (navigate) {
            if (onclick) await onclick(e);
            domNavigate(navigate);
        } else {
            if (onclick) await onclick(e);
        }

        if (!isMountedRef?.current) return;

        setDisabledBtn(false);
    };

    const selectValueByProps = () => {
        let variantBtn, sizeBtn, animationBtn;
        switch (variant) {
            case 'gradient':
                variantBtn = styles.gradient;
                break;
            case 'blueGradient':
                variantBtn = styles.blueGradient;
                break;
            case 'white':
                variantBtn = styles.white;
                break;
            case 'bordered':
                variantBtn = styles.bordered;
                break;
            case 'borderedGreen':
                variantBtn = styles.borderedGreen;
                break;
            case 'square':
                variantBtn = styles.square;
                break;
            case 'squareWhite':
                variantBtn = styles.squareWhite;
                break;

            default:
                variantBtn = '';
                break;
        }

        switch (size) {
            case 'main':
                sizeBtn = styles.main;
                break;
            case 'small':
                sizeBtn = styles.small;
                break;
            case 'smallMain':
                sizeBtn = styles.smallMain;
                break;
            case 'largeMain':
                sizeBtn = styles.largeMain;
                break;

            default:
                sizeBtn = '';
                break;
        }

        switch (animation) {
            case 'pulse':
                animationBtn = styles.pulse;
                break;

            default:
                animationBtn = '';
                break;
        }

        return [variantBtn, sizeBtn, animationBtn].join(' ');
    };

    useEffect(() => {
        setDisabledBtn(disabled);
    }, [disabled]);

    return (
        <button
            className={`${styles.button} ${className} ${selectValueByProps()}`}
            onClick={(e) => {
                if (!loading && !disabled) {
                    callBackOrRedirect(e);
                }
            }}
            disabled={disabledBtn}
            type={type}
            style={{ ...sx }}
        >
            {loading ? (
                <RowLoader className={styles.loader} />
            ) : (
                <React.Fragment>
                    {startIcon && <div className={`${styles.circle}`}>{startIcon}</div>}
                    <span>{children}</span>
                    {endIcon && <div className={`${styles.circle}`}>{endIcon}</div>}
                </React.Fragment>
            )}
        </button>
    );
};
