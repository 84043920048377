import React from 'react';
import styles from './PillVisionsStars.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';

/**
 *
 * @param {object} props
 * @param {number} props.visionsStars set number of visions stars
 * @param {"long"} props.size set type of size pill default "short"
 * @param {"small" | "large"} props.sizeImg set size to the image vision stars
 * @param {"gradientOpacity" | "blue"} props.variant set variant pill ui default "gradient"
 * @param {boolean} props.less set less visions stars
 * @param {boolean} props.remove set remove pill
 * @param {object} props.sx set quickly inline style pill
 * @param {string} props.className set additional className in parents component
 * @returns
 */
export const PillVisionsStars = ({
    visionsStars,
    size = '',
    sizeImg = '',
    variant = '',
    less = false,
    remove = false,
    sx = {},
    className,
}) => {
    const setSelectedProps = () => {
        let setVariant, setSizeImg;
        switch (variant) {
            case 'gradientOpacity':
                setVariant = styles.gradientOpacity;
                break;
            case 'blue':
                setVariant = styles.blue;
                break;
            default:
                break;
        }

        switch (sizeImg) {
            case 'small':
                setSizeImg = styles.small;
                break;
            case 'large':
                setSizeImg = styles.large;
                break;
            default:
                break;
        }

        return [setVariant, setSizeImg].join(' ');
    };

    return (
        <div
            className={`${styles.pill} ${className} ${setSelectedProps()}`}
            style={{ display: remove && 'none', ...sx }}
        >
            <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
            {size === 'long' ? (
                <span>{visionsStars || 0} VisionsStars</span>
            ) : (
                <span>
                    {less ? '-' : '+'}
                    {visionsStars || 0}
                </span>
            )}
        </div>
    );
};
