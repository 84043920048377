import React from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../../config';
import { appLinks } from '../../../data/enums/appLinks';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import styles from './onlylogocard.module.scss';

const CARD_SIZE = {
    normal: '',
    small: styles.small,
};

const OnlyLogoCard = ({ service, size = 'normal', isPublic = false }) => {
    const link = `${appLinks.catalog.serviceDetails}/${service._id}` || '#';
    const navigate = useNavigate();
    return (
        <div className={`${styles.card} ${CARD_SIZE[size]}`} onClick={() => (isPublic ? {} : navigate(link))}>
            <img src={`${config.logoBaseUrl}/${service.logo}`} alt={`${service.name} Logo`} />
            {size === 'normal' && (
                <div className={styles.bg}>
                    <div className={styles.rewards}>
                        <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                        <span>+{service?.galaxy?.user?.visionsStars}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OnlyLogoCard;
