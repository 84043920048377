/// SET OF HELPER METHODS TO WORK WITH MISSIONS

import { percentageOf } from '../../utils/math/mathUtils';
import { serverCall } from '../../utils/serverAPICall';

const setUserMissionHelpers = (userMission) => {
    userMission.totalTasks = getTotalTasks(userMission);
    userMission.completedTasks = getCompletedTasks(userMission);
    userMission.totalStars = getTotalStars(userMission);
    userMission.isCompleted = isMissionCompleted(userMission);
    userMission.progressRatio = `${userMission.completedTasks}/${userMission.totalTasks}`;
    userMission.completionPercentage = percentageOf(userMission.completedTasks, userMission.totalTasks);
    return userMission;
};

// * INTRO

// HARDCODE
export class StarterMission {
    static hasSeenPopupStorageKey = 'hasSeenStarterMissionPopup';
    static mission(user) {
        const sm = {
            completion: [
                {
                    task: {
                        name: 'Découvrez un outil',
                        visionsStars: 50,
                    },
                    completed: false,
                },
                {
                    task: {
                        name: 'Récupérez votre récompense',
                        visionsStars: 50,
                    },
                    completed: false,
                },
            ],
        };

        const curStep = user?.gamification?.starterMissionStep || -1;
        if (curStep !== -1 && curStep < sm.completion.length) {
            for (let i = 0; i < curStep; i++) {
                sm.completion[i].completed = true;
            }
        }

        if (curStep >= sm.completion.length || user?.gamification?.hasCompletedStarterMission) {
            for (const el of sm.completion) {
                el.completed = true;
            }
        }

        const final = setUserMissionHelpers(sm);
        return final;
    }

    static async validateCurrent(user) {
        const sm = await this.mission(user);
        const idx = sm.completion.findIndex((el) => el.completed === false);
        if (idx !== -1) {
            sm.completion[idx].completed = true;
            await serverCall.POST('/gamification/intro/startermission', {
                userId: user._id,
                completed: setUserMissionHelpers(sm).isCompleted,
            });
            return sm;
        } else return sm;
    }

    static getBtnText(mission) {
        switch (mission.completedTasks) {
            case 0:
                return 'Je commence';
            case 1:
                return 'Continuer';
            case 2:
                return 'Fermer';
            default:
                return 'Continuer';
        }
    }

    static setHasSeenPopupStorage(mission) {
        if (mission.completion[0].completed) sessionStorage.setItem(this.hasSeenPopupStorageKey, 'true');
    }

    static hasSeenPopupStorage() {
        return sessionStorage.getItem(this.hasSeenPopupStorageKey) === 'true';
    }
}

// * GETTERS

/**
 * Returns an array of all VisionsTrust GlobalPurposes (objectives)
 * @returns {any[]} Objectives array
 */
export const getObjectives = async () => {
    const objectives = await serverCall.GET('/public/missionobjectiveassociations/objectives');
    return objectives;
};

/**
 * Returns filtered or non filtered user missions. The filter parameter is optional,
 * if not set, will return all usermissions by default
 * @param {string} userId The user ID
 * @param {'completed' | 'incomplete'} filter The filters to use
 */
export const getUserMissions = async (userId, filter = '') => {
    const userMissions = await serverCall.GET(`/gamification/usermissions/${userId}?filter=${filter}`);
    return userMissions;
};

/**
 * Returns the populated user mission with the corresponding ID. Returns null if not found.
 * @param {string} userMissionId The user mission ID
 */
export const getUserMissionById = async (userMissionId) => {
    try {
        const userMission = await serverCall.GET(`/gamification/usermission/${userMissionId}`);
        return setUserMissionHelpers(userMission);
    } catch (err) {
        console.error(err);
        return null;
    }
};

/**
 * Returns the missions associated to an objective along with a
 * completion array for each. Even if the user has not yet started
 * @returns The missions array with a completion array
 */
export const getMissionsFromObjective = (missionsFromObjective, userMissions) => {
    const result = [];
    try {
        const makeMissionCompletionArray = (mission) => {
            const completion = [];
            for (const t of mission.tasks) {
                completion.push({ task: t, completed: false });
            }
            return completion;
        };

        const filteredMissionsFromObjective = missionsFromObjective.missions.filter((m) => m?.tasks?.length > 0);

        // Make array of missions with the progression from the user
        for (const m of filteredMissionsFromObjective) {
            const missionWithCompletion = m;
            const started = userMissions.find((el) => el?.mission?._id === m._id);
            if (!started) missionWithCompletion.completion = makeMissionCompletionArray(m);
            else missionWithCompletion.completion = started.completion;

            result.push(setUserMissionHelpers(missionWithCompletion));
        }
        return result;
    } catch (err) {
        console.error(err);
        return result;
    }
};

/**
 * Fetches the task by ID from VisionsTrust
 * @param {string} taskId The task ID to get info from
 * @returns The task
 */
export const getTaskById = async (taskId) => {
    const task = await serverCall.GET('/gamification/tasks/' + taskId);
    return task;
};

// * TASKS AND MISSION PROGRESSION

/**
 * Starts a mission and returns a userMission
 * @param {string} missionId The mission ID to start
 * @param {string} userId The user ID to start the mission for
 * @returns The started userMission
 */
export const startMission = async (missionId, userId) => {
    const userMission = await serverCall.POST('/gamification/usermissions', {
        missionId,
        userId,
    });

    return setUserMissionHelpers(userMission);
};

/**
 * Returns the current task of the user in the mission
 * @param {any} userMission The user mission as received by VisionsTrust
 * @param {boolean} returnIndex If the response should be an object containing the index of the task
 * @returns {any | {currentTask: any, index: number}} The current task
 */
export const getCurrentTask = async (userMission, returnIndex = false) => {
    // If the userMission is used from the getter for all user missions,
    // the completion array might not be populated. This only acts as a check
    // if we have an _id instead of the task object
    if (typeof userMission.completion[0].task === 'string') userMission = await getUserMissionById(userMission._id);

    const { completion } = userMission;
    const idx = completion.findIndex((el) => el.completed === true);

    if (idx === -1) return completion[0];
    if (returnIndex) return { currentTask: completion[idx], index: idx };

    return completion[idx];
};

/**
 * Returns the total number of tasks in the mission
 * @param {any} userMission The populated userMission
 * @returns {number}
 */
const getTotalTasks = (userMission) => userMission?.completion?.length || 0;

/**
 * Returns the number of completed tasks in the mission
 * @param {any} userMission The populated userMission
 * @returns {number} The number of completed  tasks
 */
const getCompletedTasks = (userMission) => {
    return userMission?.completion?.filter((task) => task.completed === true).length || 0;
};

/**
 * Gets the total amount of stars from the mission's tasks
 * @param {any} userMission The populated userMission
 * @returns The sum of all stars of all tasks
 */
const getTotalStars = (userMission) => {
    return (
        userMission?.completion?.reduce((prev, cur) => {
            return (prev += cur.task.visionsStars);
        }, 0) || 0
    );
};

/**
 * Checks if the userMission is fully completed
 * @param {any} userMission The populated userMission
 * @returns If the mission tasks have all been completed
 */
const isMissionCompleted = (userMission) => {
    return getCompletedTasks(userMission) === getTotalTasks(userMission);
};

/**
 * Finds the index of the corresponding task in the userMission completion array
 * @param {any} userMission Tne user mission as received by VisionsTrust
 * @param {string} taskId The task to get the index from in the user mission
 * @returns The task index in the user mission completion array
 */
export const getTaskIndex = (userMission, taskId) => {
    const idx = userMission.completion.findIndex((el) => el._id === taskId);
    return userMission.completion[idx];
};

/**
 * Returns the next task in the mission if there is one
 * @param {any} userMission The user mission as received by VisionsTrust
 * @returns {any | null} the next task if there is one, or null
 */
export const getNextTask = async (userMission) => {
    const { index } = await getCurrentTask(userMission, true);

    const max = userMission.completion.length;
    if (index + 1 >= max) return null;

    return userMission.completion[index + 1];
};

/**
 * Completes the task and updates in on the server.
 * Also assigns corresponding VisionsStars
 * @param {string} userId The user ID
 * @param {string} taskId The task ID to complete
 * @param {string} currentMissionId Optional
 */
export const completeTask = async (userId, taskId, currentMissionId = undefined) => {
    const res = await serverCall.POST('/gamification/user/tasks/' + taskId, {
        userId,
        currentMissionId,
    });

    return res;
};

// * OBJECTIVES

export const getServicesFromTask = async (objectiveId) => {
    const services = await serverCall.GET(`/gamification/tasks/${objectiveId}/services`);
    return services || [];
};

// * HELPERS

export const getCompletedMissions = (userMissions) => {
    return userMissions.filter((um) => um.isCompleted);
};
