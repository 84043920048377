import React, { useEffect, useState } from 'react';
import styles from './Recommendation.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import ContentCardRecommendation from '../../../Components/ContentCards/ContentCardRecommendation';
import ExperienceCardContainer from '../../../Components/Cards/ExperienceCardContainer/ExperienceCardContainer';
import useAuthenticated from '../../../hooks/useAuthenticated';
import DataNotFound from '../../../Components/ContentUnavailable/DataNotFound';
import RoundButtonIcon from '../../../Components/Buttons/RoundButtonIcon/RoundButtonIcon';
import { appLinks } from '../../../data/enums/appLinks';

const Recommendation = () => {
    const authCheck = useAuthenticated()[0];
    const hasRecommendationSentencesData = localStorage.getItem('recommendationSentences') ? true : false;

    const recommendationSentences = useState(JSON.parse(localStorage.getItem('recommendationSentences')))[0];
    const [recosError, setRecosError] = useState(false);
    const [recosLoading, setRecosLoading] = useState(false);
    const [recosData, setRecosData] = useState({ recommendations: [] });

    useEffect(() => {
        if (!authCheck || !hasRecommendationSentencesData || !recommendationSentences.length) return;

        setRecosLoading(true);

        fetch('/api/recommendations', {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                recommendationSentences,
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                setRecosData(data);
            })
            .catch((err) => {
                setRecosError(true);
            })
            .finally(() => {
                setRecosLoading(false);
            });
    }, [authCheck, hasRecommendationSentencesData, recommendationSentences]);

    return (
        <>
            <HeaderWithNavBar tabActive={2} />
            <div className={styles.main}>
                <div className={styles.content}>
                    <CardBgBottom>
                        {recosLoading && (
                            <ContentCardRecommendation services={recosData.recommendations || []} loading={true} />
                        )}
                        {recosError && <div>Une erreur est survenue lors du chargement de vos recommendations</div>}
                        {!recosLoading &&
                            recosData &&
                            recosData?.recommendations &&
                            recosData?.recommendations?.length !== 0 && (
                                <ContentCardRecommendation services={recosData.recommendations} loading={false} />
                            )}
                        {!recosLoading &&
                            !recosError &&
                            recosData.recommendations &&
                            recosData.recommendations.length === 0 && (
                                <div>
                                    <DataNotFound text={"Aucune recommendation n'a pu être trouvée"} />
                                    <div className={styles.btn}>
                                        <RoundButtonIcon
                                            textContent={'Refaire le jeu'}
                                            link={appLinks.visionsSwipe}
                                            bgColor={'basic'}
                                        />
                                    </div>
                                </div>
                            )}
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.linkCard}>
                        <LinkCardContainer whichCardsArray={['cozy', 'meet', 'event']} />
                    </div>
                    <div className={styles.exp}>
                        <ExperienceCardContainer whichCardsArray={['orientation']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Recommendation;
