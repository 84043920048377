import { serverCall } from '../../utils/serverAPICall';

const EVENT_CATEGORY_NAMES = {
    1: 'Online',
    2: 'Portes Ouvertes',
    3: 'Salons Conférences',
    4: 'Ateliers',
};

const getCategoryName = (ev) => EVENT_CATEGORY_NAMES[ev?.category] || '';

/**
 * Categorises events per category in an object where the key is equal to the category (int)
 * @param {any[]} events Array of events
 * @returns {any} An object containing the events classified by category
 */
export const mapEventsToCategories = (events) => {
    const categories = {
        1: [],
        2: [],
        3: [],
        4: [],
    };
    for (const ev of events) {
        if (categories[ev.category]) {
            categories[ev.category].push(ev);
        } else {
            categories[ev.category] = [ev];
        }
    }
    return categories;
};

/**
 * Categorises events per category in an object where the key is equal to the category (int)
 * @param {any[]} events Array of events
 * @returns An array filtered for the events classified by top events category
 */
export const filterTopEvents = (events) => {
    const topEvents = events.filter((event) => {
        if (event.title === 'OpenClassrooms' || event.title === 'studi' || event.title === 'AEF') return event;
        else return null;
    });

    return topEvents;
};

/**
 * Get all VisionsGalaxy Events from VisionsTrust
 * @returns array of visionsGalaxyEvents
 */
export const getEvents = async () => {
    const evs = await serverCall.GET('/public/galaxyevents');
    return evs.map((el) => {
        return {
            ...el,
            categoryName: getCategoryName(el),
        };
    });
};

/**
 * Gets one VisionsGalaxy Event by ID
 * @param {string} eventId The VisionsGalaxy Event id
 * @returns The VisionsGalaxy Event
 */
export const getEvent = async (eventId) => {
    const ev = await serverCall.GET('/public/galaxyevents/' + eventId);
    return {
        ...ev,
        categoryName: getCategoryName(ev),
    };
};
