import React, { useEffect, useState } from 'react';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';

import styles from './TasksChoicePage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import Loader from '../../../Components/Loaders/Loader/Loader';
import { useNavigate, useParams } from 'react-router-dom';
import { config } from '../../../config';
import { usePublicVisionsGalaxyData } from '../../../hooks/usePublicVisionsGalaxyData';
import { openWindow } from '../../../utils/openWindow';
import ImageBorder from '../../../Components/Image/ImageBorder/ImageBorder';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import { generateCozyLink } from '../../../utils/cozy';
import { useUserMissions } from '../../../hooks/missions/useUserMissions';
import NoticePriceService from '../../../Components/Notices/NoticePriceService/NoticePriceService';
import { useAuth } from '../../../hooks/useAuth';
import { useTaskServices } from '../../../hooks/useTaskServices';
import { useMissionMethods } from '../../../hooks/useMissionMethods';
import { appLinks } from '../../../data/enums/appLinks';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../../Components/Buttons/Button/Button';
import { Icon } from '../../../Components/Icons/Icon/Icon';

const TasksChoicePage = () => {
    const navigate = useNavigate();

    const { user } = useAuth();

    const { completeTask } = useMissionMethods();

    const [mainObjective, setMainObjective] = useState(null);

    const { taskId } = useParams();
    const task = usePublicVisionsGalaxyData({ path: '/missiontasks/' + taskId })[2];
    const { userMissions, userMissionsLoaded } = useUserMissions(mainObjective);
    const [, servicesLoading, services] = useTaskServices({ taskId });

    const [taskCompleted, setTaskCompleted] = useState(false);
    const [taskNumber, setTaskNumber] = useState(null);

    const [fromMatchs, setFromMatchs] = useState(false);

    const handleBtnBackClick = () => {
        sessionStorage.setItem('isReturningFromTaskPage', 'true');
    };

    const generateSelectedServiceUrl = (service) => {
        if (service?.name?.toLowerCase()?.includes('cozy')) return generateCozyLink(user._id, user.email);
        else return service?.urls?.website;
    };

    useEffect(() => {
        if (!sessionStorage.getItem('fromMatchs')) return;

        setFromMatchs(true);
        sessionStorage.removeItem('fromMatchs');
    }, []);

    useEffect(() => {
        setMainObjective(JSON.parse(localStorage.getItem('mainObjective')));
    }, []);

    useEffect(() => {
        if (!user || mainObjective) return;

        setMainObjective(user?.gamification?.mainObjective);
    }, [user, mainObjective]);

    ///TODO Do better method for track task completed
    useEffect(() => {
        if (userMissionsLoaded) return;

        userMissions.forEach((mission) => {
            mission?.completion.forEach((choice, index) => {
                if (choice?.task?._id === taskId) {
                    setTaskNumber(index + 1);
                }
                if (choice?.completed && choice?.task?._id === taskId) {
                    setTaskCompleted(true);
                    return;
                }
            });
            return;
        });
    }, [userMissionsLoaded, userMissions, taskId]);

    if (servicesLoading || !task) {
        return <Loader />;
    }

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <div className={styles.btnBack}>
                <BtnBack link={fromMatchs ? appLinks.matchs : appLinks.missions.index} onclick={handleBtnBackClick} />
            </div>
            <div className={styles.container}>
                <div className={styles.cardInformation}>
                    {!fromMatchs && (
                        <h2>
                            {taskCompleted
                                ? "Vous avez choisi d'approfondir"
                                : "Prêt à partir à l'aventure ? Vous avez choisi d'accomplir"}
                        </h2>
                    )}
                    {fromMatchs && (
                        <h2>Vous souhaitez obtenir des matchs ultra précis ou avoir de nouvelles propositions ?</h2>
                    )}
                    {taskNumber !== null ? (
                        <p>
                            <span>tâche {taskNumber}</span> {task.name}
                        </p>
                    ) : (
                        <p>
                            {fromMatchs
                                ? 'Complétez davantage votre profil en déterminant plus de compétences, de métiers et de secteurs qui vous intéressent'
                                : task.name}
                        </p>
                    )}
                    {services?.length !== 0 && (
                        <div className={styles.achieve}>
                            {fromMatchs && (
                                <p>
                                    Pour compléter votre profil et découvrir de nouveaux matchs, nous vous proposons 2
                                    outils qui répondent à vos besoins.
                                </p>
                            )}
                            {taskCompleted && !fromMatchs ? (
                                <>
                                    <p style={{ fontWeight: 'bold', marginBottom: 20 }}>
                                        Vous avez déjà accompli cette tâche.
                                    </p>
                                    <p>
                                        {services?.length === 1
                                            ? "Mais vous pouvez continuer à explorer l'outil lié à cette tâche."
                                            : services?.length > 1
                                            ? `Mais vous pouvez continuer à explorer d'autres outils liés à cette tâche.
                                        Choisissez l'outil qui vous intéresse parmi les ${services.length} propositions suivantes.`
                                            : "Mais vous pouvez continuer à explorer d'autres outils liés à cette tâche. Choisissez l'outil qui vous intéresse parmi ces propositions suivantes."}
                                    </p>
                                </>
                            ) : (
                                !fromMatchs && (
                                    <p>
                                        Pour réaliser cette tâche nous vous proposons{' '}
                                        {services?.length > 0 ? services.length : 'ces'}{' '}
                                        {services?.length === 1 ? 'outil qui répond' : 'outils qui répondent'} à vos
                                        besoins, <br />
                                        {services?.length === 1
                                            ? "Terminer l'outil pour accomplir cette tâche et gagner"
                                            : "Choisissez l'outil qui vous intéresse pour accomplir cette tâche et gagner"}
                                    </p>
                                )
                            )}
                            {!taskCompleted && !fromMatchs && (
                                <div className={styles.reward}>
                                    <div className={styles.visionStars}>
                                        <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                                        <span>+{task.visionsStars}</span>
                                    </div>
                                    {/* <div className={styles.experience}>
                                        <img src="" alt="" />
                                        <span>{task.task.xp}</span>
                                    </div> */}
                                </div>
                            )}
                        </div>
                    )}

                    {services.length === 0 && (
                        <div style={{ margin: '30px 0 10px 0' }}>
                            <TextContainer>
                                Désolé, aucun outil n'est disponible pour cette tâche pour le moment.
                            </TextContainer>
                        </div>
                    )}
                </div>
                <div className={styles.choices}>
                    {services.map((service, index) => (
                        <CardBgBottom key={index} animation={'hoverBlue'}>
                            <div className={styles.contentCard}>
                                <div className={styles.edtech}>
                                    <div className={styles.header}>
                                        <ImageBorder
                                            image={`${config.logoBaseUrl}/${service.logo}`}
                                            alt={`Logo ${service.name}`}
                                            size={'xs'}
                                        />
                                        <h4>{service.name}</h4>
                                    </div>
                                    <div className={styles.content}>
                                        <ul className={styles.tags}>
                                            {service?.galaxy?.user?.tags.map((tag) => (
                                                <li key={tag}>{tag}</li>
                                            ))}
                                        </ul>
                                        <p className={styles.textInfo}>
                                            {service?.galaxy?.user?.description || service?.description}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.btn}>
                                    {service?.galaxy?.user?.isFree === false && <NoticePriceService />}
                                    <ButtonContainer>
                                        <Button
                                            
                                            endIcon={<Icon icon="arrow" />}
                                            sx={{ fontWeight: 'bold' }}
                                            onclick={async () => {
                                                await completeTask(task._id);
                                                openWindow(generateSelectedServiceUrl(service));

                                                navigate(fromMatchs ? appLinks.matchs : appLinks.missions.index);
                                            }}
                                        >
                                            Commencer
                                        </Button>
                                    </ButtonContainer>
                                </div>
                            </div>
                        </CardBgBottom>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TasksChoicePage;
