import { createContext, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { serverCall } from '../utils/serverAPICall';

const AuthContext = createContext({
    /**
     * The user from the database
     */
    user: null,

    /**
     * Method to call after logging in the user to set the user data
     */
    login: () => {},

    /**
     * Method to call after saving user info to server to update the
     * user in the context
     */
    update: () => {},

    /**
     * Method to call to log the user out and clear the user from localStorage
     */
    logout: () => {},
});

export const AuthProvider = ({ children, userData }) => {
    const [user, setUser] = useLocalStorage('user', userData);
    const navigate = useNavigate();

    const setUserFromServer = useCallback(
        async (userId) => {
            const data = await serverCall.GET(`/users/${userId}`);
            setUser(data);
            return data;
        },
        [setUser],
    );

    const login = useCallback(setUserFromServer, [setUserFromServer]);
    const update = useCallback(setUserFromServer, [setUserFromServer]);

    const logout = useCallback(
        (redirect = true) => {
            setUser(null);
            if (redirect) navigate('/', { replace: true });
        },
        [navigate, setUser],
    );

    const value = useMemo(
        () => ({
            user,
            login,
            update,
            logout,
        }),
        [user, login, update, logout],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
