import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonRecapPurchaseRewards = () => {
    return (
        <Skeleton parent rounded={10} parentSx={{ padding: 10 }} LightBgParent>
            <div style={{ display: 'flex' }}>
                <Skeleton width={80} height={80} rounded={10}></Skeleton>
                <div style={{ marginLeft: 5, flexGrow: 1 }}>
                    <Skeleton height={42} sx={{ marginBottom: 5 }}></Skeleton>
                    <Skeleton variant={'text'} width={120} sx={{ margin: '5px 0' }}></Skeleton>
                    <Skeleton variant={'text'} width={130} sx={{ margin: '5px 0' }}></Skeleton>
                </div>
            </div>
        </Skeleton>
    );
};

export default SkeletonRecapPurchaseRewards;
