import { useEffect, useState } from 'react';
import { useClient } from './useClient';

const useVisionsStars = () => {
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [visionsStars, setVisionsStars] = useState(0);

    const { client } = useClient();

    useEffect(() => {
        if (!client) return;
        let isMounted = true;

        const fetchUserRewards = async () => {
            try {
                const data = await client.GET('/userrewards/me');

                if (!isMounted) return;
                if (data?.visionsStars === undefined || data?.visionsStars === null) {
                    throw new Error('Could not retrieve VisionsStars');
                }

                setVisionsStars(data.visionsStars);
            } catch (err) {
                console.warn(err.message);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchUserRewards();

        return () => {
            isMounted = false;
        };
    }, [client]);

    return [error, loading, visionsStars, setVisionsStars];
};

export default useVisionsStars;
