import React from 'react';
import styles from './NoticePriceService.module.scss';
import TextContainer from '../../Text/TextContainer/TextContainer';
import { fontAwesome } from '../../../data/enums/fontAwesome';

const NoticePriceService = ({ bgGradient = false }) => {
    return (
        <div className={styles.notice}>
            <TextContainer border={false} className={`${styles.price} ${bgGradient ? styles.priceVariant : ''}`}>
                Essai gratuit puis abonnement
                <i className={fontAwesome.euro} style={{ color: bgGradient && '#16f7b4' }}></i>
            </TextContainer>
        </div>
    );
};

export default NoticePriceService;
