import React from 'react';

// Style & Icon
import styles from './manualexperience.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { KNOWN_CONTEXTS } from '../../../libs/experiences/context';
import { getSpecificContextExperienceData } from '../../../data/context/experienceSpecificData';

const ManualExperience = ({ experience, context }) => {
    /**
     * Verifies if the context is set and if so returns
     * the context specific data for the said key
     * @param {string} key The key to check
     */
    const contextDataOrExperienceData = (key) => {
        if (context === KNOWN_CONTEXTS.numerique) {
            return getSpecificContextExperienceData(context)[key] || experience[key];
        }
        return experience[key];
    };

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <i className={fontAwesome.mobilePhone}></i>
                </div>
                <div className={styles.title}>Mode d'emploi</div>
            </div>
            <div className={styles.content}>
                {contextDataOrExperienceData('guide').map((step, index) => (
                    <div className={styles.stepContainer} key={index}>
                        <div className={styles.stepNumber}>
                            <span>{index + 1}</span>
                        </div>
                        <p>{step}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ManualExperience;
