import { useCallback } from 'react';
import { useClient } from './useClient';

export const useMissionMethods = () => {
    const { client } = useClient();

    /**
     * Completes a task of a mission
     * @param {string} taskId The ID of the task to complete
     */
    const completeTask = useCallback(
        async (taskId) => {
            try {
                const data = await client.POST(`/usermissions/tasks/${taskId}`);
                return data;
            } catch (err) {
                console.error('Failed to complete task')
                return null;
            }
        },
        [client],
    );

    return { completeTask };
};
