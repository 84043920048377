import React, { useState, useRef, useEffect } from 'react';

// Components
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import GlobalModal, { MODAL_TYPES } from '../../../Components/Modal/GlobalModal/GlobalModal';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import ManualExperience from '../../../Components/ContentCards/ManualExperience/ManualExperience';
import StepCardService from '../../../Components/Cards/StepCardService/StepCardService';
import ProgressBar from '../../../Components/ProgressBar/ProgressBar';
import ShareBilanExperiences from '../../../Components/Experiences/ShareBilanExperiences/ShareBilanExperiences';
import PlatformJourneyGrade from '../../../Components/Grade/PlatformJourneyGrade/PlatformJourneyGrade';
import DescriptionExperience from '../../../Components/Experiences/DescriptionExperience/DescriptionExperience';
import Loader from '../../../Components/Loaders/Loader/Loader';
import ExperiencesErrors from '../../../Components/Errors/ExperiencesErrors/ExperiencesErrors';

// Hooks & Utils
import { appLinks } from '../../../data/enums/appLinks';
import { generateCozyLink } from '../../../utils/cozy';
import { percentageOf } from '../../../utils/math/mathUtils';
import { serviceIds } from '../../../data/enums/services';
import useQuery from '../../../hooks/useQuery';

// Style & Img/Icon
import styles from './ExperienceBoPage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { getContext, KNOWN_CONTEXTS, saveContext } from '../../../libs/experiences/context';
import { openWindow } from '../../../utils/openWindow';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';
import { useVisionsGalaxyData } from '../../../hooks/useVisionsGalaxyData';

const BO_EXPERIENCE_PLATFORMJOURNEY_ID = '633d7adfd0f014cae6958024';

const COZY_KONNECTORS = [
    serviceIds.orientoi,
    // serviceIds.inokufu,
    serviceIds.jobready,
];

const ExperienceBoPage = () => {
    const { user } = useAuth();
    const { _id } = user;
    const { client } = useClient();

    const { params } = useQuery();
    const comingfrom = params.get('comingfrom');
    const [comingFromModalVisible, setComingFromModalVisible] = useState(false);

    const [userContext, setUserContext] = useState(null);

    const [experienceDataError, experienceDataLoading, experienceData] = useVisionsGalaxyData({
        path: `/userplatformjourneys/${BO_EXPERIENCE_PLATFORMJOURNEY_ID}/me`,
    });

    const [curentOffsetValue, setCurrentOffsetValue] = useState(10);
    const [hasSetCurrentOffsetValue, setHasSetCurrentOffsetValue] = useState(false);

    const [slideMultiplier, setSlideMultiplier] = useState(0);
    const [transformValue, setTransformValue] = useState(0);
    const [cardAmount, setCardAmount] = useState(0);

    // Ref carousel arrows
    const refSlideRight = useRef(null);
    const refSlideLeft = useRef(null);

    const [gaugePercentage, setGaugePercentage] = useState(0);

    // Modal Open
    const [open, setOpen] = useState(false);
    const [currentPlatform, setCurrentPlatform] = useState({});

    const [statusOrientoiFinished, setStatusOrientoiFinished] = useState(false);

    const [confirmationExchange, setConfirmExchange] = useState(false);

    const [finishedPlatforms, setFinishedPlatforms] = useState(0);

    const OpenModal = (currentPlatform) => {
        setCurrentPlatform(currentPlatform);
        if (currentPlatform?.platform?.type === 1) {
            setConfirmExchange(false);
        }
        setOpen(true);
    };
    const closeModal = (confirm) => {
        setOpen(false);
        if (confirm) setConfirmExchange(confirm);
    };

    const getFinishedPlatformsNumber = (platforms) => {
        return platforms.reduce((prev, cur) => {
            return cur.status === 2 ? prev + 1 : prev;
        }, 0);
    };

    // Set experience context if existing
    useEffect(() => {
        if (!_id) return;
        const doAsync = async () => {
            const [, context] = await Promise.all([saveContext(_id), getContext(_id)]);

            setUserContext(context);
        };

        doAsync();
    }, [_id]);

    // Set slider logic on data reception
    useEffect(() => {
        if (
            !refSlideLeft.current ||
            !refSlideRight.current ||
            experienceDataError ||
            experienceDataLoading ||
            !experienceData?.platformJourney
        )
            return;

        setCardAmount(experienceData.platformJourney.platforms.length);

        if (cardAmount < 4) {
            refSlideRight.current.style.display = 'none';
            refSlideLeft.current.style.display = 'none';
        } else if (slideMultiplier * 3 + 1 > cardAmount - 3) {
            refSlideRight.current.style.display = 'none';
            refSlideLeft.current.style.display = 'flex';
        } else if (slideMultiplier === 0) {
            refSlideLeft.current.style.display = 'none';
            refSlideRight.current.style.display = 'flex';
        }
        setTransformValue(curentOffsetValue * -slideMultiplier);
    }, [slideMultiplier, curentOffsetValue, cardAmount, experienceData, experienceDataLoading, experienceDataError]);

    // Set gauge percentage
    useEffect(() => {
        if (experienceDataError || experienceDataLoading || !experienceData?.userPlatformJourney) return;

        const platforms = experienceData?.userPlatformJourney?.platforms;

        const finishedPlatforms = getFinishedPlatformsNumber(platforms);

        setFinishedPlatforms(finishedPlatforms);

        const inokufuPlaform = platforms.find((platform) => platform.platform._id === serviceIds.inokufu);

        if (inokufuPlaform.status === 2) {
            setConfirmExchange(true);
        }

        setGaugePercentage(percentageOf(finishedPlatforms, platforms.length));
    }, [experienceData, experienceDataLoading, experienceDataError]);

    const updateCardWidth = (width) => {
        if (hasSetCurrentOffsetValue) return;
        if (width * 3 === curentOffsetValue) return;
        setCurrentOffsetValue(width * 3);
        setHasSetCurrentOffsetValue(true);
    };

    const inokufuConsentExchangeSuccess = async () => {
        const data = await client.POST('/userplatformjourneys', {
            userId: _id,
            platformJourneyId: BO_EXPERIENCE_PLATFORMJOURNEY_ID,
            platformId: serviceIds.inokufu,
        });

        if (!data) return;

        setConfirmExchange(true);

        const platforms = data.userPlatformJourney?.platforms;

        const finishedPlatforms = getFinishedPlatformsNumber(platforms);

        setFinishedPlatforms(finishedPlatforms);

        const inokufuPlaform = platforms.find((platform) => platform.platform === serviceIds.inokufu);

        if (inokufuPlaform.status === 2) {
            setConfirmExchange(true);
        }

        setGaugePercentage(percentageOf(finishedPlatforms, platforms.length));
    };

    // Use effect for comingFrom
    useEffect(() => {
        if (!comingfrom) return;
        if (comingfrom !== serviceIds.orientoi) return;
        setComingFromModalVisible(true);
    }, [comingfrom]);

    if (experienceDataError)
        return (
            <div className={styles.page}>
                <HeaderWithNavBar />
                <ExperiencesErrors />
            </div>
        );
    if (experienceDataLoading)
        return (
            <div className={styles.page}>
                <HeaderWithNavBar />
                <Loader />
            </div>
        );

    if (Array.isArray(experienceData) && !experienceData.length) {
        return (
            <div className={styles.page}>
                <HeaderWithNavBar />
                <Loader />
            </div>
        );
    }

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.experiences.index} />
            {!!comingfrom && (
                <GlobalModal
                    modalType={MODAL_TYPES.comingFromPlatform}
                    open={comingFromModalVisible}
                    closeModal={() => setComingFromModalVisible(false)}
                    redirectionLink={generateCozyLink(_id, user.email, COZY_KONNECTORS)}
                />
            )}
            <GlobalModal
                open={open}
                closeModal={closeModal}
                data={currentPlatform}
                onSuccess={inokufuConsentExchangeSuccess}
                modalType={currentPlatform?.platform?.type || 0}
            />
            <div className={styles.main}>
                <div className={styles.content}>
                    <DescriptionExperience experience={experienceData.platformJourney} context={userContext} />

                    {/*Content display only on mobile device */}
                    <div className={styles.isMobile}>
                        <TitleBgCard title={'Information'} gradient={true} column={true} icon={fontAwesome.info}>
                            <p style={{ textAlign: 'center' }}>
                                Si vous avez besoin d'aide lors de votre parcours au sein des plateformes partenaires
                            </p>
                            <p className={styles.blue}>cliquez sur cette icone</p>
                            <img
                                className={styles.iconReo}
                                src={
                                    experienceData.platformJourney.image === 'default.jpg'
                                        ? appImageAssets.icon.BO
                                        : experienceData.platformJourney.image
                                }
                                alt={`Icone ${experienceData.platformJourney.name}`}
                            />
                        </TitleBgCard>
                        <CardBgBottom color={'gradient'}>
                            <ManualExperience experience={experienceData?.platformJourney} context={userContext} />
                        </CardBgBottom>
                    </div>

                    <CardBgBottom color={'blue'}>
                        <div
                            ref={refSlideRight}
                            className={`${styles.slider} ${styles.sliderRight}`}
                            onClick={() => setSlideMultiplier(slideMultiplier + 1)}
                        >
                            <i className={fontAwesome.angleRight}></i>
                        </div>
                        <div
                            ref={refSlideLeft}
                            className={`${styles.slider} ${styles.sliderLeft}`}
                            onClick={() => setSlideMultiplier(slideMultiplier - 1)}
                        >
                            <i className={fontAwesome.angleLeft}></i>
                        </div>
                        <div className={styles.cards} style={{ transform: `translateX(${transformValue}px)` }}>
                            {experienceData?.platformJourney &&
                                experienceData.platformJourney?.platforms?.map((obj, index) => {
                                    if (userContext !== null && userContext === KNOWN_CONTEXTS.numerique) {
                                        if (obj.platform._id === serviceIds.orientoi) {
                                            return (
                                                <StepCardService
                                                    key={index}
                                                    service={obj.platform}
                                                    cardAmount={experienceData?.platformJourney?.platforms?.length}
                                                    platformJourneyId={experienceData?.platformJourney?._id}
                                                    userPlatformJourney={experienceData?.userPlatformJourney}
                                                    setCardWidthFc={updateCardWidth}
                                                    setCardAmount={setCardAmount}
                                                    userId={_id}
                                                    confirmationExchange={confirmationExchange}
                                                    openModal={OpenModal}
                                                    statusOrientoiFinished={statusOrientoiFinished}
                                                    setStatusOrientoiFinished={setStatusOrientoiFinished}
                                                />
                                            );
                                        } else {
                                            return '';
                                        }
                                    } else {
                                        return (
                                            <StepCardService
                                                key={index}
                                                service={obj.platform}
                                                cardAmount={experienceData?.platformJourney?.platforms?.length}
                                                platformJourneyId={experienceData?.platformJourney?._id}
                                                userPlatformJourney={experienceData?.userPlatformJourney}
                                                setCardWidthFc={updateCardWidth}
                                                setCardAmount={setCardAmount}
                                                userId={_id}
                                                confirmationExchange={confirmationExchange}
                                                openModal={OpenModal}
                                                statusOrientoiFinished={statusOrientoiFinished}
                                                setStatusOrientoiFinished={setStatusOrientoiFinished}
                                            />
                                        );
                                    }
                                })}
                        </div>
                        {experienceData.userPlatformJourney && (
                            <ProgressBar
                                fillPercentage={gaugePercentage}
                                currentStep={finishedPlatforms}
                                totalSteps={experienceData?.userPlatformJourney?.platforms?.length}
                            />
                        )}
                        {!experienceData.userPlatformJourney && (
                            <ProgressBar
                                fillPercentage={gaugePercentage}
                                currentStep={0}
                                totalSteps={userContext ? 1 : experienceData?.platformJourney?.platforms?.length}
                            />
                        )}

                        {experienceData.platformJourney && (
                            <ShareBilanExperiences
                                locked={!statusOrientoiFinished}
                                onClickFc={() => openWindow(generateCozyLink(user._id, user.email, COZY_KONNECTORS))}
                                experienceName={experienceData?.platformJourney?.name}
                            />
                        )}
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.isMobile}>
                        <TitleBgCard title={'Information'} gradient={true} column={true} icon={fontAwesome.info}>
                            <p style={{ textAlign: 'center' }}>
                                Si vous avez besoin d'aide lors de votre parcours au sein des plateformes partenaires
                            </p>
                            <p className={styles.blue}>cliquez sur cette icone</p>
                            <img
                                className={styles.iconReo}
                                src={
                                    experienceData?.platformJourney?.image === 'default.jpg'
                                        ? appImageAssets.icon.BO
                                        : experienceData?.platformJourney?.image
                                }
                                alt={`Icone ${experienceData?.platformJourney?.name}`}
                            />
                        </TitleBgCard>
                        <CardBgBottom color={'gradient'}>
                            <ManualExperience experience={experienceData?.platformJourney} />
                        </CardBgBottom>
                        {experienceData?.platformJourney && (
                            <PlatformJourneyGrade
                                grade={experienceData?.platformJourney?.gradeGiven}
                                platformJourney={experienceData?.platformJourney}
                                userId={user._id}
                                userData={true}
                            />
                        )}
                        {!experienceData?.platformJourney  && (
                            <PlatformJourneyGrade
                                grade={experienceData?.averageGrade}
                                platformJourney={experienceData?.platformJourney}
                                userId={user._id}
                                userData={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceBoPage;
