import { serverCall } from '../../utils/serverAPICall';

/**
 * Returns the user info from storage or from the DB
 * @param {string} userId The User ID
 * @param {boolean} forceFetch if we force a fetch from the DB
 * @param {populateQuery} populateQuery optional items to populate
 * @returns The stored user info
 */
export const getUserInfo = async (userId, forceFetch = false, populateQuery = '') => {
    const getInfo = async () => {
        const userInfo = await serverCall.GET(
            `/info/user/${userId}${populateQuery ? '?populate=' + populateQuery : ''}`,
        );
        sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
        return userInfo;
    };

    if (forceFetch === true) {
        return await getInfo();
    }

    const userInfoStorage = sessionStorage.getItem('userInfo');

    if (!userInfoStorage) {
        return await getInfo();
    }

    return JSON.parse(userInfoStorage);
};

/**
 * Updates the user info in the storage from the latest one in the DB
 * @param {string} userId The User ID
 * @returns The to-date user info
 */
export const updateUserInfoStorage = async (userId) => {
    const userInfo = await serverCall.GET(`/info/user/${userId}`);
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
    return userInfo;
};

/**
 * Updates the user profile by passing in the fields to update
 * @param {string} userId The user ID to update
 * @param {any} userInfo The user profile info to update
 * @returns The updated user
 */
export const updateUserProfile = async (userId, userInfo) => {
    // Validation
    if (userInfo?.password) {
        const validate = (p) => {
            const passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
            return p.match(passw);
        };

        if (!validate(userInfo.password)) return alert('Format de mot de passe invalide');
    }

    if (userInfo?.phoneNumber) {
        const telVerification = (value) => value.length >= 10;
        if (!telVerification(userInfo.phoneNumber)) return alert('Numéro de telephone trop court');

        if (userInfo.phoneNumber.startsWith('+33')) userInfo.phoneNumber.replace('+33', '0');
    }

    const res = await serverCall.PUT('/info/user/' + userId, {
        userInfo,
    });
    return res;
};
