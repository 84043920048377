import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from '../Components/Loaders/Loader/Loader';
import { useAuth } from '../hooks/useAuth';
import { APIClient } from '../utils/APIClient';

const ClientContext = createContext({
    client: null,

    /**
     * Method that updates the user both in the DB and the context
     */
    updateUser: (payload) => {},
});

/**
 * A context provider placed one step below the AuthProvider to be able to handle
 * requests made to VisionsGalaxy and the appropriate error management regarding
 * user sessions. Having it under the AuthProvider lets it use the logout from it
 * for session expiration redirection handling
 */
export const ClientProvider = ({ children }) => {
    const { logout, user, update } = useAuth();

    const handleErrors = useCallback(
        (err) => {
            if (err.isSessionError) {
                logout();
                throw err;
            } else if (err.isClientError) {
                throw err;
            }
        },
        [logout],
    );

    const [userIdSet, setUserIdSet] = useState(false);

    useEffect(() => {
        if (!userIdSet && user?._id) {
            setUserIdSet(true);
        }
    }, [user, userIdSet]);

    const [client] = useState(new APIClient(handleErrors, user?._id || null));

    const updateUser = useCallback(
        async (payload) => {
            const updatedUser = await client.updateUser(payload);
            await update(updatedUser._id);
            return updatedUser;
        },
        [client, update],
    );

    const value = useMemo(
        () => ({
            client,
            updateUser,
        }),
        [client, updateUser],
    );

    return userIdSet ? (
        <ClientContext.Provider value={value}>{children}</ClientContext.Provider>
    ) : (
        <Loader text="Configuring client" />
    );
};

export default ClientContext;
