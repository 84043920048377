export const appLinks = {
    public: {
        landing: '/',
        bilan_orientation: '/public/bilan-orientation',
    },
    home: '/user',
    config: '/user/config',
    question: '/user/question',
    missions: {
        index: '/user/missions',
    },
    catalog: {
        index: '/user/catalog',
        category: '/user/catalog/category',
        serviceDetails: '/user/catalog/service',
    },
    choice: '/user/choice',
    profile: '/user/profile',
    rewards: {
        index: '/user/rewards',
        details: '/user/rewardsDetails',
    },
    share: '/user/share',
    matchs: '/user/matchs',
    visionsSwipe: '/user/visionsSwipe',
    events: '/user/events',
    meeting: '/user/meetings',
    experiences: {
        index: '/user/experiences',
        pre_start: '/user/experiences/pre-start',
        bilan_orientation: '/user/experiences/bilan-orientation',
        bilan_reorientation: '/user/experiences/bilan-reorientation',
        parcoursup: '/user/experiences/parcoursup',
        transition: {
            ongoing: '/user/experiences/bilan-orientation/transition',
            end: '/user/experiences/bilan-orientation/end',
        },
    },
    recommendations: '/user/recommendations',
    notifications: '/user/notifications',
    auth: {
        error: '/authentication/error',
        logout: '/user/authentication/logout',
    },
    hrefs: {
        cozyBase: 'https://manager.cozycloud.cc/v2/cozy/onboard?offer=demo_visionspol',
    },
};
