import React from 'react';
import { useParams } from 'react-router-dom';

// Components
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import RowMosaic from '../../../Components/Row/RowMosaic/RowMosaic';
import ExperienceCardContainer from '../../../Components/Cards/ExperienceCardContainer/ExperienceCardContainer';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';

// Utils
import { appLinks } from '../../../data/enums/appLinks';

// Styles
import styles from './MosaicEdtechsPage.module.scss';

const MosaicEdtechsPage = () => {
    const { name } = useParams();

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.catalog.index} extras={{ activity: 'catalog_view_all_catalog' }} />
            <div className={styles.main}>
                <div className={styles.content}>
                    <CardBgBottom>
                        <RowMosaic category={name} />
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.exp}>
                        <ExperienceCardContainer whichCardsArray={['orientation']} />
                    </div>
                    <div className={styles.linkCard}>
                        <LinkCardContainer whichCardsArray={['cozy']} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MosaicEdtechsPage;
