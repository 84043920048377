import { useEffect, useState } from 'react';

export const useApiCity = (delay = 500, limit = 10) => {
    const [citiesApiError, setCitiesApiError] = useState(false);
    const [citiesApiLoading, setCitiesApiLoading] = useState(true);
    const [citiesApiData, setCitiesApiData] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');

    useEffect(() => {
        if (!selectedCity) return;
        let unmounted = false;

        const delaySearch = setTimeout(() => {
            fetch(`https://geo.api.gouv.fr/communes?nom=${selectedCity}&boost=population&limit=${limit}`)
                .then((res) => res.json())
                .then((data) => {
                    if (unmounted) return;
                    if (!data) return;
                    setCitiesApiData(data);
                })
                .catch((err) => {
                    if (unmounted) return;
                    setCitiesApiError(true);
                })
                .finally(() => {
                    if (unmounted) return;
                    setCitiesApiLoading(false);
                });
        }, 500);

        return () => {
            clearTimeout(delaySearch);
            unmounted = true;
        };
    }, [selectedCity, delay, limit]);

    return { citiesApiError, citiesApiLoading, citiesApiData, setSelectedCity, selectedCity };
};
