import React, { useState } from 'react';

// Components
import TitleBgCard from '../../Cards/TitleBgCard/TitleBgCard';
import TextContainer from '../../Text/TextContainer/TextContainer';

// Style & Img/Icon
import styles from './platformjourneygrade.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import iconNote from '../../../img/icone note.svg';

// ICON
const star = 'fa-solid fa-star';

const PlatformJourneyGrade = ({ grade, platformJourney, userId, userData }) => {
    const [currentGrade, setCurrentGrade] = useState(grade);
    const [hover, setHover] = useState(null);

    const handleClick = async (userGrade) => {
        const res = await fetch('/api/userjourney/grade', {
            method: 'POST',
            body: JSON.stringify({
                grade: userGrade,
                platformJourneyId: platformJourney._id,
                userId,
            }),
            headers: {
                'content-type': 'application/json',
            },
        });

        if (res.status !== 200) console.error(res);

        const data = await res.json();

        if (data.gradeGiven) setCurrentGrade(data.gradeGiven);

        setCurrentGrade(userGrade);
    };

    if (userData) {
        return (
            <TitleBgCard title={'Votre note'} logo={iconNote}>
                <div className={styles.container}>
                    <div className={styles.note}>
                        <div className={styles.imgNote}>
                            <img src={appImageAssets.illustration.grade} alt="" />
                        </div>
                        <div className={styles.starsContainer}>
                            <p>
                                Après avoir fait l'expérience {platformJourney.name}, quelle note lui donneriez-vous ?
                            </p>
                            <div className={styles.stars}>
                                {[...Array(5)].map((stars, index) => {
                                    const gradeValue = index + 1;

                                    return (
                                        <label htmlFor="rating" key={index}>
                                            <input
                                                className={styles.radio}
                                                type="radio"
                                                name="rating"
                                                value={gradeValue}
                                            />
                                            <i
                                                className={`${styles.star} ${star}`}
                                                onClick={() => handleClick(gradeValue)}
                                                onMouseEnter={() => setHover(gradeValue)}
                                                onMouseOut={() => setHover(null)}
                                                style={{
                                                    color:
                                                        gradeValue <= (hover || currentGrade) ? '#21bbee' : '#18243f',
                                                }}
                                            ></i>
                                        </label>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </TitleBgCard>
        );
    } else {
        return (
            <TitleBgCard title={'Note globale'} logo={iconNote}>
                <div className={styles.container}>
                    <div className={styles.note}>
                        <div className={styles.imgNote}>
                            <img src={appImageAssets.illustration.grade} alt="" />
                        </div>
                        {grade !== 0 ? (
                            <div className={styles.starsContainer}>
                                <p>Ce que les autres en pensent</p>
                                <div className={styles.stars}>
                                    {[...Array(5)].map((stars, index) => {
                                        const gradeValue = index + 1;

                                        return (
                                            <label htmlFor="rating" key={index}>
                                                <input
                                                    className={styles.radio}
                                                    type="radio"
                                                    name="rating"
                                                    value={currentGrade}
                                                />
                                                <i
                                                    className={`${styles.star} ${star}`}
                                                    style={{
                                                        color: gradeValue <= currentGrade ? '#21bbee' : '#18243f',
                                                    }}
                                                ></i>
                                            </label>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : (
                            <TextContainer>Aucune note n'a été donnée</TextContainer>
                        )}
                    </div>
                </div>
            </TitleBgCard>
        );
    }
};

export default PlatformJourneyGrade;
