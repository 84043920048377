import { appImageAssets } from './appImageAssets';

export const ADVICE_WISHES = [
    {
        image: appImageAssets.icon.adviceOneParsoursup,
        description: 'Mettre <span>20 voeux en alternance et sans alternance</span>',
    },
    {
        image: appImageAssets.icon.adviceTwoParsoursup,
        description:
            "<span>Regarder</span> si les <span>formations</span> visées sont dispensés dans d'<span>autres établissements et villes.</span>",
    },
    {
        image: appImageAssets.icon.adviceThreeParsoursup,
        description:
            "Choisir au minimum la <span>moitié de vos voeux</span> pour lesquels vous avez une <span>forte probabilité d'être pris (supérieur à 75%)</span>",
    },
    {
        image: appImageAssets.icon.adviceFourParsoursup,
        description:
            "Mettre en place un stratégie de classement de vos voeux. <span>En fonction de phases d'adimission</span> certaines formations sont plus ou moins sélective avec un <span>taux de probabilité qui varie.</span>",
    },
];
