/**
 * A client used to encapsulate http calls to the VisionsGalaxy
 * server to centralize all error handling and make it available
 * through a react context provider
 */
export class APIClient {
    /**
     * Creates an instance of the client to communicate with VisionsGalaxy server
     * @param {() => any} handleServerErrors Callback to handle errors
     */
    constructor(handleServerErrors, userId) {
        this.handleServerErrors = handleServerErrors;
        this.userId = userId;
    }

    /**
     * Parses the path to fit the correct format
     * @param {string} path API endpoint
     * @returns parsed path
     */
    parsePath = (path) => {
        if (path.startsWith('/api')) return path.substring(4);
        return path;
    };

    /**
     * Makes a request to the API
     * @param {'GET' | 'POST' | 'PUT' | 'DELETE' } method request method
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns response data
     */
    async request(method, path, payload = {}) {
        const res = await fetch(`/api${this.parsePath(path)}`, {
            method: method,
            body: method === 'GET' ? undefined : JSON.stringify(payload),
            headers: {
                'content-type': 'application/json',
                'x-user-id': this.userId ? this.userId : undefined,
            },
        });

        if (res.status === 440 || res.status === 401) throw new SessionError('Session expired');
        if (res.status !== 200 && res.status !== 201 && res.status !== 304)
            throw new ClientError(`Failed to ${method} to server`, res);

        const data = await res.json();

        return data;
    }

    /**
     * GET request to the server
     * @param {string} path path after api proxy
     * @returns {any} response data
     */
    async GET(path) {
        try {
            return await this.request('GET', path);
        } catch (err) {
            return this.handleServerErrors(err);
        }
    }

    /**
     * POST request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    async POST(path, payload = {}) {
        try {
            return await this.request('POST', path, payload);
        } catch (err) {
            return this.handleServerErrors(err);
        }
    }

    /**
     * PUT request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    async PUT(path, payload = {}) {
        try {
            return await this.request('PUT', path, payload);
        } catch (err) {
            return this.handleServerErrors(err);
        }
    }

    /**
     * DELETE request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    async DELETE(path, payload = {}) {
        try {
            return await this.request('DELETE', path, payload);
        } catch (err) {
            return this.handleServerErrors(err);
        }
    }

    /**
     *
     * @param {any} payload The fields to update on the user
     * @returns The updated user
     */
    async updateUser(payload = {}) {
        try {
            if (!this.userId) return;
            return await this.request('PUT', '/users/' + this.userId, payload);
        } catch (err) {
            return this.handleServerErrors(err);
        }
    }
}

class SessionError extends Error {
    constructor(message = '') {
        super(message);
        this.isSessionError = true;
    }
}

class ClientError extends Error {
    /**
     * @param {string} message Optional error message
     * @param {Response} err The request response object
     */
    constructor(message = '', res = null) {
        super(message);
        this.isClientError = true;
        this.res = res;
    }
}
