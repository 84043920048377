import { useCallback, useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches a user identifier from a specific service
 * @param {object} options
 * @param {boolean} options.serviceId The Service ID to get the identifier for
 * @returns An array of 3 elements [error, loading, identifier]
 */
export const useUserIdentifier = ({ serviceId = '' }) => {
    const [identifier, setIdentifier] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const getIdentifier = async () => {
            try {
                const response = await client.GET(`/identifiers/service/${serviceId}`);
                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get identifier');
                }

                setIdentifier(response);
            } catch (err) {
                setError(true);
                setIdentifier(null);
            } finally {
                setLoading(false);
            }
        };

        getIdentifier();

        return () => {
            isMounted = false;
        };
    }, [client, serviceId]);

    return [error, loading, identifier];
};

/**
 * Fetches all user identifiers
 */
export const useUserIdentifiers = () => {
    const { client } = useClient();

    const [identifiers, setIdentifiers] = useState([]);
    const [identifiersLoading, setLoading] = useState(false);
    const [identifiersError, setError] = useState(false);

    const findIdentifierForService = useCallback(
        (serviceId) => {
            return identifiers.find((id) => id?.service === serviceId);
        },
        [identifiers],
    );

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const getIdentifier = async () => {
            try {
                const response = await client.GET(`/identifiers/me`);
                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get identifier');
                }

                setIdentifiers(response);
            } catch (err) {
                setError(true);
                setIdentifiers([]);
            } finally {
                setLoading(false);
            }
        };

        getIdentifier();

        return () => {
            isMounted = false;
        };
    }, [client]);

    return { identifiers, findIdentifierForService, identifiersLoading, identifiersError };
};
