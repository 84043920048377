import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonServiceCard = ({ topService = false }) => {
    return (
        <>
            {[...Array(topService ? 3 : 2)].map((skeleton, index) => (
                <Skeleton
                    key={index}
                    parent
                    rounded={20}
                    parentSx={{ width: topService ? '100%' : '50%', margin: 10 }}
                    LightBgParent={topService}
                >
                    <div style={{ padding: '10px 15px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                            <Skeleton height={90} width={90} sx={{ marginRight: 5 }} rounded={10}></Skeleton>
                            <div
                                style={{
                                    width: 'calc(100% - 100px)',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-around',
                                    height: 100,
                                }}
                            >
                                <Skeleton variant={'text'} width={'90%'} sx={{ margin: '5px 0' }}></Skeleton>
                                <Skeleton variant={'text'} width={70} sx={{ margin: '5px 0' }}></Skeleton>
                            </div>
                        </div>
                        {!topService && <Skeleton height={20} sx={{ margin: '5px 0' }}></Skeleton>}
                        <Skeleton height={42} sx={{ margin: '5px 0' }}></Skeleton>
                        <Skeleton width={140} height={35} rounded={30} sx={{ margin: '5px auto' }}></Skeleton>
                    </div>
                </Skeleton>
            ))}
        </>
    );
};

export default SkeletonServiceCard;
