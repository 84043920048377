import React, { useRef } from 'react';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import styles from './genericModal.module.scss';

const GenericModal = ({
    children,
    open,
    closeModal,
    image,
    bgImageWhite = false,
    hideCloseCross = false,
    maxWidthModal = 500,
}) => {
    const refContentModal = useRef(null);

    if (!open) return <></>;

    return (
        <div className={styles.backdrop} onClick={() => closeModal()}>
            <div
                className={styles.modal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                style={{ maxWidth: maxWidthModal }}
                ref={refContentModal}
            >
                <div className={styles.modalHeader}>
                    <div className={styles.modalImageContainer} style={{ background: bgImageWhite && 'white' }}>
                        <img
                            src={image ? image : appImageAssets.icon.VG}
                            alt="Icone Modal"
                            className={styles.modalImage}
                        />
                    </div>
                    <div
                        className={styles.closeModal}
                        style={{ display: hideCloseCross && 'none' }}
                        onClick={() => closeModal()}
                    >
                        <span>x</span>
                    </div>
                </div>
                <div className={styles.modalContent}>{children}</div>
            </div>
        </div>
    );
};

export default GenericModal;
