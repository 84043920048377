import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appLinks } from '../../data/enums/appLinks';

const SSOErrorPage = (message = 'Error') => {
    const navigate = useNavigate();
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <h2 style={{ padding: '25px' }}>Erreur</h2>
            <div>Une erreur est survenue pendant l'authentification à votre compte</div>
            <button onClick={() => navigate(appLinks.public.landing)}>Retourner à l'accueil</button>
        </div>
    );
};

export default SSOErrorPage;
