import React from 'react';
import styles from './infocardhelp.module.scss';
import RoundButtonIcon from '../../../Buttons/RoundButtonIcon/RoundButtonIcon';

// IMG
import illustrationHelp from '../../../../img/illustration besoins aide.svg';

const InfoCardHelp = () => {
    return (
        <div className={`${styles.card} ${styles.blue} ${styles.help}`}>
            <div className={styles.content}>
                <div className={styles.textContent}>
                    <p>
                        Vous avez <br></br>
                        <span>Besoin d'aide ?</span>
                    </p>
                </div>
                <a href="mailto:contact@visions.fr" className={styles.btn}>
                    <RoundButtonIcon textContent={'Ecrivez-nous'} hasArrow={false} />
                </a>
            </div>
            <div className={styles.img}>
                <img src={illustrationHelp} alt="illustration point d'interrogation" />
            </div>
        </div>
    );
};

export default InfoCardHelp;
