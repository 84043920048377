import React, { useEffect, useState } from 'react';
import styles from './detailsrewardcontentcard.module.scss';
import { appLinks } from '../../../data/enums/appLinks';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { config } from '../../../config';
import { StarterMission } from '../../../libs/gamification/missions';
import GenericModal from '../../Modal/GenericModal/GenericModal';
import RequestPurchaseReward from '../../Modal/ContentModal/RequestPurchaseReward/RequestPurchaseReward';
import { appInfoRewardByType } from '../../../data/enums/appInfoRewards';
import { useAuth } from '../../../hooks/useAuth';
import Loader from '../../Loaders/Loader/Loader';
import { useClient } from '../../../hooks/useClient';
import { Button } from '../../Buttons/Button/Button';
import { Icon } from '../../Icons/Icon/Icon';

const DetailsRewardContentCard = ({ reward, visionsStars, userId, authCheck }) => {
    const { user } = useAuth();
    const { client } = useClient();

    // State for open/close modal
    const [openConfirmation, setOpenConfirmation] = useState(false);

    // State for confirmation modal (after buying reward)
    const [confirmationPromoCode, setConfirmationPromoCode] = useState('');
    const [validationPurchase, setValidationPurchase] = useState(false);
    const [removeBtn, setRemoveBtn] = useState(false);

    const [isAuthorized, setIsAuthorized] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const openConfirmationModal = (reward) => {
        setConfirmationPromoCode(reward.promoCode);
        setValidationPurchase(true);
    };

    const linkConfirmation = () => {
        if (authCheck === true && userId !== null && visionsStars >= reward.price) {
            setOpenConfirmation((currentOpen) => !currentOpen);
        }
    };

    const closeCrossConfirmation = () => {
        if (validationPurchase) {
            setRemoveBtn(true);
            setValidationPurchase(false);
            window.location.href = appLinks.rewards.index;
        }

        setOpenConfirmation((currentOpen) => !currentOpen);
    };

    const makePurchase = async () => {
        if (!authCheck && userId) return;
        if (!user) return;

        // Complete starterMission
        const [, data] = await Promise.all([
            StarterMission.validateCurrent(user),
            await client.POST(`/rewards/${reward._id}`, {
                transactionType: appInfoRewardByType(reward.type).transactionType,
            }),
        ]);

        if (!data.reward) return alert('Une erreur est survenue');

        openConfirmationModal(data.reward);
    };

    const dateValide = (date) => {
        if (date) {
            return new Date(date).toLocaleDateString('fr-FR', options);
        } else {
            return 'Disponible indéfiniment';
        }
    };

    useEffect(() => {
        if (reward?.partner?.name === 'Le Coq Gourmet') {
            setIsAuthorized(false);
            setIsModalOpen(true);
        }
    }, [reward]);

    if (!reward) return <Loader text="Récupération des informations de la récompense" />;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img
                    src={reward.image ? `${config.ImageRewardBaseUrl}/${reward.image}` : appImageAssets.logo.gift}
                    alt={`Illustration ${reward.title || 'cadeau'}`}
                    style={{ objectFit: !reward.image && 'initial' }}
                />
                <div className={styles.borderGradient}></div>
            </div>
            <div className={styles.content}>
                {reward.logo && <img src={reward.logo} alt="" />}
                <div className={styles.information}>
                    <p className={styles.kind}>{reward.title ? reward.title : ''}</p>
                    <p className={styles.partner}>
                        Offert par notre partenaire <span>{reward.partner?.name}</span>
                    </p>
                    {reward.type === 1 && reward.partner?.website && (
                        <p style={{ textAlign: 'center' }}>Vous pourrez utiliser le code sur le site partenaire</p>
                    )}
                    <div className={styles.tags}>
                        <div className={styles.widget}>
                            <div className={styles.icon}>
                                <img src={appImageAssets.icon.validity} alt="Icone Compte à Rebours" />
                            </div>
                            <div className={styles.tagsInformation}>
                                <p>Valable jusqu'au</p>
                                <p>{dateValide(reward.endAt)}</p>
                            </div>
                        </div>
                        <div className={styles.widget}>
                            <div className={styles.icon}>
                                <img
                                    src={appImageAssets.icon.visionsStarsGradient}
                                    alt="Icone Visions Stars Gradient"
                                />
                            </div>
                            <div className={styles.tagsInformation}>
                                <p>Echangeable contre</p>
                                <p>{reward.price} VisionsStars</p>
                            </div>
                        </div>
                        <div className={styles.mainTag}>
                            <img
                                src={appInfoRewardByType(reward.type).icon}
                                alt={`Icone ${appInfoRewardByType(reward.type).mainTag}`}
                            />
                            <p>{appInfoRewardByType(reward.type).mainTag}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.description}>
                    <p dangerouslySetInnerHTML={{ __html: reward.description }}></p>
                </div>
                <div className={styles.btn}>
                    {!removeBtn && (
                        <Button
                            disabled={visionsStars < reward.price || isAuthorized === false}
                            endIcon={<Icon icon="arrow" />}
                            onclick={linkConfirmation}
                            size="main"
                            sx={{ color: 'white' }}
                        >
                            Obtenir
                        </Button>
                    )}
                    {removeBtn && <p className={styles.thanks}>Merci pour votre achat !</p>}
                </div>
            </div>
            <GenericModal
                open={openConfirmation}
                image={
                    validationPurchase
                        ? appImageAssets.icon.check
                        : reward?.image
                        ? `${config.ImageRewardBaseUrl}/${reward.image}`
                        : null
                }
                closeModal={closeCrossConfirmation}
            >
                <RequestPurchaseReward
                    reward={reward}
                    closeModal={validationPurchase ? closeCrossConfirmation : makePurchase}
                    validation={validationPurchase}
                    promoCode={confirmationPromoCode}
                />
            </GenericModal>

            <GenericModal open={isModalOpen}>
                <div style={{ padding: '10px' }}>
                    <h4 style={{ fontSize: '18px' }}>
                        Pour acheter cette récompense, il est nécessaire d'avoir + de 18 ans.
                    </h4>
                    <h5 style={{ fontSize: '16px' }}>Êtes-vous majeur ?</h5>
                    <div style={{ display: 'flex', marginTop: '35px', justifyContent: 'space-around' }}>
                        <Button endIcon={<Icon icon="arrow" />} navigate={appLinks.rewards.index} size="main">
                            Non
                        </Button>
                        <Button
                            endIcon={<Icon icon="arrow" />}
                            onclick={() => {
                                setIsAuthorized(true);
                                setIsModalOpen(false);
                            }}
                            size="main"
                        >
                            Oui
                        </Button>
                    </div>
                </div>
            </GenericModal>
        </div>
    );
};

export default DetailsRewardContentCard;
