/**
 * Adds or remove the item in an object state array
 * @param {object} item Any item object
 * @param {object[]} stateArray The array of objects current state
 * @param {Function} setState The setState function
 * @param {string} lookupKey The key to use for lookup
 */
export const toggleItemInObjectStateArray = (item, stateArray, setState, lookupKey = null) => {
    const newArr = [...stateArray];

    if (newArr.length === 0) {
        newArr.push(item);
        setState(newArr);
        return newArr;
    }

    if (typeof newArr[0] !== 'object') throw new Error('Cannot use non-object types');

    lookupKey = lookupKey || Object.keys(newArr[0])[0];

    const existingItem = newArr.findIndex((o) => o[lookupKey] === item[lookupKey]);
    if (existingItem === -1) {
        newArr.push(item);
        setState(newArr);
        return newArr;
    }

    newArr.splice(existingItem, 1);
    setState(newArr);
    return newArr;
};
