import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonGenericCard = ({ numberOfCards = 2}) => {
    return (
        <>
            {[...Array(numberOfCards)].map((skeleton, index) => (
                <Skeleton key={index} parent rounded={20} parentSx={{ width: '45%', margin: '10px' }}>
                    <Skeleton height={150} sx={{ marginBottom: 10 }}></Skeleton>
                    <Skeleton width={'90%'} height={42} sx={{ margin: '5px auto' }}></Skeleton>
                    <Skeleton width={'90%'} height={42} sx={{ margin: '5px auto' }}></Skeleton>
                    <Skeleton width={200} height={42} sx={{ margin: '10px auto' }} rounded={30}></Skeleton>
                </Skeleton>
            ))}
        </>
    );
};

export default SkeletonGenericCard;
