import React, { useState } from 'react';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import styles from './cardtogglecontainer.module.scss';

const CardToggleContainer = ({
    icon,
    title,
    gradient = false,
    bgGradient = false,
    noData = false,
    showToggle = true,
    minHeight = 95,
    children,
}) => {
    const [toggle, setToggle] = useState(true);

    const toggleEvent = () => {
        setToggle(!toggle);
    };

    const borderGradient = gradient === false ? '' : styles.borderGradient;
    const BgGradient = bgGradient ? styles.bgGradient : '';
    const displayNoData = noData ? styles.noData : '';

    return (
        <div className={styles.cardToggleContainer}>
            <div
                className={`${styles.contentCardToggle} ${borderGradient} ${
                    toggle ? styles.cardHeightSmall : styles.H100
                } ${BgGradient}`}
                style={{ maxHeight: minHeight }}
            >
                <div className={styles.headerCardToggle}>
                    {icon && (
                        <div className={styles.icon}>
                            <img src={icon} alt="" />
                        </div>
                    )}
                    <h2>{title}</h2>
                </div>
                <div className={`${styles.content} ${displayNoData}`}>{children}</div>
                {showToggle && (
                    <button
                        className={`${styles.arrowToggle} ${toggle ? styles.arrowToggleClose : ''}`}
                        type="button"
                        onClick={toggleEvent}
                    >
                        <i className={fontAwesome.angleTop}></i>
                    </button>
                )}
            </div>
        </div>
    );
};

export default CardToggleContainer;
