import React, { useEffect, useState } from 'react';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import GenericBtn from '../../../Components/Buttons/GenericBtn/GenericBtn';

import styles from './EventsPage.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { appImageAssets } from '../../../data/enums/appImageAssets';

import { categoryEvents } from '../../../data/tmp/dataEvents';
import Loader from '../../../Components/Loaders/Loader/Loader';
import { config } from '../../../config';
import { filterTopEvents, getEvents, mapEventsToCategories } from '../../../libs/visionsGalaxyEvents/api';
import { openWindow } from '../../../utils/openWindow';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import CarouselSlick from '../../../Components/Carousel/LibsCarousel/CarouselSlick/CarouselSlick';

//TMP
const imageTopEvents = {
    1: appImageAssets.partner.openClassrooms,
    2: appImageAssets.partner.studi,
    3: appImageAssets.partner.aef,
};

const RESPONSIVE_CAROUSEL_SETTINGS = [
    {
        breakpoint: 700,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
        },
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            infinite: true,
        },
    },
];

const EventsPage = () => {
    const [events, setEvents] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [topEvents, setTopEvents] = useState([]);
    const [categorySelected, setCategorySelected] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const [eventsFiltered, setEventsFiltered] = useState([]);

    useEffect(() => {
        const fetchEvents = async () => {
            const res = await getEvents();
            if (!res) return;
            setAllEvents(res);
            const mapping = mapEventsToCategories(res);
            console.log(mapping)
            if (mapping[1].length !== 0) setCategorySelected(1);
            setEvents(mapping);
            const top = filterTopEvents(res);
            setTopEvents(top);
        };
        fetchEvents();
    }, []);

    const expired = {
        category: [],
        top: [],
    };

    useEffect(() => {
        if (!events) return;
        if (events.length === 0) return;
        if (!categorySelected) return;

        const filterEventsCategoriesAvailable = events[categorySelected].filter(
            (event) => new Date(event.startDate) >= new Date(),
        );

        setEventsFiltered(filterEventsCategoriesAvailable);
    }, [events, categorySelected]);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={'/user/catalog'} />
            <div className={styles.container}>
                <div className={styles.content}>
                    {/* KEEP CODE */}
                    {/* <TitleBgCard title={'évènements près de chez vous'}>
                        <Carousel>
                            {eventsTMP.nearYou.map((event, index) => (
                                <CarouselItem key={index}>
                                    <div className={styles.card}>
                                        <div
                                            className={styles.bgImage}
                                            style={{ backgroundImage: event.image && `url(${event.image})` }}
                                        ></div>
                                        <div className={styles.bgGradient}></div>
                                        <div className={styles.banner}>{event.date}</div>
                                        <div className={styles.info}>
                                            <p className={styles.desc}>{event.description}</p>
                                            <GenericBtn
                                                textContent={'en savoir +'}
                                                onClickFc={() => openWindow(event.link)}
                                            />
                                        </div>
                                    </div>
                                </CarouselItem>
                            ))}
                        </Carousel>
                    </TitleBgCard> */}
                    <CardBgBottom>
                        <div className={styles.category}>
                            {categoryEvents.map((category, index) => (
                                <div
                                    className={`${styles.square} ${
                                        categorySelected === index + 1 ? styles.active : ''
                                    }`}
                                    key={index}
                                    onClick={() => {
                                        if (categorySelected === index + 1) return;
                                        setTimeout(() => {
                                            setCategorySelected(index + 1);
                                        }, 50);
                                        setShowAll(false);
                                    }}
                                >
                                    <img src={category.icon} alt="" />
                                    <p dangerouslySetInnerHTML={{ __html: category.category }}></p>
                                </div>
                            ))}
                        </div>
                        <div className={`${styles.selectedEvents} ${showAll ? styles.showAllEvents : ''}`}>
                            {!events && <Loader />}
                            {events?.length === 0 && (
                                <div style={{ margin: 10 }}>
                                    <TextContainer>Nous n'avons encore aucun évènement à vous proposer</TextContainer>
                                </div>
                            )}
                            {events?.length !== 0 && !showAll && (
                                <>
                                    {categorySelected && events[categorySelected]?.length === 0 && (
                                        <div style={{ margin: 10 }}>
                                            <TextContainer>
                                                Nous n'avons encore aucun évènement à vous proposer dans cette catégorie
                                            </TextContainer>
                                        </div>
                                    )}
                                    {categorySelected && events[categorySelected]?.length !== 0 && (
                                        <CarouselSlick
                                            type={3}
                                            slidesToShow={2}
                                            slidesToScroll={2}
                                            infinite={eventsFiltered.length <= 2 ? false : true}
                                            responsive={RESPONSIVE_CAROUSEL_SETTINGS}
                                        >
                                            {events[categorySelected].map((event, index) => {
                                                if (new Date(event.startDate) < new Date()) {
                                                    expired.category.push(event);
                                                    return null;
                                                }
                                                return (
                                                    <CardBgBottom key={index}>
                                                        <div className={styles.cardContainer}>
                                                            <div className={styles.cardContent}>
                                                                <div className={styles.header}>
                                                                    <img
                                                                        src={
                                                                            event.image
                                                                                ? `${config.ImageEventsBaseUrl}/${event.image}`
                                                                                : appImageAssets.logo.VGonboarding
                                                                        }
                                                                        alt={`Évènement ${event.title}`}
                                                                    />
                                                                    <h3>{event.title}</h3>
                                                                </div>
                                                                <div className={styles.infoEvents}>
                                                                    <div className={styles.tags}>
                                                                        <div className={styles.tag}>
                                                                            {event.categoryName}
                                                                        </div>
                                                                        <div className={styles.date}>
                                                                            {new Date(
                                                                                event.startDate,
                                                                            ).toLocaleDateString('fr-FR', options)}
                                                                        </div>
                                                                    </div>
                                                                    <p
                                                                        className={styles.desc}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: event.description,
                                                                        }}
                                                                    ></p>
                                                                </div>
                                                            </div>
                                                            <GenericBtn
                                                                textContent={'en savoir +'}
                                                                onClickFc={() => openWindow(event.link)}
                                                            />
                                                        </div>
                                                    </CardBgBottom>
                                                );
                                            })}
                                        </CarouselSlick>
                                    )}
                                    {categorySelected &&
                                        events[categorySelected]?.length === expired.category?.length && (
                                            <TextContainer sx={{ margin: '10px auto' }}>
                                                Nous n'avons plus d'évènements à vous proposer dans cette catégorie.
                                            </TextContainer>
                                        )}
                                </>
                            )}
                            {allEvents?.length !== 0 &&
                                showAll &&
                                allEvents.map((event, index) => {
                                    if (new Date(event.startDate) < new Date()) {
                                        return null;
                                    }
                                    return (
                                        <CardBgBottom key={index}>
                                            <div className={styles.cardContainer}>
                                                <div className={styles.cardContent}>
                                                    <div className={styles.header}>
                                                        <img
                                                            src={
                                                                event.image
                                                                    ? `${config.ImageEventsBaseUrl}/${event.image}`
                                                                    : appImageAssets.logo.VGonboarding
                                                            }
                                                            alt={`Évènement ${event.title}`}
                                                            style={{ objectFit: showAll && 'cover' }}
                                                        />
                                                        <h3>{event.title}</h3>
                                                    </div>
                                                    <div className={styles.infoEvents}>
                                                        <div className={styles.tags}>
                                                            <div className={styles.tag}>{event.categoryName}</div>
                                                            <div className={styles.date}>
                                                                {new Date(event.startDate).toLocaleDateString(
                                                                    'fr-FR',
                                                                    options,
                                                                )}
                                                            </div>
                                                        </div>
                                                        <p
                                                            className={styles.desc}
                                                            dangerouslySetInnerHTML={{
                                                                __html: event.description,
                                                            }}
                                                        ></p>
                                                    </div>
                                                </div>
                                                <GenericBtn
                                                    textContent={'en savoir +'}
                                                    onClickFc={() => openWindow(event.link)}
                                                />
                                            </div>
                                        </CardBgBottom>
                                    );
                                })}
                        </div>
                        {allEvents?.length !== 0 && (
                            <div className={styles.btn}>
                                <GenericBtn
                                    textContent={showAll ? 'voir moins' : 'tout voir'}
                                    hasArrow={false}
                                    onClickFc={() => {
                                        if (showAll) {
                                            setShowAll(false);
                                            setCategorySelected(1);
                                        } else {
                                            setCategorySelected(null);
                                            setShowAll(true);
                                        }
                                    }}
                                />
                            </div>
                        )}
                    </CardBgBottom>
                </div>
                <div className={styles.col} style={{ display: expired?.top?.length === topEvents?.length && 'none' }}>
                    <TitleBgCard icon={fontAwesome.star} gradient={true} column={true} title={'Top évènement'}>
                        {/* {events?.length !== 0 && !events.top && (
                            <TextContainer>Nous n'avons pas de top évènement à vous proposer</TextContainer>
                        )} */}
                        {topEvents?.length === 0 && (
                            <TextContainer>Nous n'avons pas de top évènement à vous proposer</TextContainer>
                        )}
                        {topEvents?.length !== 0 &&
                            topEvents.map((event, index) => {
                                if (new Date(event.startDate) < new Date()) {
                                    expired.top.push(event);
                                    return null;
                                }
                                return (
                                    <div className={styles.cardTop} key={index}>
                                        <div className={styles.cardLogo}>
                                            <img src={imageTopEvents[index + 1]} alt="" />
                                        </div>
                                        <div className={styles.cardTopContent}>
                                            <div className={styles.tag}>{event.categoryName}</div>
                                            <div className={styles.date}>
                                                <img src={appImageAssets.icon.calendar} alt="" />{' '}
                                                {new Date(event.startDate).toLocaleDateString('fr-FR', options)}
                                            </div>
                                            <p
                                                className={styles.desc}
                                                dangerouslySetInnerHTML={{ __html: event.description }}
                                            ></p>
                                            <GenericBtn
                                                textContent={'En savoir +'}
                                                onClickFc={() => openWindow(event.link)}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        {topEvents?.length === expired.top.length && (
                            <TextContainer>Nous n'avons pas de top évènement à vous proposer</TextContainer>
                        )}
                    </TitleBgCard>
                </div>
            </div>
        </div>
    );
};

export default EventsPage;
