import React from 'react';
import { Route, defer, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';

import HomePage from './views/Public/HomePage/HomePage';
import PublicBilanOrientation from './views/Public/PublicBilanOrientation/PublicBilanOrientation.jsx';

import SSO from './views/Auth/SSO.jsx';
import SSOErrorPage from './views/Errors/SSOErrorPage.jsx';
import SSOExpiredPage from './views/Errors/SSOExpiredPage.jsx';
import SSOLogout from './views/Auth/SSOLogout.jsx';

// Hooks
import ExperienceContextRedirect from './views/ExperienceContextRedirect.jsx';
import { AuthLayout } from './layouts/AuthLayout.jsx';
import { HomeLayout } from './layouts/HomeLayout.jsx';
import { ProtectedLayout } from './layouts/ProtectedLayout.jsx';
import Onboarding from './views/Onboarding/OnboardingPage/OnboardingPage.jsx';
import ConfigurationPage from './views/Onboarding/ConfigurationPage/ConfigurationPage.jsx';
import ProfilePage from './views/Profile/ProfilePage/ProfilePage.jsx';
import TasksChoicePage from './views/Missions/TasksChoicePage/TasksChoicePage.jsx';
import EventsPage from './views/Events/EventsPage/EventsPage.jsx';
import MeetingPage from './views/Meeting/MeetingPage/MeetingPage.jsx';
import PublicBilanOrientationTransition from './views/Public/PublicBilanOrientationTransition/PublicBilanOrientationTransition.jsx';
import PublicBilanOrientationEnd from './views/Public/PublicBilanOrientationEnd/PublicBilanOrientationEnd.jsx';
import CatalogPage from './views/Catalog/CatalogPage/CatalogPage.jsx';
import MosaicEdtechsPage from './views/Catalog/MosaicEdtechsPage/MosaicEdtechsPage.jsx';
import DetailsEdtechsPage from './views/Catalog/DetailsEdtechsPage/DetailsEdtechsPage.jsx';
import PresentationExperiencesPage from './views/Experiences/PresentationExperiencesPage/PresentationExperiencesPage.jsx';
import ExperienceSelector from './Components/Experiences/ExperienceSelector.jsx';
import RewardsPage from './views/Rewards/RewardsPage/RewardsPage.jsx';
import DetailsRewardPage from './views/Rewards/DetailsRewardsPage/DetailsRewardPage.jsx';
import Notification from './views/Legacy/Notification/Notification.jsx';
import Recommendation from './views/Legacy/Recommandation/Recommendation.jsx';
import ChoicePage from './views/ChoicePage/ChoicePage.jsx';
import ShareBilanPage from './views/Bilan/ShareBilanPage/ShareBilanPage.jsx';
import ContextMissionsTypeRouter from './router/ContextMissionsTypeRouter';
import MatchsPage from './views/Matchs/MatchsPage/MatchsPage';
import GlobalError from './Components/Errors/GlobalError/GlobalError';
import { ErrorBoundary } from './Components/Errors/Boundaries/ErrorBoundary';
import { ContextApplier } from './context/ContextApplier';
import { ContextExperienceRedirectRouter } from './router/ContextExperienceRedirectRouter';
import { TermsOfUsePage } from './views/Public/TermsOfUsePage/TermsOfUsePage.jsx';
import { LegalPage } from './views/Public/LegalPage/LegalPage.jsx';

const getUserData = () => {
    return new Promise((res, rej) => {
        if (!localStorage.getItem('user')) {
            rej('Error');
        }

        res(localStorage.getItem('user'));
    });
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
            errorElement={<GlobalError />}
        >
            <Route element={<HomeLayout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/authentication/sso" element={<SSO />}></Route>
                <Route path="/authentication/error" element={<SSOErrorPage />}></Route>
                <Route path="/authentication/expired" element={<SSOExpiredPage />}></Route>
                <Route path="/public/bilan-orientation" element={<PublicBilanOrientation />}></Route>
                <Route path="/public/experience" element={<ExperienceContextRedirect />}></Route>
                <Route path="/custom-onboard" element={<ContextExperienceRedirectRouter />} />
                <Route path="/terms-of-use" element={<TermsOfUsePage />} />
                <Route path="/legal" element={<LegalPage />} />
            </Route>
            <Route path="/user" element={<ProtectedLayout />}>
                <Route path="/user/" element={<Onboarding />}></Route>
                <Route path="/user/authentication/logout" element={<SSOLogout />}></Route>
                <Route path="/user/config" element={<ConfigurationPage />}></Route>
                <Route path="/user/profile" element={<ProfilePage />}></Route>
                <Route
                    path="/user/missions"
                    element={
                        <ContextApplier>
                            <ContextMissionsTypeRouter />
                        </ContextApplier>
                    }
                ></Route>
                <Route path="/user/missions/:taskId" element={<TasksChoicePage />}></Route>
                <Route path="/user/events" element={<EventsPage />}></Route>
                <Route path="/user/meetings" element={<MeetingPage />}></Route>
                <Route
                    path="/user/experiences/bilan-orientation/transition"
                    element={<PublicBilanOrientationTransition />}
                ></Route>
                <Route path="/user/experiences/bilan-orientation/end" element={<PublicBilanOrientationEnd />}></Route>

                <Route path="/user/catalog" element={<CatalogPage />}></Route>
                <Route path="/user/catalog/category/:name" element={<MosaicEdtechsPage />}></Route>
                <Route path="/user/catalog/service/:id" element={<DetailsEdtechsPage />}></Route>

                <Route path="/user/experiences" element={<PresentationExperiencesPage />}></Route>
                <Route
                    path="/user/experiences/:experienceName"
                    element={
                        <ErrorBoundary>
                            <ExperienceSelector />
                        </ErrorBoundary>
                    }
                ></Route>

                <Route path="/user/rewards" element={<RewardsPage />}></Route>
                <Route path="/user/rewardsDetails/:id" element={<DetailsRewardPage />}></Route>
                <Route path="/user/notifications" element={<Notification />}></Route>
                <Route path="/user/recommendations" element={<Recommendation />}></Route>

                <Route path="/user/matchs" element={<MatchsPage />}></Route>

                {/* // TODO : Rennommer les routes ci dessous en des trucs compréhensibles */}
                <Route path="/user/choice" element={<ChoicePage />}></Route>
                <Route path="/user/share" element={<ShareBilanPage />}></Route>
            </Route>
        </Route>,
    ),
);

const App = () => {
    return <RouterProvider router={router} />;
};

export default App;
