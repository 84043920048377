import React from 'react';
import { useParams } from 'react-router-dom';

import styles from './DetailsEdtechsPage.module.scss';

import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import ContentCardDetail from '../../../Components/ContentCards/ContentCardDetail';
import ExperienceCardContainer from '../../../Components/Cards/ExperienceCardContainer/ExperienceCardContainer';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import UserServiceGrade from '../../../Components/Grade/UserServiceGrade';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';

import { appLinks } from '../../../data/enums/appLinks';
import SkeletonMainCardDetails from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonMainCardDetails/SkeletonMainCardDetails';
import Skeleton from '../../../Components/Skeletons/Skeleton/Skeleton';
import { useAuth } from '../../../hooks/useAuth';
import { usePublicVisionsGalaxyData } from '../../../hooks/usePublicVisionsGalaxyData';

const DetailsEdtechsPage = () => {
    const { id } = useParams();
    const { user } = useAuth();
    const [edtechError, edtechLoading, edtechData] = usePublicVisionsGalaxyData({
        path: '/services',
        query: `?serviceId=${id}`,
    });

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.catalog.index} extras={{ activity: 'service_details_catalog' }} />
            <div className={styles.main}>
                <div className={styles.container}>
                    {edtechLoading && <SkeletonMainCardDetails />}
                    {!edtechLoading && (
                        <CardBgBottom color={'blue'}>
                            {edtechError && <div>Erreur de chargement des informations de l'EdTech</div>}
                            {edtechData && edtechData.service && <ContentCardDetail detail={edtechData.service} />}
                        </CardBgBottom>
                    )}
                </div>
                <div className={styles.col}>
                    {edtechLoading && (
                        <Skeleton parent rounded={20} parentSx={{ width: '90%', padding: 10 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Skeleton width={45} height={45} sx={{ marginRight: 10 }} rounded={10}></Skeleton>
                                <Skeleton variant={'text'} width={120}></Skeleton>
                            </div>
                            <Skeleton height={150} sx={{ margin: '5px 0' }}></Skeleton>
                            <Skeleton height={150}></Skeleton>
                        </Skeleton>
                    )}
                    <div className={styles.noteContainer}>
                        {user && edtechData && edtechData.service && (
                            <UserServiceGrade serviceId={edtechData.service?._id} userId={user._id} />
                        )}
                    </div>
                    <ExperienceCardContainer whichCardsArray={['orientation']} />
                    <div className={styles.linkCard}>
                        <LinkCardContainer whichCardsArray={['cozy']} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DetailsEdtechsPage;
