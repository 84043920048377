import React, { useState } from 'react';

import styles from './missionCard.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import Loader from '../../Loaders/Loader/Loader';
import { MISSION_TASK_PICTOS } from '../../../libs/gamification/pictos';
import HeaderCard from '../../Header/HeaderCard/HeaderCard';
import TextContainer from '../../Text/TextContainer/TextContainer';
import { useAuth } from '../../../hooks/useAuth';
import { Button } from '../../Buttons/Button/Button';

const MissionCard = ({ mission, animationBtn, numberMission, preset = false, children }) => {
    const { user } = useAuth();
    const [toggle, setToggle] = useState(false);

    if (!user || !mission) return <Loader />;

    const percentage = (task) => {
        if (mission?.isCompleted) return;
        const percent = (100 * task) / mission?.totalTasks;
        return percent;
    };
    const displayPercentage = percentage(mission?.completedTasks) + '%';

    const TMP_MissionRenamer = (missionName) => {
        const localConfig = localStorage.getItem('headai');
        if (localConfig && JSON.parse(localConfig)?.namespace === 'sdn') {
            return 'Découvrir vos matchs avec les offres de formation';
        } else {
            return missionName;
        }
    };

    return (
        <div
            className={`${styles.card} ${mission?.isCompleted ? styles.cardFinished : ''}`}
            style={{ margin: preset && 0 }}
        >
            <HeaderCard
                image={!mission?.isCompleted && appImageAssets.icon.mission}
                alt={'Icone de missions'}
                mainTitle={`mission ${numberMission}`}
                subTitle={TMP_MissionRenamer(mission?.name)}
                color={mission?.isCompleted && 'transparent'}
            ></HeaderCard>

            {preset ? (
                <>{children}</>
            ) : (
                <div className={`${styles.content} ${mission?.isCompleted ? '' : styles.carousel}`}>
                    {mission?.completion?.map((task, index) => (
                        <React.Fragment key={task + index}>
                            <div className={styles.task} key={index}>
                                <div className={styles.information}>
                                    <div className={styles.logo}>
                                        <img src={MISSION_TASK_PICTOS[task.task.icon]} alt="" />
                                        <img
                                            className={`${styles.check} ${task.completed ? styles.checked : ''}`}
                                            src={task.completed ? appImageAssets.icon.check : ''}
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        className={`${styles.description} ${
                                            task.completed && !mission?.isCompleted ? styles.taskCompleted : ''
                                        }`}
                                    >
                                        {mission?.isCompleted ? (
                                            <>
                                                <p className={styles.taskName}>
                                                    Tâche {mission?.completion?.length > 1 ? index + 1 : ''} :{' '}
                                                    <span className={styles.descriptionText}>{task.task.name}</span>
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <p className={styles.taskName}>
                                                    TÂCHE {mission?.completion?.length > 1 ? index + 1 : ''}
                                                </p>
                                                <p className={styles.descriptionText}>{task?.task?.name}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                                <div className={styles.reference}>
                                    {!mission?.isCompleted && (
                                        <div className={styles.reward}>
                                            <div
                                                className={styles.visionStars}
                                                style={{ display: task.completed && 'none' }}
                                            >
                                                <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                                                <span>+{task.task.visionsStars || 0}</span>
                                            </div>
                                            {/* <div className={styles.experience}>
                                        <img src="" alt="" />
                                        <span>{task.task.xp || 0}</span>
                                    </div> */}
                                        </div>
                                    )}

                                    <div className={styles.accomplish}>
                                        {task.completed === true ? (
                                            <Button
                                                animation={index === 0 && animationBtn ? 'pulse' : null}
                                                navigate={!preset && `/user/missions/${task.task._id}`}
                                                variant="bordered"
                                            >
                                                rejouer
                                            </Button>
                                        ) : (
                                            <Button
                                                animation={index === 0 && animationBtn ? 'pulse' : null}
                                                navigate={!preset && `/user/missions/${task.task._id}`}
                                                onclick={() => {
                                                    if (!preset) return;
                                                    setToggle((prev) => !prev);
                                                }}
                                            >
                                                accomplir
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {toggle && (
                                <div className={styles.toggleContainer}>
                                    <TextContainer>
                                        Pour réaliser cette tâche nous vous proposons 2 outils qui répondent à vos
                                        besoins. Choississez l'outil qui vous intéresse et gagner
                                    </TextContainer>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            )}

            {!mission?.isCompleted && !preset && (
                <div className={styles.report}>
                    <div className={styles.gaugeContainer}>
                        <div className={styles.gauge}>
                            <div className={styles.meter} style={{ width: displayPercentage }}></div>
                        </div>
                        <p>{mission?.progressRatio}</p>
                    </div>
                    <div className={styles.total}>
                        <div className={styles.visionStars}>
                            <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                            <span>+{100}</span>
                        </div>
                        {/* <div className={styles.experience}>
                            <img src="" alt="" />
                            <span>{totalXp.reduce((prev, current) => prev + current, 0)}</span>
                        </div> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MissionCard;
