import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import Loader from '../Components/Loaders/Loader/Loader';
import useQuery from '../hooks/useQuery';
import { getSSOURL } from '../utils/sso';

export const ContextExperienceRedirectRouter = () => {
    const { params } = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.get('headai')) {
            window.location.href = getSSOURL('signup');
        } else {
            navigate('/');
        }
    }, [params, navigate]);

    return <Loader text="Configuring context..." />;
};
