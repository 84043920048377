import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './DetailsRewardPage.module.scss';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import DetailsRewardContentCard from '../../../Components/ContentCards/DetailsRewardContentCard/DetailsRewardContentCard';
import InformationPartnerReward from '../../../Components/ContentCards/InformationPartnerReward/InformationPartnerReward';
import useVisionsStars from '../../../hooks/useVisionsStars';
import InfoCardUserStars from '../../../Components/Cards/InfoCard/InfoCardUserStars/InfoCardUserStars';
import InfoCardHelp from '../../../Components/Cards/InfoCard/InfoCardHelp/InfoCardHelp';
import { appLinks } from '../../../data/enums/appLinks';
import SkeletonMainCardDetails from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonMainCardDetails/SkeletonMainCardDetails';
import SkeletonInfoUserVisionsStars from '../../../Components/Skeletons/SkeletonsTemplate/SkeletonInfoUserVisionsStars/SkeletonInfoUserVisionsStars';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import Skeleton from '../../../Components/Skeletons/Skeleton/Skeleton';
import { useAuth } from '../../../hooks/useAuth';
import { useVisionsGalaxyData } from '../../../hooks/useVisionsGalaxyData';

const DetailsRewardPage = () => {
    const { id } = useParams();

    const { user } = useAuth();
    const { _id } = user;
    const [rewardError, rewardLoading, rewardData] = useVisionsGalaxyData({path: `/rewards/${id}`})
    const [visionsStarsError, visionsStarsLoading, visionsStars] = useVisionsStars();

    return (
        <>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.rewards.index} extras={{ activity: 'reward_details_visions_stars' }} />
            <div className={styles.main}>
                <div className={styles.container}>
                    {rewardLoading && <SkeletonMainCardDetails rewards />}
                    {!rewardLoading && (
                        <CardBgBottom color={'blue'}>
                            {rewardError && <div>ERROR</div>}
                            {!rewardError && !rewardLoading && rewardData && (
                                <DetailsRewardContentCard
                                    reward={rewardData.reward}
                                    visionsStars={visionsStars && !visionsStarsLoading ? visionsStars : 0}
                                    authCheck={!!_id}
                                    userId={_id}
                                />
                            )}
                        </CardBgBottom>
                    )}
                </div>
                <div className={styles.col}>
                    <div className={styles.recap}>
                        {visionsStarsLoading && <SkeletonInfoUserVisionsStars />}
                        {!visionsStarsError && !visionsStarsLoading && (
                            <InfoCardUserStars totalStars={visionsStars} succinct={true} />
                        )}
                    </div>
                    {rewardLoading && (
                        <Skeleton
                            parent
                            rounded={20}
                            parentSx={{ padding: 10, width: '90%' }}
                            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}
                        >
                            <Skeleton width={100} height={100} rounded={10}></Skeleton>
                            <Skeleton width={200} height={30} sx={{ margin: '5px 0' }}></Skeleton>
                            <Skeleton width={200} height={30} sx={{ margin: '5px 0' }}></Skeleton>
                            <Skeleton width={200} height={60} sx={{ margin: '5px 0' }}></Skeleton>
                            <Skeleton width={200} height={35} sx={{ margin: '15px auto' }} rounded={30}></Skeleton>
                        </Skeleton>
                    )}
                    {!rewardLoading && (
                        <CardBgBottom>
                            {rewardError && <TextContainer>Une erreur est survenue</TextContainer>}
                            {!rewardError && !rewardLoading && rewardData && (
                                <InformationPartnerReward partner={rewardData?.reward?.partner} />
                            )}
                        </CardBgBottom>
                    )}
                    <InfoCardHelp />
                </div>
            </div>
        </>
    );
};

export default DetailsRewardPage;
