import React, { useState } from 'react';
import styles from './meetingCard.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';
import GenericModal from '../../Modal/GenericModal/GenericModal';
import { appLinks } from '../../../data/enums/appLinks';

const MeetingCard = ({ meet, selectedFc, openModal, closeModal }) => {
    const [userBilan] = useState(true);
    const [meetingConfirm, setMeetingConfirm] = useState(false);

    const contentModal = (condition) => {
        switch (condition) {
            case 'bilan':
                return (
                    <div className={styles.contentModal}>
                        <div className={styles.informationModal}>
                            <h3>Finaliser votre demande</h3>
                            <p>
                                Pour bénéficier d'un meilleur accompagnement, souhaitez-vous transmettre votre bilan
                                VisionsGalaxy ?
                            </p>
                        </div>
                        <div className={styles.hook}>
                            <img src={appImageAssets.illustration.hook} alt="crochet" />
                        </div>
                        <div className={styles.btn}>
                            <GenericBtn textContent={'Non'} hasArrow={false} onClickFc={() => closeModal()} />
                            <GenericBtn
                                textContent={'Oui'}
                                hasArrow={false}
                                onClickFc={() => setMeetingConfirm(true)}
                            />
                        </div>
                    </div>
                );
            case 'noBilan':
                return (
                    <div className={styles.contentModal}>
                        <div className={styles.informationModal}>
                            <h3>Bilan d'Orientation</h3>
                            <p>
                                Afin de bénéficier d'un meilleur accompagnement, nous vous invitons à faire notre bilan
                                d'Orientation
                            </p>
                        </div>
                        <div className={styles.hook}>
                            <img src={appImageAssets.illustration.hook} alt="crochet" />
                        </div>
                        <div className={styles.btn}>
                            <GenericBtn textContent={'Non'} hasArrow={false} onClickFc={() => closeModal()} />
                            <GenericBtn
                                textContent={'Oui'}
                                hasArrow={false}
                                link={appLinks.experiences.bilan_orientation}
                            />
                        </div>
                    </div>
                );
            case 'valid':
                return (
                    <div className={styles.contentModal}>
                        <div className={styles.informationModal}>
                            <h3>Merci !</h3>
                            <h4>Votre demande de rendez-vous est envoyé</h4>
                            <p>L'établissement reviendra vers vous pour fixer une date</p>
                        </div>
                        <div className={styles.btn}>
                            <GenericBtn textContent={'Ok'} hasArrow={false} onClickFc={() => closeModal()} />
                        </div>
                    </div>
                );
            default:
                break;
        }
    };

    if (meet.request)
        return (
            <div className={`${styles.card} ${styles.request}`}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h3>{meet.name}</h3>
                        <div className={styles.info}>
                            <i className={fontAwesome.location}></i>
                            <span>{meet.city}</span>
                        </div>
                    </div>
                    <div className={styles.bilan}>
                        <p>Bilan d'Orientation transmis au conseiller :</p>
                        <div className={styles.transmittedContainer}>
                            <span className={meet.transmitted ? '' : styles.transmitted}>Non</span>
                            <span className={meet.transmitted ? styles.transmitted : ''}>Oui</span>
                        </div>
                    </div>
                    <GenericBtn textContent={'Mettre à jour ma demande'} />
                </div>
                <div className={styles.bg}></div>
            </div>
        );

    return (
        <div className={`${styles.card} ${meet.selected ? styles.active : ''}`}>
            <div className={styles.header}>
                <h3>{meet.name}</h3>
                <div className={styles.info}>
                    <i className={fontAwesome.location}></i>
                    <span>{meet.city}</span>
                </div>
                <div className={styles.info}>
                    <i className={fontAwesome.user}></i>
                    <span>{meet.age}</span>
                </div>
            </div>
            <p>{meet.description}</p>
            <div className={styles.select} onClick={() => selectedFc(meet._id)}>
                {meet.selected && <img src={appImageAssets.icon.check} alt="Icone Check" />}
            </div>
            <GenericModal
                open={openModal && meet.selected ? true : false}
                closeModal={() => closeModal()}
                image={
                    meetingConfirm
                        ? appImageAssets.icon.check
                        : userBilan
                        ? appImageAssets.logo.file
                        : appImageAssets.icon.redirect
                }
            >
                {!meetingConfirm && contentModal(userBilan ? 'bilan' : 'noBilan')}
                {meetingConfirm && contentModal('valid')}
            </GenericModal>
        </div>
    );
};

export default MeetingCard;
