import React, { useEffect, useState } from 'react';
import UserGradeModel from './UserGradeModel';
import styles from './userservicegrade.module.scss';
import TitleBgCard from '../Cards/TitleBgCard/TitleBgCard';
import ContentCardStatsNote from '../../Components/ContentCards/ContentCardStatsNote';

// IMG
import illustationNote from '../../img/illustration-note.svg';
import iconNote from '../../img/icone note.svg';
// ICON
const star = 'fa-solid fa-star';

const UserServiceGrade = ({ userId, serviceId }) => {
    // Grade if it exists or after being set for state
    const [currentGrade, setCurrentGrade] = useState(null);
    const [hover, setHover] = useState(null);
    const [average, setAverage] = useState(null);
    const [grades, setGrades] = useState({});

    const handleClick = async (grade) => {
        const res = await fetch('/api/grade', {
            method: 'POST',
            body: JSON.stringify({
                grade,
                serviceId,
                userId,
            }),
            headers: {
                'content-type': 'application/json',
            },
        });

        if (res.status !== 200) console.error(res);

        const data = await res.json();

        if (data.grade) setCurrentGrade(data.grade);
    };

    useEffect(() => {
        // Get data from server
        fetch(`/api/grade/${userId}/${serviceId}`)
            .then((res) => res.json())
            .then((data) => {
                if (data.average) setAverage(data.average);
                if (data.grade) setCurrentGrade(data.grade);
                if (data.grades) setGrades(data.grades);
            });
    }, [userId, serviceId, currentGrade]);

    if (!currentGrade) {
        return (
            <TitleBgCard title={'Votre Note'} logo={iconNote} column={true}>
                <div className={styles.container}>
                    <div className={styles.note}>
                        <div className={styles.imgNote}>
                            <img src={illustationNote} alt="" />
                        </div>
                        <div className={styles.starsContainer}>
                            <p>Après avoir utilisé cette plate-forme quelle note lui donneriez-vous ?</p>
                            <div className={styles.stars}>
                                {[...Array(5)].map((stars, index) => {
                                    const gradeValue = index + 1;

                                    return (
                                        <UserGradeModel
                                            gradeValue={gradeValue}
                                            hover={hover}
                                            currentGrade={currentGrade}
                                            setHover={setHover}
                                            handleClick={handleClick}
                                            key={index}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </TitleBgCard>
        );
    } else {
        return (
            <TitleBgCard icon={star} title={'Aperçu des notes'} column={true}>
                <ContentCardStatsNote
                    handleClick={handleClick}
                    setHover={setHover}
                    hover={hover}
                    currentGrade={currentGrade}
                    average={average}
                    grades={grades}
                />
            </TitleBgCard>
        );
    }
};

export default UserServiceGrade;
