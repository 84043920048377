import React from 'react';
import styles from './CheckboxInput.module.scss';

const CheckboxInput = ({ data, index, onclick, checked }) => {
    if (!data) return;

    return (
        <label htmlFor={`checkbox-${index}`} className={styles.switch}>
            <input
                type="checkbox"
                name={`checkbox-${index}`}
                id={`checkbox-${index}`}
                onClick={(e) => onclick(data, e)}
            />
            <div>
                <span className={checked ? styles.checked : styles.unChecked}></span>
            </div>
        </label>
    );
};

export default CheckboxInput;
