import React from 'react';
import NavBarLandingPage from '../../../Components/NavBar/NavBarLandingPage/NavBarLandingPage';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import styles from './TermsOfUsePage.module.scss';
import { trySetContext } from '../../../libs/experiences/context';
import { useNavigate } from 'react-router';
import useQuery from '../../../hooks/useQuery';
import { getSSOURL } from '../../../utils/sso';
import { Button } from '../../../Components/Buttons/Button/Button';

export const TermsOfUsePage = () => {
    const navigate = useNavigate();
    const { params } = useQuery();
    const context = params.get('context');

    /**
     * Redirects to the SSO Server
     * @param {string} type login || signup
     */
    const doAuth = (type) => {
        window.location.href = getSSOURL(type);
    };

    const doExperienceAuth = (type, storageKey) => {
        localStorage.setItem(storageKey, 'true');
        trySetContext(context);
        window.location.href = getSSOURL(type);
    };

    const doAuthBO = (type) => {
        // doExperienceAuth(type, 'isLoggingFromBO');
        localStorage.setItem('isLoggingFromBO', 'true');
        trySetContext(context);
        navigate('/public/bilan-orientation');
    };

    const doAuthREO = (type) => {
        doExperienceAuth(type, 'isLoggingFromREO');
    };

    return (
        <main>
            <NavBarLandingPage logo={appImageAssets.logo.VGonboarding} link={'/'}>
                <a href="/">VisionsGalaxy</a>
                <span onClick={() => doAuthBO('signup')} className={styles.experiences}>
                    Bilan d'Orientation
                </span>
                <span onClick={() => doAuthREO('signup')} className={styles.experiences}>
                    Bilan de RéOrientation
                </span>
                <a target="_blank" rel="noopener noreferrer" href="mailto:contact@visionspol.eu">
                    Contact
                </a>
                <span className={styles.experiences}>
                    <Button variant="gradient" onclick={() => doAuth('login')} sx={{ fontWeight: 'bold' }}>
                        Me connecter
                    </Button>
                </span>
            </NavBarLandingPage>

            <div className={styles.container}>
                <h1>Conditions générales d’utilisation du site visionsgalaxy.com</h1>

                <section>
                    <h2>Article 1 : Objet</h2>
                    <p>
                        Les présentes « conditions générales d’utilisation » ont pour objet l’encadrement juridique des
                        modalités de mise à disposition des services du site visionsandyou.com et leur utilisation par «
                        l’Utilisateur ».
                    </p>
                    <p>
                        Les conditions générales d’utilisation doivent être acceptées par tout Utilisateur souhaitant
                        accéder au site. Elles constituent le contrat entre le site et l’Utilisateur. L’accès au site
                        par l’Utilisateur signifie son acceptation des présentes conditions générales d’utilisation.
                    </p>
                    <p>
                        En cas de non-acceptation des conditions générales d’utilisation stipulées à l’accès des
                        services proposées par le site.
                    </p>
                    <p>
                        Visionsgalaxy.com se réserve le droit de modifier unilatéralement et à tout moment le contenu
                        des présentes conditions générales d’utilisation.
                    </p>
                </section>

                <section>
                    <h2>Article 2 : Mentions Légales</h2>
                    <p>
                        L’édition du site VisionsGalaxy est assurée par la Société Visions SAS au capital de 2 072,50 €
                        dont le siège social est situé au 366 RUE DE VAUGIRARD 75015 PARIS.
                    </p>
                    <p>Le directeur de publication est Monsieur De Bièvre Matthias.</p>
                </section>

                <section>
                    <h2>Article 3 : Définitions</h2>
                    <p>La présente clause a pour objet de définir les différents termes essentiels du contrat :</p>
                    <ul>
                        <li>
                            Utilisateur : ce terme désigne toute personne qui utilise le site ou l’un des services
                            proposés par le site.
                        </li>
                        <li>Contenu utilisateur : ce sont les données transmises par l’Utilisateur au sein du site.</li>
                        <li>Membre : l’Utilisateur devient membre lorsqu’il est identifié sur le site.</li>
                        <li>
                            Identifiant et mot de passe : c’est l’ensemble des informations nécessaires à
                            l'identification d’un Utilisateur sur le site. Le mot de passe est confidentiel.
                        </li>
                    </ul>
                </section>

                <section>
                    <h2>Article 4 : Accès aux services</h2>
                    <p>Le site permet à l’Utilisateur un accès gratuit aux services suivants :</p>
                    <ul>
                        <li>Les missions</li>
                        <li>Les récompenses</li>
                        <li>Les évènements</li>
                    </ul>
                    <p>
                        Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès Internet.
                        Tous les frais supportés par l’Utilisateur pour accéder au service (matériel informatique,
                        logiciels, connexion Internet, etc.) sont à sa charge.
                    </p>
                    <p>
                        L’Utilisateur non membre n’a pas accès aux services réservés aux membres. Pour cela, il doit
                        s’identifier à l’aide de son identifiant et de son mot de passe.
                    </p>
                    <p>
                        Le site met en œuvre tous les moyens mis à sa disposition pour assurer un accès de qualité à ses
                        services. L’obligation étant de moyens, le site ne s’engage pas à atteindre ce résultat.
                    </p>
                    <p>
                        Tout événement dû à un cas de force majeure ayant pour conséquence un dysfonctionnement du
                        réseau ou du serveur n’engage pas la responsabilité de visionsgalaxy.com
                    </p>
                    <p>
                        L’accès aux services du site peut à tout moment faire l’objet d’une interruption, d’une
                        suspension, d’une modification sans préavis pour une maintenance ou pour tout autre cas.
                        L’Utilisateur s’oblige à ne réclamer aucune indemnisation suite à l’interruption, à la
                        suspension ou à la modification du présent contrat.
                    </p>
                    <p>
                        L’Utilisateur a la possibilité de contacter le site par messagerie électronique à l’adresse :
                        contact@visionspol.eu.
                    </p>
                </section>

                <section>
                    <h2>Article 5 : Les récompenses et cadeaux</h2>
                    <p>
                        En effectuant des missions, l’Utilisateur gagne des points virtuels échangeables contre des
                        codes promo, des bons d’achat, des services et produits gratuits. L’échange des points est
                        limité à un par récompense et par personne. Un Utilisateur ne peut donc pas échanger deux fois
                        ces points pour une même récompense.
                    </p>
                    <p>
                        L’accès à certaines récompenses lié à de l’alcool sont interdit pour les Utilisateurs ayant
                        moins de 18 ans. De ce fait, une carte d’identité peut vous être demandée pour confirmer votre
                        âge. Votre carte d’identité peut également vous être demandée pour confirmer votre identité lors
                        des réclamations des produits offerts.
                    </p>
                    <p>
                        Dans le cas des codes promo, VisionsGalaxy se réserve le droit de vérifier auprès des
                        partenaires participants de leurs utilisations ou non.
                    </p>
                </section>

                <section>
                    <h2>Article 6 : Propriété intellectuelle</h2>

                    <p>
                        Les logos, signes, illustrations et tout autre contenu du site font l’objet d’une protection par
                        le Code de la propriété intellectuelle et plus particulièrement par le droit d’auteur.
                    </p>
                    <p>
                        L’Utilisateur sollicite l’autorisation préalable du site pour toute reproduction, publication,
                        copie des différents contenus.
                    </p>
                    <p>
                        L’Utilisateur s’engage à une utilisation des contenus du site dans un cadre strictement privé.
                        Une utilisation des contenus à des fins commerciales est strictement interdite.
                    </p>
                    <p>
                        Tout contenu mis en ligne par l’Utilisateur est de sa seule responsabilité. L’Utilisateur
                        s’engage à ne pas mettre en ligne de contenus pouvant porter atteinte aux intérêts de tierces
                        personnes. Tout recours en justice engagé par un tiers lésé contre le site. L’Utilisateur ne
                        reçoit aucune justification et notification préalablement à la suppression ou à la modification
                        du contenu Utilisateur.
                    </p>
                </section>

                <section>
                    <h2>Article 7 : Données personnelles</h2>
                    <p>
                        Les informations demandées à l’inscription au site sont nécessaires et obligatoires pour la
                        création du compte de l’Utilisateur. En particulier, l’adresse électronique pourra être utilisée
                        par le site pour l’administration, la gestion et l’animation du service.
                    </p>
                    <p>
                        Le site assure à l’Utilisateur une collecte et un traitement d’informations personnelles dans le
                        respect de la vie privée conformément à la loi n°78-17 du 6 janvier 1978 relative à
                        l’informatique, aux fichiers et aux libertés.
                    </p>
                    <p>
                        En vertu des articles 48 et suivants de la loi n°78-17 relative à l’informatique, aux fichiers
                        et aux libertés en date du 6 janvier 1978, applicable au 1er juin 2019, l’Utilisateur dispose
                        d’un droit d’accès, de rectification, de suppression et d’opposition de ses données
                        personnelles. L’Utilisateur exerce ce droit via :
                    </p>
                    <ul>
                        <li>Son espace personnel ;</li>
                        <li>Un formulaire de contact ;</li>
                        <li>Par mail à contact@visionspol.eu ;</li>
                        <li>Par voie postale au 366 RUE DE VAUGIRARD 75015 PARIS.</li>
                    </ul>
                </section>

                <section>
                    <h2>Article 8 : Responsabilité et force majeure</h2>
                    <p>
                        Les sources des informations diffusées sur le site sont réputées fiables. Toutefois, le site se
                        réserve la faculté d’une non-garantie de fiabilité des sources. Les informations données sur le
                        site le sont à titre purement informatif. Ainsi, l’Utilisateur assume seul l’entière
                        responsabilité de l’utilisation des informations et contenus du présent site.
                    </p>
                    <p>
                        L’Utilisateur s’assure de garder son mot de passe secret. Toute divulgation du mot de passe,
                        quelle que soit sa forme, est interdite.
                    </p>
                    <p>
                        L’Utilisateur assume les risques liés à l’utilisation de son identifiant et mot de passe. Le
                        site décline toute responsabilité.
                    </p>
                    <p>
                        Tout usage du service par l’Utilisateur ayant directement ou indirectement pour conséquence des
                        dommages doit faire l’objet d’une indemnisation au profit du site.
                    </p>
                    <p>
                        Une garantie optimale de la sécurité et de la confidentialité des données transmises n’est pas
                        assurée par le site. Toutefois, le site s’engage à mettre en œuvre tous les moyens nécessaires
                        afin de garantir au mieux la sécurité et la confidentialité des données.
                    </p>
                    <p>
                        La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible
                        et insurmontable d’un tiers.
                    </p>
                </section>

                <section>
                    <h2>Article 9 : Liens hypertextes</h2>
                    <p>
                        De nombreux liens hypertextes sortants sont présents sur le site cependant les pages web où
                        mènent ces liens n’engagent en rien la responsabilité de visionsandyou.com qui n’a pas le
                        contrôle de ces liens.
                    </p>
                    <p>
                        L’Utilisateur s’interdit donc à engager la responsabilité du site concernant le contenu et les
                        ressources relatives à ces liens hypertextes sortants.
                    </p>
                </section>

                <section>
                    <h2>Article 10 : Evolution du contrat</h2>
                    <p>
                        Le site se réserve à tout moment le droit de modifier les clauses stipulées dans le présent
                        contrat.
                    </p>
                </section>

                <section>
                    <h2>Article 11 : Durée</h2>
                    <p>
                        La durée du présent contrat est indéterminée. Le contrat produit ses effets à l’égard de
                        l’Utilisateur à compter de l’utilisation du service.
                    </p>
                </section>

                <section>
                    <h2>Article 12 : Droit applicable et juridiction compétente</h2>
                    <p>
                        La législation française s’applique au présent contrat. En cas d’absence de résolution amiable
                        d’un litige né entre les parties, seuls les tribunaux du ressort de la Cour d’appel de Paris de
                        la ville de Paris sont compétents.
                    </p>
                </section>
            </div>
        </main>
    );
};
