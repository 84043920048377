import React from 'react';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import styles from './notification.module.scss';

const Notification = () => {
    return (
        <div className={styles.container}>
            <HeaderWithNavBar />
            <h1>Notifications</h1>
            <div className={styles.content}>
                <div className={styles.new}>
                    <div className={styles.title}>Les nouveautés</div>
                    <p>Pour l'instant rien de nouveau mais reste attentif !</p>
                </div>
            </div>
        </div>
    );
};

export default Notification;
