import { serverCall } from '../../utils/serverAPICall';
import { getUserInfo } from '../user/userInfo';

/**
 * List of known context codes as an enum
 */
export const KNOWN_CONTEXTS = {
    numerique: 1,
};

export const EXPERIENCE_CONTEXT_PLATFORMS = {
    'bilan-orientation': {
        Orientoi: true,
        Inokufu: false,
        Jobready: false,
    },
};

/**
 * Sets the context in localStorage for later usage
 * @param {string} context The context keyword
 */
export const trySetContext = (context) => {
    if (!context || context === 'null') return;
    localStorage.setItem('context', context);
};

/**
 * Saves the context in the user profile
 * @param {string} userId The user ID
 */
export const saveContext = async (userId) => {
    const context = localStorage.getItem('context');
    if (!context || context === 'null') return;
    await serverCall.PUT('/experiences/context', { userId, context });
};

/**
 * Returns the user's current context code
 * @returns {number | null} The context code from Known_contexts
 */
export const getContext = async (userId) => {
    const context = localStorage.getItem('context');

    if (!context) {
        const user = await getUserInfo(userId);
        const userContext = user?.experiencesInfo?.context;
        if (userContext) {
            localStorage.setItem('context', userContext);
        }

        if (KNOWN_CONTEXTS[userContext]) return KNOWN_CONTEXTS[userContext];
    }

    if (KNOWN_CONTEXTS[context]) return KNOWN_CONTEXTS[context];

    return null;
};
