import React from 'react';
import styles from './ContainerFunctioningLandingPage.module.scss';
import ImageBorder from '../../Image/ImageBorder/ImageBorder';

const ContainerFunctioningLandingPage = ({ icon, sx = {}, children }) => {
    return (
        <div className={styles.container} style={{ ...sx }}>
            <ImageBorder size={'xs'} image={icon} space={12}></ImageBorder>
            <div className={styles.info}>{children}</div>
        </div>
    );
};

export default ContainerFunctioningLandingPage;
