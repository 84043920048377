import React, { useEffect, useState } from 'react';

import NavBarLandingPage from '../../../Components/NavBar/NavBarLandingPage/NavBarLandingPage';
import GenericBtn from '../../../Components/Buttons/GenericBtn/GenericBtn';

import styles from './PublicBilanOrientationEnd.module.scss';

// Image & icon import
import check from '../../../img/Checkbuy.svg';
import logoVisions from '../../../img/LOGO ONBOARDING.svg';
import congratIllu from '../../../img/publicExperiences/felicitations.svg';

import { appLinks } from '../../../data/enums/appLinks';
import { logoPartner } from '../../../data/enums/logoPartner';
import Loader from '../../../Components/Loaders/Loader/Loader';
import { generateCozyLink } from '../../../utils/cozy';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';

const COZY_KONNECTORS = [
    '60796546a2be8fc2e9b02027',
    // '5fb272090ee6207b28216bab',
    // '60796606a2be8fc2e9b0202f',
];

const PublicBilanOrientationEnd = () => {
    const { user } = useAuth();
    const { _id: id, email } = user;
    const { client } = useClient();
    const [completeExperience, setCompleteExperience] = useState(false);

    useEffect(() => {
        if (!id) return;
        let isMounted = true;
        const completeXp = async () => {

            await client.POST('/bo/complete', {userId: id});

            if (!isMounted) return;
            setCompleteExperience(true);
        };

        completeXp();

        return () => {
            isMounted = false;
        };
    }, [id, client]);

    return (
        <>
            <div className={styles.header}>
                <NavBarLandingPage logo={logoVisions} link={appLinks.public.landing}></NavBarLandingPage>
                <div className={styles.border}></div>
            </div>
            <main>
                <section className={styles.roadmapContainer}>
                    <div className={styles.roadmap}>
                        <div className={styles.rowDashed}></div>
                        <div className={styles.stepRoadmap}>
                            <div className={styles.step}>
                                <img src={logoPartner.jobready.icon} alt="icone Jobready" />
                                <p>Jobready</p>
                            </div>
                            <div className={styles.step}>
                                <img src={logoPartner.orientoi.icon} alt="icone Orientoi" />
                                <p>Orientoi</p>
                            </div>
                            <div className={styles.step}>
                                <img src={logoPartner.inokufu.icon} alt="icone Inokufu" />
                                <p>Inokufu</p>
                            </div>
                            <div className={`${styles.step} ${styles.stepActive}`}>
                                <img src={logoPartner.cozy.icon} alt="icone Cozy.io" />
                                <p>Cozy.io</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className={styles.endContent}>
                    <img src={congratIllu} alt="illustration félicitations" />
                    <h3>Vous avez terminé votre bilan d’orientation et nous vous en remercions !</h3>
                    <p>
                        Vous allez retrouver toutes vos informations du bilan dans un cloud personnel avec Cozy, qui
                        vous permet d’accéder à tous vos documents en les synchronisant à vos différents écrans tout le
                        temps & partout à la prochaine et dernière étape.
                    </p>
                    <p>Les données suivantes pourront être partagées à votre Cozy Cloud :</p>
                    <div className={styles.recapSharing}>
                        <div className={styles.check}>
                            <img src={check} alt="" />
                            <p>Vos badges Jobready</p>
                        </div>
                        <div className={styles.check}>
                            <img src={check} alt="" />
                            <p>Vos talents Orientoi</p>
                        </div>
                        <div className={styles.check}>
                            <img src={check} alt="" />
                            <p>Vos recommandations Orientoi</p>
                        </div>
                        <div className={styles.check}>
                            <img src={check} alt="" />
                            <p>Vos contenus Inokufu</p>
                        </div>
                    </div>
                </section>

                <div className={styles.btn}>
                    {completeExperience === false ? (
                        <Loader text="Veuillez patienter, nous enregistrons la fin de votre expérience Bilan d'Orientation..." />
                    ) : (
                        <GenericBtn
                            textContent={'Je découvre mon cloud personnel Cozy'}
                            hasArrow={false}
                            onClickFc={() => (window.location.href = generateCozyLink(id, email, COZY_KONNECTORS))}
                        />
                    )}
                </div>
            </main>
        </>
    );
};

export default PublicBilanOrientationEnd;
