import { Suspense } from 'react';
import { Await, useLoaderData, useOutlet } from 'react-router-dom';
import Loader from '../Components/Loaders/Loader/Loader';
import { AuthProvider } from '../context/AuthProvider';
import ScrollTop from '../Components/ScrollTop';
import { HomeLayout } from './HomeLayout';

export const AuthLayout = () => {
    const outlet = useOutlet();

    const { userPromise } = useLoaderData();

    return (
        <Suspense fallback={<Loader text="Chargement..." />}>
            <Await
                resolve={userPromise}
                errorElement={<ErrTest></ErrTest>}
                children={(user) => (
                    <ScrollTop>
                        <AuthProvider userData={user}>{outlet}</AuthProvider>
                    </ScrollTop>
                )}
            />
        </Suspense>
    );
};

const ErrTest = () => {
    localStorage.setItem('user', null);
    return <HomeLayout></HomeLayout>;
};
