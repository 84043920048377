import React from 'react';

// Styles & Img/Icon
import styles from './experienceserrors.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';

const ExperiencesErrors = () => {
    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <h2>Oups ! une erreur est survenue</h2>
                <img src={appImageAssets.illustration.error} alt="" />
            </div>
            <p>Une erreur est survenue lors du chargement de votre expérience, veuillez ressayer ultérieurement.</p>
        </div>
    );
};

export default ExperiencesErrors;
