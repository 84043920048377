import { useState } from "react";

/**
 * Stores / gets a state to / from localStorage
 * @param {string} keyName Key name in LocalStorage
 * @param {string} defaultValue Default starting value
 * @returns 
 */
export const useLocalStorage = (keyName, defaultValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		try {
			const value = window.localStorage.getItem(keyName);
			if (value) {
				return JSON.parse(value);
			} else {
				window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
				return defaultValue;
			}
		} catch (err) {
			return defaultValue;
		}
	});

	const setValue = (newValue) => {
		try {
			window.localStorage.setItem(keyName, JSON.stringify(newValue));
		} catch (err) {}
		setStoredValue(newValue);
	};

	return [storedValue, setValue];
};
