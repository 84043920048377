import React, { useState, useEffect } from 'react';
import { AVATAR_DATA } from '../../../libs/gamification/avatars';

import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import Loader from '../../../Components/Loaders/Loader/Loader';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';

import styles from './ProfilePage.module.scss';
import { useApiCity } from '../../../hooks/useApiCity';
import { useAuth } from '../../../hooks/useAuth';
import { useClient } from '../../../hooks/useClient';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../../Components/Buttons/Button/Button';

const ProfilePage = () => {
    const { user } = useAuth();
    const { updateUser } = useClient();
    const [formState, setFormState] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        experiencesInfo: {
            city: '',
            situation: '',
            scholarshipLevel: '',
        },
        profile: {
            age_group: '',
        },
    });
    const [avatar, setAvatar] = useState(0);
    const [btnActive, setBtnActive] = useState(false);
    const [recommendationName, setRecommentationName] = useState('');
    const [recommendationStore, setRecommendationStore] = useState(
        JSON.parse(localStorage.getItem('recommendations')) === null
            ? []
            : JSON.parse(localStorage.getItem('recommendations')),
    );
    const [openScrollingMenu, setOpenScrollingMenu] = useState(false);
    const [changedInputValue, setChangeInputValue] = useState(false);
    const [validationUpdateUserProfile, setValidationUpdateUserProfile] = useState(false);

    const [contextSavedMsg, setContextSavedMsg] = useState('');

    const { citiesApiError, citiesApiLoading, citiesApiData, setSelectedCity } = useApiCity();

    useEffect(() => {
        if (localStorage.getItem('recommendations')) return;
        else {
            localStorage.setItem('recommendations', JSON.stringify([]));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('recommendations', JSON.stringify(recommendationStore));
    }, [recommendationStore]);

    const saveRecommandationName = (event) => {
        if (event.target.value !== '') {
            setRecommentationName(event.target.value);
            setBtnActive(true);
        } else {
            setBtnActive(false);
        }
    };

    const saveRecommandationInLocalStorage = () => {
        if (recommendationStore.length === 0) {
            setRecommendationStore((current) => [recommendationName]);
        } else {
            const check = recommendationStore.some((el) => el.toLowerCase() === recommendationName.toLowerCase());
            if (check) {
                alert('Votre recommandation à déjà été ajoutée');
                return;
            }
            setRecommendationStore((oldRecommandations) => [...oldRecommandations, recommendationName]);
        }
    };

    const changeContext = (e) => {
        const value = e.target.value;
        if (value === 'numerique') {
            localStorage.setItem('context', value);
        } else {
            localStorage.removeItem('context');
        }

        setContextSavedMsg('Contexte modifié !');
    };

    const saveProfile = async () => {
        await updateUser({
            ...formState,
            scholarshipLevel: formState.experiencesInfo.scholarshipLevel,
            situation: formState.experiencesInfo.situation,
            city: formState.experiencesInfo.city,
            avatar: avatar,
            age_group: formState.profile.age_group,
        });
        setValidationUpdateUserProfile(true);
        setChangeInputValue(false);
        setTimeout(() => {
            setValidationUpdateUserProfile(false);
        }, 3000);
    };

    useEffect(() => {
        if (!user) return;
        setFormState(() => {
            return {
                firstName: user.firstName,
                lastName: user.lastName,
                phoneNumber: user.phoneNumber,
                email: user.email,
                experiencesInfo: {
                    city: user?.experiencesInfo?.city,
                    situation: user?.experiencesInfo?.situation,
                    scholarshipLevel: user?.experiencesInfo?.scholarshipLevel,
                },
                profile: {
                    age_group: user?.profile?.age_group,
                },
            };
        });
        setSelectedCity(user.experiencesInfo.city);
        setAvatar(user.gamification.avatar);
    }, [user, setSelectedCity]);

    useEffect(() => {
        let mounted = true;
        if (!contextSavedMsg) return;

        setTimeout(() => {
            if (mounted) setContextSavedMsg('');
        }, 5000);

        return () => {
            mounted = false;
        };
    }, [contextSavedMsg]);

    if (!user) {
        return <Loader />;
    }

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <div className={styles.container}>
                <div className={styles.profil}>
                    <TitleBgCard
                        gradient={true}
                        title={'Mes informations personnelles'}
                        logo={AVATAR_DATA[avatar]}
                        sizeLogo={'profil'}
                        column={true}
                        overflow={'visible'}
                    >
                        <form action="" method="post">
                            <div className={styles.space}></div>
                            <div className={styles.field}>
                                <label htmlFor="lastName">Nom</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={formState.lastName}
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                lastName: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="firstName">Prénom</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={formState.firstName}
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                firstName: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    value={formState.email}
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                email: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="city">Ville</label>
                                <input
                                    type="search"
                                    name="city"
                                    id="city"
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setSelectedCity(e.target.value);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                experiencesInfo: {
                                                    ...prev.experiencesInfo,
                                                    city: e.target.value,
                                                },
                                            };
                                        });
                                        setOpenScrollingMenu(true);
                                        setChangeInputValue(true);
                                        if (e.target.value === '') setOpenScrollingMenu(false);
                                    }}
                                    onClick={(e) => {
                                        if (e.target.value !== '') setOpenScrollingMenu(true);
                                    }}
                                    value={formState.experiencesInfo.city}
                                    style={{ zIndex: '4000' }}
                                />
                                <div
                                    className={styles.backdrop}
                                    style={{ display: openScrollingMenu && 'block' }}
                                    onClick={() => setOpenScrollingMenu(false)}
                                ></div>
                                <div className={styles.listCity} style={{ display: !openScrollingMenu && 'none' }}>
                                    {citiesApiError && <TextContainer>Une erreur est survenue</TextContainer>}
                                    {citiesApiLoading && <Loader />}
                                    {citiesApiData?.length !== 0 &&
                                        citiesApiData.map((city, index) => (
                                            <span
                                                key={index}
                                                onClick={() => {
                                                    setFormState((prev) => {
                                                        return {
                                                            ...prev,
                                                            experiencesInfo: {
                                                                ...prev.experiencesInfo,
                                                                city: city.nom,
                                                            },
                                                        };
                                                    });
                                                    setOpenScrollingMenu(false);
                                                }}
                                            >
                                                {city.nom}, {city.codeDepartement}
                                            </span>
                                        ))}
                                </div>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="situation">Situation</label>
                                <select
                                    name="situation"
                                    id="situation"
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                experiencesInfo: {
                                                    ...prev.experiencesInfo,
                                                    situation: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={formState.experiencesInfo.situation}
                                >
                                    <option value="Demandeur d'emploi">Demandeur d'emploi</option>
                                    <option value="Employé">Employé</option>
                                    <option value="Étudiant">Étudiant</option>
                                    <option value="Indépendant">Indépendant</option>
                                </select>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="study">Niveau d'études</label>
                                <select
                                    name="study"
                                    id="study"
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                experiencesInfo: {
                                                    ...prev.experiencesInfo,
                                                    scholarshipLevel: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={formState.experiencesInfo.scholarshipLevel}
                                >
                                    <option value="CAP, BEP (niv 3)">CAP, BEP (niv 3)</option>
                                    <option value="Baccalauréat (niv 4)">Baccalauréat (niv 4)</option>
                                    <option value="BTS, DUT, DEUST, DEUG (niv5)">BTS, DUT, DEUST, DEUG (niv5)</option>
                                    <option value="Licence, licence professionnelle, BUT (niv 6)">
                                        Licence, licence professionnelle, BUT (niv 6)
                                    </option>
                                    <option value="Maîtrise, master 1 (niv 6)">Maîtrise, master 1 (niv 6)</option>
                                    <option value="Master, diplôme d'études approfondies (niv 7)">
                                        Master, diplôme d'études approfondies (niv 7)
                                    </option>
                                    <option value="Doctorat (niv8)">Doctorat (niv8)</option>
                                </select>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="age_group">Age</label>
                                <select
                                    name="age_group"
                                    id="age_group"
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                profile: {
                                                    ...prev.profile,
                                                    age_group: e.target.value,
                                                },
                                            };
                                        });
                                    }}
                                    value={formState.profile?.age_group}
                                >
                                    <option value="null">Sélectionnez votre âge</option>
                                    <option value="16-18">16-18 ans</option>
                                    <option value="18-25">18-25 ans</option>
                                    <option value="25-30">25-30 ans</option>
                                    <option value="30+">30 ans & +</option>
                                </select>
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="phone">Numéro de téléphone</label>
                                <input
                                    type="tel"
                                    name="phone"
                                    id="phone"
                                    value={formState.phoneNumber}
                                    onChange={(e) => {
                                        setChangeInputValue(true);
                                        setFormState((prev) => {
                                            return {
                                                ...prev,
                                                phoneNumber: e.target.value,
                                            };
                                        });
                                    }}
                                />
                            </div>
                            <div className={styles.field}>
                                <label htmlFor="context">Contexte</label>
                                <select
                                    name="context"
                                    id="context"
                                    onChange={(e) => changeContext(e)}
                                    defaultValue={
                                        localStorage.getItem('context') === 'numerique' ? 'numerique' : 'null'
                                    }
                                >
                                    <option value="null">Normal</option>
                                    <option value="numerique">Numérique</option>
                                </select>
                                <small>{contextSavedMsg}</small>
                            </div>
                        </form>
                        <ButtonContainer>
                            <Button onclick={() => saveProfile()} type="submit" disabled={!changedInputValue}>
                                Valider mes modifications
                            </Button>
                        </ButtonContainer>
                        {validationUpdateUserProfile && (
                            <div className={styles.updateProfile}>
                                <TextContainer>Votre profil a bien été mis a jour</TextContainer>
                            </div>
                        )}
                    </TitleBgCard>
                </div>
                <div className={styles.cardSection}>
                    <CardBgBottom>
                        <div className={styles.content}>
                            <div>
                                <h3>Mes recommandations</h3>
                                <p className={styles.description}>
                                    Pour paramétrer les outils Bilan d'Orientation et de RéOrientation nous avons besoin
                                    de connaître qui vous a indiqué de les utiliser.
                                </p>

                                <div className={styles.recommendation}>
                                    <label htmlFor="recommendation">
                                        Quel est le nom du conseiller ou de l'entité qui vous a recommandé un de ces
                                        outils ?
                                    </label>
                                    <input
                                        type="text"
                                        name="recommendation"
                                        id="recommendation"
                                        placeholder="Exemple: Université CY"
                                        onChange={(e) => saveRecommandationName(e)}
                                    />

                                    <Button
                                        disabled={!btnActive}
                                        type="submit"
                                        onclick={() => saveRecommandationInLocalStorage()}
                                        size="small"
                                    >
                                        Valider
                                    </Button>
                                </div>
                            </div>

                            <div className={styles.showRecommendationList}>
                                {recommendationStore?.length !== 0 &&
                                    recommendationStore.map((el, index) => <span key={index}>{el}</span>)}
                            </div>
                        </div>
                    </CardBgBottom>
                </div>
                <div className={styles.cardSection}>
                    <div>
                        <p>Réinitialiser les contextes appliqués sur mon compte VisionsGalaxy</p>
                        <ButtonContainer>
                            <Button
                                onclick={() => {
                                    localStorage.removeItem('headai');
                                    localStorage.removeItem('context');
                                    localStorage.removeItem('researchType');
                                    window.location.reload();
                                }}
                            >
                                Réinitialiser
                            </Button>
                        </ButtonContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfilePage;
