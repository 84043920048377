import React, { useEffect, useRef } from 'react';

// Components
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';
import { config } from '../../../config';

//Style & Img/Icon
import styles from './stepcardservice.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { openWindow } from '../../../utils/openWindow';
import NoticePriceService from '../../Notices/NoticePriceService/NoticePriceService';
import { useClient } from '../../../hooks/useClient';
import { serviceIds } from '../../../data/enums/services';

const StepCardService = ({
    service,
    setCardWidthFc,
    platformJourneyId,
    userPlatformJourney,
    userId,
    openModal,
    confirmationExchange,
    statusOrientoiFinished,
    setStatusOrientoiFinished,
}) => {
    const refCard = useRef(null);
    const { client } = useClient();

    const currentPlatform =
        userPlatformJourney?.platforms[
            userPlatformJourney?.platforms?.findIndex((p) => p?.platform?._id?.toString() === service?._id?.toString())
        ];

    /**
     * Tries to start the experience by calling VT and redirect to service
     * @param {React.MouseEvent} e The event of clicking the button
     */
    const startStep = async (e) => {
        const element = e.currentTarget;
        if (element.disabled) return; // React still fires mouse event on click if element is disabled
        element.disabled = true;

        const data = await client.POST('/userplatformjourneys', {
            userId,
            platformJourneyId,
            platformId: service?._id,
        });

        //! HARDCODED FOR PITANGOO
        if (service?._id === serviceIds.pitangoo) {
            openWindow(service.urls.website, true);
        } else {
            openWindow(data.redirectUrl, true);
        }

        element.disabled = false;
    };

    useEffect(() => {
        if (refCard.current && setCardWidthFc !== undefined) {
            const width =
                refCard.current.offsetWidth +
                parseInt(window.getComputedStyle(refCard.current).getPropertyValue('margin-left').split('p')[0]) * 2;
            setCardWidthFc(width);
        }
    }, [refCard, setCardWidthFc]);

    const getButtonText = (status) => {
        switch (status) {
            case 0:
                return 'Je commence';
            case 1:
                return 'Je continue';
            case 2:
                return `Retourner sur la platforme ${currentPlatform.platform.name}`;
            case 3:
                return `Partager mes données avec ${currentPlatform?.platform?.name || service.name}`;
            case 4:
                return `Visitez Orientoi avant de partager vos données`;
            default:
                return 0;
        }
    };

    const currentStepFC = async (e) => {
        if (currentPlatform?.status !== 2) {
            startStep(e);
        } else {
            const response = await client.GET(`/service/${service._id}/sso`);
            if (response?.data?.url) {
                openWindow(response.data.url);
                return;
            }
            openWindow(currentPlatform.platform.urls.website);
        }
    };

    useEffect(() => {
        if (
            setStatusOrientoiFinished &&
            userPlatformJourney !== null &&
            currentPlatform?.platform._id === serviceIds.orientoi &&
            currentPlatform?.status === 2
        ) {
            setStatusOrientoiFinished(true);
        }
    }, [currentPlatform, userPlatformJourney, setStatusOrientoiFinished]);

    return (
        <div className={styles.card} ref={refCard}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.logo}>
                        <img src={`${config.logoBaseUrl}/${service.logo}`} alt={service.name} />
                    </div>
                    <div className={styles.information}>
                        <h3>{service.name}</h3>
                        <div className={styles.time} style={{ display: service.type === 1 && 'none' }}>
                            <img src={appImageAssets.icon.timer} alt="sablier" />
                            <span>
                                {service.galaxy.user.timeEstimation
                                    ? service.galaxy.user.timeEstimation
                                    : 'Durée indéterminée'}
                            </span>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <div className={styles.description}>{service.description}</div>
                    <div className={styles.stepGuide}>
                        {service?.galaxy?.user?.defaultGuide.map((step, index) => (
                            <div key={index} className={styles.guideContainer}>
                                <div className={styles.guideNum}>
                                    <span>{index + 1}</span>
                                </div>
                                <p className={styles.guideDesc}>{step}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.btn}>
                {service?.galaxy?.user?.isFree === false && <NoticePriceService bgGradient />}
                {/* btn step platfrom */}
                {userPlatformJourney !== null && currentPlatform?.platform?.type !== 1 && (
                    <GenericBtn
                        textContent={getButtonText(currentPlatform.status)}
                        hasArrow={false}
                        onClickFc={(e) => currentStepFC(e)}
                    />
                )}
                {userPlatformJourney === null && service.type !== 1 && (
                    <GenericBtn textContent={getButtonText(0)} hasArrow={false} onClickFc={(e) => currentStepFC(e)} />
                )}
                {userPlatformJourney === null && service.type === 1 && (
                    <GenericBtn
                        disable={!statusOrientoiFinished}
                        textContent={getButtonText(4)}
                        hasArrow={false}
                        onClickFc={(e) => currentStepFC(e)}
                    />
                )}
                {/* btn openModal for platform with type 1 */}
                {userPlatformJourney !== null && currentPlatform?.platform?.type === 1 && (
                    <GenericBtn
                        disable={!statusOrientoiFinished}
                        textContent={!statusOrientoiFinished ? getButtonText(4) : getButtonText(3)}
                        hasArrow={false}
                        onClickFc={() => openModal(currentPlatform)}
                    />
                )}
            </div>
            {/* Status platform */}
            {userPlatformJourney === null ? (
                <div className={styles.status} style={{ display: service.type === 1 && 'none' }}>
                    Pas commencé
                </div>
            ) : (
                <div className={styles.status} style={{ display: currentPlatform?.platform?.type && 'none' }}>
                    {serviceProgress(userPlatformJourney, service)}
                </div>
            )}
        </div>
    );
};

/**
 * Gets the service progress using the UserPlatformJourny
 * @param {any} upj UserPlatformJourney
 */
const serviceProgress = (upj, service) => {
    const status =
        upj?.platforms[upj?.platforms?.findIndex((p) => p?.platform?._id?.toString() === service?._id?.toString())]
            .status;

    switch (status) {
        case 0:
            return 'Pas Commencé';
        case 1:
            return 'En Cours';
        case 2:
            return 'Terminé';

        default:
            return '';
    }
};

export default StepCardService;
