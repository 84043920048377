import React, { useState } from 'react';
import styles from './linkcard.module.scss';
import ImageBorder from '../../Image/ImageBorder/ImageBorder';
import { Button } from '../../Buttons/Button/Button';
import { ButtonContainer } from '../../Containers/ButtonContainer/ButtonContainer';
import { useClient } from '../../../hooks/useClient';
import { useAuth } from '../../../hooks/useAuth';

const LinkCard = ({ card, bgColor, available = false, largeCard }) => {
    const { user } = useAuth();
    const { updateUser } = useClient();
    const [postulate, setPostulate] = useState(user?.interests?.includes('coaching'));

    const handleClick = async () => {
        setPostulate(true);
        if (card?.name === 'coach') {
            await updateUser({ interests: user?.interests?.toString() + ',coaching' });
        }
    };

    if (!card) return null;

    return (
        <div
            className={`${styles.content} ${bgColor === 'blue' ? styles.blue : ''} ${
                available ? styles.available : ''
            } ${card.name === 'coach' ? styles.full : ''}`}
        >
            <ImageBorder
                image={card.img}
                alt={`Icone ${card.name}`}
                size={'small'}
                space={5}
                className={styles.image}
            />
            <div className={styles.information}>
                {postulate ? (
                    <p className={styles.text}>
                        Nous avons bien pris en compte votre demande. <br />
                        Nous reviendrons vers vous sous peu
                    </p>
                ) : (
                    <React.Fragment>
                        <p className={styles.text}>{card.textContent}</p>
                        <ButtonContainer margin={'10px 0 0 0'}>
                            <Button
                                variant="gradient"
                                size="small"
                                sx={{ color: '#fff' }}
                                onclick={() => handleClick()}
                                navigate={card.link}
                            >
                                {card.buttonText}
                            </Button>
                        </ButtonContainer>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};

export default LinkCard;
