import React from 'react';
import styles from './prerequisite.module.scss';
import CardPrerequisite from './CardPrerequisite/CardPrerequisite';

// IMG
import PictoLaptopAndPhone from '../../../img/ICONE-TEL-ORDI.svg';
import PictoTime from '../../../img/PICTO-PRE-REQUIS-temps.png';
import PictoEmail from '../../../img/PICTO-PRE-REQUIS-@.png';

const Prerequisite = () => {
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <header>
                    <h2>Les pré-requis dont vous avez besoin :</h2>
                </header>
                <div className={styles.cardContainer}>
                    <CardPrerequisite
                        picto={PictoLaptopAndPhone}
                        textContent={'Un Ordinateur avec une connexion internet'}
                    />
                    <CardPrerequisite picto={PictoTime} textContent={'20/30 min de disponibles'} />
                    <CardPrerequisite picto={PictoEmail} textContent={'Utilisez le même email pendant tout le bilan'} />
                </div>
            </div>
        </section>
    );
};

export default Prerequisite;
