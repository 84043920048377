import React from 'react';
import RowStatsNote from '../Row/RowStatsNote';
import styles from './contentcardstatsnote.module.scss';
import UserGradeModel from '../Grade/UserGradeModel';
// import RoundButtonIcon from '../Buttons/RoundButtonIcon/RoundButtonIcon';

//IMG
// import StarGradient from '../../img/StarGradient.svg';
// import IconeEtoile from '../../img/ICONE-ETOILE1.svg';
// ICON
const star = 'fa-solid fa-star';

const ContentCardStatsNote = ({ handleClick, setHover, hover, currentGrade, average, grades }) => {
    const sumGrades = (grade) => {
        const sum = Object.values(grades).reduce((acc, current) => acc + current, 0);
        let percentage = (100 * grade) / sum;
        return percentage;
    };

    return (
        <div className={styles.container}>
            <div className={styles.row}>
                <RowStatsNote rank={5} totalNote={grades[5]} percentage={sumGrades} />
                <RowStatsNote rank={4} totalNote={grades[4]} percentage={sumGrades} />
                <RowStatsNote rank={3} totalNote={grades[3]} percentage={sumGrades} />
                <RowStatsNote rank={2} totalNote={grades[2]} percentage={sumGrades} />
                <RowStatsNote rank={1} totalNote={grades[1]} percentage={sumGrades} />
            </div>
            <div className={styles.globalRank}>
                <div className={styles.contentRank}>
                    <p>Moyenne globale : </p>
                    <div className={styles.stars}>
                        {[...Array(5)].map((stars, index) => {
                            const gradeValue = index + 1;
                            return (
                                <label htmlFor="rating" key={index}>
                                    <input className={styles.radio} type="radio" name="rating" value={gradeValue} />
                                    <i
                                        className={`${styles.star} ${star}`}
                                        style={{ color: gradeValue <= average ? '#21bbee' : '#18243f' }}
                                    ></i>
                                </label>
                            );
                        })}
                    </div>
                </div>
                <div className={styles.contentRank}>
                    <p>Ma note : </p>
                    <div className={styles.stars}>
                        {[...Array(5)].map((stars, index) => {
                            const gradeValue = index + 1;
                            return (
                                <UserGradeModel
                                    gradeValue={gradeValue}
                                    hover={hover}
                                    currentGrade={currentGrade}
                                    setHover={setHover}
                                    handleClick={handleClick}
                                    key={index}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            {/* <div className={styles.btn}>
                <RoundButtonIcon textContent={'Changer ma note'} bgColor={'smallBasic'} hasArrow={false} onClickFc={handleClick} />
            </div> */}
        </div>
    );
};

export default ContentCardStatsNote;
