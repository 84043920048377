/**
 * Opens a new window / tab for multi-browser support.
 * SetTimeout is necessary for Safari if trying to open a window
 * from within an async function
 * @param {string} url The url we should open
 * @param {boolean} shouldReload If the current page should reload
 */
export const openWindow = (url, shouldReload = false) => {
    const safari = /^((?!chrome|android|mozilla).)*safari/i.test(navigator.userAgent);
    if (safari) {
        window.location.href = url;
    }
    setTimeout(() => {
        window.open(url, '_blank');
        if (shouldReload) window.location.reload();
    }, 0);
};
