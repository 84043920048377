import React from 'react';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import ContentCardExperience from '../../../Components/ContentCards/ContentCardExperience';
import ExperienceOrientation from '../../../Components/ContentExperience/ExperienceOrientation';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import styles from './PresentationExperiencesPage.module.scss';
import { useAuth } from '../../../hooks/useAuth';
import { OBJECTIVE_RESEARCH_TYPE } from '../../../data/enums/objectiveResearchType';
import GenericCard from '../../../Components/Cards/GenericCard/GenericCard';
import { appImageAssets } from '../../../data/enums/appImageAssets';
// import ExperienceReo from '../Components/ContentExperience/ExperienceReo';

const PresentationExperiencesPage = () => {
    const { user } = useAuth();
    return (
        <>
            <HeaderWithNavBar />
            <div className={styles.main}>
                <div
                    className={styles.container}
                    style={{
                        gridTemplateColumns:
                            user?.experiencesInfo?.situation === OBJECTIVE_RESEARCH_TYPE.student && '50% 50%',
                    }}
                >
                    {/* <CardBgBottom color={'blue'}>
                        <ContentCardExperience name={'reorientation'}>
                            <ExperienceReo />
                        </ContentCardExperience>
                    </CardBgBottom> */}

                    {user?.experiencesInfo?.situation === OBJECTIVE_RESEARCH_TYPE.student && (
                        <CardBgBottom color={'blue'}>
                            <ContentCardExperience name={'parcoursup'}>
                                <div className={styles.parcoursup}>
                                    <GenericCard className={styles.card}>
                                        <div className={styles.header}>
                                            <img src={appImageAssets.icon.experienceBriefcase} alt="Icone de profil" />
                                        </div>
                                        <p>Remplir les informations sur la formation visée</p>
                                    </GenericCard>
                                    <GenericCard className={styles.card}>
                                        <div className={styles.header}>
                                            <img src={appImageAssets.icon.experienceProfil} alt="Icone de profil" />
                                        </div>
                                        <p>Déterminer votre profil</p>
                                    </GenericCard>
                                </div>
                                <GenericCard className={`${styles.card} ${styles.cardRow}`}>
                                    <div className={styles.header}>
                                        <img src={appImageAssets.icon.experienceVisualization} alt="Icone de profil" />
                                    </div>
                                    <p>Visualiser vos pronostics</p>
                                </GenericCard>
                            </ContentCardExperience>
                        </CardBgBottom>
                    )}

                    <CardBgBottom>
                        <ContentCardExperience name={'orientation'}>
                            <ExperienceOrientation />
                        </ContentCardExperience>
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <div className={styles.linkCard}>
                        <LinkCardContainer whichCardsArray={['cozy', 'event', 'meet']} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default PresentationExperiencesPage;
