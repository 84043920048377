import React from 'react';
import styles from './contentexptools.module.scss';
import { ButtonContainer } from '../../Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../Buttons/Button/Button';
import { Icon } from '../../Icons/Icon/Icon';

const ContentExpTools = ({ title, icon, text, btnColor, link, onClickFC }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={icon} alt={`expérience ${title}`} />
                <h3>{title}</h3>
            </div>
            <p className={styles.text}>{text}</p>
            <ButtonContainer>
                <Button
                    endIcon={<Icon icon="arrow" />}
                    size="smallMain"
                    variant={btnColor !== 'blue' && 'gradient'}
                    navigate={link}
                    onclick={onClickFC}
                    sx={{ color: btnColor === 'blue' && '#fff' }}
                >
                    En savoir +
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default ContentExpTools;
