import React from 'react';
// import RoundButtonIcon from '../Buttons/RoundButtonIcon/RoundButtonIcon';
import styles from './contentcozy.module.scss';

// IMG
import logoCozy from '../../img/cozy-logo.png';

const ContentCozy = () => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <img src={logoCozy} alt="" />
            </div>
            <p className={styles.text}>
                <span>Cozy Cloud</span> est une plateforme sécurisée et hébergée en France qui permet aux utilisateurs
                de stocker leurs données personnelles sur le cloud de manière fluide.
            </p>
            {/* <div className={styles.btn}>
            <RoundButtonIcon textContent={'en savoir +'} bgColor={"smallBasic"} hasArrow={false} />
        </div> */}
        </div>
    );
};

export default ContentCozy;
