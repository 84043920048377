import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches public data from VisionsGalaxy
 * @param {object} options
 * @param {string} options.path The path to fetch data from
 * @param {string} options.query any type of query string
 * @returns An array of 3 elements [error, loading, data]
 */
export const usePublicVisionsGalaxyData = ({ path, query = '' }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const getData = async () => {
            try {
                const url = query ? path + query : path;
                const response = await client.GET('/public' + url);

                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get data');
                }

                setData(response);
            } catch (err) {
                console.warn(err.message);
                setError(true);
                setData([]);
            } finally {
                setLoading(false);
            }
        };

        getData();

        return () => {
            isMounted = false;
        };
    }, [client, query, path]);

    return [error, loading, data];
};
