import React, { useEffect } from 'react';

import useQuery from '../hooks/useQuery';
import { getSSOURL } from '../utils/sso';
import { trySetContext } from '../libs/experiences/context';
import Loader from '../Components/Loaders/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const experienceStorageKey = {
    'bilan-orientation': 'isLoggingFromBO',
    'bilan-reorientation': 'isLoggingFromREO',
};

const ExperienceContextRedirect = () => {
    const navigate = useNavigate();
    const { params } = useQuery();
    const experience = params.get('experience');
    const context = params.get('context');

    useEffect(() => {
        const doExperienceAuth = (type, storageKey) => {
            localStorage.setItem(storageKey, 'true');
            trySetContext(context);
            window.location.href = getSSOURL(type);
        };

        setTimeout(() => {
            if (!experience && !context) return navigate('/');
            doExperienceAuth('signup', experienceStorageKey[experience]);
        }, 4000);
    }, [navigate, experience, context]);

    return <Loader text="Configuration de votre expérience en cours..." />;
};

export default ExperienceContextRedirect;
