import React from 'react';
import Skeleton from '../../Skeleton/Skeleton';

const SkeletonInfoUserVisionsStars = ({ moreInfo = false }) => {
    return (
        <Skeleton
            parent
            rounded={20}
            parentSx={{
                margin: '15px 10px',
                padding: 15,
                width: 'auto',
            }}
            sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
            LightBgParent
        >
            <Skeleton variant={'circular'} width={50} height={50}></Skeleton>
            {!moreInfo && (
                <>
                    <Skeleton width={150} height={45} sx={{ margin: '5px 0' }}></Skeleton>
                    <Skeleton width={150} height={30} sx={{ margin: '5px 0' }}></Skeleton>
                </>
            )}
            {moreInfo && (
                <>
                    <Skeleton variant={'text'} width={240} sx={{ margin: 5 }}></Skeleton>
                    <Skeleton variant={'text'} width={240} height={45} sx={{ margin: 5 }}></Skeleton>
                    <Skeleton variant={'text'} width={240} sx={{ margin: 5, marginTop: 15 }}></Skeleton>
                    <Skeleton variant={'text'} width={240} sx={{ margin: 5 }} rounded={30}></Skeleton>
                </>
            )}
        </Skeleton>
    );
};

export default SkeletonInfoUserVisionsStars;
