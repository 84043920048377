import React from 'react';

// Components
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import CardShareRecap from '../../../Components/Cards/CardShareRecap/CardShareRecap';
import ContentCozy from '../../../Components/ContentCards/ContentCozy';
import GlobalError from '../../../Components/Errors/GlobalError/GlobalError';
import Skeleton from '../../../Components/Skeletons/Skeleton/Skeleton';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';

// Hooks & Utils
import { generateCozyLink } from '../../../utils/cozy';

// Styles & Img/Icon
import styles from './ShareBilanPage.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { openWindow } from '../../../utils/openWindow';
import { useAuth } from '../../../hooks/useAuth';
import { useVisionsGalaxyData } from '../../../hooks/useVisionsGalaxyData';
import { Button } from '../../../Components/Buttons/Button/Button';
import { Icon } from '../../../Components/Icons/Icon/Icon';

const COZY_KONNECTORS = [
    '60796546a2be8fc2e9b02027',
    // '5fb272090ee6207b28216bab',
    // '60796606a2be8fc2e9b0202f',
];

const ShareBilanPage = () => {
    const { user } = useAuth();
    const { _id, email } = user;

    // TODO API ROUTER
    const [servicesError, servicesLoading, services] = useVisionsGalaxyData({ path: '/services/me' });

    const cozyRedirect = () => {
        openWindow(generateCozyLink(_id, email, COZY_KONNECTORS));
    };

    return (
        <>
            <HeaderWithNavBar />
            <main className={styles.main}>
                <div className={styles.container}>
                    <div className={styles.card}>
                        <div className={styles.text}>
                            <h2>Retrouvez ici toutes vos données disponibles</h2>
                            <p>Issue des plateformes que vous avez utilisés</p>
                        </div>
                        <div className={styles.img}>
                            <img src={appImageAssets.illustration.phoneCozy} alt="" />
                        </div>
                    </div>
                    {!servicesError && !servicesLoading && services.length !== 0 && (
                        <div className={`${styles.btn} ${styles.isMobile}`}>
                            <Button
                                size="main"
                                variant="gradient"
                                endIcon={<Icon icon="arrow" />}
                                onclick={cozyRedirect}
                            >
                                Voir mon Bilan
                            </Button>
                        </div>
                    )}
                    <CardBgBottom color={'blue'}>
                        <>
                            {!servicesError && !servicesLoading && services.length === 0 ? (
                                <TextContainer sx={{ margin: 10 }}>
                                    Vous n'avez pas encore de données disponibles pour votre Cozy cloud
                                </TextContainer>
                            ) : (
                                <p className={styles.title}>Toutes les données disponibles dans votre Cozy cloud</p>
                            )}
                            <div className={styles.content}>
                                {servicesLoading === true &&
                                    [...Array(2)].map((skeleton, index) => (
                                        <Skeleton
                                            parent
                                            parentSx={{
                                                width: '90%',
                                                height: 200,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                            rounded={10}
                                            key={index}
                                        >
                                            <Skeleton sx={{ margin: '10px auto', width: '90%', height: 60 }}></Skeleton>
                                            {[...Array(3)].map((child, index) => (
                                                <div
                                                    key={index}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        width: '90%',
                                                        margin: '0 10px',
                                                    }}
                                                >
                                                    <Skeleton variant={'circular'} width={15} height={15}></Skeleton>
                                                    <Skeleton
                                                        variant={'text'}
                                                        width={200}
                                                        sx={{ margin: 5 }}
                                                    ></Skeleton>
                                                </div>
                                            ))}
                                        </Skeleton>
                                    ))}
                                {servicesError && <GlobalError flexDirection={'row'} />}
                                {services &&
                                    services.map((service, index) => <CardShareRecap service={service} key={index} />)}
                            </div>
                            <div className={styles.btn}>
                                <Button
                                    size="main"
                                    variant="gradient"
                                    endIcon={<Icon icon="arrow" />}
                                    onclick={cozyRedirect}
                                >
                                    Voir mon Bilan
                                </Button>
                            </div>
                        </>
                    </CardBgBottom>
                </div>
                <div className={styles.col}>
                    <CardBgBottom>
                        <ContentCozy />
                    </CardBgBottom>
                    <LinkCardContainer whichCardsArray={['meet', 'event']} largeCard={true} />
                </div>
            </main>
        </>
    );
};

export default ShareBilanPage;
