import React from 'react';
import styles from './textContainer.module.scss';

const TextContainer = ({ sx = {}, border = true, className, children }) => {
    return (
        <div className={`${styles.text} ${className} ${border ? '' : styles.noBorder}`} style={{ ...sx }}>
            {children}
        </div>
    );
};

export default TextContainer;
