const locations = {
    '/home': 'page_home',
    '/catalog': 'page_catalog',
    '/recommendation': 'page_recommendations',
    '/visionsstars': 'page_visions_stars',
    '/share': 'page_orientation_report',
    '/details': 'service_details',
};

const useEventLogLocation = () => {
    const { pathname } = window.location;
    const nestedPath = (pathname.match(/\//g) || []).length === 2;

    if (nestedPath) {
        if (pathname.split('/')[1] === 'catalog') {
            sessionStorage.setItem('eventLogLocation', locations['/catalog']);
            sessionStorage.setItem('eventLogLocationStarter', locations['/catalog']);
        }
    } else {
        sessionStorage.setItem('eventLogLocation', locations[pathname]);
        const split = locations[pathname]?.split('_');
        if (split)
            sessionStorage.setItem('eventLogLocationStarter', split.length < 3 ? split[1] : `${split[1]}_${split[2]}`);
    }

    return {
        eventLogLocation: sessionStorage.getItem('eventLogLocation'),
        eventLogLocationStarted: sessionStorage.getItem('eventLogLocationStarter'),
    };
};

export default useEventLogLocation;
