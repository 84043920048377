import React from 'react';
import styles from './ButtonContainer.module.scss';

/**
 *
 * @param {object} props
 * @param {string} props.className set additional className to the container
 * @param {string | number} props.margin set quickly margin inline style to the container
 * @param {'start' | 'end'} props.position set position container default "center"
 * @returns
 */
export const ButtonContainer = ({ className, margin, position, children }) => {
    const setPositionContainer = () => {
        switch (position) {
            case 'start':
                return styles.start;
            case 'end':
                return styles.end;

            default:
                break;
        }
    };

    return (
        <div className={`${styles.container} ${className} ${setPositionContainer()}`} style={{ margin: margin }}>
            {children}
        </div>
    );
};
