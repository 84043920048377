/**
 * Compares arrays of primitive values in 2 arrays and returns true if all values are present
 * @param {number[] | string[]} arr1
 * @param {number[] | string[]} arr2
 */
export const isEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;

    for (const i of arr1) {
        if (arr2.indexOf(i) === -1) return false;
    }
    return true;
};
