import React, { useState } from 'react';
import styles from './CustomSelectInput.module.scss';
import ArrowIndicator from '../../Arrows/ArrowIndicator/ArrowIndicator';
import SelectModal from '../../Modal/SelectModal/SelectModal';

const CustomSelectInput = ({ option, selectedOption, updateSelectedOption, border = false, children }) => {
    const [show, setShow] = useState(false);

    return (
        <div style={{ position: 'relative' }}>
            <div className={`${styles.pill} ${border ? styles.border : ''}`} onClick={() => setShow(true)}>
                {selectedOption ? <span>{selectedOption.name}</span> : <span>{children}</span>}
                <ArrowIndicator active={show} />
            </div>
            <SelectModal onClose={() => setShow(false)} show={show}>
                {option?.length !== 0 &&
                    option?.map((obj, index) => {
                        if (selectedOption?.name === obj?.objective?.name) return null;
                        if (!obj?.objective?.name) return null;
                        return (
                            <span
                                key={index}
                                onClick={() => {
                                    updateSelectedOption(obj.objective);
                                    setShow(false);
                                }}
                            >
                                {obj?.objective?.name}
                            </span>
                        );
                    })}
            </SelectModal>
        </div>
    );
};

export default CustomSelectInput;
