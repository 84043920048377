import React from 'react';
import styles from './experienceorientation.module.scss';
import CardBilanOrientation from '../Cards/CardBilanOrientation/CardBilanOrientation';

// TMP DATA
import { experiences } from '../../data/tmp/servicesData';
const { bilanservice } = experiences;

const ExperienceOrientation = () => {
    return (
        <div className={styles.content}>
            {bilanservice.map((service, index) => {
                return <CardBilanOrientation key={index} service={service} />;
            })}
        </div>
    );
};

export default ExperienceOrientation;
