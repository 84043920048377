const contextExperienceData = {
    1: {
        timeEstimation: '10',
        description: `Le Bilan d’Orientation vous aidera à mieux vous connaître, explorer des métiers, des secteurs et des contextes professionnels. Une fois réalisé, le Bilan d'Orientation vous permettra de découvrir les formations qui vous correspondent et de prendre rendez-vous avec les établissements de formation pour les rencontrer en ligne le 23 et 24 novembre.`,
        guide: [
            'Faites votre bilan d’orientation pour trouver votre voie professionnelle',
            'Générez votre bilan dans votre Cozy Cloud et découvrez les résultats obtenus',
            'Consultez les propositions de métiers et de formations dans le numérique puis prenez rendez-vous facilement en partageant votre bilan.',
        ],
        information:
            "Faites votre Bilan d'Orientation pour débloquer vos matchs avec les formations et prendre rendez-vous sur notre plateforme dédiée",
    },
};

/**
 * Returns a specific context data for an experience
 * @param {KNOWN_CONTEXTS} knownContextCode
 * @returns Specific data for the context
 */
export const getSpecificContextExperienceData = (knownContextCode) => {
    return contextExperienceData[knownContextCode];
};
