import React from 'react';
import styles from './ExperienceParcoursupPage.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import { appLinks } from '../../../data/enums/appLinks';
import GenericCard from '../../../Components/Cards/GenericCard/GenericCard';
import ImageBorder from '../../../Components/Image/ImageBorder/ImageBorder';
import TextContainer from '../../../Components/Text/TextContainer/TextContainer';
import CardBgBottom from '../../../Components/Cards/CardBgBottom/CardBgBottom';
import HeaderCard from '../../../Components/Header/HeaderCard/HeaderCard';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { ADVICE_WISHES } from '../../../data/enums/adviceWishes';
import ManualExperience from '../../../Components/ContentCards/ManualExperience/ManualExperience';
import LinkCardContainer from '../../../Components/Cards/LinkCard/LinkCardContainer';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../../Components/Buttons/Button/Button';
import { PillVisionsStars } from '../../../Components/Pills/PillVisionsStars/PillVisionsStars';

const TMP_EXPERIENCE_DATA = {
    description:
        "Ne stressez plus pour ParcourSup grâce au premier simulateur de pronostics de vos voeux ! ParcourSup Coach vous aide à mieux appréhender le choix de vos voeux ParcourSup en découvrant quels sont vos chances d'être acceptés pour chaque formation visée selon les 3 phases d'admissions.",
    subDescription:
        "Cet outil n'est pas parfait, mais il vous offre la possibilité d'avoir une tendance globale de réussite ou non selon votre profil à partir des données de 2018 à 2021 disponibles en opendata issues de ParcourSup.",
    image: '',
    name: 'ParcoursupCoach',
    visionsStars: 100,
    guide: [
        'Remplissez les cases concernant la formation visée',
        'Complétez les critères sur votre profil et cliquez sur <<lancer la simulation>>',
        'Analysez votre résultat et cliquez sur <<nouvelle simulation>> pour connaître vos pronostics sur une autre formation',
    ],
};

const ExperienceParcoursupPage = () => {
    return (
        <div className={styles.page}>
            <HeaderWithNavBar />

            <BtnBack link={appLinks.missions.index} />

            <div className={styles.main}>
                <section className={styles.container}>
                    <GenericCard className={styles.descriptionExperience}>
                        <ImageBorder
                            image={appImageAssets.logo.parcoursupCoach}
                            alt="Logo Parcoursup Coach"
                        ></ImageBorder>
                        <div className={styles.descriptionExperienceContent}>
                            <div className={styles.descriptionExperienceContentHeader}>
                                <h2>{TMP_EXPERIENCE_DATA.name}</h2>
                                <TextContainer className={styles.exclu}>
                                    Exclusivité VisionsGalaxy by Inokufu
                                </TextContainer>
                                <PillVisionsStars
                                    sizeImg="small"
                                    visionsStars={TMP_EXPERIENCE_DATA.visionsStars}
                                ></PillVisionsStars>
                            </div>
                            <p className={styles.descriptionExperienceContentDescription}>
                                {TMP_EXPERIENCE_DATA.description}
                            </p>
                            <p className={styles.descriptionExperienceContentSubDescription}>
                                {TMP_EXPERIENCE_DATA.subDescription}
                            </p>
                        </div>
                    </GenericCard>

                    <CardBgBottom className={styles.iframeContainer}>
                        <GenericCard className={styles.results}>
                            <h3>Interprétation des résultats</h3>
                            <p>
                                Retrouvez ci-dessous les correspondances des pourcentages données pour vos voeux et ce
                                qu'ils représentent
                            </p>
                            <img
                                src={appImageAssets.illustration.percentageParcoursup}
                                alt="Interprétation des pourcentages de Parcoursup"
                            />
                        </GenericCard>
                        <iframe src="https://parcoursupcoach.fr/v0" frameBorder="0" title="Inline Inokufu"></iframe>
                    </CardBgBottom>
                </section>

                <div className={styles.col}>
                    <GenericCard className={styles.titleCard}>
                        <HeaderCard
                            className={styles.titleCardHeader}
                            icon={fontAwesome.info}
                            mainTitle="Informations"
                            bgImage
                        ></HeaderCard>
                        <div className={styles.titleCardContent}>
                            <p>
                                <span>
                                    La version disponible est une version bêta. Le document de conception de ParcourSup
                                    Coach est en accès libre{' '}
                                    <a target="_blank" rel="noreferrer" href="https://www.parcoursup.fr/">
                                        ici
                                    </a>
                                </span>
                            </p>
                            <ul>
                                Une nouvelle version de cet outil sortira l'année prochaine avec :
                                <li>- de nouveaux critères pour préciser d'avantage votre profil</li>
                                <li>
                                    - des pronostics plus précis grâce à l'analyse des voeux des lycéens de 2023 et de
                                    leurs taux d'acceptation
                                </li>
                            </ul>

                            <ButtonContainer>
                                <Button>Participer à l'amélioration de cet outil</Button>
                            </ButtonContainer>
                        </div>
                    </GenericCard>

                    <GenericCard className={styles.titleCard}>
                        <HeaderCard
                            className={styles.titleCardHeader}
                            icon={fontAwesome.bulb}
                            mainTitle="Conseils pour vos voeux"
                            bgImage
                        ></HeaderCard>

                        <div className={styles.titleCardContent}>
                            {ADVICE_WISHES.map((advice, index) => (
                                <div className={styles.titleCardContentAdvice} key={index}>
                                    <img src={advice.image} alt="" />
                                    <p dangerouslySetInnerHTML={{ __html: advice.description }}></p>
                                </div>
                            ))}
                        </div>
                    </GenericCard>

                    <CardBgBottom className={styles.manual} color={'blue'}>
                        <ManualExperience experience={TMP_EXPERIENCE_DATA} />
                    </CardBgBottom>

                    <LinkCardContainer whichCardsArray={['coach']} />
                </div>
            </div>
        </div>
    );
};

export default ExperienceParcoursupPage;
