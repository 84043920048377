import React from 'react';
import styles from './cardbilanorientation.module.scss';

const CardBilanOrientation = ({ service }) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img src={service.minilogo ? service.minilogo : service.logo} alt="" />
                </div>
                <h3>{service.name}</h3>
            </div>
            {service.datatypes.map((datatype, index) => (
                <div className={styles.description} key={index}>
                    <p>{datatype.description}</p>
                </div>
            ))}
        </div>
    );
};

export default CardBilanOrientation;
