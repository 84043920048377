import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches The mission and userMission from VisionsGalaxy
 * @param {object} options
 * @param {boolean} options.missionId The ID of the mission
 */
export const useUserMission = ({ missionId }) => {
    const [mission, setMission] = useState(null);
    const [userMission, setUserMission] = useState(null);
    const [loading, setLoading] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        if (!missionId) return;
        let isMounted = true;

        setLoading(true);

        const getMission = async () => {
            try {
                const [missionRes, userMissionRes] = await Promise.all([
                    client.GET(`/public/missions/${missionId}`),
                    client.GET(`/usermissions/mission/${missionId}`),
                ]);

                if (!isMounted) return;

                if (!missionRes) {
                    throw new Error('Could not get mission');
                }

                setMission(missionRes);
                setUserMission(userMissionRes);
            } catch (err) {
                setMission(null);
                setUserMission(null);
            } finally {
                setLoading(false);
            }
        };

        getMission();

        return () => {
            isMounted = false;
        };
    }, [client, missionId]);

    return { loading, mission, userMission };
};
