import React, { useState, useEffect } from 'react';

// Components
import GenericBtn from '../../../Buttons/GenericBtn/GenericBtn';
import RowDatatypesAPI from './RowDatatypesAPI/RowDatatypesAPI';
import Loader from '../../../Loaders/Loader/Loader';

// Hooks & Utils
import { config } from '../../../../config';

// Style
import styles from './exchangedataapi.module.scss';
import illusShareData from '../../../../img/Partager-mes-donnees.svg';
import { fontAwesome } from '../../../../data/enums/fontAwesome';
import TextContainer from '../../../Text/TextContainer/TextContainer';
import { useClient } from '../../../../hooks/useClient';
import { useAuth } from '../../../../hooks/useAuth';
import { useVisionsGalaxyData } from '../../../../hooks/useVisionsGalaxyData';

// TODO Handle error response messages to show different messages based on the use case

const ExchangeDataAPI = ({
    closeModal,
    confirm,
    setConfirm,
    serviceImportId,
    serviceExportId,
    purposeId,
    tooltipWidth,
    onSuccess,
}) => {
    const { user } = useAuth();
    const userId = user._id;
    const { client } = useClient();

    const exchangeData = {
        serviceImportId,
        serviceExportId,
        userId,
        purposeId,
    };

    const urlEncoded = encodeURI(JSON.stringify(exchangeData));

    const [consentDataError, consentDataLoading, consentData] = useVisionsGalaxyData({path: `/consents/popup/${urlEncoded}`});

    const [responseError, setResponseError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [userSelection, setUserSelection] = useState([]);
    const [tooltip, setTooltip] = useState(false);
    const [hideDescription, setHideDescription] = useState(true);

    useEffect(() => {
        if (consentDataError) return;
        if (consentDataLoading) return;

        setUserSelection(consentData.datatypes);
    }, [consentDataError, consentDataLoading, consentData]);

    if (consentDataLoading) return <Loader />;
    if (consentDataError) return <TextContainer>Une erreur est survenue</TextContainer>;

    const updateUserSelection = (datatypeId) => {
        const newSelection = [...userSelection];
        const idx = newSelection.findIndex((datatype) => datatype?.datatype?._id === datatypeId);
        if (idx !== -1) {
            newSelection[idx].checked = !newSelection[idx].checked;
        }
        setUserSelection(newSelection);
    };

    const validationSharingUserData = async () => {
        setLoading(true);
        const datatypesRequestBody = [];

        userSelection.forEach((datatype) => {
            datatypesRequestBody.push({
                id: datatype?.datatype?._id,
                datatype: datatype?.datatype?.name,
                checked: datatype?.checked,
            });
        });

        const body = {
            serviceId: consentData?.serviceExport?.id,
            requestBody: {
                datatypes: datatypesRequestBody,
                emailExport: consentData?.user?.exportIdentifier?.email,
                emailImport: consentData?.user?.importIdentifier?.email,
                userKey: consentData?.user?.exportIdentifier?.userKey,
                purpose: consentData?.purpose?.id,
                isNewAccount: consentData?.shouldRequestNewAccount,
            },
        };

        setLoading(false);
        setConfirm(true);
        if (onSuccess) await onSuccess();
        const res = await client.POST('/consents/export', body);

        if (res === null) {
            alert(
                "Une erreur est survenue, le consentement que vous avez donné pour votre partage de données n'a pas été correctement pris en compte et doit être redonné",
            );
        }
    };

    const positionTooltip = tooltipWidth && -tooltipWidth / 2 + 15;
    const widthTooltip = tooltipWidth && tooltipWidth - 10;

    return (
        <>
            {consentData && !confirm && (
                <div className={styles.content}>
                    <div className={styles.information}>
                        <div className={styles.header}>
                            <h3>{consentData?.purpose?.name}</h3>
                            <i className={fontAwesome.question} onClick={() => setTooltip(!tooltip)}>
                                <p
                                    className={styles.tooltip}
                                    style={{
                                        display: tooltip && 'block',
                                        width: widthTooltip,
                                        left: positionTooltip,
                                    }}
                                >
                                    {consentData?.infoText}
                                </p>
                                <div className={styles.arrowUp} style={{ display: tooltip && 'block' }}></div>
                            </i>
                        </div>
                        <div className={styles.exchange}>
                            <div>
                                <img
                                    src={`${config.logoBaseUrl}/1618578207099.jpg`}
                                    alt={`Icone ${consentData?.serviceExport?.name}`}
                                />
                                <span>{consentData?.serviceExport?.name}</span>
                            </div>
                            <div>
                                <i className={fontAwesome.share}></i>
                            </div>
                            <div>
                                <img
                                    src={`${config.logoBaseUrl}/1618578014892.png`}
                                    alt={`Icone ${consentData?.serviceImport?.name}`}
                                />
                                <span>{consentData?.serviceImport?.name}</span>
                            </div>
                        </div>
                        <p
                            className={`${styles.description} ${hideDescription ? styles.hideDescription : ''}`}
                            onClick={() => setHideDescription(!hideDescription)}
                        >
                            {consentData?.purpose?.description}
                        </p>
                        <p className={styles.readMore} onClick={() => setHideDescription(!hideDescription)}>
                            {hideDescription && 'En lire plus'}
                        </p>
                        {consentData && (
                            <table>
                                <tbody>
                                    {userSelection.map((datatype, index) => (
                                        <RowDatatypesAPI
                                            key={index}
                                            checked={datatype.checked}
                                            datatype={datatype.datatype}
                                            updateUserSelection={updateUserSelection}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        )}
                        {consentData?.shouldRequestEmail && (
                            <div className={styles.email}>
                                <label htmlFor="email">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        defaultValue={consentData?.user?.importIdentifier?.email}
                                    />
                                </label>
                            </div>
                        )}
                        {consentData?.shouldRequestNewAccount && (
                            <label className={styles.checkAccount}>
                                <input type="checkbox" name="checkbox" id="checkbox" />
                                <span className={styles.checkbox}></span>
                                <span>Je n'ai pas de compte chez {consentData?.serviceImport?.name}</span>
                            </label>
                        )}
                    </div>
                    {loading && <Loader />}
                    {!loading && !responseError && (
                        <div className={styles.btn}>
                            <GenericBtn
                                textContent={'Partager mes données'}
                                hasArrow={false}
                                onClickFc={() => validationSharingUserData()}
                            />
                        </div>
                    )}
                </div>
            )}
            {confirm && (
                <div className={styles.content}>
                    <div className={styles.information}>
                        <div className={styles.header}>
                            <h3>Félicitations !</h3>
                        </div>
                        <div className={styles.recap}>
                            <p>
                                Vos données ont bien été partagées avec la platforme{' '}
                                <span>{consentData?.serviceImport?.name}</span>
                            </p>
                            <img src={illusShareData} alt="Illustration Partage de données" />
                        </div>
                    </div>
                    <div className={styles.btn}>
                        <GenericBtn
                            textContent={'Fermer la fenêtre'}
                            hasArrow={false}
                            onClickFc={() => closeModal(confirm)}
                        />
                    </div>
                </div>
            )}
            {responseError && (
                <div className={styles.error}>
                    <TextContainer>
                        une erreur est survenue <i className={fontAwesome.danger}></i>
                    </TextContainer>
                </div>
            )}
        </>
    );
};

export default ExchangeDataAPI;
