import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches Objectives from VisionsGalaxy
 * @returns An array of 3 elements [error, loading, objectives]
 */
export const useObjectives = () => {
    const [objectives, setobjectives] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const getObjectives = async () => {
            try {
                const response = await client.GET(`/public/missionobjectiveassociations/objectives`);
                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get objectives');
                }

                setobjectives(response);
            } catch (err) {
                setError(true);
                setobjectives([]);
            } finally {
                setLoading(false);
            }
        };

        getObjectives();

        return () => {
            isMounted = false;
        };
    }, [client]);

    return [error, loading, objectives];
};
