import React, { useState, useRef, useEffect } from 'react';

// Components
import ExchangeDataAPI from '../ContentModal/ExchangeDataAPI/ExchangeDataAPI';

// Style & Img
import styles from './globalmodal.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import ComingFromPlatformModal from '../ContentModal/ComingFromPlatformModal/ComingFromPlatformModal';

export const MODAL_TYPES = {
    consent: 0,
    consentAPI: 1,
    comingFromPlatform: 2,
    redirection: 3,
};

const GlobalModal = ({ open, closeModal, data, onSuccess = null, modalType = 0, redirectionLink = null }) => {
    const refContentModal = useRef(null);
    const [confirm, setConfirm] = useState(false);
    const [currentWidthModal, setCurrentWidthModal] = useState(refContentModal?.current?.offsetWidth || 10);

    useEffect(() => {
        if (!refContentModal) return;
        if (open) {
            setConfirm(false);
            window.addEventListener('resize', () => {
                const width =
                    refContentModal.current.offsetWidth +
                    parseInt(
                        window.getComputedStyle(refContentModal.current).getPropertyValue('margin-left').split('p')[0],
                    ) *
                        2;
                setCurrentWidthModal(width);
            });
            const width =
                refContentModal.current.offsetWidth +
                parseInt(
                    window.getComputedStyle(refContentModal.current).getPropertyValue('margin-left').split('p')[0],
                ) *
                    2;
            setCurrentWidthModal(width);
        }
    }, [refContentModal, open]);

    const contentModal = (method) => {
        switch (method) {
            case 0:
                return;
            case 1:
                return (
                    <ExchangeDataAPI
                        closeModal={closeModal}
                        confirm={confirm}
                        setConfirm={setConfirm}
                        serviceImportId={'60796606a2be8fc2e9b0202f'}
                        serviceExportId={'60796546a2be8fc2e9b02027'}
                        purposeId={'60effd3cdba5250506f24577'}
                        tooltipWidth={currentWidthModal}
                        onSuccess={onSuccess}
                    />
                );
            case 2:
                return <ComingFromPlatformModal closeModal={closeModal} redirectionLink={redirectionLink} />;
            default:
                break;
        }
    };

    const handleBackdropClick = () => {
        closeModal(confirm);
    };
    return (
        <div className={`${styles.backdrop} ${open ? styles.openModal : ''}`} onClick={() => handleBackdropClick()}>
            <div
                className={styles.modal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                ref={refContentModal}
            >
                <div className={styles.modalHeader}>
                    <div className={styles.modalLogo}>
                        <img src={appImageAssets.icon.VG} alt="Icone Visons Galaxy" className={styles.modalImage} />
                    </div>
                    <div className={styles.closeModal} onClick={() => closeModal(confirm)}>
                        <span>x</span>
                    </div>
                </div>
                <div className={styles.modalContent}>{contentModal(modalType)}</div>
            </div>
        </div>
    );
};

export default GlobalModal;
