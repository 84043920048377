import { useEffect, useRef } from 'react';

/**
 * Uses a mutable reference that persists accross renders to verify the mounted
 * state of the component. Very helpful for async handler functions.
 */
export const useComponentIsMounted = () => {
    const isMountedRef = useRef(false);

    useEffect(() => {
        isMountedRef.current = true;
        return () => {
            isMountedRef.current = false;
        };
    }, []);

    return isMountedRef;
};
