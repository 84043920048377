import React from 'react';

const SvgMatchGauge = ({ progress = '0%', className }) => {
    let cx, userOne, userTwo, userThree;
    switch (progress) {
        case '0%':
            cx = 29.59;
            userOne = '#21BBEE';
            userTwo = '#fff';
            userThree = '#fff';
            break;
        case '50%':
            cx = 249.59;
            userOne = '#1C2057';
            userTwo = '#21BBEE';
            userThree = '#fff';
            break;
        case '100%':
            cx = 470.59;
            userOne = '#1C2057';
            userTwo = '#1C2057';
            userThree = '#16f7b4';
            break;
        default:
            cx = 29.59;
            break;
    }

    return (
        <svg
            id="Calque_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 500 78.9"
            style={{
                enableBackground: 'new 0 0 500 78.9',
                transition: 'all 0.2s',
            }}
            xmlSpace="preserve"
            className={className}
        >
            <style>{'.st0{fill:#1f93b2}.st1{fill:#17243f}.st7{fill:#fff}'}</style>
            <symbol id="Nouveau_symbole" viewBox="-21.6 -7.8 43.1 15.6">
                <path
                    className={progress === '50%' ? 'st0' : 'st1'}
                    d="M-12.2.7c-1.3.9-2.8 1.3-4.4 1.2-.6 0-1.2-.1-1.7-.2l.3 3.1h6.6v2h-8.7l-.6-5.8 1.3-1.6c.9.2 1.9.4 2.8.4.9.1 1.8-.2 2.5-.6l.1-.1c1-1.1 1-2.8-.1-3.8-.6-.5-1.4-.7-2.2-.7-.7 0-1.4.1-2.1.3-.7.3-1.3.7-1.7 1.3l-1.5-1.5c.6-.8 1.4-1.4 2.4-1.7.9-.3 1.9-.4 2.9-.4 1.5-.1 2.9.3 4.1 1.2 1 .8 1.6 2.1 1.5 3.3.2 1.5-.4 2.8-1.5 3.6zM-3.7 7.5c-2.8-.2-5-2.6-4.8-5.4V-2c-.1-1.4.4-2.9 1.4-3.9 2.2-1.8 5.4-1.8 7.6 0 .9 1.1 1.4 2.5 1.2 4V2.8C1.6 5.6-.8 7.7-3.7 7.5zM-.8-2c.1-.9-.2-1.7-.7-2.5-.4-.5-1.1-.8-1.8-.8s-1.4.3-1.9.9c-.5.7-.7 1.6-.6 2.4v4.1c-.1.8.2 1.6.6 2.3.5.6 1.2.8 1.9.8s1.4-.3 1.9-.8c.4-.7.6-1.5.6-2.3V-2zM20.7.1c-1.4 1-3.4 1-4.8 0-.6-.6-.9-1.4-.9-2.3v-2.5c-.1-.9.3-1.7.9-2.4 1.4-.9 3.4-.9 4.8.1.6.6.9 1.5.8 2.3v2.5c.1.9-.2 1.7-.8 2.3zm-1-4.7c0-.4-.1-.9-.4-1.2s-.6-.4-1-.4-.8.1-1 .4c-.3.4-.4.8-.4 1.2v2.5c0 .4.1.8.4 1.1s.6.4 1 .4.7-.1 1-.4.4-.7.4-1.1v-2.5zM15.9 6.9l-8.4-14h1.8l8.4 14zM10.2 2.3v2.5c0 .8-.3 1.6-.9 2.2-.7.5-1.5.8-2.4.8S5.2 7.5 4.5 7c-.6-.6-.9-1.4-.9-2.3V2.3c0-.9.3-1.8.9-2.4S6-1 6.9-.9c.9-.1 1.8.2 2.4.8.6.6.9 1.5.9 2.4zm-1.9 0c0-.4-.1-.9-.3-1.3-.2-.3-.6-.4-1-.4S6.3.7 6 1c-.4.4-.5.8-.5 1.3v2.5c0 .4.1.8.4 1.1.3.3.6.4 1 .4s.7-.1 1-.4c.2-.3.4-.7.3-1.1V2.3h.1z"
                />
            </symbol>
            <symbol id="Nouveau_symbole_1" viewBox="-20.9 -6.5 41.8 13.1">
                <path
                    className={progress === '100%' ? 'st0' : 'st1'}
                    d="M-20.9 4.4V3l2.7 1.6V-5.9h1.5V5.9h-1.4zM-12.7 5.1c-.7-.9-1.1-2.1-1-3.3v-3.4c-.1-1.2.3-2.4 1-3.3 1.7-1.5 4.3-1.5 6.1 0 .7 1 1.1 2.1 1 3.3v3.4c.1 1.2-.3 2.3-1 3.3-1.8 1.5-4.4 1.5-6.1 0zm5.6-6.8c.1-.8-.1-1.7-.6-2.4l-.2-.2c-1.1-1-2.7-.9-3.7.2-.5.7-.7 1.6-.7 2.5v3.4c-.1.9.2 1.7.7 2.4l.1.1c1.1 1 2.7 1 3.7-.1.6-.7.8-1.6.7-2.4v-3.5zM-2.5 5.1c-.7-.9-1.1-2.1-1-3.3v-3.4c-.1-1.2.3-2.3 1-3.3 1.7-1.5 4.3-1.5 6.1 0 .8.9 1.1 2.1 1 3.3v3.4c.1 1.2-.3 2.4-1 3.3-1.7 1.5-4.3 1.5-6.1 0zm5.7-6.8c.1-.8-.2-1.7-.7-2.4 0-.1-.1-.2-.1-.2-1.1-1-2.7-.9-3.7.2-.5.7-.7 1.6-.7 2.4v3.4c0 .9.2 1.7.7 2.4l.1.1c1.1 1 2.7 1 3.7-.1.5-.6.8-1.5.7-2.3v-3.5zM11.5 1.9v2c0 .7-.2 1.4-.7 1.9-1.1 1-2.7 1-3.8.1-.4-.5-.6-1.3-.6-1.9V2c-.1-.8.2-1.4.7-2 1.1-1 2.7-1 3.8-.1.5.6.7 1.3.6 2zm-1.1 0c0-.5-.1-.9-.4-1.3-.2-.3-.6-.4-1-.4s-.8.1-1.1.5c-.2.3-.4.8-.3 1.2v2c0 .5.1.9.4 1.3.2.3.6.5 1 .5s.8-.1 1.1-.4c.3-.4.4-.8.4-1.3V1.9h-.1zM16.7 5.9 9.6-5.9h1.1l7.1 11.8zM20.2.1c-1.1.9-2.7.9-3.8 0-.5-.5-.7-1.3-.7-2v-2c-.1-.7.2-1.4.7-2 1.1-.9 2.7-.9 3.8 0 .5.6.7 1.3.7 2v2c0 .8-.2 1.5-.7 2zm-.4-4c0-.5-.1-.9-.4-1.3-.3-.3-.6-.5-1-.5s-.8.1-1.1.5c-.3.4-.4.8-.4 1.3v2c0 .5.1.9.4 1.3.3.4.7.6 1.1.5.4 0 .8-.1 1.1-.4.3-.4.4-.8.4-1.3l-.1-2.1z"
                />
            </symbol>
            <symbol id="Nouveau_symbole_2" viewBox="-12.2 -6.6 24.4 13.2">
                <path
                    className={progress === '0%' ? 'st0' : 'st1'}
                    d="M-8.2 6.1c-1.1.1-2.2-.3-3-1.1-.7-.9-1.1-2.1-1-3.3v-3.4c-.1-1.2.3-2.4 1-3.3.8-.8 1.9-1.2 3-1.2 1.2 0 2.3.4 3 1.2.8.9 1.1 2.1 1 3.3v3.4C-4 2.9-4.4 4-5.2 5c-.8.7-1.8 1.2-3 1.1zm2.7-7.8c.1-.9-.2-1.7-.7-2.5-.5-.5-1.2-.9-1.9-.8-.7 0-1.5.3-1.9.9-.5.7-.7 1.6-.7 2.5v3.4c-.1.9.2 1.7.7 2.4.4.4 1.1.8 1.9.7.7.1 1.5-.2 2-.8.5-.7.7-1.5.7-2.4l-.1-3.4zM2.8 1.9v2c.1.7-.2 1.5-.7 2-.4.5-1.1.7-1.8.7s-1.4-.2-1.9-.7c-.5-.6-.7-1.3-.6-2v-2c-.1-.7.2-1.4.6-2C-1.1-.6-.4-.8.3-.8s1.4.2 1.9.7c.5.6.7 1.3.6 2zm-1.1 0c0-.5-.1-.9-.4-1.3L1.2.5C.7 0-.2 0-.8.6c-.3.4-.4.9-.4 1.3v2c0 .5.1.9.4 1.3.6.6 1.5.6 2.1 0 .3-.4.4-.8.4-1.3v-2zM8 5.9.9-6H2L9.1 5.9zM11.5.1c-1.1.9-2.7.9-3.8 0-.5-.5-.7-1.3-.7-2v-2c0-.7.2-1.4.7-2 1.1-.9 2.7-.9 3.8 0 .5.6.7 1.3.7 2v2c0 .7-.2 1.4-.7 2zm-.5-4c0-.5-.1-.9-.3-1.3-.6-.6-1.5-.6-2.1 0-.3.4-.4.8-.4 1.3v2c0 .5.1.9.3 1.3.6.6 1.5.6 2.1 0 .3-.4.5-.9.4-1.3v-2z"
                />
            </symbol>
            <linearGradient
                id="SVGID_1_"
                gradientUnits="userSpaceOnUse"
                x1={250}
                y1={1035.23}
                x2={250}
                y2={1055.03}
                gradientTransform="translate(0 -1000)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: '#1c2057',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#17243e',
                    }}
                />
            </linearGradient>
            <path
                d="M500 44.3v2c0 5-4 9-9 9H9c-5 0-9-4-9-9v-2c0-5 4-9 9-9h482c5 0 9 4 9 9z"
                style={{
                    fill: 'url(#SVGID_1_)',
                }}
            />
            {progress === '50%' ? (
                <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1={2574.4}
                    y1={1045}
                    x2={3067}
                    y2={1045}
                    gradientTransform="matrix(-1 0 0 1 3069.91 -1000)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: '#fff',
                        }}
                    />
                    <stop
                        offset={0.05}
                        style={{
                            stopColor: '#f5fbfd',
                        }}
                    />
                    <stop
                        offset={0.14}
                        style={{
                            stopColor: '#d9eff8',
                        }}
                    />
                    <stop
                        offset={0.25}
                        style={{
                            stopColor: '#adddf0',
                        }}
                    />
                    <stop
                        offset={0.39}
                        style={{
                            stopColor: '#6fc3e5',
                        }}
                    />
                    <stop
                        offset={0.54}
                        style={{
                            stopColor: '#21a2d6',
                        }}
                    />
                    <stop
                        offset={0.54}
                        style={{
                            stopColor: '#20a2d6',
                        }}
                    />
                    <stop
                        offset={0.68}
                        style={{
                            stopColor: '#1e70a5',
                        }}
                    />
                    <stop
                        offset={0.82}
                        style={{
                            stopColor: '#1d457b',
                        }}
                    />
                    <stop
                        offset={0.93}
                        style={{
                            stopColor: '#1c2a61',
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: '#1c2057',
                        }}
                    />
                </linearGradient>
            ) : progress === '100%' ? (
                <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1={2574.4}
                    y1={1045}
                    x2={3067}
                    y2={1045}
                    gradientTransform="matrix(-1 0 0 1 3069.91 -1000)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: '#16f7b4',
                        }}
                    />
                    <stop
                        offset={0.05}
                        style={{
                            stopColor: '#16f7b4',
                        }}
                    />
                    <stop
                        offset={0.14}
                        style={{
                            stopColor: '#16f7b4',
                        }}
                    />
                    <stop
                        offset={0.28}
                        style={{
                            stopColor: '#16f7b4',
                        }}
                    />
                    <stop
                        offset={0.39}
                        style={{
                            stopColor: '#6fc3e5',
                        }}
                    />
                    <stop
                        offset={0.54}
                        style={{
                            stopColor: '#21a2d6',
                        }}
                    />
                    <stop
                        offset={0.54}
                        style={{
                            stopColor: '#20a2d6',
                        }}
                    />
                    <stop
                        offset={0.68}
                        style={{
                            stopColor: '#1e70a5',
                        }}
                    />
                    <stop
                        offset={0.82}
                        style={{
                            stopColor: '#1d457b',
                        }}
                    />
                    <stop
                        offset={0.93}
                        style={{
                            stopColor: '#1c2a61',
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: '#1c2057',
                        }}
                    />
                </linearGradient>
            ) : (
                <linearGradient
                    id="SVGID_2_"
                    gradientUnits="userSpaceOnUse"
                    x1={2574.4}
                    y1={1045}
                    x2={3067}
                    y2={1045}
                    gradientTransform="matrix(-1 0 0 1 3069.91 -1000)"
                >
                    <stop
                        offset={0}
                        style={{
                            stopColor: '#fff',
                        }}
                    />
                    <stop
                        offset={0.15}
                        style={{
                            stopColor: '#f5fbfd',
                        }}
                    />
                    <stop
                        offset={0.44}
                        style={{
                            stopColor: '#d9eff8',
                        }}
                    />
                    <stop
                        offset={0.65}
                        style={{
                            stopColor: '#adddf0',
                        }}
                    />
                    <stop
                        offset={0.79}
                        style={{
                            stopColor: '#6fc3e5',
                        }}
                    />
                    <stop
                        offset={0.94}
                        style={{
                            stopColor: '#21a2d6',
                        }}
                    />
                    <stop
                        offset={1}
                        style={{
                            stopColor: '#20a2d6',
                        }}
                    />
                </linearGradient>
            )}
            <path
                d="M485.8 51.8c5.8 0 10.5-3 10.5-6.8s-4.7-6.8-10.5-6.8H14.2c-5.8 0-10.5 3-10.5 6.8s4.7 6.8 10.5 6.8h471.6z"
                style={{
                    fill: 'url(#SVGID_2_)',
                }}
            />
            <path
                d="M14.2 52.1c-5.8 0-10.5-3-10.5-6.8s4.7-6.8 10.5-6.8h471.7c5.8 0 10.5 3 10.5 6.8s-4.7 6.8-10.5 6.8H14.2z"
                style={{
                    fill: 'none',
                    stroke: '#fff',
                    strokeWidth: 1.19,
                    strokeMiterlimit: 10,
                }}
            />
            <g id="Calque_2-2">
                <g id="Calque_1-2">
                    <path
                        d="M257.2 19.5c1.9-2.1 3-4.8 3-7.6.3-5.7-4.2-10.6-10-10.9s-10.6 4.2-10.9 10v.9c0 2.8 1.1 5.5 3 7.6-6.2 3.1-10.1 9.6-10.1 16.5h35c.1-7-3.8-13.4-10-16.5z"
                        style={{
                            fill: '#21bbee',
                        }}
                    />
                    <linearGradient
                        id="SVGID_3_"
                        gradientUnits="userSpaceOnUse"
                        x1={231.2}
                        y1={-3818.493}
                        x2={268.202}
                        y2={-3818.493}
                        gradientTransform="matrix(1 0 0 -1 0 -3800)"
                    >
                        <stop
                            offset={0}
                            style={{
                                stopColor: '#1c2057',
                            }}
                        />
                        <stop
                            offset={1}
                            style={{
                                stopColor: '#17243e',
                            }}
                        />
                    </linearGradient>
                    <path
                        d="M257.2 19.5c1.9-2.1 3-4.8 3-7.6.3-5.7-4.2-10.6-10-10.9s-10.6 4.2-10.9 10v.9c0 2.8 1.1 5.5 3 7.6-6.2 3.1-10.1 9.6-10.1 16.5h35c.1-7-3.8-13.4-10-16.5z"
                        style={{
                            fill: 'none',
                            stroke: 'url(#SVGID_3_)',
                            strokeWidth: 2,
                            strokeMiterlimit: 10,
                        }}
                    />
                </g>
            </g>
            <g id="Calque_2-3">
                <g id="Calque_1-2-2">
                    <path
                        className="st7"
                        style={{ fill: userThree }}
                        d="M477.5 19.5c1.9-2.1 3-4.8 3-7.6.3-5.7-4.2-10.6-10-10.9-5.7-.3-10.6 4.2-10.9 10v.9c0 2.8 1.1 5.5 3 7.6-6.2 3.2-10.1 9.6-10 16.5h35c.1-7-3.8-13.4-10.1-16.5z"
                    />
                    <linearGradient
                        id="SVGID_4_"
                        gradientUnits="userSpaceOnUse"
                        x1={451.56}
                        y1={-3818.493}
                        x2={488.564}
                        y2={-3818.493}
                        gradientTransform="matrix(1 0 0 -1 0 -3800)"
                    >
                        <stop
                            offset={0}
                            style={{
                                stopColor: '#1c2057',
                            }}
                        />
                        <stop
                            offset={1}
                            style={{
                                stopColor: '#17243e',
                            }}
                        />
                    </linearGradient>
                    <path
                        d="M477.5 19.5c1.9-2.1 3-4.8 3-7.6.3-5.7-4.2-10.6-10-10.9-5.7-.3-10.6 4.2-10.9 10v.9c0 2.8 1.1 5.5 3 7.6-6.2 3.2-10.1 9.6-10 16.5h35c.1-7-3.8-13.4-10.1-16.5z"
                        style={{
                            fill: 'none',
                            stroke: 'url(#SVGID_4_)',
                            strokeWidth: 2,
                            strokeMiterlimit: 10,
                        }}
                    />
                </g>
            </g>
            <g id="Calque_2-4">
                <g id="Calque_1-2-3">
                    <path
                        d="M37.7 19.5c1.9-2.1 3-4.8 3-7.6C40.8 6 36.2 1.1 30.3 1h-.1c-5.9.1-10.5 5-10.4 10.9 0 2.8 1.1 5.5 3 7.6C16.6 22.6 12.7 29 12.7 36h35c.1-7-3.8-13.4-10-16.5z"
                        style={{
                            fill: '#1c2057',
                        }}
                    />
                    <linearGradient
                        id="SVGID_5_"
                        gradientUnits="userSpaceOnUse"
                        x1={11.7}
                        y1={-3818.5}
                        x2={48.702}
                        y2={-3818.5}
                        gradientTransform="matrix(1 0 0 -1 0 -3800)"
                    >
                        <stop
                            offset={0}
                            style={{
                                stopColor: '#1c2057',
                            }}
                        />
                        <stop
                            offset={1}
                            style={{
                                stopColor: '#17243e',
                            }}
                        />
                    </linearGradient>
                    <path
                        d="M37.7 19.5c1.9-2.1 3-4.8 3-7.6C40.8 6 36.2 1.1 30.3 1h-.1c-5.9.1-10.5 5-10.4 10.9 0 2.8 1.1 5.5 3 7.6C16.6 22.6 12.7 29 12.7 36h35c.1-7-3.8-13.4-10-16.5z"
                        style={{
                            fill: 'none',
                            stroke: 'url(#SVGID_5_)',
                            strokeWidth: 2,
                            strokeMiterlimit: 10,
                        }}
                    />
                </g>
            </g>
            <circle className="st7" cx={cx} cy={45.1} r={9} />
            <circle
                className="st7"
                cx={cx}
                cy={45.1}
                r={6.3}
                style={{
                    fill: 'url(#SVGID_6_)',
                }}
            />
            <linearGradient
                id="SVGID_6_"
                gradientUnits="userSpaceOnUse"
                x1={243.23}
                y1={1045.149}
                x2={255.93}
                y2={1045.149}
                gradientTransform="translate(0 -1000)"
            >
                <stop
                    offset={0}
                    style={{
                        stopColor: '#1c2057',
                    }}
                />
                <stop
                    offset={1}
                    style={{
                        stopColor: '#17243e',
                    }}
                />
            </linearGradient>
            <use
                xlinkHref="#Nouveau_symbole"
                width={43.1}
                height={15.6}
                x={-21.6}
                y={-7.8}
                transform="matrix(1 0 0 -1 250.413 71.322)"
                style={{
                    overflow: 'visible',
                    transform: progress !== '50%' && 'matrix(1, 0, 0, -1, 250.413, 71.322) scale(0.8)',
                    stroke: progress !== '50%' && '#17243f',
                    strokeWidth: progress !== '50%' && '0%',
                }}
            />
            <use
                xlinkHref="#Nouveau_symbole_2"
                width={24.4}
                height={13.2}
                x={-12.2}
                y={-6.6}
                transform="matrix(1 0 0 -1 27.715 70.125)"
                style={{
                    overflow: 'visible',
                    transform: progress === '0%' && 'matrix(1, 0, 0, -1, 27.715, 70.125) scale(1.2)',
                    stroke: progress === '0%' && '#1f93b2',
                    strokeWidth: progress === '0%' && '3%',
                }}
            />
            <use
                xlinkHref="#Nouveau_symbole_1"
                width={41.8}
                height={13.1}
                x={-20.9}
                y={-6.5}
                transform="matrix(1 0 0 -1 470.275 70.213)"
                style={{
                    overflow: 'visible',
                    transform: progress === '100%' && 'matrix(1, 0, 0, -1, 470.275, 70.213) scale(1.2)',
                    stroke: progress === '100%' && '#16f7b4',
                    strokeWidth: progress === '100%' && '4%',
                }}
            />
        </svg>
    );
};

export default SvgMatchGauge;
