import React from 'react';
import styles from './cardvalues.module.scss';

const CardValues = ({ title, logo, bgColor, filter = true }) => {
    const BgColor = bgColor === 'blue' ? styles.blue : '';
    const filterImg = filter ? styles.filter : '';
    return (
        <div className={`${styles.card} ${BgColor}`}>
            <div className={styles.logo}>
                <img src={logo} alt="" className={filterImg} />
            </div>
            <div className={styles.title}>
                <h3>{title}</h3>
            </div>
        </div>
    );
};

export default CardValues;
