import { useEffect, useState } from 'react';
import { getMissionsFromObjective } from '../../libs/gamification/missions';
import { useAuth } from '../useAuth';
import { useClient } from '../useClient';

export const useUserMissions = () => {
    const { user } = useAuth();
    const { client } = useClient();

    const [userMissions, setUserMissions] = useState([]);
    const [userMissionsLoaded, setUserMissionsLoaded] = useState(false);

    useEffect(() => {
        if (user?.gamification?.mainObjective === undefined) return;
        const getMissions = async () => {
            let isMounted = true;
            if (!user) return;

            try {
                const [missionsFromObjective, userMissions] = await Promise.all([
                    client.GET(`/public/missions?objective=${user?.gamification?.mainObjective}`),
                    client.GET(`/usermissions/me`),
                ]);
                const missions = getMissionsFromObjective(missionsFromObjective, userMissions);
                if (isMounted) {
                    setUserMissions(missions);
                    setUserMissionsLoaded(true);
                }
            } catch (err) {
                console.log(err);
                setUserMissionsLoaded(true);
            }

            return () => {
                isMounted = false;
            };
        };

        getMissions();
    }, [user, client]);

    return { userMissions, userMissionsLoaded };
};
