import React from 'react';
import styles from './cardbgbottom.module.scss';

const CardBgBottom = ({
    children,
    color,
    soon = false,
    contentRow = false,
    border = false,
    scale = false,
    animation = null,
    className = '',
}) => {
    const Color = color === 'blue' ? styles.blue : '';
    const Soon = soon ? styles.soon : '';
    const row = contentRow ? styles.row : '';

    let classAnimation;
    switch (animation) {
        case 'hoverBlue':
            classAnimation = styles.hoverBlue;
            break;

        default:
            break;
    }

    return (
        <section
            className={`${styles.card} ${Color} ${Soon} ${border ? styles.border : ''} ${
                scale ? styles.scale : ''
            } ${classAnimation} ${className}`}
        >
            <div className={`${styles.content} ${row}`}>{children}</div>
            <div className={styles.bg}></div>
        </section>
    );
};

export default CardBgBottom;
