import React from 'react';
import styles from './SelectModal.module.scss';

const SelectModal = ({ show, onClose, children, sx = {} }) => {
    if (!show) return null;
    return (
        <>
            <div className={styles.backdrop} style={{ display: show && 'block' }} onClick={onClose}></div>
            <div className={styles.modal} style={{ display: show && 'flex', ...sx }}>
                {children}
            </div>
        </>
    );
};

export default SelectModal;
