import React from 'react';
import styles from './ArrowIndicator.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';

const ArrowIndicator = ({ active, sx = {} }) => {
    return (
        <div className={styles.arrowContainer} style={{ ...sx }}>
            <i className={active ? fontAwesome.angleTop : fontAwesome.angleBottom}></i>
        </div>
    );
};

export default ArrowIndicator;
