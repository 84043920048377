import { Navigate, Outlet } from 'react-router-dom';
import { ClientProvider } from '../context/ClientProvider';
import { useAuth } from '../hooks/useAuth';

export const ProtectedLayout = () => {
    const { user } = useAuth();

    if (!user) {
        return <Navigate to="/" />;
    }

    return (
        <ClientProvider>
            <Outlet />
        </ClientProvider>
    );
};
