import React, { useState } from 'react';
import styles from './MeetingPage.module.scss';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import BtnBack from '../../../Components/Buttons/BtnBack/BtnBack';
import { appLinks } from '../../../data/enums/appLinks';
import TitleBgCard from '../../../Components/Cards/TitleBgCard/TitleBgCard';
import MeetingCard from '../../../Components/Cards/MeetingCard/MeetingCard';
import GenericBtn from '../../../Components/Buttons/GenericBtn/GenericBtn';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { appImageAssets } from '../../../data/enums/appImageAssets';

//TMP
const MEETINGS = [
    {
        _id: 1,
        name: 'Mission locale',
        city: 'Aubervillers',
        age: '18-25ans',
        description:
            "Notre établissement vous accueille pour vous informer, vous orientez professionnellement et vous accompagner en cas de difficultés d'insertion pour construire avec vous un projet professionnel et de vie.",
        selected: false,
        request: false,
    },
    {
        _id: 2,
        name: 'Mission locale',
        city: 'Aubervillers',
        age: '18-25ans',
        description:
            "Notre établissement vous accueille pour vous informer, vous orientez professionnellement et vous accompagner en cas de difficultés d'insertion pour construire avec vous un projet professionnel et de vie.",
        selected: false,
        request: false,
    },
    {
        _id: 3,
        name: 'Mission locale',
        city: 'Aubervillers',
        age: '18-25ans',
        description:
            "Notre établissement vous accueille pour vous informer, vous orientez professionnellement et vous accompagner en cas de difficultés d'insertion pour construire avec vous un projet professionnel et de vie.",
        selected: false,
        request: true,
        transmitted: false,
    },
    {
        _id: 4,
        name: 'Mission locale',
        city: 'Aubervillers',
        age: '18-25ans',
        description:
            "Notre établissement vous accueille pour vous informer, vous orientez professionnellement et vous accompagner en cas de difficultés d'insertion pour construire avec vous un projet professionnel et de vie.",
        selected: false,
        request: true,
        transmitted: true,
    },
];

const MeetingPage = () => {
    const [selectedMeet, setSelectedMeet] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const selectedMeeting = (meetId) => {
        const filterMeet = MEETINGS.filter((el) => {
            if (el._id === meetId) {
                el.selected = !el.selected;
                return el;
            } else {
                return (el.selected = false);
            }
        });
        if (filterMeet) setSelectedMeet(filterMeet);
    };

    const closeModal = () => {
        setOpenModal(false);
    };

    return (
        <div className={styles.page}>
            <HeaderWithNavBar />
            <BtnBack link={appLinks.catalog.index} />
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.banner}>
                        <div className={styles.bannerInfo}>
                            <h2>Prenez rendez-vous avec un conseiller</h2>
                            <p>Pour vous faire orienter et consulter vos demandes</p>
                        </div>
                        <div className={styles.logo}>
                            <img src={appImageAssets.icon.meeting} alt="Icone prise de rendez-vous" />
                        </div>
                    </div>
                    <TitleBgCard title={'Prendre Rendez-vous'}>
                        <div className={styles.meetContainer}>
                            <p>
                                Sélectionnez l'établissement auprès duquel vous souhaitez faire une demande de
                                rendez-vous
                            </p>
                            <div className={styles.meet}>
                                {MEETINGS.map((meet) => {
                                    if (!meet.request) {
                                        return (
                                            <MeetingCard
                                                key={meet._id}
                                                meet={meet}
                                                selectedFc={selectedMeeting}
                                                openModal={openModal}
                                                closeModal={closeModal}
                                            />
                                        );
                                    } else {
                                        return <div key={meet._id}></div>;
                                    }
                                })}
                            </div>
                            <GenericBtn
                                textContent={'Valider votre RDV'}
                                hasArrow={false}
                                disable={!selectedMeet[0]?.selected}
                                onClickFc={() => setOpenModal(true)}
                            />
                        </div>
                    </TitleBgCard>
                </div>
                <div className={styles.col}>
                    <TitleBgCard title={'Mes demandes de rendez-vous'} icon={fontAwesome.clock}>
                        <div className={styles.meetRequest}>
                            {MEETINGS.map((meet) => {
                                if (meet.request) {
                                    return <MeetingCard key={meet._id} meet={meet} selectedFc={selectedMeeting} />;
                                } else {
                                    return <div key={meet._id}></div>;
                                }
                            })}
                        </div>
                    </TitleBgCard>
                </div>
            </div>
        </div>
    );
};

export default MeetingPage;
