import React from 'react';
import styles from './purchasereward.module.scss';
import { openWindow } from '../../utils/openWindow';
import { appImageAssets } from '../../data/enums/appImageAssets';
import { config } from '../../config';

const PurchaseReward = ({ reward, transaction }) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const dateValide = (date) => {
        if (date) {
            return new Date(date).toLocaleDateString('fr-FR', options);
        } else {
            return 'Disponible indéfiniment';
        }
    };

    if (!reward) {
        return null;
    }

    return (
        <div className={styles.container}>
            <div
                className={styles.img}
                onClick={() => reward.partner?.website && openWindow(reward.partner?.website)}
                style={{ cursor: reward.partner?.website ? 'pointer' : 'auto' }}
            >
                <img
                    src={
                        reward.image
                            ? `${config.visionstrustUrl}/images/uploads/rewards/${reward.image}`
                            : appImageAssets.logo.gift
                    }
                    alt={reward.name}
                    style={{ objectFit: !reward.image && 'contain' }}
                />
            </div>
            <div className={styles.content}>
                <div className={styles.state}>
                    {/* <p>{reward.state ? reward.state : 'En cours de traitement'}</p> */}
                    <p>Acheté le {new Date(transaction?.createdAt).toLocaleString().split(',')[0]}</p>
                </div>
                <div className={styles.description}>
                    <p>{reward.title}</p>
                    {reward.promoCode && (
                        <p>
                            Code : <span style={{ fontWeight: 'bold' }}>{reward.promoCode}</span>
                        </p>
                    )}
                    {reward.endAt && <p>valide jusqu'au {dateValide(reward.endAt)}</p>}
                </div>
            </div>
        </div>
    );
};

export default PurchaseReward;
