import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appLinks } from '../../data/enums/appLinks';

const SSOExpiredPage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <h2>Session expirée</h2>
            <div>Votre session a expiré, veuillez vous reconnecter.</div>
            <button onClick={() => navigate(appLinks.public.landing)}>Retourner à l'accueil</button>
        </div>
    );
};

export default SSOExpiredPage;
