import React, { useEffect, useRef } from 'react';
import styles from './rewardCard.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';
import { config } from '../../../config';
import { ButtonContainer } from '../../Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../Buttons/Button/Button';
import { Icon } from '../../Icons/Icon/Icon';

const RewardCard = ({ reward, updateCardWidth, icon, mainTag, link }) => {
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current && updateCardWidth !== undefined) {
            const width =
                ref.current.offsetWidth +
                parseInt(window.getComputedStyle(ref.current).getPropertyValue('margin').split('p')[0]) * 2;
            updateCardWidth(width);
        }
    }, [updateCardWidth, ref]);

    return (
        <section ref={ref} className={styles.card}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.img}>
                        <img
                            src={
                                reward.image ? `${config.ImageRewardBaseUrl}/${reward.image}` : appImageAssets.logo.gift
                            }
                            alt={`Illustration ${reward.title || 'cadeau'}`}
                            style={{ objectFit: !reward.image && 'contain' }}
                        />
                    </div>
                    {reward.logo && (
                        <div className={styles.logo}>
                            <img src={reward.logo} alt="" />
                        </div>
                    )}
                </div>
                <div className={styles.content}>
                    <div className={styles.tags}>
                        <div className={styles.mainTag}>
                            <img src={icon} alt="" />
                            <span>{mainTag}</span>
                        </div>
                        <div className={styles.visionsStars}>
                            <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                            <span>- {reward.price}</span>
                        </div>
                    </div>
                    <p className={styles.description}>{reward.title}</p>
                </div>
            </div>
            <div className={styles.bottomCard}>
                <ButtonContainer>
                    <Button endIcon={<Icon icon="arrow" />} navigate={link}>
                        En savoir +
                    </Button>
                </ButtonContainer>
                <div className={styles.bg}></div>
            </div>
        </section>
    );
};

export default RewardCard;
