import React from 'react';
import styles from './GenericCard.module.scss';

/**
 *
 * @param {object} props
 * @param {string} props.className set additional className into the parent component
 * @param {"gradient" | true} props.bar set background bar at the bottom of card default "blue"
 * @returns
 */
const GenericCard = ({ className, bar = '', children }) => {
    const setBarVariant = () => {
        switch (bar) {
            case 'gradient':
                return styles.barGradient;

            default:
                return null;
        }
    };
    return (
        <section className={`${styles.card} ${className}`} style={{ paddingBottom: bar && 45 }}>
            {children}
            {bar && <div className={`${styles.bar} ${setBarVariant()}`}></div>}
        </section>
    );
};

export default GenericCard;
