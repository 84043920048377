export const addEventLog = async (userId, activity, status, resource = '') => {
    try {
        await fetch('/api/eventlogs', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                userId,
                activity,
                status,
                resource,
            }),
        });
        return true;
    } catch (err) {
        console.log(err.message);
        return false;
    }
};

export const setEventLogResource = (resource) => {
    sessionStorage.setItem('eventLogResource', resource);
};

export const setEventLogTarget = (target) => {
    sessionStorage.setItem('eventLogTarget', target);
};

export const storeEventLog = (userId, activity, status, resource = '') => {
    const eventLogs = JSON.parse(sessionStorage.getItem('eventLogArray')) || [];
    eventLogs.push({ userId, activity, status, timestamp: Date.now(), resource });
    sessionStorage.setItem('eventLogArray', JSON.stringify(eventLogs));
};

export const sendStoredEventLogs = () => {
    const eventLogs = JSON.parse(sessionStorage.getItem('eventLogArray'));
    if (!eventLogs || !eventLogs.length) return;

    fetch('/api/eventlogs/batch', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            eventLogs,
        }),
    });

    sessionStorage.setItem('eventLogArray', JSON.stringify([]));
};

export const eventLogNavigation = {
    0: 'page_missions',
    1: 'page_catalog',
    2: 'page_visions_stars',
    3: 'page_orientation_report',
    // 5: 'logout',
};
