import ZalandoTMP from '../../img/zalando_img.jpg';
import ZalandoLOGOTMP from '../../img/Zalando-Logo.png';
import illustrationTMP from '../../img/multi-ethnic-young-people-using-smartphone-and-tablet-computers.jpg';

import { appImageAssets } from '../enums/appImageAssets';

export const categoryEvents = [
    {
        category: 'Online',
        icon: appImageAssets.icon.online,
    },
    {
        category: 'Porte ouverte',
        icon: appImageAssets.icon.openHouse,
    },
    {
        category: 'Salons <br/> Conférences',
        icon: appImageAssets.icon.conference,
    },
    {
        category: 'Ateliers',
        icon: appImageAssets.icon.workshop,
    },
];

export const eventsTMP = {
    online: [
        {
            _id: '1',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '2',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '2',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
    openHouse: [
        {
            _id: '3',
            category: 'Porte ouverte',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '4',
            category: 'Porte ouverte',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
    conference: [
        {
            _id: '5',
            category: 'Salons Conférences',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '6',
            category: 'Salons Conférences',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
    workshop: [
        {
            _id: '7',
            category: 'Ateliers',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '8',
            category: 'Ateliers',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
    top: [
        {
            _id: '1',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: '',
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '2',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: '',
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '8',
            category: 'atelier',
            date: '20 mai / 10h à 13h',
            image: ZalandoTMP,
            logo: '',
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
    nearYou: [
        {
            _id: '10',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: illustrationTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '11',
            category: 'online',
            date: '20 mai / 10h à 13h',
            image: illustrationTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
        {
            _id: '12',
            category: 'Atelier',
            date: '20 mai / 10h à 13h',
            image: illustrationTMP,
            logo: ZalandoLOGOTMP,
            title: 'Zalando',
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris in nunc egestas, cursus ligula non, tempor turpis. Phasellus nec maximus felis, ac lobortis felis. Nunc eget nisi nec justo convallis tincidunt. Sed auctor ligula eget gravida condimentum. Curabitur feugiat, nibh non commodo scelerisque, augue orci tristique ante',
            link: '',
        },
    ],
};
