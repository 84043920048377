import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './navbarlandingpage.module.scss';

const NavBarLandingPage = ({ logo, size, link, children }) => {
    const navigate = useNavigate();
    const resize = size === 'large' ? styles.large : '';

    const menuRef = useRef(null);
    const navRef = useRef(null);

    const toggleClass = () => {
        if (!menuRef) return;
        if (!navRef) return;
        menuRef.current.classList.toggle(styles.active);
        navRef.current.classList.toggle(styles.navActive);
    };

    return (
        <nav>
            <div className={styles.logo}>
                <img src={logo} alt="Logo VisionsGalaxy" className={resize} onClick={() => navigate(link)} />
            </div>
            {children !== undefined && (
                <button ref={menuRef} className={styles.menuToggler} onClick={toggleClass}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            )}
            <div className={styles.navbarMenu} ref={navRef}>
                {children}
            </div>
        </nav>
    );
};

export default NavBarLandingPage;
