import { config } from '../config';

const SSO_URL = config.ssoUrl + '/sso/interapp';

/**
 * Generates the full sso login or registration url
 * @param {"signup" | "login"} type The type of the url
 * @returns The url
 */
export const getSSOURL = (type) => {
    const loc = window.location;
    const serviceURL = `${loc.protocol}//${loc.host}/authentication/sso`;
    return `${SSO_URL}/${type}?serviceURL=${serviceURL}`;
};
