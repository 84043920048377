import React from 'react';
import styles from './SummaryVisionsStars.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';

const SummaryVisionsStars = ({ visionsStars, less = false, remove = false, sx = {}, className }) => {
    if (remove) return null;

    return (
        <div className={`${styles.reward} ${className}`} style={{ ...sx }}>
            <div className={styles.visionStars} style={{ display: remove && 'none' }}>
                <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                <span>
                    {less ? '-' : '+'}
                    {visionsStars || 0}
                </span>
            </div>
        </div>
    );
};

export default SummaryVisionsStars;
