export const stepConfig = {
    0: {
        service: 'Jobready',
        title: 'Tu vas découvrir la plateforme Jobready !',
        main: "Révèle la force qui est en toi !<br/><br/>Pour avoir confiance en toi et décrocher ton prochain job, il faut connaître toutes tes forces ! 💪 </br><br/> Jobready, c'est la seule plateforme qui transforme toutes tes expériences, professionnelles, personnelles, bénévoles, scolaires... en compétences claires et visibles pour les recruteurs.",
        steps: [
            {
                summary: 'Raconte tes expériences à Georges et transforme les en compétences.',
                content:
                    'Échange avec le chatbot pour découvrir tes compétences douces à travers tes expériences personnelles et professionnelles.',
            },
            {
                summary:
                    "Ton auto-évaluation et l'appréciation de tes collègues ou référents t'aident à connaître tes soft skills et à mieux les défendre en entretien.",
                content: 'Évalue tes compétences douces.',
            },
            {
                summary: 'Même sans diplôme pour les valider, sois-en sûr, tes soft skills méritent d’être connues',
                content: 'Découvre tes compétences traduites en badge !',
            },
            {
                summary:
                    '60% des recruteurs déclarent que les compétences transversales sont plus importantes que les compétences techniques.',
                content: 'Partage tes badges vers Orientoi.',
            },
        ],
        code: 'VISIONS',
        api: false,
        startUrl: 'https://www.jobready.fr/connexion',
    },
    1: {
        service: 'Orientoi',
        title: 'Tu vas découvrir la plateforme Orientoi !',
        code: 'VISIONS',
        main: "Orientoi, l'outil d'aide à l'orientation scolaire et professionnelle. Et si on s'orientait par le jeu ?<br/>Orientoi a développé une solution ludique qui permet à chacun de construire son projet d'orientation de manière ludique, positive et amusante.",
        steps: [
            {
                summary: 'Etre soi-même par le jeu',
                content: 'Joue à des mini-jeux ludiques',
            },
            {
                summary: 'Apprendre à se connaître, une première étape',
                content: 'Visualise ton profil de personnalité',
            },
            {
                summary: "D'incroyables découvertes métiers et secteurs",
                content: 'Découvre les propositions professionnelles générées pour toi',
            },
            {
                summary: 'Ces choix sont pris en compte tout au long du parcours',
                content: 'Positionne-toi sur ces propositions',
            },
            {
                summary: 'Vous partagerez et passerez à la suite du bilan !',
                content: 'Et partage tes talents et tes positionnements vers Skilit !',
            },
        ],
        api: false,
        startUrl: 'https://app.orientoi.fr/#/sso/visions-login',
    },
    // ! TMP HACK FOR CONSISTENCY IN THE BO
    2: {
        service: 'Orientoi',
        title: 'Tu vas découvrir la plateforme Orientoi !',
        code: 'VISIONS',
        main: "Orientoi, l'outil d'aide à l'orientation scolaire et professionnelle. Et si on s'orientait par le jeu ?<br/>Orientoi a développé une solution ludique qui permet à chacun de construire son projet d'orientation de manière ludique, positive et amusante.",
        steps: [
            {
                summary: 'Etre soi-même par le jeu',
                content: 'Joue à des mini-jeux ludiques',
            },
            {
                summary: 'Apprendre à se connaître, une première étape',
                content: 'Visualise ton profil de personnalité',
            },
            {
                summary: "D'incroyables découvertes métiers et secteurs",
                content: 'Découvre les propositions professionnelles générées pour toi',
            },
            {
                summary: 'Ces choix sont pris en compte tout au long du parcours',
                content: 'Positionne-toi sur ces propositions',
            },
            {
                summary: 'Vous partagerez et passerez à la suite du bilan !',
                content: 'Et partage tes talents et tes positionnements vers Skilit !',
            },
        ],
        api: false,
        startUrl: 'https://app.orientoi.fr/#/sso/visions-login',
    },
    3: {
        service: 'Inokufu',
        title: 'Vous avez partagé vos données avec Inokufu',
        main: "La recherche de ressources pédagogiques sur le web est pleine de défis et de distractions. Il n'est pas toujours facile de repérer les fake news et les contenus publicitaires. <br/> Notre technologie vous fournit uniquement des ressources d'apprentissage vérifiées et sûres. <br>Actuellement, nous vous donnons accès à de nombreux formats dont des vidéos, des moocs, des podcasts, des livres, des ebooks, des formations, etc, tout ça dans votre boîte mail.",
        steps: [
            {
                summary: "Recevez  les meilleures ressources pédagogiques en fonction de vos objectifs d'apprentissage",
                content: 'À la fin du bilan, n’oubliez pas d’aller vérifier votre boite mail.',
            },
        ],
        api: true,
    },
};
