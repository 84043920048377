import React from 'react';
import styles from './linkcardcontainer.module.scss';
import LinkCard from './LinkCard';

// TMP DATA
import { linkCards } from '../../../data/project/LinkCards';

const LinkCardContainer = ({ whichCardsArray, largeCard = false }) => {
    const displayLargeCard = largeCard ? styles.large : '';

    // HARDCODED during prep of meeting setup page
    const filterCards = () => {
        return whichCardsArray.filter((el) => el !== 'meet');
    };

    return (
        <div className={`${styles.container} ${displayLargeCard}`}>
            {filterCards().map((cards, index) => {
                const card = linkCards[cards];
                let bgColor;
                let available;
                if (cards === 'game' || cards === 'cozy') {
                    bgColor = 'blue';
                }
                return <LinkCard card={card} key={index} bgColor={bgColor} available={available} largeCard={true} />;
            })}
        </div>
    );
};

export default LinkCardContainer;
