import React, { useEffect, useState } from 'react';

import styles from './navbar.module.scss';

const FullScreenMenu = ({ opened = false, closeMenuFc, children }) => {
    const [closing, setClosing] = useState(false);

    const closeMenu = () => {
        setClosing(true);
    };

    useEffect(() => {
        if (!closing) return;
        setTimeout(() => {
            setClosing(false);
            closeMenuFc();
        }, 200);
    }, [closing, closeMenuFc]);

    if (!opened) {
        return <></>;
    }

    return (
        <div className={styles.backdropFullScreenMenu} onClick={() => closeMenu()}>
            <div
                className={`${styles.fullScreenMenu} ${closing ? styles.closeFullScreenMenu : ''}`}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <span onClick={() => closeMenu()}>X</span>
                <ul>{children}</ul>
            </div>
        </div>
    );
};

export default FullScreenMenu;
