import React from 'react';
import styles from './cardsharerecap.module.scss';
import { config } from '../../../config';

const BilanCards = ({ service }) => {
    // Hardcoded to not show COZY
    if (service?.name?.toLowerCase().trim() === 'cozy.io') {
        return null;
    }

    if (!service?.datatypes?.length) return null;

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img src={`${config.logoBaseUrl}/${service.logo}`} alt="" />
                </div>
                <p>{service.name}</p>
            </div>
            {service?.datatypes?.map((datatype, index) => (
                <div className={styles.list} key={index}>
                    <div className={`${styles.circle} ${styles.on}`}></div>
                    <p>{datatype.name}</p>
                </div>
            ))}
        </div>
    );
};

export default BilanCards;
