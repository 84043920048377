import { appImageAssets } from './appImageAssets';

export const appInfoRewardByType = (type) => {
    return INFO_TYPE_REWARD[type] ? INFO_TYPE_REWARD[type] : INFO_TYPE_REWARD[0];
};

export const INFO_TYPE_REWARD = {
    0: {
        icon: appImageAssets.logo.gift,
        mainTag: 'Cadeau',
        transactionType: 'GiftCard',
    },
    1: {
        icon: appImageAssets.icon.promoCode,
        mainTag: 'Code Promo',
        transactionType: 'PromoCode',
    },
    2: {
        icon: appImageAssets.icon.gift,
        mainTag: 'Chèques Cadeaux',
        transactionType: 'GiftCard',
    },
    3: {
        icon: appImageAssets.icon.briefcase,
        mainTag: 'Produit ou Service',
        transactionType: 'ProductOrService',
    },
    4: {
        icon: appImageAssets.icon.ticket,
        mainTag: 'Billet Offert',
        transactionType: 'GiftCard',
    },
    5: {
        icon: appImageAssets.icon.box,
        mainTag: 'Box Cadeau',
        transactionType: 'GiftCard',
    },
    6: {
        icon: '',
        mainTag: 'Coaching',
        transactionType: 'Coaching',
    },
};
