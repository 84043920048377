import React from 'react';

// Components
import GenericBtn from '../../Buttons/GenericBtn/GenericBtn';

// Style & Img/Icon
import styles from './sharebilanexperiences.module.scss';
import { fontAwesome } from '../../../data/enums/fontAwesome';

const ShareBilanExperiences = ({ locked = true, onClickFc, experienceName }) => {
    return (
        <div className={`${styles.card} ${!locked && styles.unLock}`}>
            <div className={styles.logo}>
                <i className={`${locked ? fontAwesome.lock : fontAwesome.unLock}`}></i>
            </div>
            <div className={styles.content}>
                {locked ? (
                    <>
                        {experienceName === "Bilan d'Orientation" ? (
                            <p>
                                Terminez la plateforme <span>Orientoi</span> pour pouvoir{' '}
                                <span>débloquer votre {experienceName}</span>
                            </p>
                        ) : (
                            <p>
                                Terminez les <span>trois plateformes</span> qui composent l'expérience pour pouvoir{' '}
                                <span>débloquer votre {experienceName}</span>
                            </p>
                        )}
                    </>
                ) : (
                    <p>
                        Vous pouvez générer votre <span>{experienceName}</span>, en cliquant sur le bouton{' '}
                        <span>ci-dessous</span>
                    </p>
                )}
            </div>
            <div className={styles.btn}>
                <GenericBtn
                    textContent={`Générer mon ${experienceName}`}
                    bgColor={'gradient'}
                    disable={locked}
                    onClickFc={onClickFc}
                />
            </div>
        </div>
    );
};

export default ShareBilanExperiences;
