import { useEffect } from 'react';
import Loader from '../../Components/Loaders/Loader/Loader';

import { config } from '../../config';
import { useAuth } from '../../hooks/useAuth';
import { useClient } from '../../hooks/useClient';

const SSOLogout = () => {
    const { logout } = useAuth();
    const { client } = useClient();

    const redirectURL = `${window.location.protocol}//${window.location.host}`;
    const url = `${config.ssoUrl}/sso/logout?redirectURL=${redirectURL}`;

    useEffect(() => {
        const doLogout = async () => {
            await client.DELETE('/auth/logout');
            logout(false);
            window.location.href = url;
        };

        doLogout();
    }, [logout, url, client]);

    return <Loader text="Logging out" />;
};

export default SSOLogout;
