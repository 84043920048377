import React from 'react';
import logoV from '../../img/LOGO-VISIONSGALAXY.svg';
import styles from './headervisionsgalaxy.module.scss';

const HeaderVisionsGalaxy = () => {
    return (
        <header className={styles.header}>
            <img className={styles.logo} src={logoV} alt="" />
        </header>
    );
};

export default HeaderVisionsGalaxy;
