import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useQuery from '../hooks/useQuery';
import Loader from '../Components/Loaders/Loader/Loader';

export const ContextSetter = ({ type = 'local' }) => {
    const [ready, setReady] = useState(false);
    const { params } = useQuery();

    useEffect(() => {
        for (const [k, v] of params.entries()) {
            const decoded = decodeURIComponent(v);
            if (type === 'local') {
                localStorage.setItem(k, decoded);
            } else {
                sessionStorage.setItem(k, decoded);
            }
        }

        setReady(true);
    }, [params, type]);

    if (!ready) return <Loader text="Configuration de votre expérience" />;
    else return <Outlet />;
};
