import React from 'react';
import styles from './imageBorder.module.scss';

const ImageBorder = ({ image = '', alt = '', borderColor, size = 'medium', space = 0, className }) => {
    const displayBorderColor = () => {
        switch (borderColor) {
            case 'blue':
                return styles.blue;
            default:
                return;
        }
    };

    const displaySize = () => {
        switch (size) {
            case 'large':
                return styles.large;
            case 'medium':
                return styles.medium;
            case 'small':
                return styles.small;
            case 'xs':
                return styles.xs;
            default:
                break;
        }
    };

    return (
        <div className={`${styles.containerImage} ${displayBorderColor()} ${displaySize()} ${className}`} style={{ padding: space }}>
            <img src={image} alt={alt} />
        </div>
    );
};

export default ImageBorder;
