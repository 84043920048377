import React, { useEffect, useState } from 'react';
import HeaderWithNavBar from '../../../Components/Header/HeaderWithNavBar';
import styles from './MissionPresetPage.module.scss';
import GenericCard from '../../../Components/Cards/GenericCard/GenericCard';
import MissionCard from '../../../Components/Cards/MissionCard/MissionCard';
import TaskPresetCard from '../../../Components/Cards/TasksCard/TaskPresetCard/TaskPresetCard';
import TaskPresetIframe from '../../../Components/Cards/TasksCard/TaskPresetIframe/TaskPresetIframe';
import { fontAwesome } from '../../../data/enums/fontAwesome';
import { useUserMission } from '../../../hooks/useUserMission';
import { OBJECTIVE_RESEARCH_TYPE } from '../../../data/enums/objectiveResearchType';
import { useClient } from '../../../hooks/useClient';
import { ConsentModal } from '../../../Components/Modal/ConsentModal/ConsentModal';
import GenericModal from '../../../Components/Modal/GenericModal/GenericModal';
import { serviceIds } from '../../../data/enums/services';
import { useAuth } from '../../../hooks/useAuth';
import { getPresetFromAgeGroupAndSituation } from '../../../data/missions/presetMissionConfig';
import Loader from '../../../Components/Loaders/Loader/Loader';
import { ButtonContainer } from '../../../Components/Containers/ButtonContainer/ButtonContainer';
import { Button } from '../../../Components/Buttons/Button/Button';
import { Icon } from '../../../Components/Icons/Icon/Icon';

const MissionPresetPage = ({ situation, presetCompleted }) => {
    const { client } = useClient();
    const { user } = useAuth();

    const localConfig = localStorage.getItem('headai');
    const namespaceContext = localConfig ? JSON.parse(localConfig)?.namespace : null;
    const age_group = user?.profile?.age_group;

    // HARDCODE TMP DEMOS MATTHIAS
    const isDemo = localStorage.getItem('isDemo');

    const presetConfig = getPresetFromAgeGroupAndSituation(
        situation,
        age_group,
        isDemo === 'true' ? 'demo' : namespaceContext,
    );

    const { userMission: userMissionOne } = useUserMission({ missionId: presetConfig?.missions[0] });
    const { userMission: userMissionTwo } = useUserMission({ missionId: presetConfig?.missions[1] });
    const { userMission: userMissionThree } = useUserMission({ missionId: presetConfig?.missions[2] });

    const [allMissions, setAllMissions] = useState([]);
    const [ready, setReady] = useState(false);

    const [precisionLevelStep, setPrecisionLevelStep] = useState(0);
    const [missionsPresetInProgress, setMissionsPresetInProgress] = useState(0);
    const [missionsPresetCompleted, setMissionsPresetCompleted] = useState(0);
    const [showIframe, setShowIframe] = useState(false);

    const [consentModalVisible, setConsentModalVisible] = useState(false);
    const [consentDataLoading, setConsentDataLoading] = useState(false);
    const [importIdentifier, setImportIdentifier] = useState(null);

    const [errorMessage, setErrorMessage] = useState(false);

    const [tab, setTab] = useState(0);

    const updateErrorMessage = (state) => {
        setErrorMessage(state);
    };

    const updatePrecisionLevel = (level) => {
        setPrecisionLevelStep(level);
    };

    const handleConsent = async () => {
        setConsentModalVisible(false);
        setConsentDataLoading(true);
    };

    const handleFakeConsent = async () => {
        setConsentModalVisible(false);
        setShowIframe(true);
    };

    /**
     * Handles the logic after the consents fired received responses from visionstrust
     */
    const handleDataExchangeComplete = async (responses) => {
        console.log(
            `Successful consent data exchanges: ${responses?.map((res) => res?.success)?.length}/${responses?.length}`,
        );

        // Retrieve the ID of the user for HeadAI to then use in the iframe
        const id = await client.GET('/identifiers/service/' + serviceIds.headai);

        if (id?._id) {
            setImportIdentifier(id);
            setTimeout(() => {
                setShowIframe(true);
                setConsentDataLoading(false);
            }, 3000);
        }
    };

    const getIframeURL = () => {
        const ID = importIdentifier?._id || 'test';
        const localHeadAISettings = localStorage.getItem('headai');
        const namespace = localHeadAISettings ? JSON.parse(localHeadAISettings)?.namespace || 'onisep' : 'onisep';
        const jobs_source = localHeadAISettings ? JSON.parse(localHeadAISettings)?.jobs_source || null : null;
        const jobOrCourse = situation === OBJECTIVE_RESEARCH_TYPE.job ? 'jobs' : 'courses';
        const city = user?.experiencesInfo?.city;
        let url = `https://visions.headai.com/${jobOrCourse}?user=${ID}&namespace=${namespace}`;

        if (jobs_source) url += `&jobs_source=${jobs_source}`;
        if (city) url += `&area=${city}`;

        return url;
    };

    useEffect(() => {
        if (!userMissionOne || !userMissionTwo) return;
        if (situation === OBJECTIVE_RESEARCH_TYPE.student && !userMissionThree) return;

        if (situation === OBJECTIVE_RESEARCH_TYPE.student) {
            const arr = [userMissionOne, userMissionTwo, userMissionThree];
            setAllMissions(arr);
            setReady(true);
            return;
        }

        const arr = [userMissionOne, userMissionTwo];
        setAllMissions(arr);
        setReady(true);
    }, [userMissionOne, userMissionTwo, userMissionThree, situation]);

    useEffect(() => {
        if (!allMissions) return;

        let incrementor = 0;
        let inProgess = 0;

        allMissions?.forEach((mission) => {
            mission?.completion.forEach((task) => {
                if (task?.completed) {
                    incrementor++;
                    return;
                } else {
                    inProgess++;
                    return;
                }
            });
        });

        setMissionsPresetCompleted(incrementor);
        setMissionsPresetInProgress(inProgess);
    }, [allMissions]);

    const isMissionCompleted = (mission) => {
        if (!mission) return false;
        return mission?.completion?.every((o) => o.completed === true);
    };

    return (
        <>
            <HeaderWithNavBar tabActive={1} />
            {consentModalVisible ? (
                <GenericModal
                    open={consentModalVisible}
                    closeModal={() => {
                        setConsentModalVisible(false);
                    }}
                >
                    <ConsentModal
                        preConfiguredProviders={presetConfig.services}
                        allowSelection={true}
                        dataUserId={serviceIds.headai}
                        onConsent={handleFakeConsent} // WARNING FAKE CONSENT LOGIC
                        onDataExchangeComplete={handleDataExchangeComplete}
                    />
                </GenericModal>
            ) : null}
            <div className={styles.page}>
                <GenericCard className={`${styles.informationCard}`}>
                    {situation === OBJECTIVE_RESEARCH_TYPE.job ? (
                        <h2>Matchez avec les offres d'emploi qui vous correspondent</h2>
                    ) : situation === OBJECTIVE_RESEARCH_TYPE.formation && namespaceContext === 'gen' ? (
                        <h2>
                            Matchez avec des formations dans le numérique issues du catalogue de la Grande École du
                            Numérique qui vous correspondent
                        </h2>
                    ) : (
                        <h2>Matchez avec les formations qui vous correspondent</h2>
                    )}
                    {namespaceContext === 'gen' && (
                        <p style={{ marginTop: 10, textAlign: 'center' }}>
                            Accomplissez la mission 1 en utilisant l’outil Orientoi et en jouant aux 3 jeux disponibles
                            sur la plateforme. Ensuite, retournez sur VisionsGalaxy, et cliquez sur « voir mes matchs »
                            dans la mission 2 pour visualiser des formations dans le numérique adaptées à votre profil.
                        </p>
                    )}
                </GenericCard>

                {namespaceContext === 'gen' && (
                    <p className={styles.infoPopup}>Veuillez vérifier si votre navigateur ne bloque pas les pop-ups</p>
                )}

                <div className={styles.btnGroupContainer}>
                    <p className={styles.informationFilter}>
                        Filtrer vos missions selon votre progession dans votre parcours
                    </p>
                    <div className={styles.btnGroup}>
                        <Button
                            startIcon={<i className={fontAwesome.clockRotate}></i>}
                            variant={tab === 0 ? 'square' : 'squareWhite'}
                            onclick={() => setTab(0)}
                        >
                            En cours
                            <span className={styles.indicatorFilter}>({missionsPresetInProgress})</span>
                        </Button>
                        <Button
                            startIcon={<i className={fontAwesome.check}></i>}
                            variant={tab === 1 ? 'square' : 'squareWhite'}
                            onclick={() => setTab(1)}
                        >
                            Terminé
                            <span className={styles.indicatorFilter}>({missionsPresetCompleted})</span>
                        </Button>
                    </div>
                </div>

                {tab === 0 && (
                    <>
                        {ready &&
                            allMissions.length &&
                            allMissions.map((mission, index) => (
                                <React.Fragment key={index}>
                                    {!isMissionCompleted(mission) ? (
                                        <MissionCard
                                            key={index}
                                            mission={mission?.mission}
                                            numberMission={index + 1}
                                            preset
                                        >
                                            {mission?.completion?.map((completionData, index) => {
                                                if (completionData?.task?.action === 'iframe' && showIframe) {
                                                    return (
                                                        <TaskPresetIframe
                                                            key={index}
                                                            servicesCompleted={precisionLevelStep}
                                                            userPartnerIdentifier={importIdentifier?._id}
                                                            iframe={getIframeURL()}
                                                            loading={consentDataLoading}
                                                            situation={situation}
                                                            onRefreshResults={async () => {
                                                                setConsentModalVisible(true);
                                                            }}
                                                        ></TaskPresetIframe>
                                                    );
                                                } else {
                                                    if (
                                                        consentDataLoading &&
                                                        completionData?.task?.action === 'iframe'
                                                    ) {
                                                        return (
                                                            <Loader
                                                                key={'loader' + index}
                                                                text="Récupération de vos matchings"
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <TaskPresetCard
                                                                key={index}
                                                                missionTask={completionData?.task}
                                                                missionTaskCompleted={completionData.completed}
                                                                missionTaskNumber={
                                                                    mission?.completion?.length > 1 ? index + 1 : ''
                                                                }
                                                                servicesCompleted={missionsPresetCompleted}
                                                                updatePrecisionLevel={updatePrecisionLevel}
                                                                updateErrorMessage={updateErrorMessage}
                                                                errorMessage={errorMessage}
                                                                onClick={() => {
                                                                    if (missionsPresetCompleted === 0) {
                                                                        setMissionsPresetCompleted((prev) => prev + 1);
                                                                        return;
                                                                    }

                                                                    if (completionData?.task?.action === 'iframe') {
                                                                        setMissionsPresetCompleted((prev) => {
                                                                            if (prev === 2) return prev;
                                                                            else return prev + 1;
                                                                        });
                                                                        setConsentModalVisible(true);
                                                                    }
                                                                }}
                                                                actions={completionData?.actions || []}
                                                            ></TaskPresetCard>
                                                        );
                                                    }
                                                }
                                            })}
                                        </MissionCard>
                                    ) : null}
                                </React.Fragment>
                            ))}
                    </>
                )}
                {tab === 1 && (
                    <>
                        {ready &&
                            allMissions.length &&
                            allMissions.map((mission, index) => (
                                <React.Fragment key={index}>
                                    {isMissionCompleted(mission) ? (
                                        <MissionCard
                                            key={index}
                                            mission={mission?.mission}
                                            numberMission={index + 1}
                                            preset
                                            id={`mission-${index}`}
                                        >
                                            {mission?.completion?.map((completionData, index) => {
                                                if (completionData?.task?.action === 'iframe' && showIframe) {
                                                    return (
                                                        <TaskPresetIframe
                                                            key={index}
                                                            servicesCompleted={precisionLevelStep}
                                                            userPartnerIdentifier={importIdentifier?._id}
                                                            iframe={getIframeURL()}
                                                            loading={consentDataLoading}
                                                            situation={situation}
                                                            onRefreshResults={async () => {
                                                                setConsentModalVisible(true);
                                                            }}
                                                        ></TaskPresetIframe>
                                                    );
                                                } else {
                                                    if (
                                                        consentDataLoading &&
                                                        completionData?.task?.action === 'iframe'
                                                    ) {
                                                        return (
                                                            <Loader
                                                                key={'loader' + index}
                                                                text="Récupération de vos matchings"
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <TaskPresetCard
                                                                key={index}
                                                                missionTask={completionData?.task}
                                                                missionTaskCompleted={completionData.completed}
                                                                missionTaskNumber={index + 1}
                                                                servicesCompleted={missionsPresetCompleted}
                                                                updatePrecisionLevel={updatePrecisionLevel}
                                                                updateErrorMessage={updateErrorMessage}
                                                                errorMessage={errorMessage}
                                                                onClick={() => {
                                                                    if (missionsPresetCompleted === 0) {
                                                                        setMissionsPresetCompleted((prev) => prev + 1);
                                                                        return;
                                                                    }

                                                                    if (completionData?.task?.action === 'iframe') {
                                                                        setMissionsPresetCompleted((prev) => {
                                                                            if (prev === 2) return prev;
                                                                            else return prev + 1;
                                                                        });
                                                                        setConsentModalVisible(true);
                                                                    }
                                                                }}
                                                                actions={completionData?.actions || []}
                                                            ></TaskPresetCard>
                                                        );
                                                    }
                                                }
                                            })}
                                        </MissionCard>
                                    ) : null}
                                </React.Fragment>
                            ))}
                    </>
                )}

                <GenericCard className={`${styles.validation} ${missionsPresetCompleted === 2 ? styles.unLock : ''}`}>
                    <i className={missionsPresetCompleted === 2 ? fontAwesome.unLock : fontAwesome.lock}></i>
                    <p>
                        Terminez <span>la mission 1 et 2</span> pour pouvoir <span>débloquer d'autres objectifs</span>{' '}
                        et missions
                    </p>
                    <ButtonContainer>
                        <Button
                            disabled={missionsPresetCompleted < 2}
                            variant="gradient"
                            size="main"
                            endIcon={<Icon icon="arrow" />}
                            onclick={() => presetCompleted()}
                        >
                            Choisir un nouvel Objectif
                        </Button>
                    </ButtonContainer>
                </GenericCard>
            </div>
        </>
    );
};

export default MissionPresetPage;
