class SessionError extends Error {
    constructor(message = '') {
        super(message);
        this.isSessionError = true;
    }
}

/**
 * Parses the path to fit the correct format
 * @param {string} path API endpoint
 * @returns parsed path
 */
const parsePath = (path) => {
    if (path.startsWith('/api')) return path.substring(4);
    return path;
};

/**
 * Handles errors fired from the VisionsGalaxy server
 * @param {Error | SessionError} err Error fired after processing response codes
 * @returns null or redirect
 * @todo This whole serverCall client should be in a context so that logout from authProvider can be used
 */
const handleServerErrors = (err) => {
    if (err.isSessionError) {
        window.location.href = '/';
    } else {
        console.error(err);
        return null;
    }
};

/**
 * Helper for server calls that are rerouted to VisionsTrust
 * contains helper methods for basic CRUD operations
 */
export const serverCall = {
    /**
     * Makes a request to the API
     * @param {'GET' | 'POST' | 'PUT' | 'DELETE' } method request method
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns response data
     */
    request: async (method, path, payload = {}) => {
        const res = await fetch(`/api${parsePath(path)}`, {
            method: method,
            body: method === 'GET' ? undefined : JSON.stringify(payload),
            headers: {
                'content-type': 'application/json',
            },
        });

        if (res.status !== 200) throw new Error(`Failed to ${method} to server`);
        if (res.status === 440) throw new SessionError('Session expired');

        const data = await res.json();

        return data;
    },

    /**
     * GET request to the server
     * @param {string} path path after api proxy
     * @returns {any} response data
     */
    GET: async (path) => {
        try {
            return await serverCall.request('GET', path);
        } catch (err) {
            return handleServerErrors(err);
        }
    },

    /**
     * POST request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    POST: async (path, payload = {}) => {
        try {
            return await serverCall.request('POST', path, payload);
        } catch (err) {
            return handleServerErrors(err);
        }
    },

    /**
     * PUT request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    PUT: async (path, payload = {}) => {
        try {
            return await serverCall.request('PUT', path, payload);
        } catch (err) {
            return handleServerErrors(err);
        }
    },

    /**
     * DELETE request to the server
     * @param {string} path path after api proxy
     * @param {any} payload request payload
     * @returns {any} response data
     */
    DELETE: async (path, payload = {}) => {
        try {
            return await serverCall.request('DELETE', path, payload);
        } catch (err) {
            return handleServerErrors(err);
        }
    },
};
