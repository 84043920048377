import React from 'react';

// Styles & Img/Icon
import styles from './globalerror.module.scss';
import { appImageAssets } from '../../../data/enums/appImageAssets';

const GlobalError = ({ flexDirection = 'column', sxImg = {} }) => {
    return (
        <div className={styles.main} style={{ flexDirection: flexDirection }}>
            <div className={styles.header} style={{ flexDirection: flexDirection }}>
                <h2>Oups ! une erreur est survenue</h2>
                <img src={appImageAssets.illustration.error} alt="" style={{ ...sxImg }} />
            </div>
        </div>
    );
};

export default GlobalError;
