import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './CarouselSlick.module.scss';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <div className={`${className} ${styles.arrowControl}`} style={{ right: 15 }} onClick={onClick} />;
}

function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <div className={`${className} ${styles.arrowControl}`} style={{ left: 15 }} onClick={onClick} />;
}

const CarouselSlick = ({
    dots = false,
    infinite = true,
    lazyLoad = true,
    speed = 500,
    slidesToShow = 1,
    slidesToScroll = 1,
    focusOnSelect = false,
    autoplay = false,
    centerMode = false,
    arrows = true,
    responsive = [],
    className = null,
    type = 1,
    children,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const MultipleItems = {
        dots: false,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        focusOnSelect: true,
        adaptiveHeight: true,
        centerMode: centerMode,
        className: styles.carousel,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        // beforeChange: (prev, next) => {
        //     // prev = la valeur de départ avant le changement
        //     // next = la valeur target en fonction des slides to scroll
        //     setCurrentSlide(currentSlide + 1);
        // },
        // appendDots: (dots) => (
        //     <div
        //         style={{
        //             position: 'initial',
        //             margin: '10px 0',
        //         }}
        //     >
        //         <ul style={{ margin: '0px' }}> {dots} </ul>
        //     </div>
        // ),
        // customPaging: (i) => {
        //     return <span className={i === currentSlide ? styles.indicatorSelected : styles.indicator}></span>;
        // },
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const SimpleSlider = {
        dots: true,
        infinite: true,
        lazyLoad: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: true,
        centerMode: centerMode,
        arrows: false,
        className: styles.carouselSimple,
        beforeChange: (prev, next) => {
            setCurrentSlide(next);
        },
        appendDots: (dots) => (
            <div
                style={{
                    position: 'initial',
                    margin: '10px 0',
                }}
            >
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </div>
        ),
        customPaging: (i) => {
            return <span className={i === currentSlide ? styles.indicatorSelected : styles.indicator}></span>;
        },
    };

    const CustomSlider = {
        dots,
        infinite,
        lazyLoad,
        speed,
        slidesToShow,
        slidesToScroll,
        focusOnSelect,
        autoplay,
        centerMode,
        arrows,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        className: className ? `${className}` : '',
        beforeChange: (prev, next) => {
            setCurrentSlide(next);
        },
        appendDots: (dots) => (
            <div
                style={{
                    position: 'initial',
                    margin: '10px 0',
                }}
            >
                <ul style={{ margin: '0px' }}> {dots} </ul>
            </div>
        ),
        customPaging: (i) => {
            return <span className={i === currentSlide ? styles.indicatorSelected : styles.indicator}></span>;
        },
        responsive,
    };

    const activeCarouselType = (type) => {
        switch (type) {
            case 1:
                return SimpleSlider;
            case 2:
                return MultipleItems;
            case 3:
                return CustomSlider;
            default:
                return SimpleSlider;
        }
    };

    return <Slider {...activeCarouselType(type)}>{children}</Slider>;
};

export default CarouselSlick;
