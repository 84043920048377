import React from 'react';
import styles from './experiencecardcontainer.module.scss';
import ExperienceCard from '../ExperienceCard/ExperienceCard';

// TMP DATA
import { experiences } from '../../../data/tmp/dataExperience';

const ExperienceCardContainer = ({ whichCardsArray }) => {
    return (
        <section className={styles.container}>
            {whichCardsArray.map((key, index) => {
                const card = experiences[key];
                return <ExperienceCard card={card} key={index} />;
            })}
        </section>
    );
};

export default ExperienceCardContainer;
