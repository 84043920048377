import React from 'react';
import { useNavigate } from 'react-router-dom';
import { storeEventLog } from '../../../utils/api/eventLogs';

import styles from './genericbtn.module.scss';

const defaultExtras = {
    status: 'completed',
    resource: '',
};

const GenericBtn = ({
    bgColor,
    arrowColor,
    hasArrow = true,
    borderLarge = false,
    textContent,
    capitalize = false,
    icon = 'fas fa-arrow-right',
    reverse = false,
    className,
    type,
    link,
    disable = false,
    animation = null,
    onClickFc,
    extras = {},
}) => {
    const userId = sessionStorage.getItem('userId');
    const navigate = useNavigate();

    // TODO Verify link definition for button enable/disable
    const callBackOrRedirect = async (e) => {
        if (userId && extras.activity)
            storeEventLog(
                userId,
                extras.activity,
                extras.status ? extras.status : defaultExtras.status,
                extras.resource ? extras.resource : defaultExtras.resource,
            );
        if (link) {
            if (onClickFc) await onClickFc(e);
            navigate(link);
        } else {
            if (onClickFc) await onClickFc(e);
        }
    };

    let classBg;
    switch (bgColor) {
        case 'basic':
            classBg = styles.basic;
            break;
        case 'smallBasic':
            classBg = styles.btnSmallBasic;
            break;
        case 'gradient':
            classBg = styles.gradient;
            break;
        case 'smallGradient':
            classBg = styles.btnSmallGradient;
            break;
        case 'white':
            classBg = styles.btnWhite;
            break;
        case 'minimalist':
            classBg = styles.minimalist;
            break;
        default:
            classBg = styles.basic;
            break;
    }

    let classAnimation;
    switch (animation) {
        case 'pulse':
            classAnimation = styles.pulse;
            break;
        default:
            break;
    }

    const displayBorder = borderLarge ? styles.borderLarge : '';
    const classArrow = arrowColor === 'gradient' ? styles.arrowGradient : styles.arrowBasic;
    const reverseContent = reverse ? styles.reverse : '';
    const disableBtn = disable ? styles.disable : '';
    const capitalizeText = capitalize ? styles.capitalize : '';

    return (
        <button
            className={`${classBg} ${displayBorder} ${reverseContent} ${disableBtn} ${classAnimation} ${className}`}
            type={type}
            onClick={(e) => callBackOrRedirect(e)}
        >
            <p className={`${styles.textContent} ${capitalizeText}`}>{textContent}</p>
            {hasArrow && (
                <div className={`${styles.circle} ${classArrow}`}>
                    <i className={icon}></i>
                </div>
            )}
        </button>
    );
};

export default GenericBtn;
