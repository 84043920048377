import React from 'react';
import styles from './allrewardsused.module.scss';

const AllRewardsUsed = ({ textContent }) => {
    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <i className="fa-solid fa-circle-info"></i>
            </div>
            <p>{textContent}</p>
        </div>
    );
};

export default AllRewardsUsed;
