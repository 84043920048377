import React from 'react';
import styles from './datanotfound.module.scss';

// IMG
import Image from '../../img/ILLUSTRATION PAGE AIDE.svg';

const DataNotFound = ({ text, maxHeightImg = 250 }) => {
    return (
        <div className={styles.container}>
            <div className={styles.img}>
                <img src={Image} alt="" style={{ maxHeight: maxHeightImg }} />
            </div>
            <div className={styles.text}>{text}</div>
        </div>
    );
};

export default DataNotFound;
