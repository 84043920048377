import { useEffect, useState } from 'react';
import { useClient } from './useClient';

/**
 * Fetches rewards for a user from VisionsGalaxy
 * @param {object} options
 * @param {boolean} options.sort If they should be sorted by price
 * @returns An array of 3 elements [error, loading, rewards]
 */
export const useRewards = ({ sort = true }) => {
    const [rewards, setRewards] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { client } = useClient();

    useEffect(() => {
        let isMounted = true;

        setLoading(true);

        const searchParams = new URLSearchParams({ sort: sort.toString() });

        const getServices = async () => {
            try {
                const response = await client.GET(`/rewards?${searchParams}`);
                if (!isMounted) return;

                if (!response) {
                    throw new Error('Could not get rewards')
                }

                setRewards(response);
            } catch (err) {
                setError(true);
                setRewards([]);
            } finally {
                setLoading(false);
            }
        };

        getServices();

        return () => {
            isMounted = false;
        };
    }, [client, sort]);

    return [error, loading, rewards];
};
