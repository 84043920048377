import React from 'react';
import styles from './servicecard.module.scss';

import { config } from '../../../config';
import { appLinks } from '../../../data/enums/appLinks';

import { appImageAssets } from '../../../data/enums/appImageAssets';
import ImageBorder from '../../Image/ImageBorder/ImageBorder';
import NoticePriceService from '../../Notices/NoticePriceService/NoticePriceService';
import { Button } from '../../Buttons/Button/Button';
import { Icon } from '../../Icons/Icon/Icon';

const ServiceCard = ({ service, bgGradient = false }) => {
    let gradient = '';

    const cardGradient = bgGradient ? styles.bgCardGradient : '';

    return (
        <section className={`${styles.card} ${gradient} ${cardGradient}`}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <ImageBorder image={`${config.logoBaseUrl}/${service.logo}`} alt={service.name} />
                    <div className={styles.headline}>
                        <div className={styles.title}>{service.name}</div>
                        <div className={styles.rewards}>
                            <img src={appImageAssets.icon.visionsStars} alt="Icone Visions Stars" />
                            <span>+{service?.galaxy?.user?.visionsStars}</span>
                        </div>
                    </div>
                </div>
                <div className={styles.content}>
                    <ul className={styles.tags}>
                        {service?.galaxy?.user?.tags.map((tag) => (
                            <li key={tag}>{tag}</li>
                        ))}
                    </ul>
                    <div className={styles.description}>
                        {service.galaxy.user.description ? service.galaxy.user.description : service.description}
                    </div>
                </div>
            </div>
            <div className={styles.btn}>
                {service?.galaxy?.user?.isFree === false && <NoticePriceService bgGradient={bgGradient} />}
                <Button endIcon={<Icon icon="arrow" />} navigate={`${appLinks.catalog.serviceDetails}/${service._id}`}>
                    En savoir +
                </Button>
            </div>
            <div className={styles.bg}></div>
        </section>
    );
};

export default ServiceCard;
